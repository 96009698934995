import { createSlice } from "@reduxjs/toolkit";
export const allShortlistRequestFilterSlice = createSlice({
  name: "allShortlistRequestPaginationFilter",
  initialState: {
    currentPage: 1,
    filters: {
      nameOrMobile: "",
      sortByskills: "",
      sortByActiveDate: "",
      shortlistStatus: "",
      interviewScheduled: "",
      hiredStatus: "",
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReduxFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setCurrentPage, setReduxFilters } =
  allShortlistRequestFilterSlice.actions;
export default allShortlistRequestFilterSlice.reducer;
