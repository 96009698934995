import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import performRequest from "../../../APIs/request";
import { useParams } from "react-router";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import ProfileCompare from "./profilesCompare";
import { Form, Formik } from "formik";
import CustomInput from "../../CustomComponent/custom_input";
import CustomSelect from "../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import { interviewScheduleInfo } from "../../../Schema Info/info";
import { InterviewSchema } from "../../../Schemas/Interview Schedule/interview_schedule_schema";
const CandidateCompare = () => {
  const [data, setData] = useState();
  const [interviewScheduleId, setInterviewScheduleId] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [backupProfiles, setBackupProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [interviewScheduled, setInterviewSchedule] = useState(
    interviewScheduleInfo
  );
  const { id } = useParams();
  const handleViewApplicantList = (id) => {
    performRequest(
      "GET",
      `/applyedcandidate/getallapplyedcandidatebyjobsid/${id}`
    )
      .then((res) => {
        if (res.status === 200) {
          let applyedCandidate = res.data?.data;
          let shorlistedCandidate = applyedCandidate.filter(
            (item) => item.shortlistByRole === "recruiter"
          );
          setApplicants(shorlistedCandidate);
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (id) {
      handleViewApplicantList(id);
    }
  }, [id, refresh]);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `jobs/getjobbyid/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setInterviewSchedule({
            address: res.data?.interviewAddress ||"",
            contactNumber: "",
            contactPersonName:res.data?.contactPersonName || "",
            contactPersonNumber:res.data?.contactPersonNumber || "",
            date: "",
            meetLink:res.data?.meetLink || "",
            modeofInterview: res.data?.modeofInterview || "",
            time: "",
          });
          setLoading(false);
        } else {
          Toast("error", "Server Error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (data) {
      performRequest(
        "POST",
        `/applyedcandidate/getcandidatesmatchestoskills/${id}`,
        {
          skills: data.skillsDetails,
        }
      )
        .then((res) => {
          if (res.status === 200) {
            setBackupProfiles(res.data);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [data]);

  const handelSubmit = (values, Actions) => {
  
    performRequest(
      "PATCH",
      `/applyedcandidate/updateapplyedcandidate/${interviewScheduleId}`,
      {
        interviewSchedule: values,
        hireStatus: "interview scheduled",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Interview schedule updated successfully");
          document.getElementById("closeButton-edit").click();
          document.getElementById("closeButton").click();
          setInterviewScheduleId("");
          setRefresh(!refresh);
          Actions.resetForm();
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                Shortlisted Profiles
              </h2>
              <p className="section-description mb-4 p-0">
                Compare your shortlisted profiles to get clearer overview.
              </p>
            </div>
          </div>
          <div className="row row-cards">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {applicants && applicants.length > 6 ? (
                    <ProfileCompare
                      data={applicants}
                      setInterviewScheduleId={setInterviewScheduleId}
                      setInterviewSchedule = {setInterviewSchedule}
                    />
                  ) : (
                    <>
                      <p>
                        Still Interview Going On!. Please wait for candidate
                        shortlist from below or shorlisted from{" "}
                        <a href="/job-board">Job Board</a>
                      </p>
                    </>
                  )}
                </div>

                <div className="my-2">
                  <h2 className="section-title section-title-lg m-0 p-0">
                    Backup Profiles
                  </h2>
                  <p className="section-description mb-4 p-0">
                    Profiles you did not shortlisted but still have high skill
                    relevency score.
                  </p>
                </div>
                <div className="row">
                  {backupProfiles && backupProfiles.length > 0 ? (
                    backupProfiles?.map((item, index) => {
                      return (
                        <div
                          className="col-lg-3 col-md-6 col-sm-12 mb-2"
                          key={index}
                        >
                          <div className="card  text-center">
                            <div className="text-center m-1">
                              <img
                                style={{
                                  borderRadius: "15%",
                                }}
                                width={"120px"}
                                src={
                                  item?.usermodel?.profilePicture
                                    ? item?.usermodel?.profilePicture
                                    : "https://in.ecscribe.com/assets/images/userlogo.png"
                                }
                                alt={
                                  item?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.name
                                }
                              />
                            </div>{" "}
                            <h3 className="card-title m-0 p-0">
                              Name: {item?.id}
                            </h3>
                            <h1 className="p-0 m-0 fs-3">
                              {
                                item?.usermodel?.resumemodel
                                  ?.personalDetails?.[0]?.name
                              }
                            </h1>
                            <p className="p-0 m-0">
                              {item?.usermodel?.resumemodel?.personalDetails?.map(
                                (ele, index) => {
                                  return (
                                    <span key={index}>
                                      {ele.city}, {ele.state}, {ele.country}
                                      {index > 0 && ", "}
                                    </span>
                                  );
                                }
                              )}
                            </p>
                            <p>
                              {item?.usermodel?.resumemodel?.skills?.map(
                                (ele) => {
                                  return ele.skill;
                                }
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <p>
                        Currently Not Available Contact to the Manager Assign to
                        you
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal interview scheduel */}
        <div
          className="modal modal-blur fade"
          id="modal-team"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Interview Schedule</h5>
                <button
                  id={"closeButton"}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Formik
                initialValues={interviewScheduled}
                validationSchema={InterviewSchema}
                onSubmit={handelSubmit}
              >
                {(props) => (
                  <Form>
                    <div className="modal-body">
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3  required">
                            Date of Interview:
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="date" type="date" />
                            {props.touched.date && props.errors.date && (
                              <p className="text-danger">{props.errors.date}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Interview Time:
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="time" type="time" />
                            {props.touched.time && props.errors.time && (
                              <p className="text-danger">{props.errors.time}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Contact Person Name:
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="contactPersonName" type="text" />
                            {props.touched.contactPersonName &&
                              props.errors.contactPersonName && (
                                <p className="text-danger">
                                  {props.errors.contactPersonName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Contact Person Number:
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="contactPersonNumber"
                              type="text"
                            />
                            {props.touched.contactPersonNumber &&
                              props.errors.contactPersonNumber && (
                                <p className="text-danger">
                                  {props.errors.contactPersonNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Interview Mode:
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="modeofInterview" type="text">
                              <option value="">select</option>
                              <option value="video interview">
                                Video Interview
                              </option>
                              <option value="telephonic interview">
                                Telephonic Interview
                              </option>
                              <option value="walk-in interview">
                                Walk-In Interview
                              </option>
                            </CustomSelect>
                            {props.touched.modeofInterview &&
                              props.errors.modeofInterview && (
                                <p className="text-danger">
                                  {props.errors.modeofInterview}
                                </p>
                              )}
                          </div>
                        </div>
                        {props?.values?.modeofInterview ===
                          "telephonic interview" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Contact Number:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="contactNumber" type="text" />
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        {props?.values?.modeofInterview ===
                          "walk-in interview" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Interview Address:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="address" type="text" />
                              {props.touched.address &&
                                props.errors.address && (
                                  <p className="text-danger">
                                    {props.errors.address}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        {props?.values?.modeofInterview ===
                          "video interview" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Meeting Link:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="meetLink" type="text" />
                              {props.touched.meetLink &&
                                props.errors.meetLink && (
                                  <p className="text-danger">
                                    {props.errors.meetLink}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn me-auto"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <FormSubmitButton buttonText="Create Schedule" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>


        {/* modal Edit interviewcshedule */}
        <div
          className="modal modal-blur fade"
          id="modal-edit"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Interview Schedule</h5>
                <button
                  id={"closeButton-edit"}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Formik
                enableReinitialize
                initialValues={interviewScheduled}
                validationSchema={InterviewSchema}
                onSubmit={handelSubmit}
              >
                {(props) => (
    
                  <Form>
                    <div className="modal-body">
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3  required">
                            Date of Interview:
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="date" type="date" />
                            {props.touched.date && props.errors.date && (
                              <p className="text-danger">{props.errors.date}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Interview Time:
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="time" type="time" />
                            {props.touched.time && props.errors.time && (
                              <p className="text-danger">{props.errors.time}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Contact Person Name:
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="contactPersonName" type="text" />
                            {props.touched.contactPersonName &&
                              props.errors.contactPersonName && (
                                <p className="text-danger">
                                  {props.errors.contactPersonName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Contact Person Number:
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="contactPersonNumber"
                              type="text"
                            />
                            {props.touched.contactPersonNumber &&
                              props.errors.contactPersonNumber && (
                                <p className="text-danger">
                                  {props.errors.contactPersonNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Interview Mode:
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="modeofInterview" type="text">
                              <option value="">select</option>
                              <option value="video interview">
                                Video Interview
                              </option>
                              <option value="telephonic interview">
                                Telephonic Interview
                              </option>
                              <option value="walk-in interview">
                                Walk-In Interview
                              </option>
                            </CustomSelect>
                            {props.touched.modeofInterview &&
                              props.errors.modeofInterview && (
                                <p className="text-danger">
                                  {props.errors.modeofInterview}
                                </p>
                              )}
                          </div>
                        </div>
                        {props?.values?.modeofInterview ===
                          "telephonic interview" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Contact Number:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="contactNumber" type="text" />
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        {props?.values?.modeofInterview ===
                          "walk-in interview" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Interview Address:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="address" type="text" />
                              {props.touched.address &&
                                props.errors.address && (
                                  <p className="text-danger">
                                    {props.errors.address}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        {props?.values?.modeofInterview ===
                          "video interview" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Meeting Link:
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="meetLink" type="text" />
                              {props.touched.meetLink &&
                                props.errors.meetLink && (
                                  <p className="text-danger">
                                    {props.errors.meetLink}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn me-auto"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <FormSubmitButton buttonText="Edit Schedule" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CandidateCompare;
