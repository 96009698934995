import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoadingPage = () => {
  return (
    <div
      style={{
        height: "85vh",
        display: "flex",
        margin: "auto",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default LoadingPage;
