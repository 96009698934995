
export const companyOnboardingInfo ={

    fullName:"",
    email:"",
    companyName:"",
    companyLogo:"",
    teamSize:"",
    isAgency:"false",
    companyAddress:"",
    designation:"",
    password:"",
    contactNumber:""

    
  }