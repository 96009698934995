import { axiosInstance } from "./axiosInstances";

async function performRequest(method, url, data = null, headers = {}) {
  try {
    let response;
    if (method === "GET") {
      response = await axiosInstance.get(url, { headers });
    } else if (method === "POST") {
      response = await axiosInstance.post(url, data, { headers });
    } else if (method === "PATCH") {
      response = await axiosInstance.patch(url, data, { headers });
    } else if (method === "DELETE") {
      response = await axiosInstance.delete(url, { headers });
    } else {
      throw new Error("Invalid HTTP method");
    }
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export default performRequest;
