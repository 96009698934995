import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    allNotifications:[], // Initialize with an empty array
  },
  reducers: {
    notification: (state, action) => {
      state.allNotifications = action.payload ;
    },
  
  },
});

export const {notification} = notificationSlice.actions;
export default notificationSlice.reducer;
