import { useField } from "formik";

const CustomTextArea = ({ type, ...props }) => {
  const [field] = useField(props);

  return (
    <>
      <textarea
        type={type}
        className="form-control"
        {...field}
        {...props}

        //   class="form-control"
          // data-bs-toggle="autosize"
        //   placeholder="Type something…"
      ></textarea>
    </>
  );
};
export default CustomTextArea;
