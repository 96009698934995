
import * as Yup from 'yup';

export const companyOnboardingSchema = Yup.object({
  fullName: Yup.string()
    .required('Name is required')
    .max(50, 'Name must be at most 50 characters'),
    email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
    password: Yup.string()
    .required('Passsword is required').min(8, "minimum 8 is required").max(16, "max 16 is required"),
    contactNumber: Yup.string()
    .min(10, 'Enter 10 Digit Mobile Number').max(10,'Enter 10 Digit Mobile Number')
    .required('Mobile Number  is required'),
  companyName: Yup.string()
    .required('Company Name is required')
    .max(100, 'Company Name must be at most 100 characters'),

  companyLogo: Yup.string().required('Image is required'),
  teamSize: Yup.string().required("Values is required"),
  isAgency: Yup.string()
    .required('Agency status is required'),
  companyAddress: Yup.string()
    .required('Company Address is required')
    .max(200, 'Company Address must be at most 200 characters'),
designation:Yup.string().required("designation is required"),
   
});
