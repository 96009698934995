import React from "react";
import hire48Logo from "../../../../certificates/hire48logo.png"
const PayslipTop = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
          alignItems: "center",
          width: "100%",
          padding: "0 7px",
        }}
      >
        <div
          style={{
            border: "none",
            textAlign: "center",
          }}
        >
          <div style={{ textAlign: "left" }}>
            <b
              style={{
                fontSize: "2em",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
             Hire48 Private Limited
            </b>
            <p
              className="text-muted"
              style={{
                textAlign: "left",
                fontSize: "15px",
                fontWeight: 500,
              }}
            >
              Plot No. 98, Degoan, Taluka Wai Satara Maharashtra 412803 India
            </p>
          </div>
        </div>
        <div
          style={{
            border: "none",
            textAlign: "center",
          }}
        >
          <img width={"100px"} src={hire48Logo} alt={hire48Logo} />
        </div>
      </div>
    </>
  );
};

export default PayslipTop;
