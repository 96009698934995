import * as yup from "yup";

export const BookDemoSchema = yup.object().shape({
  fullName: yup.string().required("Required"),
  companyEmail: yup.string().email("Invalid email format").required("Required"),
  companyName: yup.string().required("Required"),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits")
    .required("Required"),
  contactType: yup.string(),
  contactAs: yup.string(),
});
