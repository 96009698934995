import React, { useState, useEffect } from "react";
import { Toast } from "../CustomComponent/toast";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";
import LoadingPage from "../../Components/layouts/loading";
import { useNavigate } from "react-router";
import Pagination from "../../Components/layouts/pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setReduxFilters,
} from "../../Redux/Pagination & Filters/allCompaniesFiltersReducer";
import SimpleButton from "../../Components/layouts/simple_button";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import getFilteredURL from "../../Components/Functions/filters.getUrl";

function countPastDays(dateString) {
  if (dateString === null) {
    return <span className="badge bg-red-lt">Not active</span>;
  }
  const pastDate = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const timeDifference = today - pastDate;
  let daysPast = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysPast <= 0) {
    daysPast = 1;
  }
  if (daysPast === 1) {
    return <span className="badge bg-green-lt">Active Today</span>;
  }
  if (daysPast > 30) {
    return <span className="badge bg-red-lt">Not active</span>;
  }
  return (
    <span className="badge bg-yellow-lt">{`Active ${daysPast} days ago`}</span>
  );
}

function countActiveJobs(jobs = []) {
  let newJobs = jobs?.filter((el) => el.isActive) || [];
  return newJobs.length;
}
const ListedCompanies = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const paginationStore = useSelector(
    (store) => store.allCompaniesFilter || {}
  );
  const [page, setCurrPage] = useState(paginationStore.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState(paginationStore?.filters);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const getjobsandapplicants = () => {
      performRequest(
        "GET",
        getFilteredURL(
          "/employer/getallemployer",
          paginationStore.currentPage,
          paginationStore.filters
        )
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data?.data);
            setTotalPages(Math.ceil(res?.data?.totalData / 50));
            setLoading(false);
          } else {
            Toast("error", "Server Error");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("Error", err);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    };

    getjobsandapplicants();
  }, [paginationStore?.currentPage, refresh]);

  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-xl">
            {/* Card header welcome to onboarding */}

            <div className="col-12">
              <div className="card">
                <div className="accordion-body d-md-block">
                  <div className="row row deck m-3">
                    <h3>Filter result</h3>{" "}
                    <div className=" col-lg-3 col-12 mb-2">
                      <DynamicFilterInput
                        type="date"
                        label="Onboard end date:"
                        value={filters.onboardStartDate}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            onboardStartDate: e.target.value,
                          });
                          dispatch(
                            setReduxFilters({
                              ...filters,
                              onboardStartDate: e.target.value,
                            })
                          );
                        }}
                        placeholder="search here..."
                      />
                    </div>
                    <div className=" col-lg-3 col-12 mb-2">
                      <DynamicFilterInput
                        type="date"
                        label="Onboard end date:"
                        value={filters.onboardEndDate}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            onboardEndDate: e.target.value,
                          });
                          dispatch(
                            setReduxFilters({
                              ...filters,
                              onboardEndDate: e.target.value,
                            })
                          );
                        }}
                        placeholder="search here..."
                      />
                    </div>
                    <div className=" col-lg-3 col-12 mb-2">
                      <DynamicFilterInput
                        label="Search name /mobile:"
                        value={filters.nameOrMobile}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            nameOrMobile: e.target.value,
                          });
                          dispatch(
                            setReduxFilters({
                              ...filters,
                              nameOrMobile: e.target.value,
                            })
                          );
                        }}
                        placeholder="search here..."
                      />
                    </div>
                    <div className="col-lg-3 col-12 mb-2">
                      <DynamicFilterSelect
                        label="Sort by active jobs:"
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            sortByActiveJobs: e.target.value,
                          });
                          dispatch(
                            setReduxFilters({
                              ...filters,
                              sortByActiveJobs: e.target.value,
                            })
                          );
                        }}
                        value={filters.sortByActiveJobs}
                      >
                        <option value="">select all</option>
                        <option value="ASC">Low To High</option>
                        <option value="DESC">High To Low</option>
                      </DynamicFilterSelect>
                    </div>
                    <div className="col-lg-3 col-12 mb-2">
                      <DynamicFilterSelect
                        label="Sort by active date:"
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            sortByActiveDate: e.target.value,
                          });
                          dispatch(
                            setReduxFilters({
                              ...filters,
                              sortByActiveDate: e.target.value,
                            })
                          );
                        }}
                        value={filters.sortByActiveDate}
                      >
                        <option value="">select all</option>
                        <option value="ASC">Low To High</option>
                        <option value="DESC">High To Low</option>
                      </DynamicFilterSelect>
                    </div>
                    <div className="col-lg-3 col-12 mb-2">
                      <div className="row mt-2">
                        <div className="col-6">
                          <SimpleButton
                            onClick={() => {
                              dispatch(setCurrentPage(1));
                              setRefresh(!refresh);
                            }}
                            className="btn btn-primary w-100"
                            buttonText={"Apply Filters"}
                          />
                        </div>
                        <div className="col-6">
                          <SimpleButton
                            onClick={() => {
                              dispatch(
                                setReduxFilters({
                                  nameOrMobile: "",
                                  sortByActiveDate: "",
                                  sortByActiveJobs: "",
                                  onboardStartDate: "",
                                  onboardEndDate: "",
                                })
                              );
                              setFilters({
                                nameOrMobile: "",
                                sortByActiveDate: "",
                                sortByActiveJobs: "",
                                onboardStartDate: "",
                                onboardEndDate: "",
                              });

                              setRefresh(!refresh);
                            }}
                            className="btn btn-secondary w-100"
                            buttonText={"Reset Filters"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mb-0" />
                </div>
                <div className="table-responsive">
                  <table className="table table-vcenter table-mobile-md card-table">
                    <thead>
                      <tr>
                        <th>Company Details</th>
                        <th>Contact Details</th>
                        <th>Total Skills & Total Applications</th>
                        <th className="w-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.map((el, index) => {
                          return (
                            <tr key={index}>
                              <td data-label="Name">
                                <div className="d-flex py-1 align-items-center">
                                  <span className="avatar me-2">
                                    <img
                                      src={el.companyLogo}
                                      alt=""
                                      srcSet=""
                                    />
                                  </span>
                                  <div className="flex-fill">
                                    <div className="font-weight-medium">
                                      {el.companyName}
                                    </div>
                                    <div className="fs-5">
                                      {countPastDays(el.usermodel?.lastLogin)}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td data-label="Contact Details">
                                <div>{el.contactNumber}</div>
                                <div className="text-secondary">{el.email}</div>
                              </td>

                              <td data-label="Job Posts">
                                <div>
                                  Active Jobs:{" "}
                                  {el.jobsmodels?.length > 0
                                    ? countActiveJobs(el.jobsmodels)
                                    : el.jobsmodels?.length || 0}
                                </div>
                                <div className="text-secondary">
                                  Total Posts: {el.jobsmodels?.length || 0}
                                </div>
                              </td>
                              <td>
                                <div className="btn-list flex-nowrap">
                                  <div className="dropdown">
                                    <button
                                      className="btn dropdown-toggle align-text-top"
                                      data-bs-toggle="dropdown"
                                    >
                                      Actions
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <button
                                        onClick={() =>
                                          navigate(`/jobs-list/${el.id}`)
                                        }
                                        className="dropdown-item"
                                      >
                                        Job Posts
                                      </button>
                                      <button
                                        onClick={() =>
                                          navigate(`/company-details/${el.id}`)
                                        }
                                        className="dropdown-item"
                                      >
                                        Company Profile
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    pageDatalength={data?.length}
                    showPage={showPage}
                    setShowPage={setShowPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ListedCompanies;
