// Define the validation schema using Yup
import * as Yup from "yup";
export const personalDetailObjectSchema = Yup.object({
  name: Yup.string().required("Name  is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits")
    .min(10, "Enter 10 Digit Mobile Number")
    .max(10, "Enter 10 Digit Mobile Number")
    .required("Mobile Number  is required"),
  country: Yup.string(),

  state: Yup.string(),
  city: Yup.string(),
  workProfile: Yup.string(),
  profilePicture: Yup.string(),
});

export const personalDetailSchema = Yup.object({
  personalDetails: Yup.array().of(personalDetailObjectSchema),
});

export const professionalSummarySchema = Yup.object({
  description: Yup.string()
    .required()
    .min(150, "minimum 150")
    .max(300, "maximum 300"),
});
export const educationObjectSchema = Yup.object({
  educationSelect1: Yup.string().required("Please select the education"),
  startDate1: Yup.date().when("educationSelect1", {
    is: (val) => val === "No Formal Education",
    then: (s) => s,
    otherwise: (s) => s.required("Field is required"),
  }),

  endDate1: Yup.date().when("educationSelect1", {
    is: (val) => val === "No Formal Education",
    then: (s) => s,
    otherwise: (s) => s.required("End date is required"),
  }),
  university_1: Yup.string().when("educationSelect1", {
    is: (val) => val === "No Formal Education",
    then: (s) => s,
    otherwise: (s) => s.required("Please select university"),
  }),
  summary: Yup.string().when("educationSelect1", {
    is: (val) => val === "No Formal Education",
    then: (s) => s,
    otherwise: (s) => s.required("Summary is required"),
  }),
});

export const educationSchema = Yup.object({
  education: Yup.array().of(educationObjectSchema),
});

export const languageSchema = Yup.object().shape({
  languages: Yup.array().of(
    Yup.object().shape({
      languageSpeak: Yup.array()
        .of(Yup.string().required())
        .required("Required"),
      readLanguage: Yup.array()
        .of(Yup.string().required())
        .required("Required"),
      languageWrite: Yup.array()
        .of(Yup.string().required())
        .required("Required"),
    })
  ),
});

export const experienceObjectSchema = Yup.object({
  companyName: Yup.string().required("Company name is required"),
  employmentType: Yup.string().required("Employment type is required"),
  // exitDate: Yup.date().required('Exit date is required'),
  jobDescription: Yup.string().required("Job description is required"),
  joiningDate: Yup.date().required("Joining date is required"),
  location: Yup.string().required("Location is required"),
  locationType: Yup.string().required("Location type is required"),
  position: Yup.string().required("Position is required"),
});

export const experienceSchema = Yup.object({
  workExperience: Yup.array().of(experienceObjectSchema),
});

export const awardAndCertificateObjectSchema = Yup.object({
  awardAndCertificateName: Yup.string().required(
    "Award&Certificate name is required"
  ),
  givenBy: Yup.string().required("Given by is required"),
  acDescription: Yup.string()
    .min(100, "100 words")
    .required("Description is required"),
});

export const awardAndCertificateSchema = Yup.object({
  awardAndCertificate: Yup.array().of(awardAndCertificateObjectSchema),
});

export const hobbiesSchema = Yup.object({
  hobbies: Yup.string().required().min(3, "minimum 3").max(100, "maximum 100"),
});
export const additionalInfoSchema = Yup.object({
  additionalInformation: Yup.array().required("values are required"),
});
