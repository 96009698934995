import React from "react";
import { FaBolt, FaMapMarker, FaArrowAltCircleRight } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";
import { useNavigate } from "react-router";
const CandidateList = ({ candidates = [] }) => {
  const navigate = useNavigate();
  return (
    <>
      {candidates?.map((comp, i) => {
        return (
          <div key={i} className="card m-2 p-2">
            <div className="ribbon bg-teal">
              <FaBolt fontSize={"1em"} className="me-2" />
              IMMEDIATE JOINER
            </div>
            <div className="row">
              <div className="col-md-2 text-center">
                <div className="card">
                  <img src="https://i.pinimg.com/280x280_RS/96/44/96/9644965f1f7cbcff611256a4226e22d5.jpg" />
                </div>
                <div className="card bg-primary-lt mt-1">
                  <p className="m-0 p-0">SKILL SCORE</p>
                  <h3 className="m-0 p-0">90%</h3>
                </div>
              </div>
              <div className="col-10">
                <h3 className="m-0 p-0">{comp?.jobRole}</h3>
                <p className="m-0 p-0">
                  <strong>{comp?.personalDetails?.[0]?.name},</strong>
                  <strong> {comp?.personalDetails?.[0]?.workProfile}</strong>
                  <br />
                  {comp?.education?.map((data, i) => {
                    return (
                      <span
                        key={i}
                        className="badge badge-outline text-teal me-1"
                      >
                        {data?.educationSelect1}
                      </span>
                    );
                  })}
                </p>

                <div className="row my-2">
                  <div className="col-12">
                    <p className="m-0 p-0">Skills:</p>
                    {comp?.skills?.map((data, i) => {
                      return (
                        <div
                          key={i}
                          className="avatar avatar-md me-1"
                          style={{ backgroundImage: `url(${data?.icon})` }}
                        ></div>
                      );
                    })}
                  </div>
                </div>
                <hr className="m-0 p-0" />
                <div className="row mt-2">
                  <div className="col-9">
                    <p className="text-secondary m-0 p-0">
                      <FaMapMarker fontSize={"1em"} className="me-2" />
                      {comp?.personalDetails?.[0]?.city},{" "}
                      {comp?.personalDetails?.[0]?.state},{" "}
                      {comp?.personalDetails?.[0]?.country}
                    </p>
                  </div>
                  <div className="col-md-3  text-md-end">
                    <button
                      className="btn btn-primary mt-lg-0 mt-3 mt-md-0"
                      onClick={() => navigate(`/view-jobs/${comp.id}`)}
                    >
                      <FaArrowAltCircleRight
                        fontSize={"1em"}
                        className="me-2"
                      />
                      View Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CandidateList;
