import React from "react";

const DynamicFilterSelect = ({ label, ...props }) => {
  return (
    <div className="col-md">
      {label}

      <select className="form-control form-control-sm" {...props} />
    </div>
  );
};

export default DynamicFilterSelect;
