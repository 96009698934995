import React, { useEffect, useState } from "react";
import performRequest from "../../APIs/request";
import Footer from "../../Components/Footer/footer";
import { extractDate } from "../../Components/Functions/date.fun";

const BookDemoList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    performRequest("GET", "/bookdemo/allbookdemos")
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header">
            <h2>Booked Demo List</h2>
          </div>
          <div className="page-body">
            <div class="card">
              <div class="table-responsive">
                <table class="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Date of creation</th>
                      <th>Full name</th>
                      <th>Company name</th>
                      <th>company contact</th>
                      <th>company email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{extractDate(item.createdAt)}</td>
                          <td>{item.fullName || "N/A"}</td>
                          <td>{item.companyName || "N/A"}</td>
                          <td>{item.contactNumber || "N/A"}</td>
                          <td>{item.companyEmail || "N/A"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default BookDemoList;
