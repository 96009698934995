import React, { useEffect, useState } from "react";
import performRequest from "../../APIs/request";
import { useParams } from "react-router";
import PageNotFound from "../PageNotFound/page_not_found";
import Footer from "../../Components/Footer/footer";

const Companydetails = () => {
  const [data, setData] = useState({});
  const [pageNotFound, setPageNotFound] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    const getCompanyDetails = () => {
      if (id) {
        performRequest("GET", `/employer/getEmployerById/${id}`)
          .then((res) => {
            setData(res.data);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              setPageNotFound(true);
            }
          });
      }
    };
    getCompanyDetails();
  }, []);

  if (pageNotFound) {
    return <PageNotFound />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header">
            <h2>Company Information</h2>
          </div>
          <div className="page-body">
            <div className="card card-sm">
              <div className="card-header">
                <h3 className="card-title">Company Details</h3>
              </div>
              <div className="card-body">
                <div className="row row-cards">
                  <div className="col-lg-2 text-center col-md-2 col-sm-6">
                    <span className="avatar avatar-2xl border">
                      {data && <img src={data?.companyLogo} alt="" srcSet="" />}
                    </span>
                    <br />
                  </div>
                  {/* col -2 */}
                  <div className="card p-2 col-lg-5 col-md-5 col-sm-12 border-0">
                    <div className="mb-3 row">
                      <label className="form-label col-3">Company Name :</label>
                      <div className="w-100 col">
                        <p>{data?.companyName}</p>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="form-label col-3">Website :</label>
                      <div className="w-100 col">
                        <p>{data?.website}</p>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="form-label col-3">
                        Contact Number :
                      </label>
                      {data?.contactNumber}
                    </div>
                
                    <div className="mb-3 row">
                      <label className="form-label col-3">
                        Founding Year :
                      </label>
                      <div className="w-100 col">
                        <p>{data?.foundedYear}</p>
                      </div>
                    </div>{" "}
                    <div className="mb-3 row">
                      <label className="form-label col-3">
                        Email-Address :
                      </label>
                      <div className="w-100 col">
                        <p>{data?.email}</p>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="form-label col-3">Headquarter :</label>
                      <div className="w-100 col">
                        <p>{data?.headquarter}</p>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="form-label col-3">Address :</label>
                      <div className="w-100 col">
                        <p>{data?.companyAddress}</p>
                      </div>
                    </div>
                  </div>
                  {/* col-3 */}
                  <div className="card p-2 col-lg-5 col-md-5 border-0">
                  <div className="mb-3 row">
                      <label className="form-label col-3">Company Type :</label>

                      <div className="w-100 col">
                        <p>{data?.companyType}</p>
                      </div>
                    </div>
                   
                    <div className="mb-3 row">
                      <label className="form-label col-3">
                        Industry Name :
                      </label>
                      <div className="w-100 col">
                        <p>{data?.industryName}</p>
                      </div>
                    </div>
                    
                
                    <div className="mb-3 row">
                      <label className="form-label col-3">Team Size :</label>
                      <div className="w-100 col">
                        <p>{data?.teamSize}</p>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="form-label col-3">Industry :</label>
                      <div className="w-100 col">
                        <p>{data?.industry}</p>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="form-label col-3">Sector Name :</label>
                      <div className="w-100 col">
                        <p>{data?.sectorName}</p>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="form-label col-3">
                        About Company :
                      </label>
                      <div className="w-100 col">
                        <p>{data?.aboutCompany}</p>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            
              <div className="card-footer text-end"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Companydetails;
