import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import performRequest from "../../APIs/request";
import { useDispatch, useSelector } from "react-redux";

import LoadingPage from "../../Components/layouts/loading";

import SimpleButton from "../../Components/layouts/simple_button";



const Companyinfo = () => {
  const navigate = useNavigate();
  const store = useSelector((store) => store?.userLogin);
  const [JobData, setJobData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    console.log(id)
    if (store?.user?.UserId && id) {
      setLoading(true);
      performRequest("GET", `/employer/getcompanyinfo/${id}`)
        .then((res) => {
            setJobData(res?.data)
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
               Company : {JobData?.companyName}
              </h2>
              <p className="section-description mb-4 p-0">
                <span className="status status-green">
                  <span className="status-dot status-dot-animated"></span>
                  Actively Hiring
                </span>
              </p>
            </div>

            {JobData ? (
              <div className="row">
                <div
                  className="col-lg-4 col-md-4 mb-sm-3"
                  
                >
                  <div className="card">
                    <div className="section-header">
                      <div className="text-center">
                        <img
                          src={JobData?.companyLogo}
                          className="w-75 m-2 p-2"
                          alt="Card side image"
                        />
                        <div className="my-2">
                          <h2 className="m-0 p-0">
                            {JobData?.companyName}
                          </h2>
                          <p className="m-0 p-0">PRIVATE LIMITED COMPANY</p>
                        </div>
                      </div>
                      <hr className="m-1" />
                      <div className="m-3 text-start">
                        <table className="table table-sm table-borderless m-2">
                          <tbody>
                            <tr>
                              <td>
                                <span>Teamsize:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {JobData?.teamSize}+
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Founded:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {
                                    JobData?.foundedYear
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Industry:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {
                                    JobData?.industryName
                                  }
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span>Head Office:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {
                                    JobData?.headquarter
                                  }
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div
                  className="col-lg-8 col-md-8"
                >
                    
            <div className="row row deck">
                <div className="card">
              <div
                className="list-group list-group-flush overflow-auto"
                style={{ maxHeight: "50rem" }}
              >
                {JobData?.jobsmodels &&
                  JobData?.jobsmodels.length > 0 &&
                  JobData?.jobsmodels.map((el, index) => {
                    return (
                      <div className="list-group-item" key={index}>
                        <div className="row">
               
                          <div className="card m-2 p-2">
                            <div className="row">
                              <div className="col-2 text-center">
                                <div className="card">
                                  <img
                                    src={
                                      JobData?.companyLogo ||
                                      "https://i.pinimg.com/280x280_RS/96/44/96/9644965f1f7cbcff611256a4226e22d5.jpg"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-10">
                                <h3 className="m-0 p-0 d-sm-inline-block">
                                  {el.jobRole}
                                </h3>

                                <p className="m-0 p-0">
                                  at <strong>{el.companyName}</strong> in
                                  <strong>
                                    {" "}
                                    {el.jobRole} &amp; {el.jobDepartment}
                                  </strong>{" "}
                                  department.
                                </p>
                                <span className="badge bg-cyan-lt me-1">
                                  POSITIONS: {el.numberOfPositions}
                                </span>
                                {el?.workType ? ( <span className="badge badge-outline text-teal me-1">
                                  {el?.workType}
                                </span>): ''}
                               
                                <div className="row my-2">
                                  <div className="col-12">
                                    <p className="m-0 p-0">Required Skills:</p>
                                    <div className="card d-flex p-0 ">
                                      <div className="">
                                        {el?.skillsDetails &&
                                          el?.skillsDetails?.map(
                                            (ele, index) => {
                                              return (
                                                <kbd
                                                  className="m-1"
                                                  key={index}
                                                >
                                                  {ele.skill}{" "}
                                                  {ele.minReqPercentage}
                                                </kbd>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr className="m-0 p-0" />
                                <div className="row mt-2" />
                                <div className="col-9">
                                  <div className="text-secondary m-0 p-0  d-flex align-content-center">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 384 512"
                                      fontSize="0.9em"
                                      className="me-2"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path>
                                    </svg>{" "}
                                    {el.jobCity}, {el.jobState}, Pune.
                                  </div>
                                  <div className="text-secondary m-0 mt-1 p-0  d-flex align-content-center">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 640 512"
                                      fontSize="1.2em"
                                      className="me-2"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 176c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 48h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path>
                                    </svg>
                                    ₹ {el.salaryPerMonthMin} -{" "}
                                    ₹ {el.salaryPerMonthMax}
                                  </div>
                                </div>
                                <div
                                  className="col-3  text-end"
                                  style={{ width: "100%" }}
                                  
                                >
                                  {/* <div className="text-end flex-md-wrap flex-sm-wrap"> */}
                                  
                                    <SimpleButton
                                      buttonText={"View Job"}
                                      onClick={() =>
                                        navigate(`/view-jobs/${el.id}`)
                                      }
                                    />
                                  
                               
                                    
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          
              </div>
                </div>
                {/* {store?.user?.Role !== company && (
                  <div className="col-lg-3 col-md-12 mt-lg-0 mt-md-3">
                    <img
                      src="https://cdn.apna.co/cloudinary/employer-dashboard-images/Logged%20out.png"
                      className="w-100"
                      alt="Card side image"
                    />
                    <div className="card mt-2">
                      <h3 className="m-2">Similar Jobs:</h3>
                      <div className="card p-2 m-2 mt-1">
                        {similarJobs.length > 0 ? (
                          <>
                            {similarJobs &&
                              similarJobs?.map((el, index) => {
                                return (
                                  <div className="row m-2" key={index}>
                                    <div
                                      className="col-4"
                                      onClick={() =>
                                        navigate(`/view-jobs/${el.id}`)
                                      }
                                    >
                                      <div className="card">
                                        <a href="#">
                                          <img
                                            src={
                                              el?.employeronboardingmodel
                                                ?.companyLogo
                                            }
                                            alt="Card side image"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      className="col-8 m-0 p-0"
                                      onClick={() =>
                                        navigate(`/view-jobs/${el.id}`)
                                      }
                                    >
                                      <p className="m-0 p-0">
                                        {el?.companyName}
                                      </p>
                                      <p className="m-0 p-0">
                                        {
                                          el?.employeronboardingmodel
                                            ?.companyName
                                        }
                                      </p>
                                      <p className="m-0 p-0">
                                        {" "}
                                        Rs. {el?.salaryPerMonthMin}- Rs.{" "}
                                        {el?.salaryPerMonthMax}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          <>Currently not available</>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            ) : (
              <div className="card p-4">
                <h3>
                  Company not found with id: {id} or you don't have access.
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companyinfo;
