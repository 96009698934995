import React, { useEffect, useRef, useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import Footer from "../../../Components/Footer/footer";
import { FaMoneyBill1 } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router";
import performRequest from "../../../APIs/request";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";
import { company } from "../../../Config/config";
import { extractDate } from "../../../Components/Functions/date.fun";
import html2canvas from "html2canvas";
import MetaTags from "../../../Components/Metatag/meta_tag";
import { notification } from "../../../Redux/notifications/notificationsReducer";
import { getNotifications } from "../../../Components/layouts/getnotifications";
import QrCodeGenerator from "../../../Components/layouts/qr_code";
const jobData = {
  id: 5,
  companyName: "www.kryzen.com",
  jobDepartment: "Advertising",
  jobRole: "Copywriter",
  workType: "Full time",
  workShift: "Day shift",
  jobLocation: "city avenue",
  jobCity: "pune",
  jobState: "maharastra",
  receiveApplicationFrom: "2024-07-27",
  payType: "Fixed Only",
  additionalPerks: [
    "Flexible working hours",
    "Overtime pay",
    "Joining bonus",
    "PF",
    "Laptop",
  ],
  joiningFeeOrDeposit: "Yes",
  salaryPerMonthMax: 77282,
  salaryPerMonthMin: 24444,
  incetivePerMonth: 222,
  englishLevel: "No English",
  experienceRequired: "Experienced Only",
  minimumExperience: "4",
  minimumEducation: "Diploma",
  JobDescription:
    "\nWriting an Effective Job Description | Human Resources\n\nWright State University\nhttps://www.wright.edu › human-resources › writing-an...\nA job description contains the following components: job title, job purpose, job duties and responsibilities, required qualifications, preferred qualifications, ...\n\nHow to Write a Job Description\n\nIndeed\nhttps://www.indeed.com › hire › how-to-write-a-job-desc...\n5 Jun 2024 — The key to writing effective job descriptions is to find the perfect balance between providing enough detail so candidates understand the role ...\n",
  interviewType: "Telephonic/ Online Interview",
  interviewAddress: "city avenue 319",
  interviewStartDate: "2024-07-31",
  interviewEndDate: "2024-07-31",
  interviewEndTime: "18:10",
  interviewStartTime: "10:10",
  otherInstructions:
    "A job description or JD is a written narrative that describes the general tasks, or other related duties, and responsibilities of a position",
  createdAt: "2024-07-19T06:41:57.000Z",
  updatedAt: "2024-07-19T06:41:57.000Z",
  userId: null,
};

const ViewJobs = () => {
  const navigate = useNavigate();
  const store = useSelector((store) => store?.userLogin);
  const dispatch = useDispatch();
  const [JobData, setJobData] = useState();
  const [similarJobs, setSimilarJobs] = useState([]);
  const [otherJobs, setOtherJobs] = useState([]);
  const [isApplied, setIsApplied] = useState(false);
  const [isResumeBuild, setIsResumeBuild] = useState(false);
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [appliedDate, setAppliedDate] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [shareImage, setShareImage] = useState("");
  const url = `https://hire48.com/view-jobs/${id}`;
  const [qrUrl, setQrUrl] = useState("");

  const printRef = useRef();
  useEffect(() => {
    const handleJobShare = async () => {
      const element = printRef.current;
      if (!element) return;

      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
      });

      const data = canvas.toDataURL("image/png");
      const blob = await fetch(data).then((res) => res.blob());
      const imageUrl = URL.createObjectURL(blob);
      setShareImage(imageUrl);
    };
    handleJobShare();
  }, []);

  useEffect(() => {
    performRequest("GET", `/jobs/getjobbyid/${id}`)
      .then((res) => {
        setJobData(res.data);
        if (res.status == 200) {
          // similar jobs
          const similarJobName = res.data.skillsDetails?.map((el) => {
            return el.skill;
          });
          performRequest("GET", `/jobs/similarjobs/${similarJobName.join("-")}`)
            .then((response) => {
              let data = response.data.filter((el) => {
                if (el.id !== Number(id)) {
                  return el;
                }
              });

              setSimilarJobs(data);
            })
            .catch((err) => console.error(err));

          // Other job
          performRequest(
            "GET",
            `/jobs/otherjobsbysamecompany/${id}?userid=${res.data.userId}`
          )
            .then((response) => {
              setOtherJobs(response.data);
            })
            .catch((err) => console.error(err));
        }
      })
      .catch((err) => console.error(err));
    setLoading(false);
  }, [id]);

  useEffect(() => {
    const userResume = () => {
      performRequest("GET", `/resume/getresumebyuserid/${store?.user?.UserId}`)
        .then((res) => {
          if (res.status === 200) {
            setIsResumeBuild(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (store?.user?.Role !== company) userResume();
  }, []);
  const handelSkillsNavigate = (id) => {
    if (id) {
      navigate(`/get-skill-badges/${id}`);
    }
  };
  const handleApply = async (jobData) => {
    if (isResumeBuild) {
      const data = {
        userId: store?.user?.UserId,
        jobId: jobData?.id,
        jobRole: jobData?.jobRole,
      };

      try {
        const res = await performRequest(
          "POST",
          `/applyedcandidate/createapplyedcandidate`,
          data
        );

        if (res.status === 201) {
          setIsApplied(true);
          Toast("success", "Application sent successfully");

          const userId = store?.user?.UserId;
          if (userId) {
            const notificationData = await getNotifications(userId);
            // Do something with the notifications
            dispatch(notification(notificationData));
          }
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status === 451) {
            if (err.response.data.flag) {
              Toast("error", err.response.data.flag);
            } else {
              Toast("error", "You already applied to this job");
            }
          } else {
            // Handle other errors if needed
            console.error("Error:", err);
          }
        }
      }
    } else {
      Toast("success", "Please Build Your Profile First");
    }
  };

  useEffect(() => {
    if (id && store?.user?.Role !== company) {
      setLoading(true);
      performRequest(
        "GET",
        `/applyedcandidate/getapplyedcandidatebyjobsidanduserid/${id}`
      )
        .then((res) => {
          if (res.status === 200) {
            setAppliedDate(extractDate(res.data.createdAt));
            setIsApplied(true);

            if (res.data.shortlistStatus) {
              setIsShortlisted(true);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIsApplied(false);
          }
          console.error((err = console.error(err)));
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                Job details for {JobData?.companyName}
              </h2>
              <p className="section-description mb-4 p-0">
                <span className="status status-green">
                  <span className="status-dot status-dot-animated"></span>
                  Actively Hiring
                </span>
              </p>
            </div>

            {JobData ? (
              <div className="row">
                <div 
                   className={
                    store?.user?.Role !== company
                      ? "col-lg-3 col-md-4 mb-sm-3"
                      : "col-lg-3 col-md-4 mb-sm-3"
                  }
                >
                <div
                
               
                >
                  <div className="card">
                    <div className="section-header">
                      <div className="text-center">
                        <img
                          src={JobData?.employeronboardingmodel?.companyLogo}
                          className="w-75 m-2 p-2"
                        />
                        <div className="my-2">
                          <h2 className="m-0 p-0">
                            {JobData?.employeronboardingmodel?.companyName}
                          </h2>
                          <p className="m-0 p-0">PRIVATE LIMITED COMPANY</p>
                        </div>
                      </div>
                      <hr />
                      <div className="m-3 text-start">
                        <table className="table table-sm table-borderless m-2">
                          <tbody>
                            <tr>
                              <td>
                                <span>Teamsize:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {JobData?.employeronboardingmodel?.teamSize}+
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Founded:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {
                                    JobData?.employeronboardingmodel
                                      ?.foundedYear
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Industry:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {
                                    JobData?.employeronboardingmodel
                                      ?.industryName
                                  }
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span>Head Office:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {
                                    JobData?.employeronboardingmodel
                                      ?.headquarter
                                  }
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-12 border border-1">
                      <hr />
                      <div className="card mt-2 border border-0">
                        {store?.user?.Role === "company" ? (
                          <h3 className="m-2">Other Jobs by You:</h3>
                        ) : (
                          <h3 className="m-2">Other Jobs by same company:</h3>
                        )}
                        <div className="m-2">
                          {otherJobs.length > 0 ? (
                            <>
                              {otherJobs &&
                                otherJobs?.map((el, index) => {
                                  return (
                                    <div
                                      className="card card-link card-link-pop m-2 p-2"
                                      key={index}
                                    >
                                      <div className="row">
                                        <div
                                          className="col-4 m-0 p-0"
                                          onClick={() =>
                                            navigate(`/view-jobs/${el.id}`)
                                          }
                                        >
                                          <div className="">
                                            <a href="#">
                                              <img
                                                src={
                                                  el?.employeronboardingmodel
                                                    ?.companyLogo
                                                }
                                              />
                                            </a>
                                          </div>
                                        </div>

                                        <div
                                          className="col-8 m-0 p-0"
                                          onClick={() =>
                                            navigate(`/view-jobs/${el.id}`)
                                          }
                                        >
                                          <p className="m-0 p-0">
                                            {el?.companyName}
                                          </p>
                                          <p className="m-0 p-0">
                                            {
                                              el?.employeronboardingmodel
                                                ?.companyName
                                            }
                                          </p>
                                          <p className="m-0 p-0">
                                            Rs. {el?.salaryPerMonthMin}- Rs.{" "}
                                            {el?.salaryPerMonthMax}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            <>Currently Not available</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {store?.user?.Role !== company && (
                  <div className="mt-lg-1 mt-md-3 ">
                    <img
                      src="https://cdn.apna.co/cloudinary/employer-dashboard-images/Logged%20out.png"
                      className="w-100"
                      alt="Card side image"
                    />
                    {similarJobs.length > 0 && (
                      <div className="card mt-2">
                        <h3 className="m-2">Similar Jobs:</h3>
                        <div className="p-2 m-2">
                          <>
                            {similarJobs &&
                              similarJobs?.map((el, index) => {
                                return (
                                  <div className="card m-1 p-1" key={index}>
                                    <div className="row p-2">
                                      <div
                                        className="col-4 m-0 p-0"
                                        onClick={() =>
                                          navigate(`/view-jobs/${el.id}`)
                                        }
                                      >
                                        <div className="card">
                                          <a href="#">
                                            <img
                                              src={
                                                el?.employeronboardingmodel
                                                  ?.companyLogo
                                              }
                                              alt="Card side image"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div
                                        className="col-8 m-0 p-0"
                                        onClick={() =>
                                          navigate(`/view-jobs/${el.id}`)
                                        }
                                      >
                                        <p className="m-0 p-0">
                                          {el?.companyName}
                                        </p>
                                        <p className="m-0 p-0">
                                          {
                                            el?.employeronboardingmodel
                                              ?.companyName
                                          }
                                        </p>
                                        <p className="m-0 p-0">
                                          {" "}
                                          Rs. {el?.salaryPerMonthMin}- Rs.{" "}
                                          {el?.salaryPerMonthMax}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                </div>
                <div
                  className={
                    store?.user?.Role !== company
                      ? "col-lg-9 col-md-8"
                      : "col-lg-9 col-md-8"
                  }
                >
                  <div className="card p-2" ref={printRef}>
                    <MetaTags
                      title={JobData?.jobRole}
                      description={JobData?.JobDescription}
                      image={shareImage ?? ""}
                      url={url}
                    />
                    <div className="section-header">
                      <div className="row">
                        <div className="col-5">
                          <p className="section-description p-0 m-0 mt-2">
                            {JobData?.companyName}
                          </p>
                          <h2 className="section-title fs-1 section-title-lg m-0 p-0">
                            {JobData?.jobRole}
                          </h2>
                          <p className="text-secondary m-0 p-0">3 Days Ago</p>
                        </div>
                        <div>
                          {store?.user?.Role !== company &&
                          store?.user?.Role !== "recruiter" &&
                          store?.user?.Role !== "superAdmin" ? (
                            <div
                              className={isApplied ? "col-7" : "col-7 text-end"}
                            >
                              {isApplied ? (
                                <>
                                  <span className="btn btn-primary btn-light ms-2 me-2">
                                    {isShortlisted
                                      ? "Shortlisted"
                                      : "Not Shortlisted"}
                                  </span>
                                  <span className="btn btn-primary btn-light">
                                    {appliedDate
                                      ? `Apply On: ${appliedDate}`
                                      : `Apply On: ${new Date().toLocaleDateString()}`}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <button
                                    // disabled={isApplied}
                                    onClick={() => handleApply(JobData)}
                                    className="btn btn-primary my-3"
                                  >
                                    Apply Now
                                  </button>
                                </>
                              )}
                            </div>
                          ) : (
                            <div className="col-md text-md-end">
                              <span className="btn btn-light me-2">
                                {JobData?.candidateapplyandshortlists?.length >
                                0
                                  ? `Total Applications: ${JobData?.candidateapplyandshortlists.length}`
                                  : "Total Applications: 0"}
                              </span>

                              <span
                                onClick={() =>
                                  navigate(`/edit-jobs/${JobData?.id}`)
                                }
                                className="btn btn-primary"
                              >
                                Edit Job
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className="my-5 p-0" />
                      <p className="fw-bold  p-0 mb-sm-5 mb-lg-0 mb-md-0">
                        Position Details:
                      </p>
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 ">
                          <table className="table table-sm table-borderless">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Department:</span>
                                </td>
                                <td className="w-50 fw-bold">
                                  <span>{JobData?.jobDepartment}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Work Type:</span>
                                </td>
                                <td className="w-50 fw-bold">
                                  <span>{JobData?.workType}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Shift Type:</span>
                                </td>
                                <td className="w-50 fw-bold">
                                  <span>{JobData?.workShift}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-6 col-sm-12 ">
                          <table className="table table-sm table-borderless">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Expereince:</span>
                                </td>
                                <td className="w-50 fw-bold">
                                  <span>{JobData?.experienceRequired}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Education:</span>
                                </td>
                                <td className="w-50 fw-bold">
                                  <span>{JobData?.minimumEducation}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Communication:</span>
                                </td>
                                <td className="w-50 fw-bold">
                                  <span>{JobData?.englishLevel}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr className="my-5 p-0" />
                      <p className="fw-bold m-0  p-0">Skills Required:</p>
                      <div className="m-0 my-3 p-0">
                        {JobData?.skillsDetails?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => handelSkillsNavigate(data?.id)}
                              className="avatar avatar-lg mx-1 cursor-pointer"
                              style={{
                                backgroundImage: `url(${data.icon})`,
                              }}
                            ></div>
                          );
                        })}
                      </div>
                      <p className="text-secondary">
                        Having required skills on your resume increases your
                        chances of getting hired by 35%!
                      </p>
                      <hr className="my-5 p-0" />
                      <div className="my-1">
                        <p className="fw-bold m-0 p-0">Job Description:</p>
                        <p className="text-secondary m-0 p-0">
                          {JobData?.JobDescription}
                        </p>
                      </div>
                      <div className="my-1">
                        <p className="fw-bold m-0 p-0">Additional Details:</p>
                        <p className="text-secondary m-0 p-0">
                          {JobData?.otherInstructions}
                        </p>
                      </div>
                      <div className="my-1">
                        <p className="fw-bold m-0 p-0">Job Location:</p>
                        <p className="text-secondary m-0 p-0">
                          {<IoLocationOutline />}
                          {JobData?.jobLocation}, {JobData?.jobCity}
                        </p>
                      </div>
                      <hr className="my-5 p-0" />
                      <div className="my-1">
                        <p className="fw-bold m-0 p-0">Salary Range & Pays:</p>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="card bg-primary-lt text-center p-2">
                              <h1 className="m-0 p-0 fs-1">
                                {JobData?.salaryPerMonthMin} -{" "}
                                {JobData?.salaryPerMonthMax}
                              </h1>
                              <p className="m-0 p-0">CTC / Month</p>
                            </div>
                            <p className="my-2 p-0">
                              CTC is cost to company and not actual salary that
                              will be depositied in your back account.{" "}
                            </p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="card bg-primary-lt text-center p-2">
                              <h1 className="m-0 p-0 fs-1">
                                {Number(JobData?.salaryPerMonthMin) +
                                  Number(JobData?.incetivePerMonth)}
                                -
                                {Number(JobData?.salaryPerMonthMax) +
                                  Number(JobData?.incetivePerMonth)}
                              </h1>
                              <p className="m-0 p-0">Earning Potential</p>
                            </div>
                            <p className="my-2 p-0">
                              Earning potential is additional income as
                              incentives, tips, allowance or esops.
                            </p>
                          </div>
                        </div>
                        <p className="text-secondary">
                          Note: Your actual salary is always part of negotiation
                          during HR round!
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="btn-list justify-content-end">
                        {store?.user?.Role !== company && (
                          <button
                            disabled={isApplied}
                            onClick={() => handleApply(JobData)}
                            className={
                              isApplied ? "btn btn-teal" : "btn btn-primary"
                            }
                          >
                            {isApplied
                              ? "Already Applied for this Position"
                              : "Apply for this Position"}
                          </button>
                        )}

                        <button
                          className="btn btn-outline"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-small"
                        >
                          Share{" "}
                        </button>

                        <div
                          className="modal modal-blur fade"
                          id="modal-small"
                          tabIndex="-1"
                          role="dialog"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-md modal-dialog-centered"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  Share this job post with your friends &
                                  family.
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body text-center">
                                <span className="m-1">
                                  <FacebookShareButton url={url}>
                                    <FacebookIcon size={45} round={true} />
                                  </FacebookShareButton>
                                </span>
                                <span className="m-1">
                                  <WhatsappShareButton url={url}>
                                    <WhatsappIcon size={45} round={true} />
                                  </WhatsappShareButton>
                                </span>
                                <span className="m-1">
                                  <TwitterShareButton url={url}>
                                    <TwitterIcon size={45} round={true} />
                                  </TwitterShareButton>
                                </span>
                                <span className="m-1">
                                  <EmailShareButton url={url}>
                                    <EmailIcon size={45} round={true} />
                                  </EmailShareButton>
                                </span>
                                <span className="m-1">
                                  <LinkedinShareButton url={url}>
                                    <LinkedinIcon size={45} round={true} />
                                  </LinkedinShareButton>
                                </span>

                                <span className="m-1">
                                  <TelegramShareButton url={url}>
                                    <TelegramIcon size={45} round={true} />
                                  </TelegramShareButton>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
            ) : (
              <div className="card p-4">
                <h3>
                  Job not found with id: {id} or you don't have access to this
                  job post.
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ViewJobs;
