import React from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

export const WebcamCapture = ({ img, uploadImage }) => {
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    uploadImage(imageSrc);
  }, [webcamRef]);
  return (
    <div className="text-center">
      <div>
        <Webcam
          audio={false}
          height={250}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={300}
          videoConstraints={videoConstraints}
        />
      </div>

      <button className="text-center btn  btn-primary" onClick={capture}>
        {img ? "Retake" : "Capture photo"}
      </button>
    </div>
  );
};
