import React from "react";
import { useSelector } from "react-redux";
import calculateDaysFrom from "../../../Components/Functions/calculate_days_from";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import { company } from "../../../Config/config";
import {
  FaArrowCircleRight,
  FaBook,
  FaDownload,
  FaEnvelope,
  FaGraduationCap,
  FaHands,
  FaLandmark,
  FaLanguage,
  FaTrophy,
  FaUserAlt,
} from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

const CandidateDetailsForShortlist = ({
  setViewDetails,
  viewDetails,
  setRefresh,
  refresh,
}) => {
  const store = useSelector((store) => store?.userLogin?.user);

  const handleShortlist = (id) => {
    performRequest("PATCH", `/applyedcandidate/updateapplyedcandidate/${id}`, {
      shortlistBy: store.user?.UserId,
      shortlistByRole: store.user?.Role,
      shortlistStatus: true,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Candidate shortlisted successfully");
          setRefresh(!refresh);
          setViewDetails("");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  const handleShortlistRemove = (id) => {
    performRequest("PATCH", `/applyedcandidate/updateapplyedcandidate/${id}`, {
      shortlistBy: "",
      shortlistByRole: "",
      shortlistStatus: false,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Candidate removed from shortlisted list");
          setRefresh(!refresh);
          setViewDetails("");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  const handleShortlistReq = (id) => {
    performRequest("PATCH", `/applyedcandidate/updateapplyedcandidate/${id}`, {
      shortlistRequestBy: store.UserId,
      shortlistRequest: true,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Candidate shortlist reqest sent successfully");
          setRefresh(!refresh);
          setViewDetails("");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  const handleShortlistReqRemove = (id) => {
    performRequest("PATCH", `/applyedcandidate/updateapplyedcandidate/${id}`, {
      shortlistRequestBy: "",
      shortlistRequest: false,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Candidate shortlist request has been cancelled");
          setRefresh(!refresh);
          setViewDetails("");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                Candidate details for {viewDetails?.usermodel?.fullName}
              </h2>
              <p className="section-description mb-4 p-0">
                <span className="status status-green">
                  <span className="status-dot status-dot-animated"></span>
                  Active Candidate
                </span>
              </p>
            </div>
            <div className="row row-cards">
              <div className="col-md-5">
                {viewDetails && (
                  <div className="card p-2">
                    <div className="section-header">
                      <div className="row">
                        <div className="col">
                          <p className="section-description p-0 m-0 mt-2">
                            {viewDetails?.jobsmodel?.companyName}
                          </p>
                          <h2 className="section-title fs-1 section-title-lg m-0 p-0">
                            {viewDetails?.jobsmodel?.jobRole}
                          </h2>
                          <p className="text-secondary m-0 p-0">3 Days Ago</p>
                        </div>
                      </div>
                    </div>
                    <hr className="my-5 p-0" />
                    <p className="fw-bold  p-0 mb-sm-5 mb-lg-0 mb-md-0">
                      Position Details:
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12 ">
                        <table className="table table-sm table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <span>Department:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {viewDetails?.jobsmodel?.jobDepartment}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Work Type:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>{viewDetails?.jobsmodel?.workType}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Shift Type:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>{viewDetails?.jobsmodel?.workShift}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6 col-sm-12 ">
                        <table className="table table-sm table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <span>Expereince:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {viewDetails?.jobsmodel?.experienceRequired}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Education:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {viewDetails?.jobsmodel?.minimumEducation}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>Communication:</span>
                              </td>
                              <td className="w-50 fw-bold">
                                <span>
                                  {viewDetails?.jobsmodel?.englishLevel}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr className="my-5 p-0" />
                    <p className="fw-bold m-0  p-0">Skills Required:</p>
                    <div className="m-0 my-3 p-0">
                      {viewDetails?.jobsmodel?.skillsDetails?.map((data, i) => {
                        return (
                          <div
                            key={i}
                            className="avatar avatar-lg mx-1 cursor-pointer"
                            style={{
                              backgroundImage: `url(${data.icon})`,
                            }}
                          ></div>
                        );
                      })}
                    </div>
                    <hr className="my-5 p-0" />
                    <div className="my-1">
                      <p className="fw-bold m-0 p-0">Job Description:</p>
                      <p className="text-secondary m-0 p-0">
                        {viewDetails?.jobsmodel?.JobDescription}
                      </p>
                    </div>
                    <div className="my-1">
                      <p className="fw-bold m-0 p-0">Additional Details:</p>
                      <p className="text-secondary m-0 p-0">
                        {viewDetails?.jobsmodel?.otherInstructions}
                      </p>
                    </div>
                    <div className="my-1">
                      <p className="fw-bold m-0 p-0">Job Location:</p>
                      <p className="text-secondary m-0 p-0">
                        {<IoLocationOutline />}
                        {viewDetails?.jobsmodel?.jobLocation},{" "}
                        {viewDetails?.jobsmodel?.jobCity}
                      </p>
                    </div>
                    <hr className="my-5 p-0" />
                    <div className="my-1">
                      <p className="fw-bold m-0 p-0">Salary Range & Pays:</p>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card bg-primary-lt text-center p-2">
                            <h1 className="m-0 p-0 fs-1">
                              {viewDetails?.jobsmodel?.salaryPerMonthMin} -{" "}
                              {viewDetails?.jobsmodel?.salaryPerMonthMax}
                            </h1>
                            <p className="m-0 p-0">CTC / Month</p>
                          </div>
                          <p className="my-2 p-0">
                            CTC is cost to company and not actual salary that
                            will be depositied in your back account.{" "}
                          </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="card bg-primary-lt text-center p-2">
                            <h1 className="m-0 p-0 fs-1">
                              {Number(
                                viewDetails?.jobsmodel?.salaryPerMonthMin
                              ) +
                                Number(
                                  viewDetails?.jobsmodel?.incetivePerMonth
                                )}{" "}
                              -
                              {Number(
                                viewDetails?.jobsmodel?.salaryPerMonthMax
                              ) +
                                Number(
                                  viewDetails?.jobsmodel?.incetivePerMonth
                                )}
                            </h1>
                            <p className="m-0 p-0">Earning Potential</p>
                          </div>
                          <p className="my-2 p-0">
                            Earning potential is additional income as
                            incentives, tips, allowance or esops.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-7">
                {viewDetails && (
                  <div

                  // style={{ maxWidth: "800px", minWidth: "800px" }}
                  >
                    <div className="card">
                      {viewDetails?.usermodel?.resumemodel ? (
                        <div className="card-body">
                          <div className="row">
                            <div
                              className="col-md-4 card bg-secondary-lt p-2"
                              style={{ minHeight: "80vh" }}
                            >
                              <div>
                                <p className="text-center m-0 pb-2">
                                  {viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0] && (
                                    <img
                                      src={
                                        viewDetails?.usermodel?.resumemodel
                                          ?.personalDetails?.[0]?.profilePicture
                                      }
                                      alt="User Image"
                                      srcSet=""
                                    />
                                  )}
                                </p>
                              </div>

                              {store?.user?.Role === "recruiter" ||
                              store?.user?.Role === "superAdmin" ? (
                                <p className="fs-5 m-0">
                                  {viewDetails?.usermodel?.resumemodel
                                    .personalDetails?.[0]?.email && (
                                    <FaEnvelope
                                      fontSize={"1.2em"}
                                      className="me-2"
                                    />
                                  )}
                                  {viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.email &&
                                    viewDetails?.usermodel?.resumemodel
                                      ?.personalDetails?.[0]?.email}
                                </p>
                              ) : (
                                <></>
                              )}
                               
                              {(store?.Role === "recruiter" ||
                              store?.Role === "superAdmin") ? (
                                <p className="fs-5 m-0">
                                  {viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.mobile &&
                                    viewDetails?.usermodel?.resumemodel
                                      ?.personalDetails?.[0]?.mobile}
                                </p>
                              ) : (
                                <></>
                              )}

                              <p className="fs-5 m-0">
                                {viewDetails?.usermodel?.resumemodel
                                  ?.personalDetails?.[0]?.country &&
                                  viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.country}
                              </p>
                              <p className="fs-5 m-0">
                                {viewDetails?.usermodel?.resumemodel
                                  ?.personalDetails?.[0]?.city &&
                                  viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.city}
                                {viewDetails?.usermodel?.resumemodel
                                  ?.personalDetails?.[0]?.city && ","}
                                {viewDetails?.usermodel?.resumemodel
                                  ?.personalDetails?.[0]?.state &&
                                  viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.state}
                              </p>

                              {viewDetails?.usermodel?.resumemodel?.education &&
                                viewDetails?.usermodel?.resumemodel?.education
                                  .length > 0 &&
                                Object.values(
                                  viewDetails?.usermodel?.resumemodel
                                    ?.education?.[0]
                                ).some((value) => value !== "") && (
                                  <>
                                    <hr className="p-0 my-2" />
                                    <p className="p-0 mb-2 fs-3 fw-bold">
                                      <FaGraduationCap
                                        fontSize={"1em"}
                                        className="me-1"
                                      />
                                      Education
                                    </p>
                                  </>
                                )}

                              <>
                                {viewDetails?.usermodel?.resumemodel
                                  ?.education &&
                                  viewDetails?.usermodel?.resumemodel?.education?.map(
                                    (el, index) => (
                                      <div key={index}>
                                        <h4 className="p-0 m-0 fs-4">
                                          {el.educationSelect1}
                                        </h4>
                                        <p className="p-0 m-0 fs-5">
                                          {el.university_1}
                                        </p>
                                        <p className="fs-5">
                                          {el.startDate1}
                                          <span>
                                            {el.endDate1 && "To"}
                                            {el.endDate1}
                                          </span>
                                        </p>
                                        <p className="fs-5">{el.summary}</p>
                                      </div>
                                    )
                                  )}
                              </>

                              {viewDetails?.usermodel?.resumemodel &&
                                viewDetails?.usermodel?.resumemodel.skills
                                  ?.length > 0 && (
                                  <>
                                    <hr className="p-0 my-2" />
                                    <p className="p-0 mb-2 fs-3 fw-bold">
                                      <FaBook
                                        fontSize={"1em"}
                                        className="me-1"
                                      />{" "}
                                      Skills
                                    </p>
                                    <p className="m-0 p-0">
                                      {viewDetails?.usermodel?.resumemodel.skills?.map(
                                        (skills,index) => {
                                          return (
                                            <div
                                            key={index}
                                              className="avatar avatar-md m-1"
                                              style={{
                                                backgroundImage: `url(${skills.icon})`,
                                              }}
                                            ></div>
                                          );
                                        }
                                      )}
                                    </p>
                                    <hr className="p-0 my-2" />
                                  </>
                                )}
                              {viewDetails?.usermodel?.resumemodel?.languages &&
                                viewDetails?.usermodel?.resumemodel?.languages
                                  .length > 0 &&
                                Object.values(
                                  viewDetails?.usermodel?.resumemodel
                                    ?.languages?.[0]
                                ).some((arr) => arr.length > 0) && (
                                  <>
                                    <>
                                      <p className="p-0 mb-2 fs-3 fw-bold">
                                        <FaLanguage
                                          fontSize={"1em"}
                                          className="me-1"
                                        />{" "}
                                        Languages
                                      </p>

                                      {viewDetails?.usermodel?.resumemodel
                                        ?.languages &&
                                        viewDetails?.usermodel?.resumemodel
                                          ?.languages?.[0].languageSpeak
                                          ?.length > 0 && (
                                          <>
                                            <p className="p-0 m-0 fs-4 fw-bold">
                                              Speak:
                                            </p>
                                            <p className="p-0 m-0">
                                              {viewDetails?.usermodel?.resumemodel?.languages?.[0]?.languageSpeak?.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </>
                                        )}

                                      {viewDetails?.usermodel?.resumemodel
                                        ?.languages &&
                                        viewDetails?.usermodel?.resumemodel
                                          ?.languages?.[0]?.readLanguage
                                          ?.length > 0 && (
                                          <>
                                            <p className="p-0 m-0 fs-4 fw-bold">
                                              Read:
                                            </p>
                                            <p className="p-0 m-0">
                                              {viewDetails?.usermodel?.resumemodel?.languages?.[0]?.readLanguage.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </>
                                        )}

                                      {viewDetails?.usermodel?.resumemodel
                                        .languages?.[0]?.languageWrite.length >
                                        0 && (
                                        <>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            Write:
                                          </p>
                                          <p className="p-0 m-0">
                                            {viewDetails?.usermodel?.resumemodel
                                              .languages?.[0]?.languageWrite
                                              .length > 0 ? (
                                              viewDetails?.usermodel?.resumemodel.languages?.[0]?.languageWrite.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </>
                                )}

                              {viewDetails?.usermodel?.resumemodel.hobbies && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 mb-2 fs-3 fw-bold">
                                    <FaHands
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Hobbies
                                  </p>
                                  <p>
                                    {
                                      viewDetails?.usermodel?.resumemodel
                                        ?.hobbies
                                    }
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="col-md-8">
                              <h1 className="p-0 m-0 fs-1">
                                {viewDetails?.usermodel?.resumemodel
                                  ?.personalDetails?.[0] &&
                                  viewDetails?.usermodel?.resumemodel
                                    ?.personalDetails?.[0]?.name}
                              </h1>

                              {viewDetails?.usermodel?.resumemodel
                                ?.personalDetails &&
                                viewDetails?.usermodel?.resumemodel
                                  ?.personalDetails?.[0]?.workProfile && (
                                  <>
                                    <p className="p-0 m-0 fs-4">
                                      <FaArrowCircleRight
                                        fontSize={"1em"}
                                        className="me-1"
                                      />{" "}
                                      <span>
                                        {" "}
                                        {viewDetails?.usermodel?.resumemodel
                                          ?.personalDetails &&
                                          viewDetails?.usermodel?.resumemodel
                                            ?.personalDetails?.[0]?.workProfile}
                                      </span>
                                    </p>
                                  </>
                                )}

                              {viewDetails?.usermodel?.resumemodel
                                ?.additionalInformation &&
                                viewDetails?.usermodel?.resumemodel?.additionalInformation?.map(
                                  (el, index) => {
                                    return (
                                      <>
                                        <span className="badge badge-outline text-green my-1 me-1">
                                          {el}
                                        </span>
                                      </>
                                    );
                                  }
                                )}

                              {viewDetails?.usermodel?.resumemodel
                                .description && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaLandmark
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Professional Summary
                                  </p>
                                </>
                              )}

                              <p className="fs-5 ps-3">
                                {viewDetails?.usermodel?.resumemodel
                                  .description &&
                                  viewDetails?.usermodel?.resumemodel
                                    .description}
                              </p>
                              <hr className="p-0 my-2" />
                              {viewDetails?.usermodel?.resumemodel
                                ?.workExperience &&
                                viewDetails?.usermodel?.resumemodel
                                  ?.workExperience.length > 0 &&
                                Object.values(
                                  viewDetails?.usermodel?.resumemodel
                                    ?.workExperience?.[0]
                                ).some((value) => value !== "") && (
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaUserAlt
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Professional Experience
                                  </p>
                                )}

                              <div className="ps-3">
                                <>
                                  {viewDetails?.usermodel?.resumemodel
                                    ?.workExperience &&
                                    viewDetails?.usermodel?.resumemodel?.workExperience.map(
                                      (el) => (
                                        <div key={el.position + el.companyName}>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            {el.position}
                                          </p>
                                          <p className="p-0 m-0 fs-5">
                                            {el.companyName} <br />
                                            {el.location} <br />
                                            {el.locationType} <br />
                                            {el.joiningDate}{" "}
                                            {el.exitDate && "To"} {el.exitDate}
                                          </p>
                                          <p className="fs-5 pt-2">
                                            {el.jobDescription}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </>
                              </div>

                              {viewDetails?.usermodel?.resumemodel
                                ?.awardAndCertificate &&
                                viewDetails?.usermodel?.resumemodel
                                  ?.awardAndCertificate.length > 0 &&
                                Object.values(
                                  viewDetails?.usermodel?.resumemodel
                                    ?.awardAndCertificate?.[0]
                                ).some((value) => value !== "") && (
                                  <>
                                    <hr className="p-0 my-2" />
                                    <p className="p-0 m-0 fs-4 fw-bold">
                                      <FaTrophy
                                        fontSize={"1em"}
                                        className="me-1"
                                      />
                                      Awards & Certificates
                                    </p>
                                  </>
                                )}

                              <div className="ps-2">
                                <>
                                  {viewDetails?.usermodel?.resumemodel
                                    ?.awardAndCertificate &&
                                    viewDetails?.usermodel?.resumemodel?.awardAndCertificate?.map(
                                      (el, index) => (
                                        <div key={index}>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            {el.awardAndCertificateName}
                                          </p>
                                          <p className="p-0 m-0 fs-5">
                                            {el.givenBy}
                                          </p>
                                          <p className="fs-5 pt-2">
                                            {el.acDescription}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <div className="btn-list justify-content-end">
                              {store?.Role === company &&
                                !viewDetails.shortlistStatus && (
                                  <button
                                    onClick={() => {
                                      if (!viewDetails.shortlistRequest) {
                                        handleShortlistReq(viewDetails.id);
                                      } else {
                                        handleShortlistReqRemove(
                                          viewDetails.id
                                        );
                                      }
                                    }}
                                    className={
                                      !viewDetails.shortlistRequest
                                        ? "btn btn-teal"
                                        : "btn btn-red"
                                    }
                                  >
                                    {!viewDetails.shortlistRequest
                                      ? "Sent request for shortlist"
                                      : "Cancel request of shortlist"}
                                  </button>
                                )}
                              {store?.Role === company &&
                                viewDetails.shortlistStatus && (
                                  <button disabled className={"btn btn-teal"}>
                                    Already shortlisted
                                  </button>
                                )}
                              {store?.Role !== company && (
                                <button
                                  onClick={() => {
                                    if (!viewDetails.shortlistStatus) {
                                      handleShortlist(viewDetails.id);
                                    } else {
                                      handleShortlistRemove(viewDetails.id);
                                    }
                                  }}
                                  className={
                                    !viewDetails.shortlistStatus
                                      ? "btn btn-teal"
                                      : "btn btn-red"
                                  }
                                >
                                  {!viewDetails.shortlistStatus
                                    ? "Shortlist Now"
                                    : "Remove from shortlist"}
                                </button>
                              )}

                              <button
                                className="btn btn-outline"
                                onClick={() => setViewDetails("")}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        "User Profile Not Found"
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDetailsForShortlist;
