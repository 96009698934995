import React, { useEffect, useState } from "react";
import performRequest from "../../APIs/request";
import Footer from "../../Components/Footer/footer";
import { extractDate } from "../../Components/Functions/date.fun";
const ContactUsList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    performRequest("GET", "/contactus/allcontactus")
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header">
            <h2>Contact Received List</h2>
          </div>
          <div className="page-body">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Date of creation</th>
                      <th>Full name</th>
                      <th>Contact as</th>
                      <th>company contact</th>
                      <th>company email</th>
                      <th>message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{extractDate(item.createdAt)}</td>
                          <td>{item.fullName || "N/A"}</td>
                          <td>{item.contactAs || "N/A"}</td>
                          <td>{item.contactNumber || "N/A"}</td>
                          <td>{item.email || "N/A"}</td>
                          <td>{item.message || "N/A"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUsList;
