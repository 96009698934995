import * as yup from "yup";
export const CreateTaskSchema = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string(),
  assignTo: yup.string().required("Required"),
  deadline: yup.string(),
  status: yup.string(),
  feedback: yup.string(),
  taskType: yup.string().required("Required"),
  startDate: yup.date(),
  frequency: yup.string(),
  frequencyType: yup.string(),
});