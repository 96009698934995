export function CamelCaseToFirstUpper(word) {
  // Split the word by camel case pattern and replace with space
  if (word) {
    const formattedWord = word.replace(/([a-z])([A-Z])/g, "$1 $2");

    // Capitalize the first letter of the formatted word
    const capitalizedWord =
      formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1);

    return capitalizedWord;
  }
  return;
}

export function capitalizeFirstLetterOfEachWord(sentence) {
  if (sentence) {
    const words = sentence.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }
  return;
}

export const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};
