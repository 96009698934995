import performRequest from "../../APIs/request";

export  const getNotifications = (userId)=>{
   return performRequest("GET", `/notification/getnotification/${userId}`)
    .then((res) => {
      if (res.status === 200) {
       return res.data
      } 
    })
    .catch((err) => {
        console.log(err)
    });
     
  }


  