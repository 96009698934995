export const testInfo = {
  type: "",
  level: "",
  icon: "",
  name: "",
  department: "",
  description: "",
  passingScore: "",
  questionsAnsOpt: [],
};
export const questionInfo = {
  question: "",
  answer: "",
  options: {
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  },
};
export const userDetails = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  contactNumber: "",
  permanentAddress: "",
  residentialAddress: "",
  emergencyContactNumber: "",
  emergencyContactName: "",
  photograph: "",
  emergencyContactRelation: "",
};
export const personalDetailsObjectInfo = {
  name: "",
  email: "",
  mobile: "",
  workProfile: "",
  country: "",
  state: "",
  city: "",
  profilePicture: "",
};
export const personalDetailsInfo = {
  personalDetails: [personalDetailsObjectInfo],
};

export const professionalSummary = {
  description: "",
};

export const languageOjbectInfo = {
  languageRead: [],
  languageWrite: [],
  languageSpeak: [],
};
export const languageInfo = {
  languages: [languageOjbectInfo],
};

export const educationObjectInfo = {
  educationSelect1: "",
  startDate1: "",
  endDate1: "",
  university_1: "",
  summary: "",
};
export const educationInfo = {
  education: [educationObjectInfo],
};

export const experienceObjectInfo = {
  position: "",
  employmentType: "",
  locationType: "",
  companyName: "",
  location: "",
  joiningDate: "",
  exitDate: "",
  jobDescription: "",
};
export const experienceInfo = {
  workExperience: [experienceObjectInfo],
};
export const awardAndCertificateObjectInfo = {
  awardAndCertificateName: "",
  givenBy: "",
  acDescription: "",
};
export const awardAndCertificateInfo = {
  awardAndCertificate: [awardAndCertificateObjectInfo],
};
export const skillInfo = {
  skill: "",
  icon: "",
  minReqPercentage: "",
};
export const hobbiesInfo = {
  hobbies: "",
};

export const additionalInfo = {
  additionalInformation: [],
};

export const JobsInfo = {
  employerId: "",
  companyName: "",
  jobDepartment: "",
  jobRole: "",
  jobType: "",
  numberOfPositions: "",
  skillsDetails: [],
  workType: "",
  workShift: "",
  jobLocation: "",
  jobCity: "",
  jobState: "",
  receiveApplicationFrom: "",
  payType: "",
  additionalPerks: [],
  joiningFeeOrDeposit: "",
  salaryPerMonthMax: "",
  salaryPerMonthMin: "",
  incetivePerMonth: "",
  minimumEducation: "",
  englishLevel: "",
  experienceRequired: "",
  minimumExperience: "",
  JobDescription: "",
  interviewType: "",
  interviewAddress: "",
  interviewStartDate: "",
  interviewEndDate: "",
  interviewEndTime: "",
  interviewStartTime: "",
  otherInstructions: "",
  contactPersonName:"",
  contactPersonNumber:"",
  contactNumber:"",
  modeofInterview:"",
  meetLink:""
};

export const BookDemoInfo = {
  fullName: "",
  companyEmail: "",
  companyName: "",
  contactNumber: "",
  contactType: "bookDemo",
  contactAs: "Employer",
};
export const interviewScheduleInfo = {
  date: "",
  time: "",
  modeofInterview: "",
  address: "",
  meetLink: "",
  contactNumber: "",
  contactPersonName: "",
  contactPersonNumber: "",
};

export const ContactUsInfo = {
  contactAs: "",
  fullName: "",
  email: "",
  message: "",
  contactNumber: "",
  contactType: "contactUs",
};
