import * as Yup from "yup";

const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9#]+\/?)*$/;

export const editCompanyProfileSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  companyName: Yup.string().required('Company Name is required'),
  aboutCompany: Yup.string().required('About company is required'),
  companyType:Yup.string().required('Company Type is required'),
  companyLogo:Yup.string().required('Company Logo is required'),
  headquarter:Yup.string().required('Company location is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  contactNumber: Yup.string()
  .min(10, 'Enter 10 Digit Mobile Number').max(10,'Enter 10 Digit Mobile Number')
  .required('Mobile Number  is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  designation: Yup.string().required('Designation is required'),
  teamSize: Yup.string().required('team size  is required'),
  website: Yup.string().matches(urlRegex, 'Invalid URL format')
  .required('Website is required'),
  industry: Yup.string().required(),
  sectorName: Yup.string().required(),
  industryName: Yup.string().required(),
  foundedYear:Yup.string().required(),

});

export default editCompanyProfileSchema;
