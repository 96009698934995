import { useField } from "formik";

const CustomCheckbox = ({ width, height, ...props }) => {
  const [field] = useField(props);

  return (
    <>
      <input
        className="form-check-input"
        // style={{ width: `${width}`, height: `${height}` }}
        {...field}
        {...props}
      />
    </>
  );
};
export default CustomCheckbox;
