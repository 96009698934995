import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const VerifiedProfiles = () => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();

  return (
    <div class="page">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="container-xl">
            <header className="hero text-center py-10 my-6 my-md-8">
              <h1
                className="hero-title aos-init aos-animate my-5 display-4 fw-bold"
                data-aos="zoom-y-out"
              >
                Every candidate on our platform is notable person!
              </h1>
              <h2 className="p-0 m-0">
                No Fake profiles, or long pool of irrelevent candidates;
                Everything pre-assesed & verified.
              </h2>
              {/* <div className="row my-4">
                <div className="col-mg-12 col-lg-6 text-lg-end text-md-center mb-3">
                  <div className="">
                    <span
                      onClick={() => {
                        if (!store?.isLoggedIn) {
                          navigate("/account-login");
                        } else {
                          navigate("/resume-builder");
                        }
                      }}
                      className="btn btn-primary btn-lg p-3 btn-text"
                    >
                      <FaAddressCard fontSize={"1.2em"} className="me-2" />{" "}
                      BUILD YOUR PROFILE
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 text-lg-start text-md-center">
                  <div className="">
                    <span
                      className="btn btn-lg p-3 btn-text"
                      onClick={() => {
                        navigate("/top-hires");
                      }}
                    >
                      <FaSearch fontSize={"1.2em"} className="me-2" />
                      BROWSE JOB POSTS
                    </span>
                  </div>
                </div>
              </div> */}
            </header>
            <hr />
            <div className="row">
              <div className="col-md-6"></div>

              <div className="col-md-6">
                <h2>AI Based resume analyzer</h2>
                <p>
                  One of the first things to look out for when vetting fake
                  candidates is their resume. A resume that lists an excessive
                  amount of skill sets may raise suspicions. False applicants
                  will often exaggerate their qualifications in an attempt to
                  capture the attention of potential employers. Excessive
                  elaboration on job roles and responsibilities is another
                  indicator of a fraudulent candidate. They often lack genuine
                  experience and resort to copying information from the
                  internet. A genuine candidate typically focuses on key
                  responsibilities, whereas a fake candidate tends to replicate
                  every detail found online. Background plagarism checker and
                  also skill analyzer will insure your get acess to verified
                  profiles.
                </p>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6">
                <h2>The best pre-hire assesment.</h2>
                <p>
                  When it comes to hiring the best talent, pre-hire assessment
                  is one of the most useful mechanism we use. This provides an
                  objective way of gaining insight into the capabilities of the
                  prospective employee. When you shortlist candidate, our team
                  comes into action and verifies there certifications,
                  marksheets and also previous emplyment record.
                </p>
              </div>

              <div className="col-md-6"></div>
            </div>

            <hr />
            <h1
              className="aos-init aos-animate my-5 display-4 fw-bold p-0 m-0  text-center"
              data-aos="zoom-y-out"
            >
              Have any question in mind?
            </h1>
            <h2 className="p-0 m-0 text-center">
              Shoot us a mail on contact@hire48.com
            </h2>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default VerifiedProfiles;
