import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginReducer from "./User/login_reducer";
import paginationFilterSlice from "./Pagination & Filters/filtersReducer";
import allCandidatesFilterSlice from "./Pagination & Filters/allCandidateFiltersReducer";
import allCompaniesFilterSlice from "./Pagination & Filters/allCompaniesFiltersReducer";
import allShortlistRequestFilterSlice from "./Pagination & Filters/allShortlistRequestFiltersReducer";
import allPaymentsFilterSlice from "./Pagination & Filters/allPaymentsFiltersReducer";
import notificationSlice from "./notifications/notificationsReducer";
import jobsListsFilterSlice from "./Pagination & Filters/jobsListsFiltersReducer";
const persistConfig = {
  key: "root",
  storage,
};
const persistedNotificationSlice = persistReducer(
  persistConfig,
  notificationSlice
);

export const store = configureStore({
  reducer: {
    userLogin: loginReducer,
    filters: paginationFilterSlice,
    allCandidatesFilter: allCandidatesFilterSlice,
    allCompaniesFilter: allCompaniesFilterSlice,
    allShortlistRequestFilter: allShortlistRequestFilterSlice,
    allPaymentsFilter: allPaymentsFilterSlice,
    allNotifications: persistedNotificationSlice,
    jobsListsFilter: jobsListsFilterSlice,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE", // Add any other persist-related actions you want to ignore
          "persist/PURGE",
        ],
      },
    }).concat(thunk),
});

export const persistor = persistStore(store);
