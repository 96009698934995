import * as Yup from 'yup';

// Create the validation schema
export const addAdminCreditSchema = Yup.object().shape({
  companyName: Yup.string()
    .required('Company Name is required')
    .min(2, 'Company Name must be at least 2 characters long'),
  
  companyId: Yup.string()
    .required('Company ID is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Company ID can only contain letters and numbers'),

    availableCredits: Yup.string(),

  numberOfCredit: Yup.number()
    .required('Number of Credit is required')
    .min(1, 'Number of Credit must be at least 1')
    .integer('Number of Credit must be an integer'),
});

