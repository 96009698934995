import React from "react";
import Footer from "../../Components/Footer/footer";
import { useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import { useState } from "react";
import { useNavigate } from "react-router";

const Pricing = () => {
  const store = useSelector((store) => store?.userLogin);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()


  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="card card-lg">
            <div className="card-body ">
              <div className="table-responsive">
                <table className="table table-vcenter table-bordered table-nowrap card-table">
                  <thead>
                    <tr>
                      <td className="w-50">
                        <h2>Simple flat pricing plans.</h2>
                        <div className="text-secondary text-wrap">
                          Choose an affordable plan that comes with the best
                          features to build your team.
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="text-uppercase text-secondary font-weight-medium">
                          For Employee Salary below
                        </div>
                        <div className="text-uppercase text-primary font-weight-medium">
                          15000/Mo
                        </div>
                        <div className="display-6 fw-bold my-3">4499</div>
                      </td>
                      <td className="text-center">
                        <div className="text-uppercase text-secondary font-weight-medium">
                          For Employee Salary below
                        </div>
                        <div className="text-uppercase text-primary font-weight-medium">
                          50,000/Mo
                        </div>
                        <div className="display-6 fw-bold my-3">9999</div>
                      </td>
                      <td className="text-center">
                        <div className="text-uppercase text-secondary font-weight-medium">
                          For Headhunting
                        </div>
                        <div className="text-uppercase text-primary font-weight-medium">
                          TM & CXO
                        </div>
                        <div className="display-6 fw-bold my-3">Call</div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-light">
                      <th colspan="4" className="subheader">
                        Features
                      </th>
                    </tr>
                    <tr>
                      <td>Dedicated Account Manager</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>Manually Filtered Profiles</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>Unlimited Profile Options</td>
                      <td className="text-center">No</td>
                      <td className="text-center">No</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>Live Screening Score</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>Skill Verification</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr className="bg-light">
                      <th colspan="4" className="subheader">
                        USP of Hire48
                      </th>
                    </tr>
                    <tr>
                      <td>Background Verification</td>
                      <td className="text-center">No</td>
                      <td className="text-center">No</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>Free 3 months replacement</td>
                      <td className="text-center">No</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center">Yes</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td>
                        <button
                          value="4999"
                          onClick={() => {
                            if (store?.user) {
                              navigate("/payment");
                            } else {
                              navigate("/account-login");
                            }
                          }}
                          href="#"
                          className="btn w-100"
                        >
                          Choose plan
                        </button>
                      </td>
                      <td>
                        <button
                          value="9999"
                          onClick={() => {
                            if (store?.user) {
                              navigate("/payment");
                            } else {
                              navigate("/account-login");
                            }
                          }}
                          href="#"
                          className="btn btn-green w-100"
                        >
                          Choose plan
                        </button>
                      </td>
                      <td>
                        <a href="#" className="btn w-100">
                          Choose plan
                        </a>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};


export default Pricing;
