import { useField } from "formik";

const CustomSelect = ({ ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <select className="form-select" {...field} {...props} />
    </>
  );
};
export default CustomSelect;
