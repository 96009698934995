import { createSlice } from "@reduxjs/toolkit";
export const allCompaniesFilterSlice = createSlice({
  name: "allCompaniesPaginationFilter",
  initialState: {
    currentPage: 1,
    filters: {
      nameOrMobile: "",
      sortByActiveDate: "",
      sortByActiveJobs: "",
      onboardStartDate: "",
      onboardEndDate: "",
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReduxFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setCurrentPage, setReduxFilters } =
  allCompaniesFilterSlice.actions;
export default allCompaniesFilterSlice.reducer;
