import React from "react";

const GetRandomUserImage = ({
  max = 20,
  userData = [],
  classes = "avatar",
}) => {
  return (
    <div>
      {" "}
      {userData?.map((item, index) => {
        return (
          <span
            key={index}
            className={classes}
            style={{
              backgroundImage: `url(${item?.image})`,
            }}
          ></span>
        );
      })}
    </div>
  );
};

export default GetRandomUserImage;
