import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const generateDateLabels = (days) => {
  const labels = [];
  const dates = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    labels.push(date.toISOString().split("T")[0]);
    dates.push(date);
  }
  return { labels, dates };
};

function parseTimeToSeconds(timeStr) {
  const period = timeStr.slice(-2).toLowerCase();
  let [hours, minutes, seconds] = timeStr.slice(0, -2).trim().split(":");
  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);
  seconds = parseInt(seconds, 10);

  if (period === "pm" && hours !== 12) {
    hours += 12;
  } else if (period === "am" && hours === 12) {
    hours = 0;
  }
  return hours * 3600 + minutes * 60 + seconds;
}

const getLoginTime = (attendance, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const filteredAttendance = attendance.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return createdAt >= startOfDay && createdAt <= endOfDay;
    });
    if (filteredAttendance.length > 0 && filteredAttendance[0].time) {
      return parseTimeToSeconds(filteredAttendance[0].time);
    } else {
      return 0;
    }
  });
};

const getLogoutTime = (attendance, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const filteredAttendance = attendance.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return createdAt >= startOfDay && createdAt <= endOfDay;
    });
    if (filteredAttendance.length > 0 && filteredAttendance[0].logoutTime) {
      return parseTimeToSeconds(filteredAttendance[0].logoutTime);
    } else {
      return 0;
    }
  });
};

const getTimeDiff = (attendance, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const filteredAttendance = attendance.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return createdAt >= startOfDay && createdAt <= endOfDay;
    });
    if (
      filteredAttendance.length > 0 &&
      filteredAttendance[0].logoutTime &&
      filteredAttendance[0].time
    ) {
      return (
        parseTimeToSeconds(filteredAttendance[0].logoutTime) -
        parseTimeToSeconds(filteredAttendance[0].time)
      );
    } else {
      return 0;
    }
  });
};

function secondsToHMS(seconds) {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = (seconds % 60).toString().padStart(2, "0");
  return `${h}:${m}:${s}`;
}

export default function Attendancechart({ attendanceFrom, attendance }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const { labels, dates } = generateDateLabels(attendanceFrom);
    const loginTime = getLoginTime(attendance, dates);
    const logoutTime = getLogoutTime(attendance, dates);
    const timeDiff = getTimeDiff(attendance, dates);

    const data = {
      labels: labels,
      datasets: [
        {
          type: "line",
          data: loginTime,
          borderWidth: 2,
          fill: false,
          label: "Login Time",
          borderColor: "rgba(60, 179, 113, 1)",
        },
        {
          type: "line",
          label: "Logout Time",
          borderColor: "rgba(238, 130, 238, 1)",
          data: logoutTime,
          borderWidth: 2,
          fill: false,
        },
        {
          type: "bar",
          label: "Total Time",
          backgroundColor: "rgb(255, 200, 0,1)",
          data: timeDiff,
          borderColor: "white",
          borderWidth: 1,
        },
      ],
    };

    setChartData(data);
  }, [attendanceFrom, attendance]);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return secondsToHMS(tooltipItem.raw);
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return secondsToHMS(value);
          },
        },
      },
    },
  };

  return <Chart type="bar" data={chartData} options={options} />;
}