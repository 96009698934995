import React from "react";
import { convertRupeesToWords } from "../../../../Components/Functions/amt.fun";

const SalaryBreakDown = ({ attendanceData }) => {
  // let netPay = (
  //   +attendanceData?.totalSalary +
  //   +attendanceData?.incentiveEmployee -
  //   +attendanceData?.employeeData?.pfptDeduction
  // )?.toFixed(2);
  let netPay = (
    (parseInt(attendanceData?.totalSalary) || 0) +
    // +(parseInt(attendanceData?.incentiveEmployee) || 0) - Uncomment if you need this
    -(parseInt(attendanceData?.employeeData?.pfptDeduction) || 0)
  ).toFixed(2);

  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-sm-6">
          <table className="table table-borderless">
            <thead>
              <tr style={{ backgroundColor: "rgba(187, 174, 181, 0.16)" }}>
                <th
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Earnings
                </th>
                <th
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingBottom: "0px" }}>Basic</td>
                <td style={{ paddingBottom: "0px" }}>
                  ₹{(attendanceData?.totalSalary * 0.5)?.toFixed(2)}
                </td>
                {/* <td style={{ paddingBottom: "0px" }}>-</td> */}
              </tr>
              <tr>
                <td style={{ paddingBottom: "0px" }}>House Rent Allowance</td>
                <td style={{ paddingBottom: "0px" }}>
                  ₹{(attendanceData?.totalSalary * 0.25)?.toFixed(2)}
                </td>
                {/* <td style={{ paddingBottom: "0px" }}>-</td> */}
              </tr>
              <tr>
                <td style={{ paddingBottom: "0px" }}>Transport Allowance</td>
                <td style={{ paddingBottom: "0px" }}>
                  ₹{(attendanceData?.totalSalary * 0.06)?.toFixed(2)}
                </td>
                {/* <td style={{ paddingBottom: "0px" }}>-</td> */}
              </tr>
              <tr>
                <td style={{ paddingBottom: "0px" }}>Fixed Allowance</td>
                <td style={{ paddingBottom: "0px" }}>
                  ₹{(attendanceData?.totalSalary * 0.19)?.toFixed(2)}
                </td>
                {/* <td style={{ paddingBottom: "0px" }}>-</td> */}
              </tr>
              <tr>
                <td style={{ paddingBottom: "0px" }}>Incentive</td>
                <td style={{ paddingBottom: "0px" }}>
                  ₹{attendanceData?.incentiveEmployee?.toFixed(2) || "0"}
                </td>
                {/* <td style={{ paddingBottom: "0px" }}>-</td> */}
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Gross Earnings</th>
                <th>₹{(+attendanceData?.totalSalary)?.toFixed(2)}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="col-sm-5">
          <table className="table table-borderless">
            <thead>
              <tr style={{ backgroundColor: "rgba(187, 174, 181, 0.16)" }}>
                <th
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Deductions
                </th>
                <th
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </th>
                {/* <th
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  YTD
                </th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingBottom: "0px" }}>Professional Tax</td>
                <td style={{ paddingBottom: "0px" }}>
                  ₹{attendanceData?.employeeData?.pfptDeduction}
                </td>
                {/* <td style={{ paddingBottom: "0px" }}>-</td> */}
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total Deductions</th>
                <th>₹{attendanceData?.employeeData?.pfptDeduction}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div
          style={{
            backgroundColor: "rgba(125, 227, 88, 0.16)",
            alignItems: "center",
            paddingTop: "5px",
          }}
        >
          <h3>
            <span style={{ color: "green" }}>|</span> Total Net Payable: ₹
            {netPay} Indian Rupee -{convertRupeesToWords(netPay)}
          </h3>
        </div>
        <div className="col">
          <p className="mt-2 text-muted">
            <p>**Total Net Payable = Gross Earnings - Total Deductions</p>
          </p>
        </div>
      </div>
      <div className="text-center mt-5">
        <p className="text-muted">
          &mdash; This is a system-generated document. &mdash;
        </p>
      </div>
    </div>
  );
};

export default SalaryBreakDown;
