import * as yup from "yup";

export const changePasswordSchema = yup.object().shape({
    oldPassword: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
