import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
// import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../APIs/request";
import { EmployeeLeaveSchema } from "../../../Schemas/employee/employee_leave_schema";
import CustomInput from "../../CustomComponent/custom_input";
import CustomSelect from "../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { useSelector } from "react-redux";

import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import { CustomErrorResponse } from "../../CustomComponent/custom_error_response";
import { extractDate } from "../../../Components/Functions/date.fun";
const Editleaves = () => {
  const [employeeLeaveInfo, setEmployeeLeaveInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const [leaveCount] = useState({ paid: 0, unpaid: 0 });
  let role = useSelector((store) => store?.userLogin?.user?.Role);
  let user = useSelector((store) => store?.login?.user);
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    // setLoading(true);
    performRequest("GET", `/leave/getleavesbyid/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const fetchedData = res.data;
          const updatedData = res.data.map((el) => {
            if (el.leaveDate) {
              el.leaveDate = extractDate(el.leaveDate.toString());
              return el;
            } else {
              el.leaveStartDate = extractDate(el.leaveStartDate.toString());
              el.leaveEndDate = extractDate(el.leaveEndDate.toString());
              return el;
            }
          });
          setEmployeeLeaveInfo(updatedData);
          setData(fetchedData);
        } else {
          Toast("error", "Error while getting leave data!");
        }
        setLoading(false);
      })
      .catch((err) => {
        CustomErrorResponse(err);
        setLoading(false);
      });
  }, [id]);

  const handleSubmit = (values) => {
    // setLoading(true);
    performRequest("PATCH", `/leave/updateleaves/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Leave data updated successfully!");
          // navigate(`/employee-leave-management/view/${id}`);
        } else {
          Toast("error", "Error while updating leave data!");
        }
        setLoading(false);
      })
      .catch((err) => {
        // Toast("error", "Server Error");
        // console.error("Error", err);
        CustomErrorResponse(err);
        setLoading(false);
      });
  };

  // if (loading || !employeeLeaveInfo || !role) {
  //   return <LoadingPage />;
  // }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="container-xxl">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h2 className="page-title mb-1">
                    <FcCalendar className="fs-2  me-1" /> Update Leaves
                  </h2>
                  <h4 className="text-muted">
                    {/* <StepRoutes /> */} Update leaves status.
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="btn-list justify-content-end">
                    <a href={"/employee-leave-management"}>
                      <button className="btn btn-primary">
                        <BsCalendar2XFill className="me-2 fs-2" /> View All
                        Leaves
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                {data && (
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>Specs.</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-secondary">Account Name</td>
                          <td>
                            {(
                              data?.[0]?.usermodel?.fullName || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Employee Id</td>
                          <td>{data?.[0].userId || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Total Leaves</td>
                          <td>{data?.totalLeaves || "0"}</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Pending Leaves</td>
                          <td>{data?.pendingLeaves || "0"}</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Sick Leaves</td>
                          <td>{leaveCount.paid} (Obtained)</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Casual Leaves</td>
                          <td>{leaveCount.unpaid} (Obtained)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={{ employeeLeaveInfo: employeeLeaveInfo }}
                  validationSchema={EmployeeLeaveSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {(props) => (
                    <Form>
                      
                      <div className="card-body">
                        <FieldArray name="leavesDetails">
                          {({ push, remove }) =>
                            props?.values?.employeeLeaveInfo?.map(
                              (leave, index) => (
                                <div
                                  key={index}
                                  className="row g-3 align-items-center"
                                >
                                  <div className="col-lg">
                                    <label className="form-label input-group-link">
                                      Leave Date
                                    </label>
                                    <div className="w-100 col">
                                      <CustomInput
                                        type="date"
                                        name={`employeeLeaveInfo[${index}].${
                                          leave.leaveDate
                                            ? "leaveDate"
                                            : "leaveStartDate"
                                        }`}
                                        disabled={
                                          leave.leaveStatus === "approved"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className="form-label input-group-link">
                                      Leave Type
                                    </label>
                                    <div className="w-100 col">
                                      <CustomSelect
                                        name={`employeeLeaveInfo[${index}].leaveType`}
                                        disabled={
                                          leave.leaveStatus === "approved"
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="halfDay">
                                          Half Day
                                        </option>
                                        <option value="fullDay">
                                          Full Day
                                        </option>
                                        <option value="multipleDays">
                                          Multiple Day
                                        </option>
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className="form-label input-group-link">
                                      Leave Reason
                                    </label>
                                    <div className="w-100 col">
                                      <CustomInput
                                        type="text"
                                        name={`employeeLeaveInfo[${index}].leaveReason`}
                                        disabled={
                                          leave.leaveStatus === "approved"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className="form-label input-group-link">
                                      Leave Reason Type
                                    </label>
                                    <div className="w-100 col">
                                      <CustomSelect
                                        name={`employeeLeaveInfo[${index}].leaveReasonType`}
                                        disabled={
                                          leave.leaveStatus === "approved"
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="sick">Sick</option>
                                        <option value="casual">Casual</option>
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className="form-label input-group-link">
                                      Leave Status
                                    </label>
                                    <div className="w-100 col">
                                      <CustomSelect
                                        name={`employeeLeaveInfo[${index}].leaveStatus`}
                                        disabled={role !== "superAdmin"}
                                      >
                                        <option value="">Select</option>
                                        <option value="pending">Pending</option>
                                        <option value="approved">
                                          Approved
                                        </option>
                                        <option value="rejected">
                                          Rejected
                                        </option>
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <hr className="my-2 p-0" />
                                </div>
                              )
                            )
                          }
                        </FieldArray>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Leave Tracking" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Editleaves;
