import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import kryzenLogo from "../../assets/hire48logoHorizontal.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "../../Components/layouts/loading";

import CustomInput from "../CustomComponent/custom_input";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import { UserLoginSchema } from "../../Schemas/User/user_login_schema";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import { login } from "../../Redux/User/login_reducer";
import { notification } from "../../Redux/notifications/notificationsReducer";

const AccountLogin = ({ handleModalClose, isPopUp, setIspopup }) => {
  const store = useSelector((store) => store.userLogin);
  const location = window.location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const showPassword = () => {
    setShowPass(!showPass);
  };

  const [email, setEmail] = useState("");
  const handelSubmit = async (values, actions) => {
    setLoading(true);
    performRequest("POST", "/auth/login", values)
      .then((res) => {
        if (res.status === 200 && res.data.msg === "Login successful") {
          Toast("success", "Login successful");
        if(isPopUp){
          handleModalClose();
        }
          dispatch(notification(res.data.notifications));
          delete res.data.notifications;
          dispatch(login(res.data));
          let data = localStorage.getItem("previousUrl");
          let isEmployee = res?.data?.isEmployee
           if(!isEmployee){
            if (data) {
              navigate(data);
            } else {
              navigate("/");
            }
                
           }
        } else if (res.data.msg === "User not found. Please signup.") {
          Toast("error", "Please Check your email and try again");
        } else {
          Toast("error", "wrong credentials");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const handelPasswordReset = (e) => {
    e.preventDefault();
    let closeBtn = document.getElementById("btn-close");
    performRequest("POST", "/resetpass/send-mail", {
      email,
      link: `${location?.origin}/reset-password`,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data === "user not found") {
            setError(`User not found in the database with this email`);
          }
          if (res.data === "Email sent successfully") {
            closeBtn.click();
            Toast(
              "success",
              `Password reset mail sent successfully to: ${email}`
            );
            setEmail("");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };


  
  useEffect(() => {

    if (store.isLoggedIn && store.userlog && store.userlog.firstLogin) {
      console.log('bbbb')
      navigate("/");
    } else if (
      store.isLoggedIn && 
      (store?.user?.isEmployee === true )
    ) {
      console.log('ppppp')
      navigate("/attendance");
    }
  }, [store]);


  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page page-center">
      <div className="container container-tight py-4">
        <div className="text-center mt-4 mb-4">
          <img src={kryzenLogo} height="100" alt="" className="w-25" />
        </div>
        <div className="card card-md">
          <div className="card-body">
            {/* <h2 className="text-center mb-1">Welcome to Hire48.</h2> */}
            <p className="mb-3 text-center">
              Let's revamp the future together!
            </p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={UserLoginSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form id="k-user-login-form">
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <CustomInput
                      type="email"
                      name="email"
                      placeholder="your@email.com"
                      autoComplete="off"
                    />

                    {props.touched.email && props.errors.email && (
                      <p className="text-danger">{props.errors.email}</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Password</label>
                    <div className="input-group input-group-flat">
                      <CustomInput
                        name="password"
                        autoComplete="off"
                        placeholder="Enter password"
                        type={showPass ? "text" : "password"}
                      />

                      <span className="input-group-text">
                        <span
                          onClick={showPassword}
                          className="link-secondary"
                          title="Show password"
                          role="button"
                          data-bs-toggle="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    {props.touched.password && props.errors.password && (
                      <p className="text-danger">{props.errors.password}</p>
                    )}
                  </div>
                  {/* <div className="mb-2">
                    <label className="form-check">
                      <input type="checkbox" className="form-check-input" />
                      <span className="form-check-label">Remember me</span>
                    </label>
                  </div> */}
                  <div className="form-footer">
                    <FormSubmitButton buttonText={"Sign in"} width={"full"} />
                  </div>
                  <div className="my-3 mx-2">
                    {isPopUp ? (
                      <span>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-team"
                          id="btn-forget"
                          onClick={() => navigate("/account-login")}
                        >
                          Forgot your password?
                        </a>
                      </span>
                    ) : (
                      <span>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-team"
                          id="btn-forget"
                        >
                          Forgot your password?
                        </a>
                      </span>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="hr-text">or</div>
          <div className="card-body">
            <p className="p-0 m-0">Don't have an account? register here as</p>
            <br />
             <div className="row">
              <div className="col-md-6">
              {isPopUp ? (
              ""
            ) : (
              <button
              onClick={() => navigate("/employer-onboarding")}
              className="btn btn-primary cursor-pointer m-2 w-100 btn-success"
            >
              I want to Hire!
            </button>
              // <span
              //   onClick={() => {
                  
              //     navigate("/employer-onboarding");
              //   }}
              //   className="p-0 m-0 fs-2 fw-bold text-lime cursor-pointer"
              // >
              //   Employer
              // </span>
            )}

              </div>

              <div className="col-md-6">
                <button
                 onClick={() => {
                  if (isPopUp) {
                    setIspopup(false);
                  } else {
                    return navigate("/account-signup");
                  }
                }}
                  className="btn btn-primary cursor-pointer m-2 w-100 btn-success"
                >
                  I want a job!
                </button>
              </div>
             </div>
         
          </div>
          
          <div
            className="modal modal-blur fade"
            id="modal-team"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Enter your email to recover password
                  </h5>
                  <button
                    type="button"
                    id={"btn-close"}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form onSubmit={handelPasswordReset}>
                  <div className="modal-body">
                    <label className="form-label"> Email Address:</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError("");
                      }}
                      placeholder="Enter email address of your account"
                      className="form-control"
                      required
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn me-auto"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Send recovery email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <p className="p-0 my-1 text-center">
          &copy; 2019-2024 Kryzen Biotech Private Limited.
        </p> */}
      </div>
    </div>
  );
};

export default AccountLogin;
