import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import CustomInput from "../CustomComponent/custom_input";
import CustomSelect from "../CustomComponent/custom_select";
import { Formik, Form } from "formik";
import { InterviewSchema } from "../../Schemas/Interview Schedule/interview_schedule_schema";
import { interviewScheduleInfo } from "../../Schema Info/info";
import Footer from "../../Components/Footer/footer";
import Pagination from "../../Components/layouts/pagination";
import { setCurrentPage } from "../../Redux/Pagination & Filters/filtersReducer";

function Myscheduleinterview() {
  const store = useSelector((store) => store?.userLogin);
  const paginationStore = useSelector((store) => store.filters);
  const [data, setData] = useState([]);
  const [scheduleData, setScheduleData] = useState();
  const [interviewData, setInterviewData] = useState();
  const [scheduleId, setScheduleId] = useState();
  const [page, setCurrPage] = useState(paginationStore?.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const getScheduleInterviewData = () => {
      setLoading(true);
      performRequest(
        "GET",
        `/applyedcandidate/getallscheduleinterviews/${store?.user.UserId}?page=${paginationStore?.currentPage}`
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data?.data);
            setTotalPages(Math.ceil(res?.data?.totalData / 50));
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    };
    getScheduleInterviewData();
  }, [refresh, paginationStore?.currentPage]);
  const handleAction = (value, id) => {
    const data = { hireStatus: value };
    performRequest(
      "PATCH",
      `/applyedcandidate/updateapplyedcandidate/${id}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", `Candidate status updated to '${value}'`);

          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  const handelRemoveSchedule = (id) => {
    const data = { hireStatus: "schedule cancelled" };
    performRequest(
      "PATCH",
      `/applyedcandidate/updateapplyedcandidate/${id}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Interview schedule cancelled");

          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  const getStatusBadge = (status) => {
    if (status === "hire") {
      return <span className="badge bg-green-lt">Hired</span>;
    }
    if (status === "negotiate") {
      return <span className="badge bg-yellow-lt">Negotiate</span>;
    }
    if (status === "reject") {
      return <span className="badge bg-red-lt">Reject</span>;
    }
    if (status === "schedule cancelled") {
      return <span className="badge bg-orange-lt">Schedule Cancelled</span>;
    } else {
      return <span className="badge bg-teal-lt">Interview Scheduled</span>;
    }
  };
  const handelReschedule = (id, data) => {
    setScheduleData({ ...interviewScheduleInfo, ...data });
    setScheduleId(id);
  };
  const handelSubmit = (values, Actions) => {
    const data = { hireStatus: "schedule cancelled" };
    performRequest(
      "PATCH",
      `/applyedcandidate/updateapplyedcandidate/${scheduleId}`,
      {
        interviewSchedule: values,
        hireStatus: "interview scheduled",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Interview schedule updated successfully");
          document.getElementById("closeButton").click();
          setRefresh(!refresh);
          Actions.resetForm();
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
      });
  };
  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="card">
              <div className="card-header">
                <h2 className="m-0 p-0">ALL SCHEDULED INTERVIEW LIST</h2>
              </div>
              <div className="card-body m-0 p-0">
                <div className="table-responsive">
                  {data && data.length > 0 ? (
                    <table className="table table-vcenter table-mobile-md card-table">
                      <thead>
                        <tr>
                          <th>Candidate Name</th>
                          <th>Position</th>
                          <th>Interview Date & Time</th>
                          <th>Status</th>
                          <th>view schedule</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data?.map((el, index) => {
                            return (
                              <tr key={index}>
                                <td data-label="Name">
                                  <div className="d-flex py-1 align-items-center">
                                    <span className="avatar me-2">
                                      <img
                                        src={el?.usermodel?.profilePicture}
                                        alt=""
                                      />
                                    </span>
                                    <div className="flex-fill">
                                      <div className="font-weight-medium">
                                        {el?.usermodel?.fullName}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td data-label="Postion">
                                  <div>{el?.jobsmodel?.jobRole}</div>
                                  <div className="text-secondary">
                                    {el?.jobsmodel?.jobDepartment}
                                  </div>
                                </td>
                                <td
                                  className="text-primary"
                                  data-label="Interview Date"
                                >
                                  {el?.interviewSchedule.date}{" "}
                                  {el?.interviewSchedule.time}
                                  {":00"}
                                </td>
                                <td>
                                  {el?.hireStatus
                                    ? getStatusBadge(el?.hireStatus)
                                    : "N/A"}
                                </td>
                                <td>
                                  <div className="btn-list flex-nowrap">
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#modal-view-schedule"
                                      onClick={() =>
                                        setInterviewData(el.interviewSchedule)
                                      }
                                      className="btn"
                                    >
                                      View Interview details
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className="btn-list flex-nowrap">
                                    <div className="dropdown">
                                      <button
                                        className="btn dropdown-toggle align-text-top"
                                        data-bs-toggle="dropdown"
                                      >
                                        Actions
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end">
                                        {
                                          <button
                                            onClick={() => {
                                              handleAction("hire", el.id);
                                            }}
                                            className="dropdown-item btn btn-primary"
                                          >
                                            Hire
                                          </button>
                                        }
                                        <button
                                          onClick={() => {
                                            handleAction("reject", el.id);
                                          }}
                                          className="dropdown-item btn btn-primary"
                                        >
                                          Reject
                                        </button>
                                        <button
                                          onClick={() => {
                                            handleAction("negotiate", el.id);
                                          }}
                                          className="dropdown-item btn btn-primary"
                                        >
                                          Negotiate
                                        </button>
                                        <button
                                          // onClick={() =>
                                          //   handelRemoveSchedule(el.id)
                                          // }
                                          onClick={() => {
                                            handleAction(
                                              "cancle schedule",
                                              el.id
                                            );
                                          }}
                                          className="dropdown-item btn btn-primary"
                                        >
                                          Cancel Schedule
                                        </button>
                                        <button
                                          data-bs-toggle="modal"
                                          data-bs-target="#modal-team"
                                          onClick={() => {
                                            handelReschedule(
                                              el.id,
                                              el.interviewSchedule
                                            );
                                          }}
                                          className="dropdown-item btn btn-primary"
                                        >
                                          Reschedule
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="m-3">
                      <h3>No schedule inteviews found!</h3>
                    </div>
                  )}
                </div>
                <div>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    pageDatalength={data?.length}
                    showPage={showPage}
                    setShowPage={setShowPage}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* update interview schedule model */}
          <div
            className="modal modal-blur fade"
            id="modal-team"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            onClick={(e) => {
              if (e.target?.id === "modal-team") {
                setScheduleData("");
              }
            }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-team"
                    // id="openScheduleModal"
                  >
                    Add Interview Schedule
                  </h5>
                  <button
                    id={"closeButton"}
                    type="button"
                    onClick={() => setScheduleData("")}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {scheduleData && (
                  <Formik
                    initialValues={scheduleData}
                    validationSchema={InterviewSchema}
                    onSubmit={handelSubmit}
                  >
                    {(props) => (
                      <Form>
                        <div className="modal-body">
                          <div className="card-body">
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3  required">
                                Date of Interview:
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="date" type="date" />
                                {props.touched.date && props.errors.date && (
                                  <p className="text-danger">
                                    {props.errors.date}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Interview Time:
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="time" type="time" />
                                {props.touched.time && props.errors.time && (
                                  <p className="text-danger">
                                    {props.errors.time}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Contact Person Name:
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  name="contactPersonName"
                                  type="text"
                                />
                                {props.touched.contactPersonName &&
                                  props.errors.contactPersonName && (
                                    <p className="text-danger">
                                      {props.errors.contactPersonName}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Contact Person Number:
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  name="contactPersonNumber"
                                  type="text"
                                />
                                {props.touched.contactPersonNumber &&
                                  props.errors.contactPersonNumber && (
                                    <p className="text-danger">
                                      {props.errors.contactPersonNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Interview Mode:
                              </label>
                              <div className="w-100 col">
                                <CustomSelect
                                  name="modeofInterview"
                                  type="text"
                                >
                                  <option value="">select</option>
                                  <option value="video interview">
                                    Video Interview
                                  </option>
                                  <option value="telephonic interview">
                                    Telephonic Interview
                                  </option>
                                  <option value="walk-in interview">
                                    Walk-In Interview
                                  </option>
                                </CustomSelect>
                                {props.touched.modeofInterview &&
                                  props.errors.modeofInterview && (
                                    <p className="text-danger">
                                      {props.errors.modeofInterview}
                                    </p>
                                  )}
                              </div>
                            </div>
                            {props?.values?.modeofInterview ===
                              "telephonic interview" && (
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3 required">
                                  Contact Number:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="contactNumber"
                                    type="text"
                                  />
                                  {props.touched.contactNumber &&
                                    props.errors.contactNumber && (
                                      <p className="text-danger">
                                        {props.errors.contactNumber}
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}
                            {props?.values?.modeofInterview ===
                              "walk-in interview" && (
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3 required">
                                  Interview Address:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput name="address" type="text" />
                                  {props.touched.address &&
                                    props.errors.address && (
                                      <p className="text-danger">
                                        {props.errors.address}
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}
                            {props?.values?.modeofInterview ===
                              "video interview" && (
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3 required">
                                  Meeting Link:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput name="meetLink" type="text" />
                                  {props.touched.meetLink &&
                                    props.errors.meetLink && (
                                      <p className="text-danger">
                                        {props.errors.meetLink}
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn me-auto"
                            onClick={() => setScheduleData("")}
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <FormSubmitButton buttonText="Create Schedule" />
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
          {/* view interview schedule model */}
          <div
            className="modal modal-blur fade"
            id="modal-view-schedule"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Schedule Details</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-3">
                        Date of Interview:
                      </label>
                      <div className="w-100 col ">{interviewData?.date}</div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Interview Time:
                      </label>
                      <div className="w-100 col ">{interviewData?.time}</div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Interview Mode:
                      </label>
                      <div className="w-100 col ">
                        {interviewData?.modeofInterview}
                      </div>
                    </div>
                    {interviewData?.modeofInterview ===
                      "telephonic interview" && (
                      <div className="mb-3 row">
                        <label className="w-33 form-label col-">
                          Contact Number:
                        </label>
                        <div className="w-100 col ">
                          {interviewData?.contactNumber}
                        </div>
                      </div>
                    )}
                    {interviewData?.modeofInterview === "walk-in interview" && (
                      <div className="mb-3 row">
                        <label className="w-33 form-label col-">
                          Interview Address:
                        </label>
                        <div className="w-100 col ">
                          {interviewData?.address}
                        </div>
                      </div>
                    )}
                    {interviewData?.modeofInterview === "video interview" && (
                      <div className="mb-3 row">
                        <label className="w-33 form-label col-">
                          Meeting Link:
                        </label>
                        <div className="w-100 col ">
                          {interviewData?.meetLink}
                        </div>
                      </div>
                    )}
                    {interviewData?.contactPersonName && (
                      <div className="mb-3 row">
                        <label className="w-33 form-label col-">
                          Contact Person:
                        </label>
                        <div className="w-100 col ">
                          {interviewData?.contactPersonName}
                        </div>
                      </div>
                    )}
                    {interviewData?.contactPersonNumber && (
                      <div className="mb-3 row">
                        <label className="w-33 form-label col-">
                          Contact Person Number:
                        </label>
                        <div className="w-100 col ">
                          {interviewData?.contactPersonNumber}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn me-auto"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Myscheduleinterview;
