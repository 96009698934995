import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import { useSelector } from "react-redux";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";

const JobApplications = () => {
  const store = useSelector((store) => store?.userLogin);
  const [viewApplicants, setViewApplicants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const handleViewApplicantList = (id) => {
    performRequest(
      "GET",
      `/applyedcandidate/getallapplyedcandidatebyjobsid/${id}`
    )
      .then((res) => {
        if (res.status === 200) {
          setViewApplicants(res.data);
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (id) {
      handleViewApplicantList(id);
    }
  }, [id, refresh]);
  const handleShortlist = (id) => {
    setLoading(true);
    performRequest("PATCH", `/applyedcandidate/updateapplyedcandidate/${id}`, {
      shortlistBy: store.user?.UserId,
      shortlistByRole: store.user?.Role,
      shortlistStatus: true,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Candidate shortlisted successfully");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  const handleShortlistRemove = (id) => {
    setLoading(true);
    performRequest("PATCH", `/applyedcandidate/updateapplyedcandidate/${id}`, {
      shortlistBy: "",
      shortlistByRole: "",
      shortlistStatus: false,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Candidate removed from shortlisted list");
          setRefresh(!refresh);
        } else {
          Toast("error", "Server Error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.message);
        } else {
          Toast("error", "Server Error");
        }
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="card">
              <div className="card-header">
                <h2 className="m-2">All Application</h2>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>name</th>
                        <th>education</th>
                        <th>skills</th>
                        <th>description</th>
                        <th>actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewApplicants?.map((el, i) => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              <img
                                width={"100px"}
                                src={el?.usermodel?.profilePicture}
                                alt=""
                                srcSet=""
                              />
                            </td>
                            <td className="text-muted">
                              {el?.usermodel?.fullName}
                            </td>
                            <td className="text-muted ">
                              {el?.usermodel?.resumemodel?.education.map(
                                (el, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="datagrid-content"
                                    >
                                      {el.educationSelect1}
                                    </div>
                                  );
                                }
                              )}
                            </td>
                            <td className="text-muted">
                              <div className="d-flex">
                                {el?.usermodel?.resumemodel?.skills?.map(
                                  (skills, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className=" avatar avatar-md m-1"
                                        style={{
                                          backgroundImage: `url(${skills.icon})`,
                                        }}
                                      ></div>
                                    );
                                  }
                                )}
                              </div>
                            </td>
                            <td>{el?.usermodel?.resumemodel?.description}</td>
                            {!el.shortlistStatus && (
                              <td>
                                {" "}
                                <button
                                  onClick={() => {
                                    handleShortlist(el.id);
                                  }}
                                  className="btn btn-primary mt-1"
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    fontSize="1em"
                                    className="me-2"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path>
                                  </svg>{" "}
                                  Shortlist{" "}
                                </button>
                              </td>
                            )}
                            {el.shortlistStatus && (
                              <td>
                                {" "}
                                <button
                                  onClick={() => {
                                    handleShortlistRemove(el.id);
                                  }}
                                  className="btn btn-warning mt-1"
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    fontSize="1em"
                                    className="me-2"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path>
                                  </svg>{" "}
                                  Remove
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default JobApplications;
