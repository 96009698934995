// export const backendUrl = "http://localhost:4501";
// export const backendUrl = "https://hire48-server-vxtxmo7zma-el.a.run.app";
export const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
export const superAdmin = "superAdmin";
export const candidate = "candidate";
export const recruiter = "recruiter";
export const company = "company";
export const purchaseStaff = "purchaseStaff";
export const saleStaff = "saleStaff";
export const opsStaff = "opsStaff";
export const inspectionStaff = "inspectionStaff";
export const marketingStaff = "marketingStaff";
export const labourStaff = "labourStaff";
export const salesAgency = "salesAgency";
export const ITStaff = "ITStaff";
export const SSMStaff = "SSMStaff";
export const salesManagerStaff = "salesManagerStaff";
export const clientFeedback = "clientFeedback";
export const accountStaff = "accountStaff";
