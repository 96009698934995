import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import { RxCross2 } from "react-icons/rx";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import performRequest from "../../../APIs/request";
import { useNavigate, useParams } from "react-router";
import InputWithSearchAndSelect from "../../../Components/layouts/input_search_&_select";
import { useSelector } from "react-redux";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";

const JobData = {
  id: 5,
  companyName: "www.kryzen.com",
  jobDepartment: "Advertising",
  jobRole: "Copywriter",
  workType: "Full time",
  workShift: "Day shift",
  jobLocation: "city avenue",
  jobCity: "pune",
  jobState: "maharastra",
  receiveApplicationFrom: "2024-07-27",
  payType: "Fixed Only",
  additionalPerks: [
    "Flexible working hours",
    "Overtime pay",
    "Joining bonus",
    "PF",
    "Laptop",
  ],
  joiningFeeOrDeposit: "Yes",
  salaryPerMonthMax: 77282,
  salaryPerMonthMin: 24444,
  incetivePerMonth: 222,
  englishLevel: "No English",
  experienceRequired: "Experienced Only",
  minimumExperience: "4",
  minimumEducation: "Diploma",
  JobDescription:
    "\nWriting an Effective Job Description | Human Resources\n\nWright State University\nhttps://www.wright.edu › human-resources › writing-an...\nA job description contains the following components: job title, job purpose, job duties and responsibilities, required qualifications, preferred qualifications, ...\n\nHow to Write a Job Description\n\nIndeed\nhttps://www.indeed.com › hire › how-to-write-a-job-desc...\n5 Jun 2024 — The key to writing effective job descriptions is to find the perfect balance between providing enough detail so candidates understand the role ...\n",
  interviewType: "Telephonic/ Online Interview",
  interviewAddress: "city avenue 319",
  interviewStartDate: "2024-07-31",
  interviewEndDate: "2024-07-31",
  interviewEndTime: "18:10",
  interviewStartTime: "10:10",
  otherInstructions:
    "A job description or JD is a written narrative that describes the general tasks, or other related duties, and responsibilities of a position",
  createdAt: "2024-07-19T06:41:57.000Z",
  updatedAt: "2024-07-19T06:41:57.000Z",
  userId: null,
};

const JobDetails = () => {
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tests, setTests] = useState([]);
  const [testsUpload, setTestsUplode] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/jobs/getjobbyid/${id}`)
      .then((res) => {
        setData(res.data);
        if (res.data?.skillsDetails && res.data?.skillsDetails.length > 0) {
          let data = res.data?.skillsDetails.map((el) => {
            return el.skill;
          });
          setSelected(data);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", "/test/alltests")
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setTests(res.data);
          let data = res.data.map((el) => {
            return el.name;
          });

          setSkills(data);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selected.length > 0) {
      let arr = [];
      for (let i = 0; i < selected.length; i++) {
        for (let j = 0; j < tests.length; j++) {
          if (tests[j].name === selected[i]) {
            arr.push({
              skill: tests[j].name,
              id: tests[j].id,
              icon: tests[j].icon,
              passingScore: tests[j].passingScore,
            });
            break;
          }
        }
      }
      setTestsUplode(arr);
    }
  }, [selected]);
  const removeFilter = (ele) => {
    let arr = selected.filter((f) => f !== ele);
    setSelected(arr);
  };

  const publishJob = () => {
    if (selected.length == 0) {
      return Toast("error", "Please select the skill");
    }

    let updateTestsUpload = [...testsUpload];
    if (testsUpload?.length === 0) {
      if (data?.skillsDetails.length > 0) {
        updateTestsUpload = [...data?.skillsDetails];
        setTestsUplode(updateTestsUpload);
      }
    }
    performRequest("PATCH", `/jobs/updatejob/${id}`, {
      skillsDetails: updateTestsUpload,
      isActive: true,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Your Job Published Successfully");
          navigate(`/view-jobs/${id}`);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <style>
        {`
  .Cross-hover {
opacity: 0;
  }
  .Cross-hover:hover {
    opacity: 1;
  }
`}
      </style>
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                REVIEW & PUBLISH YOUR JOB
              </h2>
              <p className="section-description mb-4 p-0">
                Add up some more details to make sure job post targets right
                candidates.
              </p>
            </div>
          </div>
          <div className="row row-cards">
            {data ? (
              <div className="card">
                <div className="card-body">
                  <div className="card bg-green-lt p-2 my-4">
                    <div className="row">
                      <div className="col-lg-3 col-md-6  text-center">
                        <h1 className="p-0 m-0 fs-1">12,450 - 18,590</h1>
                        <p className="p-0 m-0">
                          Candidates eligible Across India.
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6  text-center">
                        <h1 className="p-0 m-0 fs-1">250 - 490</h1>
                        <p className="p-0 m-0">
                          Candidates eligible in {data?.jobCity}.
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6  text-center">
                        <h1 className="p-0 m-0 fs-1">65 - 92</h1>
                        <p className="p-0 m-0">
                          Candidates have skills you requested.
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-6  text-center">
                        <h1 className="p-0 m-0 fs-1">12 - 17</h1>
                        <p className="p-0 m-0">Possible shortlist options.</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <div className="card bg-primary-lt p-2 m-1">
                        <h3>Job Details:</h3>
                        <p className="my-1 p-0">
                          You want to hire candidate for position of{" "}
                          {data?.jobRole} in department of {data?.jobDepartment}{" "}
                          in {data?.companyName}. This is a {data?.workType}{" "}
                          position in {data?.workShift}. The location of the
                          employment will be at {data?.jobLocation} in city of{" "}
                          {data?.jobCity}.
                        </p>

                        {/* <button
                        className="btn btn-primary-lt my-2 w-50"
                        type="submit"
                      >
                        <FaArrowAltCircleLeft
                          fontSize={"1em"}
                          className="me-1"
                        />{" "}
                        Edit Job Details
                      </button> */}
                      </div>
                      <div className="card bg-primary-lt p-2 m-1">
                        <h3>Job Description:</h3>
                        <p className="my-1 p-0">{data?.JobDescription}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="card bg-primary-lt p-2 m-1">
                        <h3>Candidate Profile:</h3>
                        <div className="row">
                          <label className="form-label col-6">
                            What is the pay type?
                          </label>
                          <div className="w-100 col">{data?.payType}</div>
                        </div>
                        <div className="row">
                          <label className="form-label col-6">
                            Fixed salary/Mo.
                          </label>
                          <div className="w-100 col">
                            ₹{data?.salaryPerMonthMin} - ₹
                            {data?.salaryPerMonthMax}
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label col-6">
                            Average Incentive/Mo.
                          </label>
                          <div className="w-100 col">
                            ₹{data?.incetivePerMonth}
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label col-6">
                            Minimum Education
                          </label>
                          <div className="w-100 col">
                            {data?.minimumEducation}
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label col-6">
                            English level required
                          </label>
                          <div className="w-100 col">{data?.englishLevel}</div>
                        </div>

                        <div className="row">
                          <label className="form-label col-6">
                            Total experience required
                          </label>
                          <div className="w-100 col">
                            {data?.experienceRequired}
                          </div>
                        </div>
                        {/* <div className="row">
                        <label className="form-label col-6">
                          Minimum experience
                        </label>
                        <div className="w-100 col">
                          {data?.minimumExperience} Years.
                        </div>
                      </div> */}

                        {/* <div className="row">
                        <label className="form-label col-6">
                          Type of Interview
                        </label>
                        <div className="w-100 col">{data?.interviewType}</div>
                      </div> */}
                        <div className="row">
                          <label className="form-label col-6">
                            Interview Address
                          </label>
                          <div className="w-100 col">
                            {data?.interviewAddress}
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label col-6">
                            Interview Period
                          </label>
                          <div className="w-100 col">
                            {data?.interviewStartDate} -{" "}
                            {data?.interviewEndDate}
                          </div>
                        </div>
                      </div>
                      <div className="card bg-primary-lt p-2 m-1">
                        <div className="row">
                          <label className="form-label col-3">Perks:</label>
                          <div className="w-100 col">
                            {data?.additionalPerks?.map((key) => {
                              return (
                                <span key={key} className="badge m-1">
                                  {key}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-12">
                      <div className="card bg-primary-lt p-2 m-1">
                        <h3>Required Skills:</h3>
                        <div className="m-0 p-0" id="req-skills">
                          {testsUpload?.map((data, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  backgroundImage: `url(${data.icon})`,
                                }}
                                className="avatar avatar-lg m-1 cursor-pointer"
                              >
                                <RxCross2
                                  className="Cross-hover"
                                  style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                  onClick={() => removeFilter(data.skill)}
                                />
                              </div>
                            );
                          })}
                        </div>
                        {skills && selected && (
                          <InputWithSearchAndSelect
                            selected={selected}
                            setSelected={setSelected}
                            arrayData={skills}
                          />
                        )}
                        <p>
                          Mark required skills. Candidate who have passed those
                          skills test will be shortlisted as applicant.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <button
                      className="btn btn-primary-lt"
                      type="submit"
                      onClick={() => navigate(`/edit-jobs/${id}`)}
                    >
                      <FaArrowAltCircleLeft fontSize={"1em"} className="me-1" />{" "}
                      Edit Job Details
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={publishJob}
                    >
                      <FaArrowAltCircleRight
                        fontSize={"1em"}
                        className="me-1"
                      />{" "}
                      Publish Job
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card p-4">
                <h3>
                  Job not found with id: {id} or you don't have access to this
                  job post.
                </h3>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default JobDetails;
