import performRequest from "../../APIs/request";
import Marquee from "react-fast-marquee";
import React, { useState, useEffect } from "react";

function Scrollbar({ direction }) {
  const [userData, setuserData] = useState([]);
  useEffect(() => {
    const getResumeBasedOnSkills = () => {
      performRequest("GET", "/demouser/alldemouser")
        .then((res) => {
          setuserData(res.data);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    };
    getResumeBasedOnSkills();
  }, []);
  return (
    <>
      <div style={{ display: "flex" }}>
        <Marquee
          direction={`${direction}`}
          className=""
          style={{ display: "flex" }}
        >
          {userData.map((data, index) => (
            <div key={index}>
              <div
                className="border m-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "5px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  marginBottom: "5px",
                  width: "250px",
                  borderRadius: "15px",
                  background: "rgb(192,230,255)",
                  background:
                    "linear-gradient(153deg, rgba(192,230,255,1) 0%, rgba(249,254,255,1) 100%)",
                  boxShadow:
                    "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <img
                  src={data.image}
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                ></img>
                <p
                  style={{
                    marginLeft: "8px",
                    marginTop: "8px",
                    fontWeight: "500",
                  }}
                >
                  {data.name} got shortlisted{" "}
                  {Math.floor(Math.random() * 12) + 1} hours ago
                </p>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </>
  );
}

export default Scrollbar;
