import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import JobsList from "./Browse Top Hires/jobs_lists";
import performRequest from "../../APIs/request";

import {
  FaAddressCard,
  FaArrowAltCircleRight,
  FaBolt,
  FaFilter,
  FaMapMarker,
  FaMoneyBill,
} from "react-icons/fa";
import { FaArrowUpShortWide } from "react-icons/fa6";
import CandidateList from "./Browse Top Candidate/candidate_list";
import { useNavigate } from "react-router";
const department = [
  { dep: "Admin & Back Office" },
  { dep: "Advertising" },
  { dep: "Aviation & Aerospace" },
  { dep: "Banking & Insurance" },
  { dep: "Fitness & Personal Care" },
  { dep: "Communication" },
  { dep: "Computer Operator" },
  { dep: "Construction Consulting" },
  { dep: "Site Engineering Consulting" },
  { dep: "Content & Journalism" },
  { dep: "CSR & Social Service" },
  { dep: "Customer Support" },
  { dep: "Data Science & Analytics" },
  { dep: "Delivery & Logistics" },
  { dep: "Digital Marketing" },
  { dep: "Energy & Mining" },
  { dep: "Hardware & Networks" },
  { dep: "Environment & Safety" },
  { dep: "Facility Management" },
  { dep: "Finance & Accounting" },
  { dep: "Financial Services" },
  { dep: "Healthcare & Hospital Staff" },
  { dep: "Human Resources" },
  { dep: "IT & Information Security" },
  { dep: "Legal & Regulatory" },
  { dep: "Maintenance Services" },
  { dep: "Marketing & Branding" },
  { dep: "Media Operations" },
  { dep: "Media & Entertainment" },
  { dep: "Product Management" },
  { dep: "Production, Manufacturing" },
  { dep: "Purchase & Supply Chain" },
  { dep: "Quality Assurance" },
  { dep: "Research & Development" },
  { dep: "Retail & eCommerce" },
  { dep: "Risk Mang & Compliance" },
  { dep: "Sales & BD" },
  { dep: "Security Services" },
  { dep: "Shipping & Maritime" },
  { dep: "Software Engineering" },
  { dep: "Strategic & Management" },
  { dep: "Tailoring, Apparel" },
  { dep: "Teaching & Training" },
  { dep: "UX, Design & Architecture" },
];
const cData = [
  {
    id: 3,
    workProfile: "Software engineer",
    profilePicture:
      "https://res.cloudinary.com/dph1m5tq7/image/upload/v1721722917/hire48TestLogo/xap2l7yqsqnxud9yt0po.jpg",
    name: "Suraj Bhan Singh",
    email: "surajbhan2boaz@gmail.com",
    mobile: "8920213684",
    country: "Argentina",
    state: "Misiones",
    city: "Faridabad",
    description:
      "I'm a passionat compute scence enthusiast with a focus on full-stack development using Node.js, JavaScript, HTML, CSS, React and MongoDB. I have experience working on various projects, including web applications and websites. I believe in creating user-friendly website.",
    hobbies: "Cricket, Music and Movies",
    education: [
      {
        summary:
          "I believe in creating user-friendly and vrtunities anfree to rea\nnew opportunities and projects, so feel free to reach",
        endDate1: "2024-03",
        startDate1: "2024-05",
        university_1: "indian institute  of science",
        educationSelect1: "Primary Education",
      },
      {
        summary:
          "I admit in creating user-friendly and visuall creating I admit in crely and visually appefree ppor jkkgkjhkjh",
        endDate1: "2024-06",
        startDate1: "2024-01",
        university_1: "Nursing insitute of purne",
        educationSelect1: "Associate of Arts",
      },
    ],
    skills: [],
    workExperience: [
      {
        exitDate: "2024-02",
        location: "pune",
        position: "software enginner",
        companyName: "kryzen",
        joiningDate: "2024-01",
        locationType: "Work From Office",
        employmentType: "Full Time",
        jobDescription:
          "in creating user-friendly and vrtunities anfree to rea new opportunities and projects, so feel free to reach",
      },
    ],
    personalDetails: [
      {
        city: "Faridabadt",
        name: "Suraj Bhan Singh",
        email: "surajbhan2boaz@gmail.com",
        state: "Australian Capital Territory",
        mobile: "8920213687",
        userId: "1",
        country: "Australia",
        workProfile: "Software engineer",
        profilePicture:
          "https://res.cloudinary.com/dph1m5tq7/image/upload/v1721908953/hire48TestLogo/y4ncioblhb6tdozrmplh.jpg",
      },
    ],
    languages: [
      {
        readLanguage: ["Hindi"],
        languageSpeak: ["Punjabi"],
        languageWrite: ["Dogri"],
      },
    ],
    additionalInformation: [
      "Immediate Joinee",
      "Internship Only",
      "Available for Night Shifts",
    ],
    Projects: [],
    awardAndCertificate: [
      {
        givenBy: "Google ",
        acDescription:
          "Our AI algorithm selects the best-fit candidates for your job role, helping employers like you connect directly with candidates who have relevant experience and the desired skills.",
        awardAndCertificateName: "Google cloud certification",
      },
      {
        givenBy: "",
        acDescription: "",
        awardAndCertificateName: "",
      },
    ],
    createdAt: "2024-07-19T11:12:00.000Z",
    updatedAt: "2024-07-25T17:17:00.000Z",
    userId: "1",
    userModel: {
      fullName: "",
      userRole: "candidate",
    },
  },
];
const BrowesTopCandidate = () => {
  const [candidates, setCandidates] = useState([cData]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const removeFilter = (ele) => {
    ele.ele.checked = false;
    let arr = filters.filter((f) => f.value !== ele.value);
    setFilters(arr);
  };
  const handelCheckboxChange = (e) => {
    if (e.target.checked) {
      let arr = filters?.filter((ele) => ele.value !== e.target.value);
      let obj = {
        key: e.target.name,
        value: e.target.value,
        ele: e.target,
      };
      arr.unshift(obj);
      setFilters(arr);
    } else {
      let arr = filters?.filter((ele) => ele.value !== e.target.value);
      setFilters(arr);
    }
  };
  const handelRedioChnage = (e) => {
    if (e.target.checked) {
      let arr = filters?.filter((ele) => ele.key !== e.target.name);
      let obj = {
        key: e.target.name,
        value: e.target.value,
        ele: e.target,
      };
      arr.unshift(obj);
      setFilters(arr);
    }
  };

  // useEffect(() => {
  //   let arr = filters.map((filter) => {
  //     return {
  //       key: filter.key,
  //       value: filter.value,
  //     };
  //   });
  //   performRequest("POST", "/jobs/getjobsbyfilters", { filters: arr })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setJobData(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error", err);
  //     });
  // }, [filters]);

  useEffect(() => {
    performRequest("GET", "/resume/getallresume")
      .then((res) => {
        if (res.status === 200) {
          setCandidates(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                FIND RELATIVE CANDIDATE AROUND YOU.
              </h2>
              <p className="section-description mb-4 p-0">
                Search from 25000+ candidate listed on HIRE48.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="card">
                  <h3 className="m-2 p-2">
                    <FaFilter fontSize={"1.2em"} className="me-2" /> Filter your
                    search
                  </h3>
                  <hr className="m-0 p-0" />
                  <div className="m-2 p-2">
                    <h4 className="m-0 p-0">Filters Applied:</h4>
                    {filters?.map((ele, index) => {
                      return (
                        <span
                          className="m-1 tag"
                          key={index}
                          onClick={() => removeFilter(ele)}
                        >
                          {ele.value}
                          <button className="btn-close"></button>
                        </span>
                      );
                    })}
                    <hr className="my-2 p-0" />
                    <div className="card my-1">
                      <div
                        id="faq-1"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-1-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />{" "}
                                Sort By
                              </strong>
                            </button>
                          </div>

                          <div
                            id="faq-1-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-1"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="Relevant"
                                      name="sortby"
                                    />
                                    <span className="form-check-label">
                                      Relevant for your skills
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="Salary - High to low"
                                      name="sortby"
                                    />
                                    <span className="form-check-label">
                                      Salary - high to low
                                    </span>
                                  </label>
                                </div>
                                {/* <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="Distance - Near to far"
                                      name="sortby"
                                    />
                                    <span className="form-check-label">
                                      Distance - Near to far
                                    </span>
                                  </label>
                                </div> */}
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="Date posted - New to Old"
                                      name="sortby"
                                    />
                                    <span className="form-check-label">
                                      Date posted - new to old
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      <div
                        id="faq-9"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-9-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />
                                Department
                              </strong>
                            </button>
                          </div>
                          <div
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            id="faq-9-1"
                            data-bs-parent="#faq-9"
                          >
                            <div
                              className="accordion-body pt-0"
                              style={{
                                maxHeight: "20rem",
                                overflow: "scroll",
                              }}
                            >
                              <div>
                                {department.map((dep, index) => {
                                  return (
                                    <div key={index}>
                                      <label className="my-1 form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={(e) =>
                                            handelCheckboxChange(e)
                                          }
                                          name="Department"
                                          value={dep?.dep}
                                        />
                                        <span className="form-check-label">
                                          {dep?.dep}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card my-1">
                      <div
                        id="faq-2"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button collapsed fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-2-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />
                                Experience
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-2-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-2"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <p className="text-muted">
                                  Select your work experience.
                                </p>
                                <div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="Fresher"
                                        name="experience"
                                      />
                                      <span className="form-check-label">
                                        Fresher
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="0 - 1 year"
                                        name="experience"
                                      />
                                      <span className="form-check-label">
                                        0 - 1 year / Internship
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="1 - 3 years"
                                        name="experience"
                                      />
                                      <span className="form-check-label">
                                        1 - 3 years
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="3+ years"
                                        name="experience"
                                      />
                                      <span className="form-check-label">
                                        3+ years
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      <div
                        id="faq-3"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button collapsed fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-3-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />
                                Salary
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-3-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-3"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <p className="text-muted">
                                  Your monthly salary expectation.
                                </p>
                                <div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="5000 - 15000 ₹"
                                        name="salary"
                                      />
                                      <span className="form-check-label">
                                        ₹5,000 - ₹15,000
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="25000 - 50000 ₹"
                                        name="salary"
                                      />
                                      <span className="form-check-label">
                                        ₹15,000 - ₹50,000
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="50000 - 100000 ₹"
                                        name="salary"
                                      />
                                      <span className="form-check-label">
                                        ₹50,000 - ₹1,00,000
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="100000 - 500000 ₹"
                                        name="salary"
                                      />
                                      <span className="form-check-label">
                                        ₹1,00,000 - ₹5,00,000
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="500000+ ₹"
                                        name="salary"
                                      />
                                      <span className="form-check-label">
                                        ₹5,00,000+
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      {/* <div
                    id="faq-4"
                    className="accordion "
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div>
                      <div className="accordion-header my-0" role="tab">
                        <button
                          className="accordion-button collapsed fs-3"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-4-1"
                        >
                          <strong>Date posted</strong>
                        </button>
                      </div>
                      <div
                        id="faq-4-1"
                        className="accordion-collapse collapse show"
                        role="tabpanel"
                        data-bs-parent="#faq-4"
                      >
                        <div className="accordion-body pt-0">
                          <div>
                            <div>
                              <label className="my-1 form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  style={{
                                    width: "18px",
                                    height: `18px`,
                                  }}
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let arr = filters?.filter(
                                        (ele) => ele.key !== e.target.name
                                      );
                                      let obj = {
                                        key: e.target.name,
                                        value: e.target.value,
                                        ele: e.target,
                                      };
                                      arr.unshift(obj);
                                      setFilters(arr);
                                    }
                                  }}
                                  value="All Dates"
                                  name="datePosted"
                                />
                                <span className="form-check-label">All</span>
                              </label>
                            </div>
                            <div>
                              <label className="my-1 form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  style={{
                                    width: "18px",
                                    height: `18px`,
                                  }}
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let arr = filters?.filter(
                                        (ele) => ele.key !== e.target.name
                                      );
                                      let obj = {
                                        key: e.target.name,
                                        value: e.target.value,
                                        ele: e.target,
                                      };
                                      arr.unshift(obj);
                                      setFilters(arr);
                                    }
                                  }}
                                  value="Last 24 hours"
                                  name="datePosted"
                                />
                                <span className="form-check-label">
                                  Last 24 hours
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="my-1 form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  style={{
                                    width: "18px",
                                    height: `18px`,
                                  }}
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let arr = filters?.filter(
                                        (ele) => ele.key !== e.target.name
                                      );
                                      let obj = {
                                        key: e.target.name,
                                        value: e.target.value,
                                        ele: e.target,
                                      };
                                      arr.unshift(obj);
                                      setFilters(arr);
                                    }
                                  }}
                                  value="Last 3 days"
                                  name="datePosted"
                                />
                                <span className="form-check-label">
                                  Last 3 days
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="my-1 form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  style={{
                                    width: "18px",
                                    height: `18px`,
                                  }}
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let arr = filters?.filter(
                                        (ele) => ele.key !== e.target.name
                                      );
                                      let obj = {
                                        key: e.target.name,
                                        value: e.target.value,
                                        ele: e.target,
                                      };
                                      arr.unshift(obj);
                                      setFilters(arr);
                                    }
                                  }}
                                  value="Last 7 days"
                                  name="datePosted"
                                />
                                <span className="form-check-label">
                                  Last 7 days
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                      <div
                        id="faq-5"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button collapsed fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-5-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />
                                Education
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-5-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-5"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <p className="text-muted">
                                  Your highest education.
                                </p>
                                <div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="10 or Below 10th"
                                        name="highestEducation"
                                      />
                                      <span className="form-check-label">
                                        10<sup>th</sup> or Below 10<sup>th</sup>{" "}
                                        / S.S.C.
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="12th pass"
                                        name="highestEducation"
                                      />
                                      <span className="form-check-label">
                                        12<sup>th</sup> Pass / H.S.C.
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="diploma"
                                        name="highestEducation"
                                      />
                                      <span className="form-check-label">
                                        Diploma / ITI
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="Graduate"
                                        name="highestEducation"
                                      />
                                      <span className="form-check-label">
                                        Graduation (4Yrs)
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="my-1 form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        onChange={handelRedioChnage}
                                        value="Post Graduate"
                                        name="highestEducation"
                                      />
                                      <span className="form-check-label">
                                        PG / MS / Masters
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      <div
                        id="faq-6"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button collapsed fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-6-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />
                                Preferred work mode
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-6-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-6"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      value="Work from Home"
                                      name="workMode"
                                    />
                                    <span className="form-check-label">
                                      Work from home
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      value="Work from office"
                                      name="workMode"
                                    />
                                    <span className="form-check-label">
                                      Work from office
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      value="Work from field"
                                      name="workMode"
                                    />
                                    <span className="form-check-label">
                                      Work from field
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      <div
                        id="faq-7"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button collapsed fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-7-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />{" "}
                                Preferred work duration
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-7-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-7"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      value="Full time"
                                      name="workType"
                                    />
                                    <span className="form-check-label">
                                      Full time (8Hrs /Day)
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <label className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      value="Part Time"
                                      name="workType"
                                    />
                                    <span className="form-check-label">
                                      Part Time (4Hrs / Day)
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      <div
                        id="faq-8"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div>
                          {" "}
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button collapsed fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-8-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />{" "}
                                Preferred work shift
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-8-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-8"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <div>
                                  <label className="my-1 form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      value="Day shift"
                                      name="workShift"
                                    />
                                    <span className="form-check-label">
                                      Day shift
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <label className="my-1 form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => handelCheckboxChange(e)}
                                      name="workShift"
                                      value="Night shift"
                                    />
                                    <span className="form-check-label">
                                      Night shift
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-1">
                      <div
                        id="faq-10"
                        className="accordion "
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="accordion-item">
                          <div className="accordion-header my-0" role="tab">
                            <button
                              className="accordion-button fs-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#faq-10-1"
                            >
                              <strong>
                                <FaArrowUpShortWide
                                  fontSize={"1.2em"}
                                  className="me-2"
                                />{" "}
                                English communication
                              </strong>
                            </button>
                          </div>
                          <div
                            id="faq-10-1"
                            className="accordion-collapse collapse show"
                            role="tabpanel"
                            data-bs-parent="#faq-10"
                          >
                            <div className="accordion-body pt-0">
                              <div>
                                <p className="text-muted">
                                  Your English communication level.
                                </p>
                                <div>
                                  <label className="my-1 form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      style={{
                                        width: "18px",
                                        height: `18px`,
                                      }}
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="No English"
                                      name="englishLevel"
                                    />
                                    <span className="form-check-label">
                                      No English
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <label className="my-1 form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="Basic English"
                                      name="englishLevel"
                                    />
                                    <span className="form-check-label">
                                      Basic English
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <label className="my-1 form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={handelRedioChnage}
                                      value="Good English"
                                      name="englishLevel"
                                    />
                                    <span className="form-check-label">
                                      Advanced English
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="card pb-3">
                  <div className="m-3 p-0">
                    <h3 className="m-0 p-0">Candidates Currently Available</h3>
                    <p className="m-0 p-0">
                      Total candidate found: {candidates?.length || 0}
                    </p>
                  </div>
                  <hr className="m-0 p-0" />
                  <CandidateList candidates={candidates} />
                </div>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="card card-active text-center p-1">
                  <h4 className="my-1 p-0 fs-4">
                    CREATE PROFILE & GET JOB OFFER IN 48HRS!
                  </h4>

                  <hr className="my-1 p-0" />
                  <p className="text-secondary">
                    <strong>PROFESSIONAL</strong>
                    <br /> RESUME BUILDER
                  </p>
                  <p className="text-secondary">
                    <strong>AI BASED</strong>
                    <br />
                    MOCKUP PREP
                  </p>
                  <p className="text-secondary">
                    <strong>SOFT SKILL</strong>
                    <br />
                    FREE TRAINING
                  </p>
                  <p className="text-secondary">
                    <strong>TEST BASED</strong>
                    <br />
                    SKILL BADGE
                  </p>
                  <button
                    onClick={() => navigate("/create-jobs")}
                    className="btn btn-primary mx-3 my-1"
                  >
                    <FaAddressCard fontSize={"1.2em"} className="me-2" /> Create
                    Job
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default BrowesTopCandidate;
