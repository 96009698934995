import React from 'react';

const Buttonloader = ({className}) => {
  const loaderStyle = {
    animation: 'rotate 2s linear infinite',
    width: '24px',
    height: '24px'
  };

  const keyframes = `
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  return (
    <button className={className} disabled  >
      <style>{keyframes}</style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={loaderStyle}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 3a9 9 0 1 0 9 9" />
      </svg>
    </button>
  );
};

export default Buttonloader;
