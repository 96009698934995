import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import { useNavigate, useParams } from "react-router";
import { TestCounter } from "./test_counter";
import TestResults from "./test_results";
import performRequest from "../../../APIs/request";
const test = {
  id: 1,
  type: "",
  level: "basic",
  icon: "",
  name: "Access",
  description:
    "Title: Basic Access Skills Assessment for Software & Product Engineering\r\n\r\nObjective:\r\nThe objective of this test is to assess the basic skills and knowledge of candidates in using Microsoft Access, particularly for roles in the Software & Product Engineering department. This test is designed to ensure that candidates have a foundational understanding of Access to manage databases, create queries, forms, and reports, and understand key concepts such as relationships, primary keys, and data integrity.\r\n\r\nInstructions:\r\nThis test consists of 20 multiple-choice questions.\r\nEach question has four options, with only one correct answer.\r\nSelect the most appropriate answer for each question.\r\nThere is no negative marking for incorrect answers.\r\nThe test is timed; you will have 30 minutes to complete all questions.",
  passingScore: "20",
  questionsAnsOpt: [
    {
      answer: "optionB",
      options: {
        optionA: " Creating websites",
        optionB: "Managing databases",
        optionC: "Editing videos",
        optionD: "Designing graphics",
      },
      question: "What is Microsoft Access primarily used for?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " A unique identifier for each record in a table",
        optionB: "A key to open the software",
        optionC: "A tool to format text",
        optionD: "A method to import data",
      },
      question: "What is a primary key in Access ?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "Number",
        optionB: "Currency",
        optionC: "Text",
        optionD: "Date/Time",
      },
      question: " Which of the following data types can store text in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "To print documents",
        optionB: " To filter and analyze data",
        optionC: "To create charts",
        optionD: "To design forms",
      },
      question: "In Access, what is a query used for?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "A connection between two fields in the same table",
        optionB: " A connection between two tables",
        optionC: "A formula for calculations",
        optionD: "A type of query",
      },
      question: "What does the term 'relationship' mean in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "Using the 'Data' tab in Excel",
        optionB: "Using the 'Import & Link' group in the 'External Data' tab",
        optionC: "Using the 'File' tab",
        optionD: " Using the 'Design View'",
      },
      question: "How can you import data into an Access database?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " A method to display and enter data in a table",
        optionB: "A way to print data",
        optionC: " A type of relationship",
        optionD: " A type of query",
      },
      question: "What is a form in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "They allow users to modify table structures",
        optionB: "They are used to create queries",
        optionC: "They provide a formatted printout of data",
        optionD: "They are used to enter data",
      },
      question: "Which of the following is a feature of Access reports?",
    },
    {
      answer: "optionB",
      options: {
        optionA: " Using the 'New' button in the 'Home' tab",
        optionB: "Using the 'Table Design' view",
        optionC: "Using the 'Layout' view",
        optionD: "Using the 'Form Design' view",
      },
      question: "How can you create a new table in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "To perform calculations",
        optionB: " To create queries",
        optionC: "To simplify data entry by creating dropdown lists",
        optionD: "To format reports",
      },
      question: "What is the purpose of the 'Lookup Wizard' in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: "A column in a table",
        optionB: "A row in a table",
        optionC: "A database",
        optionD: " A type of query",
      },
      question: " What does the term 'field' refer to in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: " Design View",
        optionB: "Datasheet View",
        optionC: "Layout View",
        optionD: " Print Preview",
      },
      question: "Which view in Access allows you to see the data in a table?",
    },
    {
      answer: "optionA",
      options: {
        optionA: "255",
        optionB: "512",
        optionC: "1024",
        optionD: "2048",
      },
      question:
        " What is the maximum number of characters you can store in a Text field in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "Click the 'Save' button on the toolbar",
        optionB: "Click 'File' then 'Save'",
        optionC: " Click 'File' then 'Save As'",
        optionD: "Click 'Home' then 'Save'",
      },
      question: " How do you save a database in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "Text",
        optionB: "Number",
        optionC: "Email",
        optionD: " Date/Time",
      },
      question: " Which of the following is NOT a valid data type in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " Using the 'Relationship' button in the 'Database Tools' tab",
        optionB: " Using the 'New' button in the 'Home' tab",
        optionC: "Using the 'Form Design' view",
        optionD: " Using the 'Table Design' view",
      },
      question:
        "How do you create a relationship between two tables in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " A small program to automate tasks",
        optionB: " A type of table",
        optionC: "A type of query",
        optionD: " A type of form",
      },
      question: "What is a macro in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "Form Design",
        optionB: " Relationships",
        optionC: " Import & Link",
        optionD: "Datasheet View",
      },
      question: "Which tool can you use to ensure data integrity in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: "Using the 'Report Wizard'",
        optionB: " Using the 'Query Wizard'",
        optionC: " Using the 'Form Wizard'",
        optionD: " Using the 'Macro Wizard'",
      },
      question: "How can you create a report in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: " It backs up the database",
        optionB: " It reduces the size of the database and repairs any issues",
        optionC: " It formats the database",
        optionD: "It exports the database",
      },
      question: "What does the 'Compact and Repair' tool in Access do?",
    },
  ],
  department: "Software Engineering",
  createdAt: "2024-07-15T12:07:05.000Z",
  updatedAt: "2024-07-15T12:07:05.000Z",
};
const GiveTest = () => {
  const [data, setData] = useState(test);
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(20 * 60);
  const [questionNum, setQuestionNum] = useState(1);
  const [checkAns, setCheckAns] = useState([]);
  const [check, setCheck] = useState("");
  const [answer, setAnswers] = useState("");
  const [display, setDisplay] = useState({ display: "block" });
  const [result, setResult] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    performRequest("GET", `/test/testsbyid/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, [id]);
  const handelTestSubmit = (ans) => {
    setAnswers(ans);
  };

  if (result) {
    return <TestResults answer={answer} test={data} />;
  }

  if (timeLeft === 0) {
    return (
      <>
        <div
          className="modal modal-blur fade show"
          id="modal-success"
          tabIndex="-1"
          style={display}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                onClick={() => {
                  setResult(true);
                  setDisplay({ display: "none" });
                }}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-status bg-success"></div>
              <div className="modal-body text-center py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon mb-2 text-green icon-lg"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                  <path d="M9 12l2 2l4 -4"></path>
                </svg>
                <h3>Test Completed</h3>
                <div className="text-secondary">
                  Your Time has been concluded{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {answer.length >= data.questionsAnsOpt.length ? (
        <TestResults answer={answer} test={data} />
      ) : (
        <div className="page">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="container-xxl">
                <div className="section-header">
                  <h2 className="section-title section-title-lg m-0 p-0">
                    CURRENT TEST : {data?.name.toUpperCase()}
                  </h2>
                  <p className="section-description mb-4 p-0">
                    Do not refresh or logout. In case you refresh the page or
                    logout your score will be calculated as zero.
                  </p>
                </div>
                <div className="row row-cards">
                  <div className="col-12 ">
                    <div className="card">
                      <div className="card-header text-center">
                        {timeLeft ? (
                          <TestCounter
                            timeLeft={timeLeft}
                            setTimeLeft={setTimeLeft}
                          />
                        ) : (
                          <div className="text-center w-100">
                            <p className="m-0 p-0">TIME REMAINING</p>
                            <h2 className="card-title fs-1 m-0 p-0">00:00</h2>
                          </div>
                        )}
                      </div>
                      <div
                        className="card-body"
                        // style={{
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   alignSelf: "center",
                        //   // justifyItems: "center",
                        // }}
                      >
                        <div className="mx-4">
                          <ul className="steps steps-green my-2">
                            {data?.questionsAnsOpt?.map((li, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    index + 1 == questionNum
                                      ? "step-item active"
                                      : "step-item"
                                  }
                                >
                                  {index + 1}
                                </li>
                              );
                            })}
                          </ul>
                          <div className="card p-2">
                            {data.questionsAnsOpt?.map((qus, index) => {
                              return index + 1 === questionNum ? (
                                <div key={index}>
                                  <h3 className="m-4 fs-2">
                                    {index + 1}. {qus.question}
                                  </h3>
                                  <div className="m-4">
                                    <div className=" row">
                                      <div className="card p-3 m-2 col-lg card-link card-link-pop">
                                        <label className="form-check form-check-inline mb-0 fs-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            onChange={(e) => {
                                              if (e.target.checked && !check) {
                                                let ans = checkAns || [];
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              } else if (
                                                e.target.checked &&
                                                check
                                              ) {
                                                let ans = checkAns || [];
                                                ans.pop();
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              }
                                            }}
                                            value="optionA"
                                            name="radios-inline"
                                          />
                                          <span className="form-check-label">
                                            {qus?.options?.optionA}
                                          </span>
                                        </label>
                                      </div>
                                      <div className="card p-3 m-2 col-lg card-link card-link-pop">
                                        <label className="form-check form-check-inline mb-0 fs-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            onChange={(e) => {
                                              if (e.target.checked && !check) {
                                                let ans = checkAns || [];
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              } else if (
                                                e.target.checked &&
                                                check
                                              ) {
                                                let ans = checkAns || [];
                                                ans.pop();
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              }
                                            }}
                                            value="optionB"
                                            name="radios-inline"
                                          />
                                          <span className="form-check-label">
                                            {qus?.options?.optionB}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="card p-3 m-2 col-lg card-link card-link-pop">
                                        <label className="form-check form-check-inline mb-0 fs-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            onChange={(e) => {
                                              if (e.target.checked && !check) {
                                                let ans = checkAns || [];
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              } else if (
                                                e.target.checked &&
                                                check
                                              ) {
                                                let ans = checkAns || [];
                                                ans.pop();
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              }
                                            }}
                                            value="optionC"
                                            name="radios-inline"
                                          />
                                          <span className="form-check-label">
                                            {qus?.options?.optionC}
                                          </span>
                                        </label>
                                      </div>
                                      <div className="card p-3 m-2 col-lg card-link card-link-pop">
                                        <label className="form-check form-check-inline mb-0 fs-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            onChange={(e) => {
                                              if (e.target.checked && !check) {
                                                let ans = checkAns || [];
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              } else if (
                                                e.target.checked &&
                                                check
                                              ) {
                                                let ans = checkAns || [];
                                                ans.pop();
                                                ans.push({
                                                  ques: index + 1,
                                                  ans: e.target.value,
                                                });
                                                setCheckAns(ans);
                                                setCheck(e.target.value);
                                              }
                                            }}
                                            value="optionD"
                                            name="radios-inline"
                                          />
                                          <span className="form-check-label">
                                            {qus?.options?.optionD}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <hr className="m-2" />
                                  <div className="text-center">
                                    <SimpleButton
                                      disabled={!check}
                                      buttonText={
                                        questionNum ==
                                        data?.questionsAnsOpt?.length
                                          ? "Submit Test"
                                          : "Submit & Next"
                                      }
                                      onClick={() => {
                                        if (
                                          questionNum ==
                                          data?.questionsAnsOpt?.length
                                        ) {
                                          handelTestSubmit(checkAns);
                                        } else {
                                          setCheck("");
                                          setQuestionNum(questionNum + 1);
                                          setAnswers(checkAns);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default GiveTest;
