import React, { useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import { FieldArray, Form, Formik } from "formik";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import { questionInfo, testInfo } from "../../../Schema Info/info";
import { testSchema } from "../../../Schemas/Tests/test_schema";
import CustomSelect from "../../CustomComponent/custom_select";
import CustomInput from "../../CustomComponent/custom_input";
import CustomTextArea from "../../CustomComponent/custom_textarea";
import Footer from "../../../Components/Footer/footer";
import performRequest from "../../../APIs/request";
import { useNavigate } from "react-router";

const AddNewTest = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handelSubmit = (values, actions) => {
    const formData = new FormData();
    for (const key in values) {
      if (key === "questionsAnsOpt") {
        values[key].forEach((question, index) => {
          for (const qKey in question) {
            if (qKey === "options") {
              for (const optKey in question[qKey]) {
                formData.append(
                  `questionsAnsOpt[${index}][options][${optKey}]`,
                  question[qKey][optKey]
                );
              }
            } else {
              formData.append(
                `questionsAnsOpt[${index}][${qKey}]`,
                question[qKey]
              );
            }
          }
        });
      } else {
        formData.append(key, values[key]);
      }
    }
    setLoading(true);
    performRequest("POST", "/test/createtests", formData, {
      "Content-type": "multipart/form-data",
    })
      .then((res) => {
        if (res.status === 201) {
          alert("Test created successfully!");
          navigate("/post-contest");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Create Test</h2>
                <h4 className="text-muted"></h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Test details</h2>
            </div>
            <Formik
              initialValues={testInfo}
              validationSchema={testSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Test name</label>
                      <div className="w-100 col">
                        <CustomInput name="name" type="text" />
                        {props.touched.name && props.errors.name && (
                          <p className="text-danger">{props.errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Test type</label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="type">
                          <option value="">Select</option>
                          <option value="excel">Excel</option>
                          <option value="tally">Tally</option>
                          <option value="aptitude">Aptitude</option>
                        </CustomSelect>

                        {props.touched.type && props.errors.type && (
                          <p className="text-danger">{props.errors.type}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Level</label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="level">
                          <option value="">Select</option>
                          <option value="basic">Basic</option>
                          <option value="moderate">Moderate</option>
                          <option value="advance">Advance</option>
                        </CustomSelect>
                        {props.touched.level && props.errors.level && (
                          <p className="text-danger">{props.errors.level}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Department
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="department">
                          <option value="">Select</option>
                          <option value="Admin & Back Office">
                            Admin & Back Office
                          </option>
                          <option value="Advertising">Advertising</option>
                          <option value="Aviation & Aerospace">
                            Aviation & Aerospace
                          </option>
                          <option value="Banking & Insurance">
                            Banking & Insurance
                          </option>
                          <option value="Beauty, Fitness & Personal Care">
                            Beauty, Fitness & Personal Care
                          </option>
                          <option value="Communication">Communication</option>
                          <option value="Computer Operator">
                            Computer Operator
                          </option>
                          <option value="Construction & Site Engineering Consulting">
                            Construction & Site Engineering Consulting
                          </option>
                          <option value="Content, Editorial & Journalism">
                            Content, Editorial & Journalism
                          </option>
                          <option value="CSR & Social Service">
                            CSR & Social Service
                          </option>
                          <option value="Customer Support">
                            Customer Support
                          </option>
                          <option value="Data Science & Analytics">
                            Data Science & Analytics
                          </option>
                          <option value="Delivery & Logistics">
                            Delivery & Logistics
                          </option>
                          <option value="Digital Marketing">
                            Digital Marketing
                          </option>
                          <option value="Energy & Mining">
                            Energy & Mining
                          </option>
                          <option value="Engineering - Hardware & Networks">
                            Engineering - Hardware & Networks
                          </option>
                          <option value="Environment Health & Safety">
                            Environment Health & Safety
                          </option>
                          <option value="Facility Management">
                            Facility Management
                          </option>
                          <option value="Finance & Accounting">
                            Finance & Accounting
                          </option>
                          <option value="Financial Services">
                            Financial Services
                          </option>
                          <option value="Healthcare & Hospital Staff">
                            Healthcare & Hospital Staff
                          </option>
                          <option value="Human Resources">
                            Human Resources
                          </option>
                          <option value="IT & Information Security">
                            IT & Information Security
                          </option>
                          <option value="Legal & Regulatory">
                            Legal & Regulatory
                          </option>
                          <option value="Maintenance Services">
                            Maintenance Services
                          </option>
                          <option value="Marketing & Branding">
                            Marketing & Branding
                          </option>
                          <option value="Media Operations">
                            Media Operations
                          </option>
                          <option value="Media Production & Entertainment">
                            Media Production & Entertainment
                          </option>
                          <option value="Product Management">
                            Product Management
                          </option>
                          <option value="Production & Manufacturing & Engineering">
                            Production & Manufacturing & Engineering
                          </option>
                          <option value="Purchase & Supply Chain">
                            Purchase & Supply Chain
                          </option>
                          <option value="Quality Assurance">
                            Quality Assurance
                          </option>
                          <option value="Research & Development">
                            Research & Development
                          </option>
                          <option value="Retail & eCommerce">
                            Retail & eCommerce
                          </option>
                          <option value="Risk Management & Compliance">
                            Risk Management & Compliance
                          </option>
                          <option value="Sales & Business Development">
                            Sales & Business Development
                          </option>
                          <option value="Security Services">
                            Security Services
                          </option>
                          <option value="Shipping & Maritime">
                            Shipping & Maritime
                          </option>
                          <option value="Software Engineering">
                            Software Engineering
                          </option>
                          <option value="Strategic & Top Management">
                            Strategic & Top Management
                          </option>
                          <option value="Tailoring, Apparel & Home Furnishing">
                            Tailoring, Apparel & Home Furnishing
                          </option>
                          <option value="Teaching & Training">
                            Teaching & Training
                          </option>
                          <option value="UX, Design & Architecture">
                            UX, Design & Architecture
                          </option>
                        </CustomSelect>
                        {props.touched.department &&
                          props.errors.department && (
                            <p className="text-danger">
                              {props.errors.department}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Passing score
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="passingScore" type="text" />
                        {props.touched.passingScore &&
                          props.errors.passingScore && (
                            <p className="text-danger">
                              {props.errors.passingScore}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Description
                      </label>
                      <div className="w-100 col">
                        <CustomTextArea
                          name="description"
                          type="text"
                          rows={6}
                        />
                        {props.touched.description &&
                          props.errors.description && (
                            <p className="text-danger">
                              {props.errors.description}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Test icon</label>
                      <div className="w-100 col">
                        <CustomInput
                          name="icon"
                          type="file"
                          onChange={(e) =>
                            props.setFieldValue("icon", e.target.files[0])
                          }
                        />
                        {props.touched.icon && props.errors.icon && (
                          <p className="text-danger">{props.errors.icon}</p>
                        )}
                      </div>
                    </div>

                    <hr />
                    <FieldArray name="questionsAnsOpt">
                      {({ push, remove }) => (
                        <>
                          {props.values?.questionsAnsOpt?.map(
                            (question, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center mb-2">
                                  <div className="col-lg">
                                    <label className=" form-label input-group-link">
                                      Write question
                                    </label>
                                    <div className="w-100 col">
                                      <CustomTextArea
                                        rows={4}
                                        name={`questionsAnsOpt[${index}].question`}
                                      />
                                      {props.touched.questionsAnsOpt?.[index]
                                        ?.question &&
                                        props.errors.questionsAnsOpt?.[index]
                                          ?.question && (
                                          <p className="text-danger">
                                            {
                                              props.errors.questionsAnsOpt[
                                                index
                                              ].question
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-lg-5">
                                    <label className=" form-label input-group-link">
                                      Answer
                                    </label>
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`questionsAnsOpt[${index}].answer`}
                                      >
                                        <option value="">Select option</option>
                                        <option value="optionA">
                                          Option (a)
                                        </option>
                                        <option value="optionB">
                                          Option (b)
                                        </option>
                                        <option value="optionC">
                                          Option (c)
                                        </option>
                                        <option value="optionD">
                                          Option (d)
                                        </option>
                                      </CustomSelect>
                                      {props.touched.questionsAnsOpt?.[index]
                                        ?.answer &&
                                        props.errors.questionsAnsOpt?.[index]
                                          ?.answer && (
                                          <p className="text-danger">
                                            {
                                              props.errors.questionsAnsOpt[
                                                index
                                              ].answer
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-lg">
                                    <label className=" form-label input-group-link">
                                      Option (a)
                                    </label>
                                    <div className="w-100 col">
                                      <CustomInput
                                        type="text"
                                        name={`questionsAnsOpt[${index}].options.optionA`}
                                      />
                                      {props.touched.questionsAnsOpt?.[index]
                                        ?.options?.optionA &&
                                        props.errors.questionsAnsOpt?.[index]
                                          ?.options?.optionA && (
                                          <p className="text-danger">
                                            {
                                              props.errors.questionsAnsOpt[
                                                index
                                              ].options.optionA
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className=" form-label input-group-link">
                                      Option (b)
                                    </label>
                                    <div className="w-100 col">
                                      <CustomInput
                                        type="text"
                                        name={`questionsAnsOpt[${index}].options.optionB`}
                                      />
                                      {props.touched.questionsAnsOpt?.[index]
                                        ?.options?.optionB &&
                                        props.errors.questionsAnsOpt?.[index]
                                          ?.options?.optionB && (
                                          <p className="text-danger">
                                            {
                                              props.errors.questionsAnsOpt[
                                                index
                                              ].options?.optionB
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className=" form-label input-group-link">
                                      Option (c)
                                    </label>

                                    <div className="w-100 col">
                                      <CustomInput
                                        type="text"
                                        name={`questionsAnsOpt[${index}].options.optionC`}
                                      ></CustomInput>
                                      {props.touched.questionsAnsOpt?.[index]
                                        ?.options?.optionC &&
                                        props.errors.questionsAnsOpt?.[index]
                                          ?.options?.optionC && (
                                          <p className="text-danger">
                                            {
                                              props.errors.questionsAnsOpt[
                                                index
                                              ].options?.optionC
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-lg">
                                    <label className=" form-label input-group-link">
                                      Option (d)
                                    </label>

                                    <div className="w-100 col">
                                      <CustomInput
                                        type="text"
                                        name={`questionsAnsOpt[${index}].options.optionD`}
                                      ></CustomInput>
                                      {props.touched.questionsAnsOpt?.[index]
                                        ?.options?.optionD &&
                                        props.errors.questionsAnsOpt?.[index]
                                          ?.options?.optionD && (
                                          <p className="text-danger">
                                            {
                                              props.errors.questionsAnsOpt[
                                                index
                                              ].options?.optionD
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-lg-1">
                                    <button onClick={() => remove(index)}>
                                      Remove
                                    </button>
                                  </div>
                                </div>

                                <hr />
                              </div>
                            )
                          )}
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              push(questionInfo);
                            }}
                          >
                            Add New
                          </button>
                        </>
                      )}
                    </FieldArray>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Test" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default AddNewTest;
