import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import SimpleButton from "../../Components/layouts/simple_button";
import { Toast } from "../CustomComponent/toast";
import {
  setCurrentPage,
  setReduxFilters,
} from "../../Redux/Pagination & Filters/jobsListsFiltersReducer";
import Pagination from "../../Components/layouts/pagination";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import { departmentsInfo } from "../../Schema Info/departmentsInfo";
import { extractDate } from "../../Components/Functions/date.fun";

const dummyImages = [
  "https://randomuser.me/api/portraits/men/85.jpg",
  "https://randomuser.me/api/portraits/men/61.jpg",
  "https://randomuser.me/api/portraits/men/62.jpg",
  "https://randomuser.me/api/portraits/men/64.jpg",
  "https://randomuser.me/api/portraits/men/65.jpg",
  "https://randomuser.me/api/portraits/men/67.jpg",
];
const JobBoard = () => {
  const store = useSelector((store) => store?.userLogin);
  const [loading, setLoading] = useState(false);
  const [boardJobs, setBoardJobs] = useState([]);
  const navigate = useNavigate();
  const paginationStore = useSelector((store) => store?.jobsListsFilter);
  const [page, setCurrPage] = useState(paginationStore.currentPage);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState(paginationStore?.filters);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (store?.user?.UserId) {
      setLoading(true);
      performRequest(
        "GET",
        getFilteredURL(
          `/jobs/getboardjobs/${store?.user?.UserId}`,
          paginationStore.currentPage,
          paginationStore.filters
        )
      )
        .then((res) => {
          setBoardJobs(res.data?.data);
          if (res.data.message) {
            setTotalPages(0);
          } else {
            setTotalPages(Math.ceil(res?.data?.totalData / 10));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [paginationStore?.currentPage, refresh]);

  const handleJobActiveAndInactive = (status, jobId) => {
    const data = { status, jobId };
    performRequest("POST", `/jobs/markjobactive/${store?.user?.UserId}`, data)
      .then((res) => {
        setBoardJobs(res.data);
        let info = "";
        if (status) {
          info = "Job Mark as Active";
        } else {
          info = "Job Mark as Inactive ";
        }
        Toast("success", `${info}`);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore.currentPage]);

  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="page">
        <div className="page-wrapper">
          <div className="container-xxl">
            <div className="page-body">
              <div className="section-header">
                <h2 className="section-title section-title-lg m-0 p-0">
                  Job posted by you
                </h2>
                <p className="section-description mb-4 p-0">
                  List of Current Job Posted
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12 py-2">
                <div className="card p-2">
                  <div
                    id="faq-1"
                    className="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div>
                      <div className="accordion-header m-0" role="tab">
                        <button
                          className="accordion-button fs-3 d-md-none p-0"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-1-1"
                        >
                          <h3 className="m-1">Filter result</h3>
                        </button>
                      </div>

                      <div
                        id="faq-1-1"
                        className="accordion-collapse collapse d-md-none"
                        role="tabpanel"
                        data-bs-parent="#faq-1"
                      >
                        <div className="accordion-body pt-0">
                          <div className="row">
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Search by role:"
                                value={filters.jobrole}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    jobrole: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      jobrole: e.target.value,
                                    })
                                  );
                                }}
                                type="text"
                              >
                                <option value="">select role</option>
                                {departmentsInfo?.map((el, index) => {
                                  return el.roles.map((el2, index2) => {
                                    return (
                                      <option key={index + index2} value={el2}>
                                        {el2}
                                      </option>
                                    );
                                  });
                                })}
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Search by department:"
                                value={filters.department}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    department: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      department: e.target.value,
                                    })
                                  );
                                }}
                                type="text"
                              >
                                <option value="">select department</option>
                                {departmentsInfo?.map((el, index) => {
                                  return (
                                    <option key={index} value={el.dep}>
                                      {el.dep}
                                    </option>
                                  );
                                })}
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Sort by active jobs:"
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    sortByActiveJobs: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      sortByActiveJobs: e.target.value,
                                    })
                                  );
                                }}
                                value={filters.sortByActiveJobs}
                              >
                                <option value="desc">active to inactive</option>
                                <option value="asc">inactive to active</option>
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Sort by jobs posted:"
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    sortByPostedDate: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      sortByPostedDate: e.target.value,
                                    })
                                  );
                                }}
                                value={filters.sortByPostedDate}
                              >
                                <option value="asc">new to old</option>
                                <option value="desc">old to new</option>
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <div className="row mt-2">
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      dispatch(setCurrentPage(1));
                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-success w-100"
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      dispatch(
                                        setReduxFilters({
                                          jobrole: "",
                                          department: "",
                                          sortByPostedDate: "",
                                          sortByActiveJobs: "",
                                        })
                                      );
                                      setFilters({
                                        jobrole: "",
                                        department: "",
                                        sortByPostedDate: "",
                                        sortByActiveJobs: "",
                                      });

                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-secondary w-100"
                                  >
                                    Reset Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body d-none d-md-block">
                        <div className="row">
                          <h3>Filter result</h3>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search by role:"
                              value={filters.jobrole}
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  jobrole: e.target.value,
                                });
                                dispatch(
                                  setReduxFilters({
                                    ...filters,
                                    jobrole: e.target.value,
                                  })
                                );
                              }}
                              type="text"
                            >
                              <option value="">select role</option>
                              {departmentsInfo?.map((el, index) => {
                                return el.roles.map((el2, index2) => {
                                  return (
                                    <option key={index + index2} value={el2}>
                                      {el2}
                                    </option>
                                  );
                                });
                              })}
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search by department:"
                              value={filters.department}
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  department: e.target.value,
                                });
                                dispatch(
                                  setReduxFilters({
                                    ...filters,
                                    department: e.target.value,
                                  })
                                );
                              }}
                              type="text"
                            >
                              <option value="">select department</option>
                              {departmentsInfo?.map((el, index) => {
                                return (
                                  <option key={index} value={el.dep}>
                                    {el.dep}
                                  </option>
                                );
                              })}
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Sort by active jobs:"
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  sortByActiveJobs: e.target.value,
                                });
                                dispatch(
                                  setReduxFilters({
                                    ...filters,
                                    sortByActiveJobs: e.target.value,
                                  })
                                );
                              }}
                              value={filters.sortByActiveJobs}
                            >
                              <option value="desc">active to inactive</option>
                              <option value="asc">inactive to active</option>
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Sort by jobs posted:"
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  sortByPostedDate: e.target.value,
                                });
                                dispatch(
                                  setReduxFilters({
                                    ...filters,
                                    sortByPostedDate: e.target.value,
                                  })
                                );
                              }}
                              value={filters.sortByPostedDate}
                            >
                              <option value="asc">new to old</option>
                              <option value="desc">old to new</option>
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    dispatch(setCurrentPage(1));
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    dispatch(
                                      setReduxFilters({
                                        jobrole: "",
                                        department: "",
                                        sortByPostedDate: "",
                                        sortByActiveJobs: "",
                                      })
                                    );
                                    setFilters({
                                      jobrole: "",
                                      department: "",
                                      sortByPostedDate: "",
                                      sortByActiveJobs: "",
                                    });

                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="card bg-primary-lt my-2 p-3">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 className="m-0 p-0">Add new job post!</h3>
                      <p className="m-0 p-0">
                        Or give call to your account manager.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <SimpleButton
                        buttonText={"Post New Opening"}
                        onClick={() => navigate("/create-jobs")}
                      />
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h2>Job list</h2>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {boardJobs &&
                        boardJobs.length > 0 &&
                        boardJobs.map((el, index) => {
                          return (
                            <div className="col-md-12" key={index}>
                              <div className="card card-link card-link-pop p-2 m-2">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="card bg-primary-lt text-center">
                                      <h1 className="m-0 p-1">
                                        {el?.candidateapplyandshortlists
                                          ?.length > 0
                                          ? el.candidateapplyandshortlists
                                              ?.length
                                          : 0}
                                      </h1>
                                      <p className="m-0 p-0">Applications</p>
                                    </div>
                                  </div>

                                  <div className="col-md-10">
                                    <h2 className="p-0 m-0">{el.jobRole} </h2>
                                    <p className="p-0 m-0">
                                      <span className="badge bg-cyan-lt me-1">
                                        POSITIONS: {el.numberOfPositions}
                                      </span>

                                      <span className="badge text-teal me-1">
                                        DEPT.: {el.department}
                                      </span>
                                      <span className="badge badge-outline text-teal me-1">
                                        {el.workType}
                                      </span>
                                    </p>
                                    <p className="p-0 m-0">
                                      Skills:
                                      {el?.skillsDetails &&
                                        el?.skillsDetails?.map((ele, index) => {
                                          return (
                                            <kbd className="m-1" key={index}>
                                              {ele.skill} {ele.minReqPercentage}
                                            </kbd>
                                          );
                                        })}
                                    </p>
                                    <p className="p-0 m-0">
                                      Salary: {el.salaryPerMonthMin} -{" "}
                                      {el.salaryPerMonthMax}, Location:{" "}
                                      {el.jobCity}, {el.jobState}
                                    </p>
                                    <p className="p-0 m-0">
                                      Posted On: {extractDate(el?.createdAt)}
                                    </p>
                                  </div>
                                </div>
                                <hr className="p-0 m-0" />

                                <div className="text-end">
                                  {el?.isActive ? (
                                    <button
                                      onClick={() => {
                                        handleJobActiveAndInactive(
                                          false,
                                          el.id
                                        );
                                      }}
                                      className="btn btn-primary mt-2 me-1"
                                    >
                                      MARK AS INACTIVE
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        handleJobActiveAndInactive(true, el.id);
                                      }}
                                      className="btn btn-secondary mt-2 me-1"
                                    >
                                      MARK ACTIVE AGAIN
                                    </button>
                                  )}
                                  <SimpleButton
                                    buttonText={"View Job"}
                                    onClick={() =>
                                      navigate(`/view-jobs/${el.id}`)
                                    }
                                  />
                                  <SimpleButton
                                    buttonText={"Edit Job Post"}
                                    onClick={() =>
                                      navigate(`/edit-jobs/${el.id}`)
                                    }
                                  />
                                  <SimpleButton
                                    buttonText={"candidate comparison"}
                                    onClick={() =>
                                      navigate(`/candidate-comparison/${el.id}`)
                                    }
                                  />
                                  <SimpleButton
                                    buttonText={"All Applications"}
                                    onClick={() =>
                                      navigate(`/candidate-listing/${el.id}`)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div>
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={boardJobs?.length || 0}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JobBoard;
