import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const VerifiedSkills = () => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();

  return (
    <div class="page">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="container-xl">
            <header className="hero text-center py-10 my-6 my-md-8">
              <h1
                className="hero-title aos-init aos-animate my-5 display-4 fw-bold"
                data-aos="zoom-y-out"
              >
                Every candidate on our platform is verified for skills!
              </h1>
              <h2 className="p-0 m-0">
                Every candidate that applies for a job post, has verified with
                the skills.
              </h2>
              {/* <div className="row my-4">
                <div className="col-mg-12 col-lg-6 text-lg-end text-md-center mb-3">
                  <div className="">
                    <span
                      onClick={() => {
                        if (!store?.isLoggedIn) {
                          navigate("/account-login");
                        } else {
                          navigate("/resume-builder");
                        }
                      }}
                      className="btn btn-primary btn-lg p-3 btn-text"
                    >
                      <FaAddressCard fontSize={"1.2em"} className="me-2" />{" "}
                      BUILD YOUR PROFILE
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 text-lg-start text-md-center">
                  <div className="">
                    <span
                      className="btn btn-lg p-3 btn-text"
                      onClick={() => {
                        navigate("/top-hires");
                      }}
                    >
                      <FaSearch fontSize={"1.2em"} className="me-2" />
                      BROWSE JOB POSTS
                    </span>
                  </div>
                </div>
              </div> */}
            </header>
            <hr />
            <div className="row">
              <div className="col-md-6"></div>

              <div className="col-md-6">
                <h2>All candidates verified for skills.</h2>
                <p>
                  HIRE48 provides you a pool of candidates whose specific skills
                  have been verified by tests designed by specialists. These
                  verified skills are highlighted separately against the
                  candidate profile to allow you to quickly differentiate
                  between candidates that are actually qualified in a particular
                  skill and those who have just mentioned it in their profiles.
                  A new ‘Candidates with verified skills’ filter also helps you
                  shortlist relevant candidates faster than before.
                </p>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6">
                <h2>The best pre-hire assesment.</h2>
                <p>
                  When it comes to hiring the best talent, pre-hire assessment
                  is one of the most useful mechanism out there. This provides
                  an objective way of gaining insight into the capabilities of
                  the prospective employee. When looking to hire the top talent,
                  a normal CV screening may not be reliable enough as candidates
                  may include misleading information. On the other hand, a
                  candidate with a pre-verified skill offers a level of surety
                  about their expertise on that particular skill, hence enabling
                  quicker and better decision making for the recruiter.
                </p>
              </div>

              <div className="col-md-6"></div>
            </div>

            <hr />
            <h1
              className="aos-init aos-animate my-5 display-4 fw-bold p-0 m-0  text-center"
              data-aos="zoom-y-out"
            >
              Have any question in mind?
            </h1>
            <h2 className="p-0 m-0 text-center">
              Shoot us a mail on contact@hire48.com
            </h2>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default VerifiedSkills;
