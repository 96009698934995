import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const InputWithSearchAndSelect = ({ selected, setSelected, arrayData }) => {
  const [data, setData] = useState();
  const [fevUp, setFevUp] = useState(false);
  const [showData, setShowData] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (arrayData) {
      setData(arrayData?.sort());
    }
  }, [arrayData]);

  function searchData(value) {
    setShowData(true);

    let newData = arrayData.filter((data) =>
      data.toLowerCase().includes(value.toLowerCase())
    );

    setData(newData);
    if (value === "") {
      setData(arrayData);
    }
  }

  let searchTimer;
  function startSearch(value) {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => searchData(value), 500);
  }
  const removeFilter = (ele) => {
    let arr = selected.filter((f) => f !== ele);
    setSelected(arr);
  };
  return (
    <div>
      {/* {selected.length > 0 && (
        <div>
          {selected?.map((ele, index) => {
            return (
              <span className="m-1 tag" key={index}>
                {ele}
                <a
                  href="#"
                  className="btn-close"
                  onClick={() => removeFilter(ele)}
                ></a>
              </span>
            );
          })}
        </div>
      )} */}
      <div className="input-group input-group-flat">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            startSearch(e.target.value);
          }}
          className="form-control"
          autoComplete="off"
          placeholder="Search or Select"
          required
        />
        <span className="input-group-text">
          {fevUp ? (
            <FaChevronUp
              onClick={() => {
                setFevUp(false);
                setShowData(false);
              }}
            />
          ) : (
            <FaChevronDown
              onClick={() => {
                setFevUp(true);
                setShowData(true);
              }}
            />
          )}
        </span>
      </div>
      <div>
        {showData && (
          <div
            className="card mx-2 px-2"
            style={{
              maxHeight: "500px",
              overflow: "auto",
              position: "absolute",
              zIndex: 1,
              width: "90%",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
            }}
          >
            {data.length < 1 && (
              <div className="p-2 input-group input-group-flat cursor-pointer ">
                Data not Found!
              </div>
            )}
            {data.map((item, index) => {
              return (
                <div
                  style={{
                    display: "inline-block",
                    position: "relative",
                    width: "100%",
                  }}
                  key={index}
                  className="p-2 input-group input-group-flat cursor-pointer "
                  onClick={() => {
                    setInputValue("");
                    let arr = selected.filter((value) => value != item);
                    arr.push(item);
                    setSelected(arr);
                    setShowData(false);
                    setData(arrayData);
                    setFevUp(false);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputWithSearchAndSelect;
