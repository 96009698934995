import { useField } from "formik";

const CustomRadioButton = ({ width, height, ...props }) => {
  const [field] = useField(props);

  return (
    <>
      <input
        type="radio"
        className="form-check-input"
        style={{ width: `${width}`, height: `${height}` }}
        {...field}
        {...props}
      />
    </>
  );
};

export default CustomRadioButton;
