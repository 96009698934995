import { Toast } from "./toast";

export const CustomErrorResponse = (err, flag) => {
  if (err.response?.data?.error) {
    console.error(err);
    Toast("error", err.response?.data.error);
  } else if (err.response?.status == 400) {
    console.error(err);
    Toast("error", "Invalid request. Please check your input.");
  } else if (err.response?.status == 401) {
    console.error(err);
    Toast("error", "Unauthorized access. Please log in.");
  } else if (err.response?.status == 403) {
    console.log(err)
    if (flag) {
      Toast("error", flag);
    } else {
      Toast("error", "Access denied. You do not have permission.");
    }
  } else if (err.response?.status == 404) {
    console.error(err);
    Toast("error", "Resource not found. Please try again.");
  } else if (err.response?.status == 405) {
    console.error(err);
    Toast("error", "Invalid request method. Please check your action.");
  } else if (err.response?.status == 409) {
    console.error(err);
    Toast("error", "Conflict detected. Resource may already exist.");
  } else if (err.response?.status == 500) {
    console.error(err);
    Toast("error", "Server error. Please try again later.");
  } else if (err.response?.status == 502) {
    console.error(err);
    Toast("error", "Bad gateway. Please try again later.");
  } else if (err.response?.status == 503) {
    console.error(err);
    Toast("error", "Service unavailable. Please try again later.");
  } else if (err.response?.status == 504) {
    console.error(err);
    Toast("error", "Request timed out. Please try again later.");
  } else {
    console.error(err);
    Toast("error", "ERR_CONNECTION_REFUSED, Something went wrong !");
  }
};
