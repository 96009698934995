import * as Yup from "yup";

export const editProfileSchema = Yup.object().shape({
    fullName: Yup.string()
    .required('Name is required')
    .max(50, 'Name must be at most 50 characters'),
    email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
    profilePicture: Yup.string().required('Image is required'),
    contactNumber: Yup.string()
    .min(10, 'Enter 10 Digit Mobile Number').max(10,'Enter 10 Digit Mobile Number')
    .required('Mobile Number  is required'),


});
