
import React from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // or QRCodeSVG

const QrCodeGenerator = () => {
  // const loginUrl = "https://www.hire48.com/account-login"; // Replace with your actual login URL

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Scan the QR Code to Login</h1>
      <QRCodeCanvas value  size={256} />
      <p>
        Please scan this QR code using your smartphone camera or a QR code scanner app.
      </p>
    </div>
  );
};

export default QrCodeGenerator;
