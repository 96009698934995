import { createSlice } from "@reduxjs/toolkit";
export const allPaymentsFilterSlice = createSlice({
  name: "allPaymentsPaginationFilter",
  initialState: {
    currentPage: 1,
    filters: {
      nameOrMobile: "",
      sortByPaymentDate: "",
      sortByPaymentStatus: "",
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReduxFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setCurrentPage, setReduxFilters } =
allPaymentsFilterSlice.actions;
export default allPaymentsFilterSlice.reducer;
