import React, { useState } from "react";
import Footer from "../../Components/Footer/footer";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import { ToastContainer } from "react-toastify";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import { forgotPasswordSchema } from "../../Schemas/User/forgot_password_schema";
import CustomInput from "../CustomComponent/custom_input";
import { useNavigate, useSearchParams } from "react-router-dom";
const ResetPassword = () => {
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const auth = searchParams.get("auth");
  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("POST", `/resetpass/new-pass`, {
      pass: auth,
      newPass: values.password,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data === "user not found") {
            Toast(
              "error",
              "User not found! please try again later with new link"
            );
            navigate("/account-login");
          } else {
            Toast("success", "Password reset successfully!");
            navigate("/account-login");
          }
        } else {
          Toast("error", "Error while password reset!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page page-wrapper">
      <div className="container-xl">
        <div className="page-center" style={{ minHeight: "85vh" }}>
          <div className="container container-normal ">
            <div className="row align-items-center g-4">
              <div className="col-lg">
                <div className="container-tight">
                  <div className="card card-md mt-7">
                    <div className="card-body">
                      <h2 className="h2 text-center mb-4">Reset Password</h2>
                      <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={forgotPasswordSchema}
                        onSubmit={handelSubmit}
                      >
                        {(props) => (
                          <Form>
                            <div className="card-body">
                              <p className="text-muted mb-4">
                                Please enter and confirm your new password.
                              </p>
                              <div className="mb-3">
                                <label className="form-label">
                                  New Password
                                </label>
                                <div className="input-group input-group-flat">
                                  <CustomInput
                                    name="password"
                                    autoComplete="off"
                                    placeholder="Enter password"
                                    type={showPass ? "text" : "password"}
                                  />

                                  <span className="input-group-text">
                                    <span
                                      onClick={() => setShowPass(!showPass)}
                                      className="link-secondary"
                                      title="Show password"
                                      role="button"
                                      data-bs-toggle="tooltip"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                {props.touched.password &&
                                  props.errors.password && (
                                    <p className="text-danger">
                                      {props.errors.password}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Confirm New Password
                                </label>
                                <div className="input-group input-group-flat">
                                  <CustomInput
                                    name="confirmPassword"
                                    placeholder="Confirm password"
                                    autoComplete="off"
                                    type={showConfirmPass ? "text" : "password"}
                                  />

                                  <span className="input-group-text">
                                    <span
                                      onClick={() =>
                                        setShowConfirmPass(!showConfirmPass)
                                      }
                                      className="link-secondary"
                                      title="Show password"
                                      role="button"
                                      data-bs-toggle="tooltip"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                {props.touched.confirmPassword &&
                                  props.errors.confirmPassword && (
                                    <p className="text-danger">
                                      {props.errors.confirmPassword}
                                    </p>
                                  )}
                              </div>

                              <div className="form-footer">
                                <FormSubmitButton
                                  buttonText={"Reset Password"}
                                  width={"full"}
                                />
                              </div>
                            </div>
                            <div className=" text-muted mt-3">
                              Forget it,
                              <a href="/account-login">send me back</a> to the
                              sign in screen.
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer />
    </div>
  );
};

export default ResetPassword;
