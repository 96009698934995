import React, { useState, useEffect } from "react";

import { Toast } from "../CustomComponent/toast";

import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";

import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import {
  setCurrentPage,
  setReduxFilters,
} from "../../Redux/Pagination & Filters/allPaymentsFiltersReducer";
import Pagination from "../../Components/layouts/pagination";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import SimpleButton from "../../Components/layouts/simple_button";
import { extractDate } from "../../Components/Functions/date.fun";
import { useNavigate } from "react-router";
function countPastDays(dateString) {
  if (dateString === null) {
    return <span className="badge bg-red-lt">Not active</span>;
  }
  const pastDate = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const timeDifference = today - pastDate;
  let daysPast = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysPast <= 0) {
    daysPast = 1;
  }
  if (daysPast === 1) {
    return <span className="badge bg-green-lt">Active Today</span>;
  }
  if (daysPast > 30) {
    return <span className="badge bg-red-lt">Not active</span>;
  }
  return (
    <span className="badge bg-yellow-lt">{`Active ${daysPast} days ago`}</span>
  );
}

// Example usage:

const Allpayments = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const paginationStore = useSelector((store) => store?.allPaymentsFilter);
  const [page, setCurrPage] = useState(paginationStore.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState(paginationStore?.filters);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    // Dynamic code

    const getjobsandapplicants = () => {
      performRequest(
        "GET",
        getFilteredURL(
          "payment/getallpayment",
          paginationStore.currentPage,
          paginationStore.filters
        )
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data?.data);
            setTotalPages(Math.ceil(res?.data?.totalData / 50));
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    };

    getjobsandapplicants();
  }, [paginationStore?.currentPage, refresh]);

  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore?.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header">
            <h2>All Payments </h2>
          </div>
          <div className="page-body">
            <div className="card">
              <div className="accordion-body d-md-block">
                <div className="row row deck m-3">
                  <h3>Filter result</h3>{" "}
                  <div className=" col-lg-3 col-12 mb-2">
                    <DynamicFilterInput
                      label="Search name /mobile:"
                      value={filters.nameOrMobile}
                      onChange={(e, value) => {
                        setFilters({
                          ...filters,
                          nameOrMobile: e.target.value,
                        });

                        dispatch(
                          setReduxFilters({
                            ...filters,
                            nameOrMobile: e.target.value,
                          })
                        );
                      }}
                      placeholder="search here..."
                    />
                  </div>
                  <div className="col-lg-3 col-12 mb-2">
                    <DynamicFilterSelect
                      label="Sort By Payment Status:"
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          sortByPaymentStatus: e.target.value,
                        });
                        dispatch(
                          setReduxFilters({
                            ...filters,
                            sortByPaymentStatus: e.target.value,
                          })
                        );
                      }}
                      value={filters.sortByPaymentStatus}
                    >
                      <option value="">select all</option>
                      <option value="Sent">Sent</option>
                      <option value="Pending">Pending</option>
                      <option value="Completed">Completed</option>
                      <option value="creditDeductionCount">
                        Credit Deduction
                      </option>
                    </DynamicFilterSelect>
                  </div>
                  <div className="col-lg-3 col-12 mb-2">
                    <DynamicFilterSelect
                      label="Sort by Payment Date:"
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          sortByPaymentDate: e.target.value,
                        });
                        dispatch(
                          setReduxFilters({
                            ...filters,
                            sortByPaymentDate: e.target.value,
                          })
                        );
                      }}
                      value={filters.sortByPaymentDate}
                    >
                      <option value="">Select All</option>
                      <option value="ASC">Oldest to Newest</option>
                      <option value="DESC">Newest to Oldest</option>
                    </DynamicFilterSelect>
                  </div>
                  <div className="col-lg-3 col-12 mb-2">
                    <div className="row mt-2">
                      <div className="col-6">
                        <SimpleButton
                          onClick={() => {
                            dispatch(setCurrentPage(1));
                            setRefresh(!refresh);
                          }}
                          className="btn btn-primary w-100"
                          buttonText={"Apply Filters"}
                        />
                      </div>
                      <div className="col-6">
                        <SimpleButton
                          onClick={() => {
                            dispatch(
                              setReduxFilters({
                                nameOrMobile: "",
                                sortByPaymentStatus: "",
                                sortByPaymentDate: "",
                              })
                            );
                            setFilters({
                              nameOrMobile: "",
                              sortByPaymentStatus: "",
                              sortByPaymentDate: "",
                            });

                            setRefresh(!refresh);
                          }}
                          className="btn btn-secondary w-100"
                          buttonText={"Reset Filters"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mb-0" />
              </div>

              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table">
                      <thead>
                      <tr className="">
                      <th>Name</th>
                      <th>Total Credits</th>
                      <th>Payment Id</th>
                      <th>Payment Date</th>
                      <th>Payment Status</th>
                      <th>Credit Added</th>
                      <th>Credit Deduction</th>
                      <th>Job</th>

                      {/* <th className="w-1">actions</th> */}
                    </tr>
                      </thead>
                        <tbody>
                    {data &&
                      data?.map((el, index) => {
                        return (
                          <tr key={index}>
                            <td data-label="Name">
                              <div className="d-flex py-1 align-items-center">
                                <span className="avatar me-2">
                                  <img
                                    src={
                                      el?.usermodel?.profilePicture ||
                                      el?.employeronboardingmodel
                                        ?.companyLogo ||
                                      "https://png.pngtree.com/element_our/20190601/ourmid/pngtree-address-book-icon-image_1347894.jpg"
                                    }
                                    alt=""
                                    srcSet=""
                                  />
                                </span>
                                <div className="flex-fill">
                                  <div className="font-weight-medium">
                                    {el?.employeronboardingmodel?.fullName}
                                  </div>
                                  <div className="fs-5">
                                    {countPastDays(el?.usermodel.lastLogin)}
                                  </div>
                                  <div>
                                    {el?.employeronboardingmodel?.contactNumber}
                                  </div>
                                  <div className="text-secondary">
                                    {el?.employeronboardingmodel?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-label="Total Credits">
                              <div className="text-secondary">
                                {`Credits: ${el?.credit}`}
                              </div>
                            </td>
                            <td data-label="Payment Id">
                              <div>{el?.paymentId || '-'}</div>
                            </td>
                            <td data-label="Payment Date">
                              <div>
                                {el?.paymentDate
                                  ? extractDate(el?.paymentDate)
                                  : <span>-</span>}
                              </div>
                            </td>
                            <td data-label="Payment Status">
                              {el?.paymentStatus === "Completed" ? (
                                <span className="status status-green border rounded-2 p-2">
                                  {el?.paymentStatus}
                                </span>
                              ) : el?.paymentStatus === "Pending" ? (
                                <span className="status status-red border rounded-2 p-2">
                                  {el?.paymentStatus}
                                </span>
                              ) : el?.paymentStatus === "Sent" ? (
                                <span className="status status-lime border rounded-2 p-2">
                                  {" "}
                                  {el?.paymentStatus}
                                </span>
                              ) : (
                                <span className="p-2">
                                 -
                                </span>
                              )}
                            </td>
                            <td data-label="Credit Added">
                              <span className="status status-green border rounded-2 p-2">
                                {el?.creditAdditionCount}
                              </span>
                            </td>
                            <td data-label="Credit Deduction">
                              <span className="status status-red border rounded-2 p-2">
                                {el?.creditDeductionCount || '-'}
                              </span>
                            </td>

                            <td data-label="Job Id">
                              {el?.jobId ? ( <button className="btn btn-primary" onClick={()=> navigate(`/view-jobs/${el?.jobId}`)}>
                                View Job
                               </button>): '-'}
                            </td>

                            {/* <td>
                              <div className="btn-list flex-nowrap">
                                <div className="dropdown">
                                  <button
                                    className="btn dropdown-toggle align-text-top"
                                    data-bs-toggle="dropdown"
                                  >
                                    Actions
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        if (el.resumemodel?.id) {
                                          navigate(
                                            `/candidate-details/${el.resumemodel?.id}`
                                          );
                                        } else {
                                          alert(
                                            "Resume not field by candidate"
                                          );
                                        }
                                      }}
                                    >
                                      View resume
                                    </button>

                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        if (el?.id) {
                                          navigate(`/applied-jobs/${el?.id}`);
                                        } else {
                                          alert("User not found");
                                        }
                                      }}
                                    >
                                      Applied Job
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Allpayments;
