import React from "react";
import FooterBottom from "./footer_copyright";
import FooterTop from "./footer_top";

const Footer = () => {
  return (
    <footer className="footer  footer-transparent d-print-none pb-0">
      <div className="bg-dark text-white pb-5 border-secondary">
        <FooterTop />
        <FooterBottom />
      </div>
    </footer>
  );
};

export default Footer;
