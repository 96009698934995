import * as yup from "yup";

export const instamojoSchema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  contactNumber: yup
    .string()
    .min(10, "Enter 10 Digit Mobile Number")
    .max(10, "Enter 10 Digit Mobile Number"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("email is required"),
  tier: yup.string().min(1).required("please select plan"),
  quantity: yup.string().min(1, "Min 1").max(10000000, "Max Limit").required('Quantity is required'),
});

export const instamojoBulkSchema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  contactNumber: yup
    .number()
    .min(10, "Enter 10 Digit Mobile Number")
    .max(10, "Enter 10 Digit Mobile Number"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("email is required"),
  quantity: yup
    .number()
    .min(1, "Min require")
    .max(10000000, "Max Limit")
    .required("Quantity required"),
  tier: yup
    .string()
    .min(1, "Please Select Plan")
    .required("please select plan"),
});
