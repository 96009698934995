import { createSlice } from "@reduxjs/toolkit";
export const allCandidatesFilterSlice = createSlice({
  name: "allCandidatesPaginationFilter",
  initialState: {
    currentPage: 1,
    filters: {
      nameOrMobile: "",
      sortByActiveDate: "",
      sortByskills: "",
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReduxFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setCurrentPage, setReduxFilters } =
  allCandidatesFilterSlice.actions;
export default allCandidatesFilterSlice.reducer;
