import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import { superAdmin } from "../../../Config/config";

const formatDate = (dateStr) => format(new Date(dateStr), "dd MMMM yyyy");

const Attendancetracking = ({
  leadOwner,
  attendanceData = [],
  attendanceFrom,
  selectedYear,
}) => {
  const store = useSelector((store) => store.userLogin.user);
  const navigate = useNavigate();

  const filteredAttendanceData = useMemo(() => {
    return attendanceData.filter((data) => {
      const dataDate = new Date(data.createdAt);
      return (
        dataDate.getMonth() === attendanceFrom &&
        dataDate.getFullYear() === selectedYear
      );
    });
  }, [attendanceData, attendanceFrom, selectedYear]);

  const getRowBackgroundColor = (data) => {
    const { loginStatus, time, logoutTime } = data;
    const workedHours = (() => {
      if (!logoutTime) return 0;

      const parseTime = (timeString) => {
        const [time, modifier] = timeString.split(" ");
        let [hours, minutes, seconds] = time.split(":").map(Number);
        if (modifier === "PM" && hours !== 12) hours += 12;
        if (modifier === "AM" && hours === 12) hours = 0;
        return { hours, minutes, seconds };
      };

      const login = parseTime(time);
      const logout = parseTime(logoutTime);

      const loginDate = new Date(
        0,
        0,
        0,
        login.hours,
        login.minutes,
        login.seconds
      );
      const logoutDate = new Date(
        0,
        0,
        0,
        logout.hours,
        logout.minutes,
        logout.seconds
      );

      return Math.floor((logoutDate - loginDate) / (1000 * 60 * 60));
    })();

    if (loginStatus === "intime" && workedHours >= 9) {
      return "rgba(0, 128, 0, 0.2)";
    } else if (loginStatus === "grace period" && workedHours >= 9) {
      return "rgba(255, 255, 0, 0.2)";
    } else if (
      ["intime", "grace period", "2nd half"].includes(loginStatus) &&
      workedHours < 9
    ) {
      return "rgba(221, 184, 84, 0.4)";
    } else if (
      ["late", "late 2nd half", "non-attended"].includes(loginStatus)
    ) {
      return "rgba(224, 109, 109, 0.3)";
    }
    return "transparent";
  };

  const goToViewAttendance = (id) => navigate(`/employee-attendance/${id}`);

  return (
    <div className="card-table table-responsive" style={{ maxHeight: "470px" }}>
      <table className="table table-vcenter">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Login Time</th>
            <th>Logout Time</th>
            <th>Total Time</th>
            {(store.Role === process.env.REACT_APP_ROLE_ADMIN ||
              store.Role === process.env.REACT_APP_ROLE_ADMIN_S) && (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {
            filteredAttendanceData.map((data, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: getRowBackgroundColor(data),
                }}
              >
                <td>{data?.usermodel?.fullName || "N/A"}</td>
                <td>{formatDate(data?.createdAt) || "N/A"}</td>
                <td>{data?.time || "N/A"}</td>
                <td>{data?.logoutTime || "N/A"}</td>
                <td>
                  {data?.logoutTime && data.logoutTime !== ""
                    ? (() => {
                        const parseTime = (timeString) => {
                          const [time, modifier] = timeString.split(" ");
                          let [hours, minutes, seconds] = time.split(":");
                          if (hours === "12") hours = "00";
                          if (modifier === "PM")
                            hours = parseInt(hours, 10) + 12;
                          return new Date(1970, 0, 1, hours, minutes, seconds);
                        };

                        const logoutTime = parseTime(data.logoutTime);
                        const currentTime = parseTime(data.time);
                        const differenceInMilliseconds =
                          logoutTime - currentTime;

                        const hours = Math.floor(
                          (differenceInMilliseconds / (1000 * 60 * 60)) % 24
                        );
                        const minutes = Math.floor(
                          (differenceInMilliseconds / (1000 * 60)) % 60
                        );
                        const seconds = Math.floor(
                          (differenceInMilliseconds / 1000) % 60
                        );

                        const formatTimeUnit = (unit) =>
                          unit.toString().padStart(2, "0");
                        return `${formatTimeUnit(hours)}:${formatTimeUnit(
                          minutes
                        )}:${formatTimeUnit(seconds)}`;
                      })()
                    : "N/A"}
                </td>
                {(store.Role === superAdmin) && (
                  <td>
                  
                    <button
                      className="btn btn-primary"
                      style={{ width: "50px", height: "25px" }}
                      onClick={() => goToViewAttendance(data?.id)}
                    >
                      View
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Attendancetracking;