import React from "react";

const DynamicFilterInput = ({ label, ...props }) => {
  return (
    <div className="col-md">
      {label}
      <input {...props} className="form-control form-control-sm" />
    </div>
  );
};

export default DynamicFilterInput;
