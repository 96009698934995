
export const taskManagementInfo = {
  name: "",
  description: "",
  assignTo: "",
  deadline: "",
  status: "",
  feedback: "",
  taskType: "",
  startDate: "",
  frequency: "",
  frequencyType: "",
};