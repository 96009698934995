import * as yup from "yup";
export const EmployeeLeaveSchema = yup.object().shape({
  leaveDate: yup.date(),
  leaveType: yup.string(),
  leaveReason: yup.string(),
  leaveStatus: yup.string(),
  leaveStartDate: yup.date(),
  leaveEndDate: yup.date(),
  leaveReasonType: yup.string(),
});
