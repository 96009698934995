import React from "react";
const EmployeeDetails = (attendanceData) => {
  const getMonthYear = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date();
    const year = date.getFullYear();
    const monthIndex = date.getMonth();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthYearText = `${monthNames[monthIndex]} ${year}`;

    return monthYearText;
  };

  return (
    <div>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.3rem",
          fontFamily: "sans-serif",
        }}
      >
        Payslip for month of{" "}
        {getMonthYear(attendanceData?.attendanceData?.month)}
      </p>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.2rem",
          fontFamily: "sans-serif",
        }}
      >
        Employee Pay Summary
      </p>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="row">
            <div className="col-sm-4">
              <p className="mb-2 text-muted" style={{ fontWeight: 500 }}>
                Employee Name:
              </p>
            </div>
            <div className="col-sm-8">
              {attendanceData?.attendanceData?.employeeData?.firstName}{" "}
              {attendanceData?.attendanceData?.employeeData?.lastName}
            </div>
            <div className="col-sm-4">
              <p className="mb-2 text-muted" style={{ fontWeight: 500 }}>
                Designation:
              </p>
            </div>
            <div className="col-sm-8">
              {attendanceData?.attendanceData?.employeeData?.roleInOrganisation}
            </div>
            <div className="col-sm-4">
              <p className="mb-2 text-muted" style={{ fontWeight: 500 }}>
                Date of Joining:
              </p>
            </div>
            <div className="col-sm-8">
              {attendanceData?.attendanceData?.employeeData?.dateOfJoining ||
                "-"}
            </div>
            <div className="col-sm-4">
              <p className="mb-2 text-muted" style={{ fontWeight: 500 }}>
                Pay Period:
              </p>
            </div>
            <div className="col-sm-8">
              {getMonthYear(attendanceData?.attendanceData?.month)}
            </div>
            <div className="col-sm-4">
              <p className="mb-2 text-muted" style={{ fontWeight: 500 }}>
                Pay Date:
              </p>
            </div>
            <div className="col-sm-8">
              5 {getMonthYear(attendanceData?.attendanceData?.month)}
            </div>
            <div className="col-sm-4">
              <p className="mb-2 text-muted" style={{ fontWeight: 500 }}>
                Bank Account No:
              </p>
            </div>
            <div className="col-sm-8">
              {attendanceData?.attendanceData?.employeeData
                ?.bankAccountNumber || "-"}
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              width: "50%",
              border: "1px groove gray",
              padding: "5px",
            }}
          >
            <h2 className="text-muted">Employee Net Pay</h2>
            <h1 style={{ color: "green", fontSize: "2rem" }}>
              ₹
              {(
                (parseInt(attendanceData?.attendanceData?.totalSalary) || 0) +
                // +(parseInt(attendanceData?.incentiveEmployee) || 0) - Uncomment if you need this
                -(parseInt(attendanceData?.employeeData?.pfptDeduction) || 0)
              ).toFixed(2) || "0"}
            </h1>
            <p className="text-muted">
              Paid Days:{" "}
              {+attendanceData?.attendanceData?.totalFullDaysWithoutSunday +
                +attendanceData?.attendanceData?.totalHalfDaysWithoutSunday +
                +attendanceData?.attendanceData?.totalWorkHolidays +
                +attendanceData?.attendanceData?.totalSundays}{" "}
              | LOP Days: {+attendanceData?.attendanceData?.totalLeavsDays}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
