
export const educationOptions = [
  { value: "No Formal Education", label: "No Formal Education" },
  { value: "Primary Education", label: "Primary Education" },
  { value: "Secondary Education", label: "Secondary Education" },
  { value: "High School Diploma", label: "High School Diploma" },
  { value: "GED or Equivalent", label: "GED or Equivalent" },
  { value: "Vocational/Technical Certificate", label: "Vocational/Technical Certificate" },
  { value: "Apprenticeship", label: "Apprenticeship" },
  { value: "Associate of Arts", label: "Associate of Arts (AA)" },
  { value: "Associate of Science", label: "Associate of Science (AS)" },
  { value: "Associate of Applied Science", label: "Associate of Applied Science (AAS)" },
  { value: "Bachelor of Arts", label: "Bachelor of Arts (BA)" },
  { value: "Bachelor of Science", label: "Bachelor of Science (BS)" },
  { value: "Bachelor of Computer Application", label: "Bachelor of Computer Application (BCA)" },
  { value: "Bachelor of Business Administration", label: "Bachelor of Business Administration (BBA)" },
  { value: "Bachelor of Engineering", label: "Bachelor of Engineering (BE/BEng)" },
  { value: "Bachelor of Technology", label: "Bachelor of Technology (B.Tech)" },
  { value: "Master of Arts", label: "Master of Arts (MA)" },
  { value: "Master of Science", label: "Master of Science (MS)" },
  { value: "Master of Business Administration", label: "Master of Business Administration (MBA)" },
  { value: "Master of Education", label: "Master of Education (MEd)" },
  { value: "Doctor of Philosophy", label: "Doctor of Philosophy (PhD)" },
  { value: "Doctor of Medicine", label: "Doctor of Medicine (MD)" },
  { value: "Doctor of Dental Surgery", label: "Doctor of Dental Surgery (DDS)" },
  { value: "Doctor of Nursing Practice", label: "Doctor of Nursing Practice (DNP)" },
  { value: "Juris Doctor", label: "Juris Doctor (JD)" },
  { value: "Doctor of Pharmacy", label: "Doctor of Pharmacy (PharmD)" },
  { value: "Doctor of Veterinary Medicine", label: "Doctor of Veterinary Medicine (DVM)" },
  { value: "Other", label: "Other" }
];


export const  readlanguageOptions = [
  { value: "", label: "Read" },
  { value: "Assamese", label: "Assamese" },
  { value: "Bengali", label: "Bengali" },
  { value: "Bodo", label: "Bodo" },
  { value: "Dogri", label: "Dogri" },
  { value: "English", label: "English" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Hindi", label: "Hindi" },
  { value: "Kannada", label: "Kannada" },
  { value: "Kashmiri", label: "Kashmiri" },
  { value: "Konkani", label: "Konkani" },
  { value: "Maithili", label: "Maithili" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Manipuri", label: "Manipuri" },
  { value: "Marathi", label: "Marathi" },
  { value: "Nepali", label: "Nepali" },
  { value: "Odia", label: "Odia" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Sanskrit", label: "Sanskrit" },
  { value: "Santali", label: "Santali" },
  { value: "Sindhi", label: "Sindhi" },
  { value: "Tamil", label: "Tamil" },
  { value: "Telugu", label: "Telugu" },
  { value: "Urdu", label: "Urdu" }
];


export const  writelanguageOptions = [
    { value: "", label: "Write" },
    { value: "Assamese", label: "Assamese" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "English", label: "English" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Urdu", label: "Urdu" }
  ];
  

export const  speaklanguageOptions = [
    { value: "", label: "Speak" },
    { value: "Assamese", label: "Assamese" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "English", label: "English" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Urdu", label: "Urdu" }

  ];
  

 export const additionalInfoOptions = [
    { value: "", label: "Select Info" },
    { value: "Immediate Joinee", label: "Immediate Joinee" },
    { value: "Join within a Week", label: "Join within a Week" },
    { value: "Join within 15 Days", label: "Join within 15 Days" },
    { value: "Work From Home Only", label: "Work From Home Only" },
    { value: "Open to Relocation", label: "Open to Relocation" },
    { value: "Remote Work Preferred", label: "Remote Work Preferred" },
    { value: "Willing to Travel", label: "Willing to Travel" },
    { value: "Seeking Full-Time Position", label: "Seeking Full-Time Position" },
    { value: "Open to Contract Work", label: "Open to Contract Work" },
    { value: "Looking for Part-Time Opportunities", label: "Looking for Part-Time Opportunities" },
    { value: "Available for Freelance Projects", label: "Available for Freelance Projects" },
    { value: "Experienced in Management", label: "Experienced in Management" },
    { value: "Specializes in Technical Roles", label: "Specializes in Technical Roles" },
    { value: "Interested in Startups", label: "Interested in Startups" },
    { value: "Seeking Leadership Roles", label: "Seeking Leadership Roles" },
    { value: "Open to Internships", label: "Open to Internships" },
    { value: "Looking for Remote Learning Opportunities", label: "Looking for Remote Learning Opportunities" },
    { value: "Available for Night Shifts", label: "Available for Night Shifts" },
    { value: "Flexible Working Hours", label: "Flexible Working Hours" },
    { value: "Seeking Mentorship Roles", label: "Seeking Mentorship Roles" },
    { value: "Looking for Entry-Level Positions", label: "Looking for Entry-Level Positions" },
    { value: "Experienced in Agile Methodologies", label: "Experienced in Agile Methodologies" },
    { value: "Certified Professional", label: "Certified Professional" },
    { value: "Looking for Long-Term Commitment", label: "Looking for Long-Term Commitment" },
    { value: "Seeking Short-Term Projects", label: "Seeking Short-Term Projects" },
    { value: "Available for On-Site Roles", label: "Available for On-Site Roles" },
    { value: "Interested in Consultancy Roles", label: "Interested in Consultancy Roles" },
    { value: "Experienced in Project Management", label: "Experienced in Project Management" },
    { value: "Expert in Data Analysis", label: "Expert in Data Analysis" },
    { value: "Skilled in Software Development", label: "Skilled in Software Development" },
    { value: "Proficient in Marketing", label: "Proficient in Marketing" },
    { value: "Experienced in Customer Service", label: "Experienced in Customer Service" },
    { value: "Fluent in Multiple Languages", label: "Fluent in Multiple Languages" },
    { value: "Experienced in Sales", label: "Experienced in Sales" },
    { value: "Skilled in Graphic Design", label: "Skilled in Graphic Design" },
  ];
  
/*  code for select options      */ 
//   {educationOptions.map(option => (
//     <option key={option.value} value={option.value}>
//       {option.label}
//     </option>
//   ))}



