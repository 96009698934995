import React, { useState, useEffect } from "react";
import { Toast } from "../CustomComponent/toast";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";
import LoadingPage from "../../Components/layouts/loading";
import { extractDate } from "../../Components/Functions/date.fun";
import SimpleButton from "../../Components/layouts/simple_button";
import { useNavigate } from "react-router";
import Pagination from "../../Components/layouts/pagination";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../Redux/Pagination & Filters/filtersReducer";

const OnboardingRequest = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const paginationStore = useSelector((store) => store.filters);
  const [page, setCurrPage] = useState(paginationStore.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    // Dynamic code
    const getjobsandapplicants = () => {
      performRequest(
        "GET",
        `/bookdemo/getallonboradrequests?page=${paginationStore.currentPage}`
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data?.data);
            setTotalPages(Math.ceil(res?.data?.totalData / 50));
            setLoading(false);
          } else {
            Toast("error", "Server Error");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("Error", err);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    };

    getjobsandapplicants();
  }, [paginationStore.currentPage]);

  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header">
            <h2>All onboard requests/ contact received</h2>
          </div>
          <div className="page-body">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Date of Contact</th>
                      <th>Contact Person</th>
                      <th>Contact Number</th>
                      <th>Email</th>
                      <th>Contact As</th>
                      <th>company Name</th>
                      <th>Message</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{extractDate(item.createdAt)}</td>
                          <td>{item.fullName || "N/A"}</td>
                          <td>{item.contactNumber || "N/A"}</td>
                          <td>{item.companyEmail || item.email || "N/A"}</td>
                          <td>{item.contactAs || "Company"}</td>
                          <td>{item.companyName || "N/A"}</td>
                          <td style={{ maxWidth: "25%" }}>
                            {item.message || "N/A"}
                          </td>
                          {!item.userFullName && (
                            <td>
                              <SimpleButton
                                buttonText={"Start Onboarding"}
                                onClick={() => navigate("/employer-onboarding")}
                              />
                            </td>
                          )}
                          {item.userFullName && (
                            <td>
                              <button
                                disabled
                                className={`btn mx-1 mt-2 btn-primary`}
                              >
                                ALREADY ONBOARDED
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                pageDatalength={data?.length}
                showPage={showPage}
                setShowPage={setShowPage}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OnboardingRequest;
