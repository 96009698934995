import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { superAdmin } from "../../../Config/config";
const Employeesalarytracking = ({ attendanceData = [], fullName }) => {
  const store = useSelector((store) => store.userLogin.user);
  const navigate = useNavigate();

  const getRowBackgroundColor = (data) => {
    const { loginStatus, time, logoutTime } = data;
    const workedHours = (() => {
      if (!logoutTime) return 0;
      const parseTime = (timeString) => {
        const [time, modifier] = timeString.split(" ");
        let [hours, minutes, seconds] = time.split(":").map(Number);
        if (modifier === "PM" && hours !== 12) hours += 12;
        if (modifier === "AM" && hours === 12) hours = 0;
        return { hours, minutes, seconds };
      };

      const login = parseTime(time);
      const logout = parseTime(logoutTime);

      const loginDate = new Date(
        0,
        0,
        0,
        login.hours,
        login.minutes,
        login.seconds
      );
      const logoutDate = new Date(
        0,
        0,
        0,
        logout.hours,
        logout.minutes,
        logout.seconds
      );

      return Math.floor((logoutDate - loginDate) / (1000 * 60 * 60));
    })();

    if (loginStatus === "intime" && workedHours >= 9) {
      return "rgba(0, 128, 0, 0.2)";
    } else if (loginStatus === "grace period" && workedHours >= 9) {
      return "rgba(255, 255, 0, 0.2)";
    } else if (
      ["intime", "grace period", "2nd half"].includes(loginStatus) &&
      workedHours < 9
    ) {
      return "rgba(221, 184, 84, 0.4)";
    } else if (
      ["late", "late 2nd half", "non-attended"].includes(loginStatus)
    ) {
      return "rgba(224, 109, 109, 0.3)";
    }
    return "transparent";
  };

  const goToViewAttendance = (id) => navigate(`/employee-attendance/${id}`);

  return (
    <div className="card-table table-responsive" style={{ maxHeight: "470px" }}>
      <table className="table table-vcenter">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Day</th>
            <th>Login Time</th>
            <th>Logout Time</th>
            <th>Total Time</th>
            {(store.Role === superAdmin) && (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {attendanceData.map((data, index) => {
            // Determine the background color based on different conditions
            let rowBackgroundColor = getRowBackgroundColor(data);

            if (data?.isWorkHoliday) {
              return (
                <tr key={index}>
                  <td
                    colSpan={
                      store.Role === superAdmin
                        ? 7
                        : 6
                    }
                    style={{ backgroundColor: "#ADD8E6", textAlign: "center" }}
                  >
                    {data?.date || "N/A"}(Holiday)
                  </td>
                </tr>
              );
            }

            if (
              data?.dayOfWeek === "Sunday" &&
              !data?.time &&
              !data?.logoutTime
            ) {
              return (
                <tr key={index}>
                  <td
                    colSpan={
                      store.Role === superAdmin
                        ? 7
                        : 6
                    }
                    style={{ backgroundColor: "#D3D3D3", textAlign: "center" }}
                  >
                    Sunday
                  </td>
                </tr>
              );
            } else if (data?.leaveDate) {
              return (
                <tr key={index}>
                  <td
                    colSpan={
                      store.Role === superAdmin
                        ? 7
                        : 6
                    }
                    style={{ backgroundColor: "#E4CFF1", textAlign: "center" }}
                  >
                    {data?.date || "N/A"}
                    {"    "} (On Leave)
                  </td>
                </tr>
              );
            }
            return (
              <tr
                key={index}
                style={{
                  backgroundColor: rowBackgroundColor,
                }}
              >
               
                 <td>{(store?.Role === superAdmin) ? (data?.usermodel?.fullName || "N/A") : (fullName || "N/A")}</td>

                <td>{data?.date || "N/A"}</td>
                <td>{data?.dayOfWeek || "N/A"}</td>
                <td>{data?.time || "N/A"}</td>
                <td>{data?.logoutTime || "N/A"}</td>
                <td>{data?.workHr?.toFixed(2) || "N/A"}</td>
                {(store.Role === superAdmin) && (
                  <td>
                    <button
                      className="btn btn-primary"
                      style={{ width: "50px", height: "25px" }}
                      onClick={() => goToViewAttendance(data?.userId)}
                    >
                      View
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Employeesalarytracking;
