import { useSelector } from "react-redux";
import performRequest from "../../../APIs/request";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Toast } from "../../CustomComponent/toast";
import Footer from "../../../Components/Footer/footer";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.contactNumber) {
    errors.contactNumber = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

function Cadidate() {
  const [initialValues, setInitialValues] = useState({
    userStatus: "",
    userRole: "",
    email: "",
    firstName: "",
    lastName: "",
    fullName: "",
    contactNumber: "",
    password: "",
    image: "",
  });
  const store = useSelector((store) => store?.userLogin);

  useEffect(() => {
    if (store?.user?.UserId) {
      performRequest("GET", `/user/userbyid/${store?.user?.UserId}`)
        .then((res) => {
          setInitialValues({
            userStatus: res.data.userStatus,
            userRole: res.data.userRole,
            email: res.data.email,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            fullName: res.data.fullName,
            contactNumber: res.data.contactNumber,
            password: res.data.password,
            image: res.data.image,
          });
        })
        .catch((err) => console.error(err));
    }
  }, [store?.user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (values) => {
      let id = store?.user?.UserId;
      performRequest("PATCH", `/user/updateUserProfile/${id}`, values)
        .then((res) => {
          if (res.status === 200) {
            alert(" updated successfully!");
            // navigate("/");
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        });
    },
  });

  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("image", file);
      setInitialValues((prv) => {
        return { ...prv, image: file.name };
      });
    }
  };

  return (
    <>
      <div className="page mt-5">
        <div class="col-lg-8 m-auto">
          <div class="row row-cards">
            <div class="col-12">
              <form class="card" onSubmit={formik.handleSubmit}>
                <div class="card-body">
                  <h3 class="card-title">Edit Profile</h3>
                  <div class="row row-cards">
                    <div class="col-md-5">
                      <div class="mb-3">
                        <label class="form-label">User Status</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.userStatus}
                          name="userStatus"
                          type="text"
                          class="form-control"
                          disabled=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="mb-3">
                        <label class="form-label">User Role</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.userRole}
                          name="userRole"
                          type="text"
                          class="form-control"
                          placeholder="Username"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-3">
                        <label class="form-label">Email address</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          name="email"
                          type="email"
                          class="form-control"
                          placeholder="Email"
                        />
                        {formik.errors.email ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                        <br></br>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="mb-3">
                        <label class="form-label">First Name</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          name="firstName"
                          type="text"
                          class="form-control"
                          placeholder="Company"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Last Name</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          name="lastName"
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label">Full Name</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.fullName}
                          world
                          name="fullName"
                          type="text"
                          class="form-control"
                          placeholder="Home Address"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-3">
                        <label class="form-label">Contact Number</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.contactNumber}
                          name="contactNumber"
                          type="text"
                          class="form-control"
                          placeholder="City"
                        />
                        {formik.errors.contactNumber ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.contactNumber}
                          </div>
                        ) : null}
                        <br></br>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3">
                        <label class="form-label">Password</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          name="password"
                          type="test"
                          class="form-control"
                          placeholder="ZIP Code"
                        />
                        {formik.errors.password ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                        <br></br>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-label">Choose Image</div>
                    <input
                      type="file"
                      name="image"
                      class="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div class="card-footer text-end">
                  <button type="submit" class="btn btn-primary">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
      <Footer />
    </>
  );
}

export default Cadidate;
