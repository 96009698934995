import * as yup from "yup";
export const optionsSchema = yup.object().shape({
  optionA: yup.string().required("Required"),
  optionB: yup.string().required("Required"),
  optionC: yup.string().required("Required"),
  optionD: yup.string().required("Required"),
});
export const questionShcema = yup.object().shape({
  question: yup.string().required("Required"),
  answer: yup.string().required("Required"),
  options: optionsSchema.required(),
});
export const testSchema = yup.object().shape({
  type: yup.string(),
  level: yup.string(),
  icon: yup.mixed(),
  name: yup.string(),
  department: yup.string(),
  description: yup.string(),
  passingScore: yup.number(),
  questionsAnsOpt: yup.array().of(questionShcema),
});
