import React from "react";

import { FaArrowCircleRight } from "react-icons/fa";

const FormSubmitButton = ({ buttonText, width, ...props }) => {
  buttonText = buttonText.toUpperCase();
  if (width === "full") {
    return (
      <button {...props} type="submit" className="mx-1 btn btn-primary w-100">
        <FaArrowCircleRight fontSize={"1em"} className="me-2" /> {buttonText}
      </button>
    );
  }
  return (
    <button type="submit" {...props} className=" btn mx-1 mt-2  btn-primary">
      <FaArrowCircleRight fontSize={"1em"} className="me-2" />
      {buttonText}
    </button>
  );
};

export default FormSubmitButton;
