import React from "react";
import Footer from "../../Components/Footer/footer";

const FAQs = () => {
  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="card card-lg">
            <div className="card-body">
              <div className="row g-4">
                <div className="col-12 markdown">
                  <h1>FAQ</h1>
                  <p>
                    Hire48 is build by the same team who build Kryzen Biotech. A
                    successful company in Agritech domain. From the same
                    problems we faced in building our team we identified few
                    gaps in the industry processes and decided to build a new
                    product for it.
                  </p>
                  <p>
                    From startups to SMEs to established enterprises, Apna
                    revolutionizes the way businesses find high-quality talent
                    quickly & effortlessly.
                  </p>
                </div>
              </div>
              <div className="row col-12 mt-4">
                <div className="col-lg-6 col-sm-12">
                  <div class="card border-0">
                    <div class="accordion" id="accordion-example">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-1">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-1"
                            aria-expanded="false"
                          >
                            What services does Hire48.com offer to employers?
                          </button>
                        </h2>
                        <div
                          id="collapse-1"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Hire48.com offers employers a dedicated account
                            manager, resume proofing, background verification,
                            salary verification, and a 48-hour hiring guarantee
                            to ensure quick and efficient hiring of the right
                            candidates.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-2">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-2"
                            aria-expanded="false"
                          >
                            How does the dedicated account manager assist
                            employers?
                          </button>
                        </h2>
                        <div
                          id="collapse-2"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Our dedicated account manager provides personalized
                            support to streamline your hiring process, offering
                            assistance with candidate selection, interview
                            scheduling, and addressing any hiring needs to help
                            you fill positions efficiently.{" "}
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-3">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-3"
                            aria-expanded="false"
                          >
                            What is the resume proofing service, and how does it
                            benefit employers?
                          </button>
                        </h2>
                        <div
                          id="collapse-3"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Our resume proofing service ensures that all
                            candidate resumes are professionally reviewed and
                            verified, giving employers confidence that the
                            information is accurate and reflective of the
                            candidate's abilities.{" "}
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-4">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-4"
                            aria-expanded="false"
                          >
                            How does the background verification process work at
                            Hire48.com?
                          </button>
                        </h2>
                        <div
                          id="collapse-4"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Our background verification process includes
                            thorough checks on employment history, criminal
                            records, education, and other key details, ensuring
                            that the candidate meets your standards for
                            reliability and integrity.{" "}
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-5">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-5"
                            aria-expanded="false"
                          >
                            What is the salary verification service?
                          </button>
                        </h2>
                        <div
                          id="collapse-5"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Our salary verification service confirms the
                            candidate’s previous salary details, providing
                            employers with an accurate understanding of the
                            candidate’s compensation history for better offer
                            alignment.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="card border-0">
                    <div class="accordion" id="accordion-example">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-6">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-6"
                            aria-expanded="false"
                          >
                            How can Hire48.com help me hire a candidate within
                            48 hours?
                          </button>
                        </h2>
                        <div
                          id="collapse-6"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            With our streamlined processes, dedicated account
                            managers, and pre-verified candidate pool,
                            Hire48.com is equipped to match you with the right
                            candidates and complete the hiring process within 48
                            hours.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-7">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-7"
                            aria-expanded="false"
                          >
                            Can I request additional screenings for a candidate?
                          </button>
                        </h2>
                        <div
                          id="collapse-7"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Yes, our team can accommodate additional screenings
                            or custom verifications based on your specific
                            hiring requirements. Your account manager will guide
                            you through the options available.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-8">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-8"
                            aria-expanded="false"
                          >
                            How does Hire48.com ensure the quality of
                            candidates?
                          </button>
                        </h2>
                        <div
                          id="collapse-8"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            We conduct rigorous screenings, including resume
                            proofing, skill verification, background checks, and
                            salary verification, to ensure that only qualified,
                            reliable candidates are recommended to you.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-9">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-9"
                            aria-expanded="false"
                          >
                            Is there a cost associated with using Hire48.com’s
                            services for employers?
                          </button>
                        </h2>
                        <div
                          id="collapse-9"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Yes, Hire48.com offers various service packages
                            tailored to employers' needs. Our team will provide
                            you with a detailed pricing structure based on your
                            hiring requirements.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-10">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-10"
                            aria-expanded="false"
                          >
                            What if the candidate hired does not meet the job
                            requirements?
                          </button>
                        </h2>
                        <div
                          id="collapse-10"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordion-example"
                        >
                          <div class="accordion-body pt-0">
                            Our goal is to ensure the perfect fit. If a
                            candidate does not meet the requirements, we work
                            with you to find a replacement candidate as quickly
                            as possible to ensure satisfaction.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FAQs;
