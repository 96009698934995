import * as yup from "yup";

export const InterviewSchema = yup.object().shape({
  date: yup.date().required("Required"),
  time: yup.string().required("Required"),
  modeofInterview: yup.string().required("Required"),
  contactPersonName: yup.string().required("Required"),
  contactPersonNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits")
    .required("Required"),
  meetLink: yup.string().when("modeofInterview", {
    is: (val) => val === "video interview",
    then: (s) => s.required("Field is required"),
    otherwise: (s) => s,
  }),
  address: yup.string().when("modeofInterview", {
    is: (val) => val === "walk-in interview",
    then: (s) => s.required("Field is required"),
    otherwise: (s) => s,
  }),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits")
    .when("modeofInterview", {
      is: (val) => val === "telephonic interview",
      then: (s) => s.required("Field is required"),
      otherwise: (s) => s,
    }),
});
