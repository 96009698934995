import React from "react";
import Footer from "../../Components/Footer/footer";
import CommingSoon from "../../Components/layouts/comming.soon";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="card card-lg">
            <div className="card-body ">
              <div className="row g-4">
                <div className="col-12 markdown">
                  <h1>Privacy Policy</h1>
                  <p>
                    Hire48 is a commercial app by Kryzen Technologies Private
                    Limited (&quot;HIRE48&quot;). This page is used to inform
                    visitors regarding our policies with the collection, use,
                    and disclosure of Personal Information for anyone using the
                    app and website of HIRE48 (&quot;Platform&quot;). By using
                    Platform, you consent to the terms of our privacy policy
                    (&quot;Privacy Policy&quot;) in addition to our Terms of
                    Service. We encourage you to read this Privacy Policy
                    regarding the collection, use, and disclosure of your
                    information from time to time to keep yourself updated with
                    the changes &amp; updated that we make to this Privacy
                    Policy. Personal Identification Information
                  </p>
                  <h3>Personal Identification Information</h3>
                  <p>
                    If you choose to use our Service, then you agree to the
                    collection and use of information in relation to this
                    policy. The Personal Identification Information that we
                    collect is used for providing and improving the Service. We
                    will not use or share your information with anyone except as
                    described in this Privacy Policy.
                  </p>

                  <p>
                    The personal information you provide on HIRE48 Platform when
                    you fill out your profile is public, such as your name,
                    location, gender, profile picture, education and
                    professional info including where you are working. This is
                    hereinafter called Public Profile. Your Public Profile will
                    be published on HIRE48/username. Your Public Profile can:
                  </p>

                  <p>
                    Be associated with you on the internet Show up when someone
                    does a search on search engine We also use your Public
                    Profile, to help connect you with friends and family which
                    increases your and your friends' chances to find suitable
                    job opportunities.
                  </p>

                  <p>
                    We may collect Personal Identification Information of users
                    including the information that is available on the internet,
                    such as from Truecaller, Facebook, LinkedIn, Twitter and
                    Google, or publicly available information that we acquire
                    from service providers. We collect this information to
                    identify users for better communication, processing and
                    personalization of the Services provided by us.
                  </p>

                  <h3>Non-personal Identification Information</h3>

                  <p>
                    We may collect non-personal identification information about
                    users whenever they interact with our site. Non-personal
                    identification information may include the type of mobile
                    phone and technical information about users, such as the
                    operating system and the Internet service providers utilized
                    including IP address and other similar information. Usage
                    and Technical Information
                  </p>

                  <h3>Usage and Technical Information</h3>

                  <p>
                    We collect the information about how you interact with our
                    Service. This information may include your IP address,
                    geographical location, browser type, referral source, length
                    of visit, pages viewed and items clicked. Information
                    Collection
                  </p>

                  <h3>Information Collection</h3>

                  <p>
                    For a better experience, while using our Service, you are
                    required to provide us with certain personally identifiable
                    information for your Public Profile, including but not
                    limited to:
                  </p>

                  <p>
                    <b>Identity information,</b> such as your first name, last
                    name, gender, date of birth, username and/or similar as may
                    be verified by voter ID card, PAN or driving license;
                    <br />
                    <b> Contact information,</b> such as your mobile number,
                    postal address, email address and telephone number;
                    <br /> <b>Professional information,</b> such as your
                    education, work experience, skills, salary, photo, city,
                    area and other relevant details. Professional information
                    helps you to get more from our Services, including helping
                    employers find you. Please do not post or add personal data
                    to your resume that you would not want to be publicly
                    available. <br /> <b>Feedback and correspondence,</b> such
                    as information you provide when you respond to surveys,
                    participate in market research activities, report a problem
                    with Service, receive customer support or otherwise
                    correspond with us; <br />
                    <b>Usage information,</b> such as information about how you
                    use the Service and interact with us; and <br />
                    <b>Marketing information,</b> such as your preferences for
                    receiving marketing communications and details about how you
                    engage with them.
                  </p>

                  <p>
                    HIRE48 Platform&rsquo;s mission is to connect talent to job
                    opportunities and employers to quality talent. We are
                    committed to be transparent about the data we collect about
                    you, how it is used and with whom it is shared.
                  </p>

                  <p>
                    When you use the services of our customers and partners,
                    such as employers or prospective employers and applicant
                    tracking systems, we share your Public Profile (e.g., your
                    job title and name of the company where you work) with
                    prospective employers to enable you to get job interviews.
                  </p>
                  <h3>Information we collect</h3>
                  <p>
                    <b> Contacts List.</b>
                  </p>

                  <p>
                    {" "}
                    When you sync your contacts with our Services, we import and
                    store the contacts list to our servers. You have the option
                    to deny us the access to your contacts list.
                  </p>

                  <p>
                    {" "}
                    We also receive personal data (including contact
                    information) about you when others import or sync their
                    contacts with our Services.
                  </p>

                  <p>
                    <b>Location Information.</b>
                    When you give location permission, we access information
                    that is derived from your GPS. We may use third-party
                    cookies and similar technologies to collect some of this
                    information.
                  </p>

                  <p>
                    <b>Storage.</b>
                    When you opt-in for storage permission, we access your
                    device storage like gallery photos.
                  </p>

                  <p>
                    <b>Camera.</b>
                    Granting camera permission allows us to access the photo
                    that you click to be displayed on your resume.
                  </p>

                  <p>
                    <b>Microphone.</b>
                    Voice and audio information when you use audio features.
                  </p>

                  <h3>How we protect your information?</h3>

                  <p>
                    We adopt appropriate data collection, storage and processing
                    practices and security measures to protect against
                    unauthorized access, alteration, disclosure or destruction
                    of your personal information, username, password,
                    transaction information and data stored on our app. The data
                    is stored securely on our servers - https://hire48.com
                  </p>
                  <h3>Sharing your personal information</h3>
                  <p>
                    We do not sell, trade, or rent users personal identification
                    information to any third party. We may share generic
                    aggregated demographic information not linked to any
                    personal identification information regarding visitors and
                    users with our business partners, trusted affiliates and
                    advertisers for the purposes outlined above.
                  </p>

                  <p>
                    The app does use third-party services that may collect
                    information used to identify you.
                  </p>

                  <p>
                    Link to privacy policy of third-party service providers used
                    by the app
                  </p>

                  <ul>
                    <li>Google Play Services</li>
                    <li>Firebase</li>
                    <li>Apple Appstore</li>
                  </ul>

                  <h3>
                    We do not disclose, transfer or share your personal
                    information with others except with:
                  </h3>

                  <p>
                    {" "}
                    Our affiliates and group companies to the extent required
                    for our internal business and/or administrative purposes
                    and/or general corporate operations and for provision of
                    services aimed at helping you in your career enhancement;
                  </p>

                  <p>
                    {" "}
                    Companies, in the event you have posted jobs on HIRE48
                    Platform on behalf of such companies;
                  </p>

                  <p>
                    {" "}
                    Candidates who have applied to the job posted by you on the
                    Platform, if we determine that the requirements of the job
                    post, match with the resume of the candidate. By registering
                    on the Platform and consenting to the terms of this Privacy
                    Policy, you agree that we may contact you or share your
                    contact details with the candidates for the purpose of the
                    Services;
                  </p>

                  <p>
                    {" "}
                    Potential recruiters/ job posters if we determine that your
                    resume matches a particular job description/ vacancy
                    available with such recruiters. By registering on the
                    Platform and consenting to the terms of this Privacy Policy,
                    you agree that we may contact you or forward your resume to
                    potential recruiters;
                  </p>

                  <p>
                    {" "}
                    Third parties including enforcement, regulatory and judicial
                    authorities, if we determine that disclosure of your
                    personal information is required to a) respond to court
                    orders, or legal process, or to establish or exercise our
                    legal rights or defend against legal claims; or b)
                    investigate, prevent, or take action regarding illegal
                    activities, suspected fraud, situations involving potential
                    threats to the physical safety of any person, violations of
                    our Terms of Service or as otherwise required by law;
                  </p>

                  <p>
                    {" "}
                    In the event of a merger, acquisition, financing, or sale of
                    assets or any other situation involving the transfer of some
                    or all of our business assets we may disclose personal
                    information to those involved in the negotiation or
                    transfer.
                  </p>

                  <p>
                    {" "}
                    Third party service providers and marketing partners that we
                    engage to a) provide services over the Platform on our
                    behalf; b) maintain the Platform and mailing lists; or c)
                    communicate with you on our behalf about offers relating to
                    its products and/or services. We will take reasonable steps
                    to ensure that these third-party service providers are
                    obligated to protect your personal information and are also
                    subject to appropriate confidentiality / non-disclosure
                    obligations.
                  </p>

                  <p>
                    You, hereby agree and acknowledge that you will use the
                    information shared with you only for the purpose of the
                    Services. You shall not use such information for any
                    personal or other business purposes. In the event you are
                    found to be misusing the information shared with you, we
                    shall, at our sole discretion, delete your account with
                    immediate effect and you will be blocked from using/
                    accessing HIRE48 Platform in future.
                  </p>
                  <h3>Log Data</h3>
                  <p>
                    Whenever you use our Service, including our sites, app and
                    platform technology, such as when you view or click on
                    content (e.g., learning video) or perform a search, install
                    or update one of our mobile apps, post messages or apply for
                    jobs and in a case of an error in the app we collect data
                    and information (through third-party products) on your phone
                    called Log Data. This Log Data may include information such
                    as log-ins, cookies, your device Internet Protocol
                    (&quot;IP&quot;) address, device name, operating system
                    version, the configuration of the app when utilizing our
                    Service, the time and date of your use of the Service, and
                    other statistics to identify you and log your use.
                  </p>
                  <h3>Cookies</h3>
                  <p>
                    Cookies are files with a small amount of data that are
                    commonly used as anonymous unique identifiers. These are
                    sent to your browser from the websites that you visit and
                    are stored on your device's internal memory.
                  </p>

                  <p>
                    This Service does not use these &quot;cookies&quot;
                    explicitly. However, the app may use third party code and
                    libraries that use &quot;cookies&quot; to collect
                    information and improve their services. You have the option
                    to either accept or refuse these cookies and know when a
                    cookie is being sent to your device. If you choose to refuse
                    our cookies, you may not be able to use some portions of
                    this Service.
                  </p>
                  <h3>Service Providers</h3>
                  <p>
                    We may employ third-party companies and individuals due to
                    the following reasons:
                  </p>

                  <p>
                    To facilitate our Service; To provide the Service on our
                    behalf; To perform Service-related services; or To assist us
                    in analyzing how our Service is used. We want to inform
                    users of this Service that these third parties have access
                    to your Personal Information. The reason is to perform the
                    tasks assigned to them on our behalf. However, they are
                    obligated not to disclose or use the information for any
                    other purpose.
                  </p>
                  <h3>Security</h3>
                  <p>
                    We value your trust in providing us your Personal
                    Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no
                    method of transmission over the internet, or method of
                    electronic storage is 100% secure and reliable, and we
                    cannot guarantee its absolute security.
                  </p>
                  <h3>Links to Other Sites</h3>
                  <p>
                    This Service may contain links to other sites. If you click
                    on a third-party link, you will be directed to that site.
                    Note that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the Privacy
                    Policy of these websites. We have no control over and assume
                    no responsibility for the content, privacy policies, or
                    practices of any third-party sites or services.
                  </p>
                  <h3>Children's Privacy</h3>
                  <p>
                    These Services do not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from children under 13. In the case we discover that a child
                    under 13 has provided us with personal information, we
                    immediately delete this from our servers. If you are a
                    parent or guardian and you are aware that your child has
                    provided us with personal information, please contact us so
                    that we will be able to do necessary actions.
                  </p>
                  <h3>No Impersonation or False Information to be Provided</h3>
                  <p>
                    You have to use your actual name on the Platform, you are
                    required to input your correct phone number to use our
                    Services. You will be getting a job based on your HIRE48
                    account. You will not falsely represent yourself as another
                    person or representative of another person to use our
                    Services. You will not lie about your details, for any
                    reason. Trust and Safety
                  </p>
                  <h3>Trust and Safety</h3>
                  <p>
                    HIRE48 takes the trust and safety all its users seriously.
                    We do not allow content that promotes abuse, fraud,
                    MLM/network marketing, job openings that charge fees,
                    suicide, self-harm, or is intended to shock or disgust
                    users. Strict action is taken against such content and with
                    people posting such messages/content in the group.
                  </p>

                  <p>
                    To ensure the best possible experience for all the users of
                    the app, we have established some basic guidelines called
                    the Community Guidelines. The Community Guidelines gets
                    updated periodically. You will be notified when this
                    happens.
                  </p>
                  <h3>Disclaimer</h3>
                  <p>
                    HIRE48 does not hold any responsibility for any incident,
                    fraud, cheat or crime that may happen to any users. We
                    advise you to check and verify information of other users
                    before proceeding with any transactions or interaction among
                    users. Also, do not share your private information on group,
                    to prevent the use of such information for any un-safe
                    purposes.
                  </p>

                  <p>
                    When you see something inappropriate or come across an
                    offending content, click on the 3 vertical dots on the top
                    right corner of the post and choose to 'report' post. This
                    alerts the admins about the post and allows for necessary
                    action to be taken.
                  </p>

                  <h3>Changes to This Privacy Policy</h3>
                  <p>
                    We may update our Privacy Policy from time to time. Thus,
                    you are advised to review this page periodically for any
                    changes. We will notify you of any changes by posting the
                    new Privacy Policy on this page. These changes are effective
                    immediately after they are posted on this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
