import * as yup from "yup";

export const accountSettingSchema = yup.object().shape({
  photograph: yup.mixed(),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  dateOfBirth: yup
    .date()
    .min(new Date(1900, 0, 1), "Date of birth must be after January 1, 1900")
    .max(new Date(2010, 0, 1), "Date of birth must be before January 1, 2010")
    .required("Required"),
  permanentAddress: yup.string().required("Required"),
  residentialAddress: yup.string().required("Required"),
  emergencyContactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits")
    .required("Required"),
  emergencyContactName: yup.string().required("Required"),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits and postive")
    .required("Required"),
  emergencyContactRelation: yup
    .string()
    .oneOf(
      ["father", "mother", "spouse", "sister", "brother", "friend", null],
      "Relation must be one of the given options"
    )
    .nullable()
    .required("Required"),
});