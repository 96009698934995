import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { CustomErrorResponse } from "../../CustomComponent/custom_error_response";
import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import { extractDate } from "../../../Components/Functions/date.fun";

const Viewleaves = () => {
  const user = useSelector((store) => store.userLogin.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [leaveCount, setLeaveCount] = useState({});
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [leaveData, setLeaveData] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/leave/getleavesbyid/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          let data = res.data;
          let getLatestLeaveData = data[data.length - 1];
          setLeaveData(getLatestLeaveData);

          let totalPaidLeaves = getLatestLeaveData.totalPaidLeaves;
          let totalPaidCasualLeaves = getLatestLeaveData.totalPaidCasualLeaves;
          let totalPaidSickLeaves = getLatestLeaveData.totalPaidSickLeaves;
          let pendingPaidLeaves = getLatestLeaveData.pendingPaidLeaves;
          let unpaidLeavesCount = getLatestLeaveData.unpaidLeavesCount;

          setLeaveCount({
            totalPaidLeaves,
            totalPaidCasualLeaves,
            totalPaidSickLeaves,
            pendingPaidLeaves,
            unpaidLeavesCount,
          });
        } else {
          Toast("error", "Error while getting leave details!");
        }
        setLoading(false);
      })
      .catch((err) => {
        CustomErrorResponse(err);
        setLoading(false);
      });

    function getFiscalQuarter(date = new Date()) {
      const month = date.getMonth() + 1;
      const fiscalYearMonth = ((month - 4 + 12) % 12) + 1;

      // Determine quarter based on fiscal year months
      if (fiscalYearMonth >= 1 && fiscalYearMonth <= 3) {
        return 1; // April to June
      } else if (fiscalYearMonth >= 4 && fiscalYearMonth <= 6) {
        return 2; // July to September
      } else if (fiscalYearMonth >= 7 && fiscalYearMonth <= 9) {
        return 3; // October to December
      } else {
        return 4; // January to March
      }
    }
    let getQuarter = getFiscalQuarter();

    setCurrentQuarter(getQuarter);
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="container-xxl">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h2 className="page-title mb-1">
                    <FcCalendar className="fs-2  me-1" /> View Leave
                  </h2>
                  <h4 className="text-muted">
                     Leaves status & updates
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="btn-list justify-content-end">
                    <a href={"/employee-leave-management"}>
                      <button className="btn btn-primary">
                        <BsCalendar2XFill className="me-2 fs-2" /> View All
                        Leaves
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                {data && (
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>Specs.</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-secondary">Account Name</td>
                          <td>
                            {(
                              data?.[0]?.usermodel?.fullName || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Employee Id</td>
                          <td>{data?.[0]?.userId || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Total Leaves</td>
                          <td>{leaveCount?.totalPaidLeaves || "0"}</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Pending Leaves</td>
                          <td>{leaveCount?.pendingPaidLeaves || "0"}</td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Sick Leaves</td>
                          <td>
                            {leaveCount.totalPaidSickLeaves} (Obtained :{" "}
                            {currentQuarter === 1
                              ? leaveData?.quarterOneLeaves?.[0].usedSickLeaves
                              : currentQuarter === 2
                              ? leaveData?.quarterTwoLeaves?.[0].usedSickLeaves
                              : currentQuarter === 3
                              ? leaveData?.quarterThreeLeaves?.[0]
                                  .usedSickLeaves
                              : currentQuarter == 4
                              ? leaveData?.quarterFourLeaves?.[0].usedSickLeaves
                              : 0}{" "}
                            )
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Casual Leaves</td>
                          <td>
                            {leaveCount.totalPaidCasualLeaves} (Obtained :
                            {currentQuarter === 1
                              ? leaveData?.quarterOneLeaves?.[0].usedCasualLeave
                              : currentQuarter === 2
                              ? leaveData?.quarterTwoLeaves?.[0].usedCasualLeave
                              : currentQuarter === 3
                              ? leaveData?.quarterThreeLeaves?.[0]
                                  .usedCasualLeave
                              : currentQuarter == 4
                              ? leaveData?.quarterFourLeaves?.[0]
                                  .usedCasualLeave
                              : 0}
                            )
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div className="table-responsive">
                  {data && (
                    <table className="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Leave Date</th>
                          <th>Leave Start Date</th>
                          <th>Leave End Date</th>
                          <th>Leave Type</th>
                          <th>Leave Reason Type</th>
                          <th>Leave Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data?.map((task, index) => {
                            let leaveDate = "";
                            leaveDate += task.leaveDate;
                            let leaveStartDate = "";
                            leaveStartDate += task.leaveStartDate;
                            let leaveEndDate = "";
                            leaveEndDate += task.leaveEndDate;

                            return (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  {task.leaveDate
                                    ? extractDate(leaveDate)
                                    : "N/A"}
                                </td>
                                <td>
                                  {task.leaveStartDate
                                    ? extractDate(leaveStartDate)
                                    : "N/A"}
                                </td>
                                <td>
                                  {task.leaveEndDate
                                    ? extractDate(leaveEndDate)
                                    : "N/A"}
                                </td>
                                <td>
                                  {task.leaveType?.toUpperCase() || "N/A"}
                                </td>
                                <td>
                                  {task.leaveReasonType?.toUpperCase() || "N/A"}
                                </td>
                                <td>
                                  <span
                                    className={`badge ${
                                      task.leaveStatus === "approved"
                                        ? "bg-green"
                                        : task.leaveStatus === "pending"
                                        ? "bg-blue"
                                        : task.leaveStatus === "rejected"
                                        ? "bg-red"
                                        : "bg-blue"
                                    }`}
                                    style={{ color: "white" }}
                                  >
                                    {task.leaveStatus?.toUpperCase() || "N/A"}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    {user?.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                      user?.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                        <SimpleButton
                          buttonText="Edit leave tracking"
                          href={`/employee-leave-management/edit/${id}`}
                        />
                      )}
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Viewleaves;
