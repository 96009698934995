import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
// import ButtonWithSVG from "../../Components/layouts/button_with_svg";
// import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import LoadingPage from "../../Components/layouts/loading";
import { useSelector } from "react-redux";
import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import { CustomErrorResponse } from "../CustomComponent/custom_error_response";
import Leavestrackingtable from "./Employee Leave Management/leaves_table";

const Employeeleavemanagement = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    employee: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const store = useSelector((store) => store.userLogin?.user);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", getFilteredURL("/leave/getleaves/", page, filters))
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);

          setTotalPages(Math.ceil(res.data?.leaveApplied / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        CustomErrorResponse(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  useEffect(() => {
  function  getCompanyEmployeeNames(){
      performRequest("GET", "/tasks/companyemployees/")
      .then((res) => setEmployeeData(res?.data))
      .catch((err) => CustomErrorResponse(err));
    }
    if(store?.Role==='superAdmin'){
      getCompanyEmployeeNames()
    }
  }, []);


  const filteredData =
    store.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
    store.Role == process.env.REACT_APP_ROLE_ADMIN
      ? data?.leave
      : data?.leave?.filter((item) => item.userId == store.UserId);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalendar className="fs-2  me-1" /> Leaves Tracking
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Apply, modify and track your leaves.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/employee-leave-management/new-leave"}>
                    <button className="btn btn-primary d-none d-sm-block">
                      <BsCalendar2XFill className="me-2 fs-2" /> Add New Leave
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-2">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none p-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3 className="m-1">Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search employee:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  employee: e.target.value,
                                })
                              }
                              value={filters.employee}
                            >
                              <option value="">select all</option>

                              {employeeData &&
                                employeeData?.map((state, index) => (
                                  <option key={index} value={state?.id} >
                                    {state?.fullName}
                                  </option>
                                ))}
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      employee: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Start Date:"
                            value={filters.startdate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                startdate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="End Date:"
                            value={filters.enddate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                enddate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                     {store?.Role === 'superAdmin' && (   <div className="col-12">
                          <DynamicFilterSelect
                            label="Search employee:"
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                employee: e.target.value,
                              })
                            }
                            value={filters.employee}
                          >
                            <option value="">select all</option>

                            {employeeData &&
                              employeeData?.map((state, index) => (
                                <option key={index} value={state?.id} >
                                    {state?.fullName}
                                  </option>
                              ))}
                          </DynamicFilterSelect>
                        </div>)}

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    startdate: "",
                                    enddate: "",
                                    employee: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {filteredData && (
                  <Leavestrackingtable
                    EX={data?.leave}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                )}

                <div className="btn-list justify-content-center">
                  <a href={"/employee-leave-management/new-leave"}>
                    <button className="btn btn-primary d-lg-none d-md-none mt-3 mb-3">
                    <BsCalendar2XFill className="me-2 fs-2" /> Add New Leave
                    </button>
                  </a>
                </div>

                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.leave?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Employeeleavemanagement;