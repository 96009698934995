export const EmployeeInfo = {
    firstName: "",
    lastName: "",
    emailId: "",
    dateOfBirth: "",
    photograph: "",
    contactNumber: "",
    permanentAddress: "",
    residentialAddress: "",
    aadharcardNumber: "",
    aadharcardPhotoFront: "",
    aadharcardPhotoBack: "",
    pancardNumber: "",
    pancardPhoto: "",
    emergencyContactNumber: "",
    emergencyContactName: "",
    emergencyContactRelation: "",
    previousOrganizationName: "",
    previousOrganizationLocation: "",
    totalYearsOfExperience: "",
    qualification: "",
    contractOfEmployment: "",
    roleInOrganisation: "",
    workingPlace:
      "319, City Avenue Pune Bangalore, Highway, Wakad, Pimpri-Chinchwad, Pune, Maharashtra 411057",
    packageJoinedAt: "",
    offerLetterAcceptance: "",
    joiningLetterScan: "",
    reportingTo: "",
    bankpassbookPhoto: "",
    residentialProofPhoto: "",
    mobileGivenByOrganisation: "",
    laptopGivenByOrganisation: "",
    simCardGivenByOrganisation: "",
    mobileId: "",
    laptopId: "",
    simCardNumber: "",
    hireViaAgencyOrDirect: "",
    agencyExpences_exlGST: "",
    salaryPerMonth: "",
    pfptDeduction: "",
    modeOfSalary: "",
    bankAccountNumber: "",
    dateOfJoining: "",
  };