import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const AiInterviewCracker = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();
  useEffect(() => {
    const getUser = () => {
      setLoading(true);
      performRequest("GET", `/user/userbyid/${store?.user?.UserId}`)
        .then((res) => {
          setData(res?.data);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    };
    if (store?.isLoggedIn) {
      getUser();
    }
  }, [store]);
  return (
    <>
      <div className="page">
        <div className="page-wrapper">
          <div className="container-xxl">
            <div className="page-body">
              <div className="section-header">
                <h2 className="section-title section-title-lg m-0 p-0">
                  Candidate details for {data?.fullName}
                </h2>
                <p className="section-description mb-4 p-0">
                  <span className="status status-green">
                    <span className="status-dot status-dot-animated"></span>
                    Active Candidate
                  </span>
                </p>
              </div>

              <div>
                {data?.resumemodel ? (
                  <div className="card px-6 py-4">
                    <div className="section-header">
                      <div className="row">
                        <div className="col-9">
                          <img
                            src={
                              data?.resumemodel?.personalDetails?.[0]
                                ?.profilePicture
                            }
                            alt=""
                          />
                          <p className="section-description p-0 m-0 mt-2">
                            {data?.fullName}
                          </p>
                          {data?.resumemodel && (
                            <p>
                              Location:{" "}
                              {data?.resumemodel?.personalDetails?.[0]?.city},{" "}
                              {data?.resumemodel?.personalDetails?.[0]?.state},{" "}
                              {data?.resumemodel?.personalDetails?.[0]?.country}
                            </p>
                          )}
                          <h2 className="section-title fs-1 section-title-lg m-0 p-0">
                            {
                              data?.resumemodel?.personalDetails?.[0]
                                ?.workProfile
                            }
                          </h2>
                        </div>
                      </div>
                    </div>
                    {data?.resumemodel?.description && (
                      <div>
                        About :{" "}
                        <strong>{data?.resumemodel?.description}</strong>
                      </div>
                    )}
                    {data?.resumemodel && (
                      <>
                        <hr className="my-5 p-0" />
                        <p className="fw-bold  p-0 mb-sm-5 mb-lg-0 mb-md-0">
                          Other Details:
                        </p>
                      </>
                    )}
                    {data?.resumemodel?.workExperience &&
                      data?.resumemodel?.workExperience.length > 0 && (
                        <div>
                          <hr />
                          <h3>Work Experience:</h3> <hr />
                          {data?.resumemodel?.workExperience?.map(
                            (el, index) => {
                              return (
                                <div key={index}>
                                  <p>Company Name: {el.companyName}</p>
                                  <p>Position: {el.position}</p>
                                  <p>Employment Type: {el.employmentType}</p>
                                  <p>Joining Date: {el.joiningDate}</p>
                                  <p>Exit Date: {el.exitDate}</p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    {data?.resumemodel?.education &&
                      data?.resumemodel?.education.length > 0 && (
                        <div>
                          <hr />
                          <h3>Education :</h3> <hr />
                          {data?.resumemodel?.education?.map((el, index) => {
                            return (
                              <div key={index}>
                                <p>Education: {el.educationSelect1}</p>
                                <p>Start Date: {el.startDate1}</p>
                                <p>End Date: {el.endDate1}</p>
                                <p>Summary: {el.summary}</p>
                                <p>University: {el.university_1}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {data?.resumemodel?.skills &&
                      data?.resumemodel?.skills.length > 0 && (
                        <div>
                          <hr />
                          <h3>Skills :</h3> <hr />
                          <div className="d-flex">
                            {data?.resumemodel?.skills?.map((el, index) => {
                              return (
                                <div key={index} className="m-1 text-center">
                                  <div
                                    className="avatar avatar-lg"
                                    style={{
                                      backgroundImage: `url(${el.icon})`,
                                    }}
                                  ></div>

                                  <p>{el.skill}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}{" "}
                    {data?.resumemodel?.languages &&
                      data?.resumemodel?.languages.length > 0 && (
                        <div>
                          <hr />
                          <h3>Languages :</h3> <hr />
                          {data?.resumemodel?.languages?.map((el, index) => {
                            return (
                              <div key={index}>
                                <p>
                                  {" "}
                                  <strong>
                                    Language Read: {el.readLanguage.join(", ")}
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    Language Write:{" "}
                                    {el.languageWrite.join(", ")}
                                  </strong>
                                </p>
                                <strong>
                                  Language Speak: {el.languageSpeak.join(", ")}
                                </strong>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {data?.resumemodel?.additionalInformation &&
                      data?.resumemodel?.additionalInformation.length > 0 && (
                        <div>
                          <hr />
                          <h3>Additional Info :</h3>
                          <hr />
                          <div>
                            {data?.resumemodel?.additionalInformation.map(
                              (el, index) => {
                                return (
                                  <span
                                    key={index}
                                    className="badge bg-blue-lt p-2 me-2"
                                  >
                                    {el}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    {data?.resumemodel?.awardAndCertificate &&
                      data?.resumemodel?.awardAndCertificate.length > 0 && (
                        <div>
                          <hr />
                          <h3>Award And Certificate :</h3>
                          <hr />
                          <div>
                            {data?.resumemodel?.awardAndCertificate.map(
                              (el, index) => {
                                return (
                                  <div key={index}>
                                    <p>Description: {el.acDescription}</p>
                                    <p>
                                      Award Or Certificate Name:{" "}
                                      {el.awardAndCertificateName}
                                    </p>
                                    <p>Given By: {el.givenBy}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <button
                    className="btn btn-primary mb-2"
                    onClick={() => navigate("/resume-builder")}
                  >
                    Please complete your Resume
                  </button>
                )}
              </div>

              <div className="row row-deck row-cards">
                <div className="col-12 mt-3">
                  <div className="card">
                    <div className="card-body">
                      <h2>Crack Your Next Job Interview with Confidence!</h2>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="col">
                    <h2 className="status status-green border rounded-0 m-0">
                      <span className="status-dot"></span>
                      Personalized Practice
                    </h2>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <img
                        className="border rounded-2"
                        src="https://img.freepik.com/free-vector/man-robot-with-computers-sitting-together-workplace-artificial-intelligence-workforce-future-flat-illustration_74855-20635.jpg?t=st=1722598684~exp=1722602284~hmac=28d661ecaa0bca18cc8f8b3e4b1c8162f8a4f70a4173a0cdd0099a70b00976cd&w=1800"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="card">
                    {" "}
                    <div className="card-body">
                      <div className="card-title">
                        Tailored Interview Practice
                      </div>
                      <p className="ps1 pe-1">
                        Our AI analyzes your profile and the job role to
                        generate relevant interview questions. Practice with
                        scenarios that mimic real-life interviews, ensuring you
                        are fully prepared Practice with scenarios that mimic
                        real-life interviews, ensuring you are fully prepared
                      </p>
                      <ul>
                        <li>
                          <b className="text-secondary">
                            Dynamic Question Generation
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">
                            Realistic Simulations
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">Continuous Learining</b>
                        </li>

                        <li>
                          <b className="text-secondary">Skill Assessment</b>
                        </li>
                        <li>
                          <b className="text-secondary">Time Management</b>
                        </li>
                      </ul>
                      <button className="btn btn-primary mt-4">Signup</button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="col">
                    <h2 className="status status-green border rounded-0 m-0">
                      <span className="status-dot"></span>
                      Instant Feedback
                    </h2>
                  </div>
                </div>

                <div className="col-lg-7 col-md-12">
                  <div className="card">
                    {" "}
                    <div className="card-body">
                      <div className="card-title">Real-Time Feedback</div>
                      <p className="ps1 pe-1">
                        Receive immediate, constructive feedback on your
                        answers. Our AI highlights areas of improvement and
                        provides actionable tips to enhance your performance.
                        ully prepared Practice with scenarios that mimic
                        real-life interviews, ensuring you are fully prepared
                      </p>
                      <ul>
                        <li>
                          <b className="text-secondary">
                            Dynamic Question Generation
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">
                            Realistic Simulations
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">Continuous Learining</b>
                        </li>

                        <li>
                          <b className="text-secondary">Skill Assessment</b>
                        </li>
                        <li>
                          <b className="text-secondary">Time Management</b>
                        </li>
                      </ul>
                      <button className="btn btn-primary mt-4">Signup</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <img
                        className="border rounded-2"
                        src="https://img.freepik.com/free-vector/organic-flat-feedback-concept_52683-62653.jpg?ga=GA1.1.1586883513.1722598535&semt=sph"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="col">
                    <h2 className="status status-green border rounded-0 m-0">
                      <span className="status-dot"></span>
                      Comprehensive Analytics
                    </h2>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <img
                        className="border rounded-2"
                        src="https://img.freepik.com/free-vector/man-robot-with-computers-sitting-together-workplace-artificial-intelligence-workforce-future-flat-illustration_74855-20635.jpg?t=st=1722598684~exp=1722602284~hmac=28d661ecaa0bca18cc8f8b3e4b1c8162f8a4f70a4173a0cdd0099a70b00976cd&w=1800"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title">Track Your Progress</div>
                      <p className="ps1 pe-1">
                        Our AI analyzes your profile and the job role to
                        generate relevant interview questions. Practice with
                        scenarios that mimic real-life interviews, ensuring you
                        are fully prepared Practice with scenarios that mimic
                        real-life interviews, ensuring you are fully prepared
                      </p>
                      <ul>
                        <li>
                          <b className="text-secondary">
                            Dynamic Question Generation
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">
                            Realistic Simulations
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">Continuous Learining</b>
                        </li>

                        <li>
                          <b className="text-secondary">Skill Assessment</b>
                        </li>
                        <li>
                          <b className="text-secondary">Time Management</b>
                        </li>
                      </ul>
                      <button className="btn btn-primary mt-4">Signup</button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="col">
                    <h2 className="status status-green border rounded-0 m-0">
                      <span className="status-dot"></span>
                      Expert Guidance
                    </h2>
                  </div>
                </div>

                <div className="col-lg-7 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title">Learn from the Best</div>
                      <p className="ps1 pe-1">
                        Access curated content from industry experts. Our
                        platform offers videos, articles, Access curated content
                        from industry experts. Our platform offers videos,
                        articles, and sample answers to help you craf
                      </p>
                      <ul>
                        <li>
                          <b className="text-secondary">
                            Dynamic Question Generation
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">
                            Realistic Simulations
                          </b>
                        </li>
                        <li>
                          <b className="text-secondary">Continuous Learining</b>
                        </li>

                        <li>
                          <b className="text-secondary">Skill Assessment</b>
                        </li>
                        <li>
                          <b className="text-secondary">Time Management</b>
                        </li>
                      </ul>
                      <button className="btn btn-primary mt-4">Signup</button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12 ">
                  <div className="card">
                    <div className="card-body">
                      <img
                        className="border rounded-2"
                        src="https://img.freepik.com/free-vector/man-robot-with-computers-sitting-together-workplace-artificial-intelligence-workforce-future-flat-illustration_74855-20635.jpg?t=st=1722598684~exp=1722602284~hmac=28d661ecaa0bca18cc8f8b3e4b1c8162f8a4f70a4173a0cdd0099a70b00976cd&w=1800"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <a href="/ai-interview" className="btn btn-primary">
                  Let's Start
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AiInterviewCracker;
