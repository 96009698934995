import React from "react";
import { useEffect, useState } from "react";
import performRequest from "../../../APIs/request";
function All_test_data() {
  const [data, setData] = new useState([]);

  useEffect(() => {
    performRequest("GET", "/test/alltests")
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <div className="col-lg-12 col-sm-12">
        <div className="card">
          <div className="table-responsive">
            <table className="table table-vcenter card-table">
              <thead>
                <tr>
                  <th>type</th>
                  <th>level</th>
                  <th>icon</th>
                  <th>name</th>
                  <th>passingScore</th>
                  <th>questionsAnsOpt</th>
                  <th>department</th>
                  <th>Button</th>
                  <th className="w-1"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((alldata, index) => (
                  <tr key={index}>
                    <td>{alldata.type}</td>
                    <td className="text-secondary">{alldata.level}</td>
                    <td className="text-secondary">
                      <a href="#" className="text-reset">
                        {alldata.icon}
                      </a>
                    </td>
                    <td className="text-secondary">{alldata.name}</td>
                    {/* <td className="text-secondary" >
                              {alldata.description}
                            </td> */}
                    <td className="text-secondary">{alldata.passingScore}</td>
                    <td className="text-secondary">Pending</td>
                    <td className="text-secondary">{alldata.department}</td>
                    <td>
                      <a href={`/edit-contest/${alldata.id}`}>Edit</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default All_test_data;
