import React from "react";
import Footer from "../../Components/Footer/footer";
import { useState } from "react";

const questionsArray = [
  {
    category: "Setup",
    question: "How many farm setups have you done till date?",
    answer:
      "We've completed over 50 farm setups to date here at Kryzen Biotech, and I'm proud to say that the majority of them are running smoothly, generating sustainable yields and profits for our valued clients.",
  },
  {
    category: "Training",
    question: "I am new to this technology, where should I start?",
    answer:
      "Starting in hydroponics can be exciting but daunting. At Kryzen Biotech, we offer comprehensive consultation services to guide you through the process, starting from site assessment to construction and ongoing support. Our expert team will ensure your journey into hydroponics is smooth and successful.",
  },
  {
    category: "All",
    question: "Differentiation from other competitors and Kryzen Biotech?",
    answer:
      "Since our establishment in 2019, we've pioneered the development of an ecosystem connecting farmers from seed to buyer, along with providing comprehensive support ranging from agronomy to farm construction. As an EPC (Engineering, Procurement, and Construction) company, we ensure seamless project execution and customer satisfaction.",
  },
  {
    category: "Setup",
    question:
      "Do I need to study about hydroponics before starting the project?",
    answer:
      "No prior knowledge of hydroponics is required. Our Hydroponic Masterclass online course covers all aspects, ensuring you're equipped with the necessary skills and knowledge for success.",
  },
  {
    category: "Setup",
    question: "What support or AMC do you provide after construction?",
    answer:
      "We offer ongoing support post-construction, including maintenance services and continuous consultation to address any concerns and ensure optimal performance of your hydroponic setup.",
  },
  {
    category: "Setup",
    question:
      "We want to talk to your clients. Can we get their contact details?",
    answer:
      "To respect the privacy of our clients, we do not disclose their contact information without their consent. However, we can provide testimonials and case studies to showcase our successful projects.",
  },
  {
    category: "Training",
    question: "What is the crop cycle or produce time?",
    answer:
      "It highly depends on the crop. But to simplify, leafy greens have a shorter cycle, typically 30 to 45 days, while vine crops have a longer cycle, around 3 to 4 months.",
  },
  {
    category: "Setup",
    question: "What technology do you provide?",
    answer:
      "We provide state-of-the-art hydroponic systems, including naturally ventilated polyhouses, fan pad polyhouses, NFT systems, grow bags, and Dutch bucket systems, tailored to your specific requirements.",
  },
  {
    category: "Setup",
    question: "Can we do a farm visit in my city?",
    answer:
      "To respect the privacy of our clients, we do not share client information with anyone. But we can plan a completely guided tour for you. You can plan our farm visit, which is located at Satara. We get almost 50 visitors a week from all over India. There you will get complete information about hydroponics and all different types of hydroponic systems.",
  },
  {
    category: "Setup",
    question: "Are projects delivered by you profitable?",
    answer:
      "Certainly! We have successfully completed numerous commercial hydroponic projects across various crops, delivering high yields and profitability for our clients.",
  },
  {
    category: "Training",
    question: "What types of crops are suitable for a commercial project?",
    answer:
      "A wide range of crops including leafy greens, herbs, tomatoes, cucumbers, and strawberries thrive in hydroponic systems, offering excellent commercial potential.",
  },
  {
    category: "Setup",
    question: "Does hydroponic yield more than traditional farming?",
    answer:
      "Hydroponically grown crops typically yield higher and faster compared to traditional methods, thanks to optimized growing conditions and resource efficiency.",
  },
  {
    category: "Setup",
    question:
      "What is the expected return on investment (ROI) for a commercial setup?",
    answer:
      "Hydroponics generally gives returns on investment in 2 to 2 and a half years. But this depends on location and also on the technology we use. Once we do a site inspection, we share a detailed project report with you. That gives you complete detailed information about return on investment, also about breakeven and all other financial details.",
  },
  {
    category: "Training",
    question: "How to manage pest and disease control in commercial farming?",
    answer:
      "We cover this topic intensively in the hydroponic masterclass. Also, we have free handholding with our agronomist, they keep on handling these issues along with you until you get a complete understanding of this topic.",
  },
  {
    category: "Setup",
    question: "What kind of training or education do you provide for staff?",
    answer:
      "We provide 10 days free hydroponic training at our farm location. We teach them from seedling preparation to harvest and packaging of produce. Once any staff completes training with us, the staff is capable of managing any hydroponic farm on his own.",
  },
  {
    category: "Setup",
    question: "Can you provide guidance on crop selection?",
    answer:
      "In the final stage of project construction, we have an onboarding call with an agronomist. The Agronomist makes sure that you have complete information about crop selection to seedling, harvest and all other things. This call is also a foundation call to establish communication between ground staff and support staff.",
  },
  {
    category: "Training",
    question: "What is your farm size?",
    answer:
      "We have multiple types of hydroponic farms and systems located in an area of 1 Acre.",
  },
  {
    category: "Training",
    question: "Where do you sell your own produce?",
    answer:
      "We use the same mechanism which we have extended to our clients. We use buyers connect to identify buyers, demand generation and product selling.",
  },
  {
    category: "Training",
    question: "What do you produce in your farm?",
    answer:
      "We produce various varieties of food. From leafy greens to cherry tomatoes, we grow red and yellow bell peppers, jalapenos, Indian vegetables like spinach. We also grow various varieties of salads.",
  },
  {
    category: "Training",
    question: "Can I have practical training?",
    answer:
      "Yes, from time to time we run practical hydroponic trainings. You can avail that. Generally, we suggest visiting your hydroponic farm along with the training.",
  },
  {
    category: "Training",
    question: "Can I have an internship at your farm?",
    answer:
      "We get many inquiries for internship. Send your resume to us at contact@kryzen.com.",
  },
  {
    category: "Setup",
    question: "Do you help in getting a subsidy?",
    answer:
      "We provide you a detailed project report. In subsidy term this is called DPR. This DPR helps you apply for subsidy. It covers all basic to advanced information which is required as project planning.",
  },
  {
    category: "Setup",
    question: "Can I get a project report?",
    answer:
      "Yes, on a successful site inspection, we provide you a detailed 30-page project report. This project report covers all aspects of profitable hydroponic construction.",
  },
  {
    category: "Setup",
    question: "Can we sell our entire farm setup to other people?",
    answer:
      "Yes, the entire structure is a nut and bolt structure which can be disassembled and assembled at any location. We also provide service for relocation of the entire farm all over India.",
  },
  {
    category: "Training",
    question: "What temperature is ideal for farming?",
    answer:
      "The ideal temperature varies depending on the crops being cultivated. However, for most hydroponic crops, a temperature range of 18-35°C is considered optimal. We use different mechanisms such as foggers, exhaust fans, and fan pad automation to control this temperature.",
  },
  {
    category: "Training",
    question: "Can we grow fruits and flowers?",
    answer:
      "Yes, hydroponic systems are versatile and suitable for growing a wide range of fruits and flowers, including strawberries, tomatoes, peppers, and orchids. We mainly use Growbags for it.",
  },
  {
    category: "Training",
    question: "How many hours of sunlight are needed for plants?",
    answer:
      "Hydroponic systems provide controlled environments, reducing reliance on sunlight. However, most crops require around 8-12 hours of light per day for optimal growth.",
  },
  {
    category: "Setup",
    question: "What Guarantee or warranty do you offer on projects?",
    answer:
      "We offer a warranty on our polyhouses and hydroponic setups to ensure quality and performance. Specific warranty terms are provided upon project completion. You will get to know about our warranty duration and conditions in our quotation as well.",
  },
  {
    category: "Setup",
    question: "How much area is needed for automation?",
    answer:
      "The area required for automation depends on the scale and complexity of the system. Our experts will assess your needs during the consultation phase and provide tailored recommendations during site inspection. From our past experiences once your setup is beyond 20,000 sqft then you need automation system to manage your farm. Below this area, the farm can be easily managed with labor.",
  },
  {
    category: "Training",
    question:
      "If temperatures in summers and winters are extreme what should be done in that condition?",
    answer:
      "Our hydroponic systems are designed to withstand varying climatic conditions. Additional climate control measures such as heating or cooling systems may be recommended for extreme temperatures.",
  },
  {
    category: "Setup",
    question: "My farm is 5000 sqft Can we do automation?",
    answer:
      "Yes, automation can be implemented on farms of varying sizes. Our team will assess your requirements and recommend suitable automation solutions for your 5000 sq. ft. farm.",
  },
  {
    category: "Setup",
    question: "What is the yield of the first batch?",
    answer:
      "Yield and harvest times vary depending on the crop cycle and system used. Typically, leafy greens can be harvested within 30-45 days, while vine crops may take 3-4 months for full maturity.",
  },
  {
    category: "Setup",
    question: "Can you do contract farming or JV?",
    answer:
      "Currently, we are not offering any JV opportunity. But if you have something in mind, you can send details about it to contact@kryzen.com. It will be redirected to the respected person in the company.",
  },
  {
    category: "Setup",
    question: "Can we use multiple home kits for commercial purposes?",
    answer:
      "While our home kits are primarily designed for personal use, larger-scale commercial projects may require customized solutions. We only suggest going with a home kit if your area is less than 1000 sqft. Above 1000 sqft, we suggest going for a customized design as it will be cheaper and more functional.",
  },
  {
    category: "Setup",
    question: "For how much time do we get agronomy support from Kryzen?",
    answer:
      "We provide continuous support to our clients, ensuring their hydroponic ventures are successful. We have lifetime access to hydroponic masterclass and also lifetime free access to our forums. In-person support is given free of cost for the first 6 months which can be renewed yearly.",
  },
  {
    category: "Setup",
    question:
      "What if our city or location does not have buyers on your platform?",
    answer:
      "Our extensive network and buyer connect services enable us to reach buyers nationwide. In cases where local buyers are limited, we explore alternative marketing and distribution channels to maximize sales opportunities. Also, we are onboarding new buyers every day. Today if you have limited access, in no time you will have access to a large pool of buyers.",
  },
  {
    category: "Setup",
    question: "How many laborers are required for a 5000 sqft farm?",
    answer:
      "From our past experience and farm operations, we see 1 laborer is good enough to manage a farm of 5000 sq ft. Even up to 10,000 sq ft, one laborer can easily manage the hydroponic system.",
  },
  {
    category: "Setup",
    question: "Do you provide seeds or fertilizers?",
    answer:
      "To sell seeds and fertilizers we need additional licenses and documentation process. We have identified a list of seeds and fertilizers which can be procured locally or online. In your software portal, you will have access to this list as well.",
  },
  {
    category: "Setup",
    question:
      "Who will bear the transportation charges of produced vegetables?",
    answer:
      "It highly depends on the quantity of production. If the quantity is less you will have to deliver it to the buyer's collection center. If the quantity is more, then buyers will offer to pick vegetables from your location. It also depends on the negotiation you have with the buyer.",
  },
  {
    category: "Setup",
    question: "What is the operational cost?",
    answer:
      "Operational costs vary depending on factors such as energy consumption, labor, and maintenance. As a rule of thumb, we have kept our hydroponic farm operation cost less than 7% of Recurring Revenue. After a site inspection DPR, you will get details about all the operation costs involved along with working capital.",
  },
  {
    category: "Setup",
    question: "Why is your setup cost high?",
    answer:
      "We know construction projects can be done at any cost. But there are some benchmarks which we have designed and strictly adhered to in order to build sustainable breakeven. Our setup costs reflect the quality, durability, and performance of our hydroponic systems. We prioritize using premium materials and technology to ensure long-term reliability and productivity.",
  },
  {
    category: "Setup",
    question: "What is the production per plant?",
    answer:
      "Production per plant varies depending on the crop type, growing conditions, and system used. This is a key calculator we use in our Break-even analysis. Generally, to understand in layman's terms, this is somewhere Rs.1.5 to Rs. 4 for leafy green and Rs. 6 to Rs. 9 for vine crops.",
  },
  {
    category: "Setup",
    question: "How to provide nutrients to the plant or farm?",
    answer:
      "Nutrient delivery in hydroponic systems is optimized through nutrient solutions, which are carefully formulated to provide essential elements for plant growth. Our team will guide you on nutrient management practices for optimal results.",
  },
  {
    category: "Setup",
    question: "Who will manage the farm?",
    answer:
      "Farm management can be handled by your trained personnel. We provide comprehensive training and support to ensure effective farm management. You can send your person for 10 days training at our farm location. We also have a tie-up with a third party man-powering agency, which will provide laborers to you.",
  },

  {
    category: "Training",
    question: "On which platform can we buy seeds and fertilizers?",
    answer:
      "We recommend reputable suppliers for seeds and fertilizers from Amazon and Flipkart, or you can purchase them through authorized channels based on your preferences and requirements.",
  },
  {
    category: "Training",
    question: "Can we grow fruity vegetables in NFT?",
    answer:
      "Every hydroponic system is different and designed for a different use case. NFT is widely used for leafy green production. Growing fruity crops into it might be tricky and may not be financially viable. In our hydroponic masterclass, we have covered a detailed topic on growing of plants into various hydroponic systems.",
  },
  {
    category: "Training",
    question: "What things can be controlled in automation?",
    answer:
      "Automation systems can control various parameters such as nutrient delivery, pH levels, temperature, humidity, and lighting schedules, ensuring precise management of growing conditions. It is a cloud-based two-way communication system. That means, it helps us telling what is happening at the farm and also it helps us giving instructions to controllers.",
  },
  {
    category: "Setup",
    question:
      "I stay far from the site or out of India how should we manage the farm?",
    answer:
      "Remote farm management is possible through our advanced automation systems and remote monitoring capabilities. You can monitor and control farm operations from anywhere with an internet connection.",
  },
  {
    category: "Setup",
    question: "Which things will you provide in the setup?",
    answer:
      "Our setup includes all necessary components such as hydroponic systems, infrastructure, irrigation systems, and automation units. You can refer to our detailed quotation, which will help you understand in more detailed manner about what are the things and standards we provide.",
  },
  {
    category: "Training",
    question: "Can we do more than 1 type of hydroponic setup in 1 polyhouse?",
    answer:
      "Yes, multiple hydroponic setups can be integrated into a single polyhouse, allowing for diverse crop cultivation and increased productivity. Hydroponic technology can be combined in any ratio or by any type.",
  },
  {
    category: "Training",
    question: "Can we grow different types of vegetables in 1 setup?",
    answer:
      "Yes, our hydroponic systems are versatile and allow for mixed cropping, enabling you to grow different types of vegetables in the same setup, maximizing space and productivity.",
  },

  {
    category: "Setup",
    question: "Do you have a middleman to connect between buyers?",
    answer:
      "No, we do not operate as middlemen between buyers. However, our buyer connect services facilitate direct connections between producers and potential buyers, ensuring transparency and fair trade practices.",
  },
  {
    category: "Setup",
    question:
      "Where is your office located? How many offices do you have Pan India?",
    answer:
      "Our main office is located at Wakad Pune. We have multiple offices coming up in Bangalore and Delhi in the next few months.",
  },
  {
    category: "Setup",
    question: "How many days does it take to complete the setup?",
    answer:
      "The duration of setup depends on various factors such as project size, complexity, and location. Our team will provide a timeline estimate during the consultation phase based on your specific requirements. Generally, to give you an idea, we complete the entire project from start to finish in 45 days, including rigorous testing and Quality analysis.",
  },
  {
    category: "Setup",
    question: "Is this the final amount of quotation?",
    answer:
      "We get inquiries from all over India, to cater to everyone we have created a generic quotation. We have given you the same generic quotation which is generic in nature. The final amount in the quotation will be worked on-site inspection. There is always scope for negotiation after we get an understanding of the location where you are finalizing.",
  },
  {
    category: "Training",
    question: "Do we require pesticides in this?",
    answer:
      "Hydroponic systems generally require fewer pesticides compared to traditional soil-based farming due to controlled growing conditions. However, integrated pest management practices may still be necessary to address pest issues effectively. Our team of agronomists works with organic pesticides and ensures that your team follows ethical and sustainable agriculture practices.",
  },
  {
    category: "Setup",
    question: "Why are vegetables in Hydroponics expensive?",
    answer:
      "The initial investment costs associated with hydroponic systems may contribute to higher prices for hydroponically grown vegetables. Also, there is always surplus demand for this produce which results in higher selling costs. It's simple demand for hydroponic produce is more than the supply of produce.",
  },
  {
    category: "Training",
    question: "Is hydroponic product organic?",
    answer:
      "Hydroponic farming can be organic if organic-certified inputs and practices are used. Many hydroponic growers adhere to organic standards to produce high-quality, environmentally friendly crops. Our agronomist guides your team into growing organic hydroponic produce.",
  },
  {
    category: "Training",
    question: "Is nutritional value high in hydroponics?",
    answer:
      "Yes, hydroponically grown crops can have high nutritional value as they receive optimal levels of nutrients and water, resulting in healthy and nutrient-rich produce.",
  },
  {
    category: "Setup",
    question: "What are the details provided in the project report?",
    answer:
      "The Detailed Project Report (DPR) includes comprehensive information such as project overview, technical specifications, financial projections, ROI analysis, and implementation timelines, providing a roadmap for project planning and execution.",
  },
  {
    category: "Setup",
    question: "What is price watcher?",
    answer:
      "Price watcher is an automated feature in our systems that monitors market prices for crops, helping farmers make informed decisions on timing and pricing for product sales.",
  },
  {
    category: "Setup",
    question: "What is buyers connect?",
    answer:
      "Buyers connect is a service we offer to connect producers with potential buyers, facilitating direct sales and market access for farm produce.",
  },
  {
    category: "Setup",
    question: "Do you provide agronomy support?",
    answer:
      "Yes, we provide agronomy support to assist farmers with crop management, pest control, nutrient management, and other agronomic aspects to optimize crop yields and quality.",
  },
  {
    category: "All",
    question: "Will you help us sell our product?",
    answer:
      "Yes, we offer buyer connect services to help farmers connect with potential buyers and market their produce effectively, maximizing sales opportunities.",
  },
  {
    category: "All",
    question: "What is your team size?",
    answer:
      "Our team consists of experienced professionals in various fields including agronomy, engineering, project management, and customer support, ensuring comprehensive support and expertise for our clients. Currently, we are a team of 35 people, with an additional 20 contractual workers and consultants.",
  },
  {
    category: "All",
    question: "How old is your company?",
    answer:
      "We started in 2019. Initial two years were spent in rigorous research and we started operations from 2021.",
  },
  {
    category: "All",
    question: "Can you connect me to your owner?",
    answer:
      "Our founders, Sudhir Devkar and Priya Devkar, are deeply involved in the company's operations and vision. While direct contact with the owners may not always be feasible, once your project is in the final stage of negotiation, I can connect you with them.",
  },
  {
    category: "Setup",
    question: "I already have a hydroponic system, can you help me run it?",
    answer:
      "Currently, we are occupied with our own farm projects and construction. Currently, we won't be able to support you. But in the future, we are launching a dedicated stream to help people manage their hydroponic farm.",
  },
  {
    category: "Training",
    question: "I need a refund of my hydroponic masterclass, What should I do?",
    answer:
      "We provide 7 days refund. Please reach out to our customer support team at contact@kryzen.com with your request for a refund for the Hydroponic Masterclass. Our team will assist you further with the process. Please send an email via the same email id which was used for setting up the account. It helps us identify if the same user has requested a refund.",
  },
  {
    category: "Setup",
    question: "Tell me details about site inspection?",
    answer:
      "Site inspection involves a comprehensive assessment of your location to evaluate factors such as climate, soil conditions, available resources, and infrastructure. This information helps us design a customized hydroponic solution tailored to your needs.",
  },
  {
    category: "Setup",
    question: "Can I decide not to set up the project after site inspection?",
    answer:
      "Yes, you have the flexibility to decide whether to proceed with the project after the site inspection. Our team will provide you with detailed information and recommendations to help you make an informed decision.",
  },
  {
    category: "Training",
    question: "What is the difference between NVPH and fanpad?",
    answer:
      "NVPH (Naturally Ventilated Polyhouse) relies on natural airflow for ventilation, while fan pad polyhouses use fans and evaporative cooling pads to regulate temperature and humidity. The choice depends on specific climatic conditions and project requirements.",
  },
  {
    category: "Training",
    question:
      "I have a temperature of 40 degrees at my location, which system I need to choose?",
    answer:
      "In hot climates, a fan pad polyhouse with evaporative cooling pads may be more suitable for maintaining optimal growing conditions. Our experts will recommend the best system based on your location and requirements.",
  },
  {
    category: "Training",
    question:
      "I have very frequent electricity outages, do I need an extra generator?",
    answer:
      "In areas with frequent electricity outages, having a backup generator can ensure uninterrupted operation of essential equipment such as pumps and ventilation systems, providing stability and reliability to your hydroponic setup. If electricity outages are for more than 3 hours and it is very regular, then we suggest you go for a dedicated generator. Below this period, our system can manage electricity failure stress effectively.",
  },
  {
    category: "Training",
    question:
      "My farm is located in a remote area, can we still do the project setup?",
    answer:
      "Yes, we have experience working in remote locations and can adapt our solutions to suit your specific circumstances. Our team will work closely with you to overcome logistical challenges and ensure successful project implementation.",
  },
  {
    category: "Setup",
    question:
      "I am doing this setup for my parents? Will they be able to manage it?",
    answer:
      "Absolutely! Our hydroponic systems are designed for ease of use and require minimal maintenance. With our training and ongoing support, your parents can successfully manage the hydroponic setup and enjoy the benefits of fresh, homegrown produce.",
  },
  {
    category: "Training",
    question: "Can you offer me hydroponic masterclass discount?",
    answer:
      "Discounts for the Hydroponic Masterclass may be available periodically. Are you planning to purchase it immediately? I can provide you with a custom coupon code from my end. But it will be available for a limited time only.",
  },
  {
    category: "Training",
    question: "Can you send me a refund of Hydroponic Masterclass on my UPI?",
    answer:
      "Refunds for the Hydroponic Masterclass are processed according to our refund policy. We refund the money via a third-party payment gateway and the amount is refunded to the same source from where the money was credited. If you have done the transaction via UPI, then the money will be refunded to UPI.",
  },
  {
    category: "Setup",
    question: "Is there will be a regular subscription for the automation app?",
    answer:
      "Subscription details vary depending on the system chosen. Currently, all our automation system subscriptions are free for the next 3 years.",
  },
  {
    category: "Setup",
    question: "Will I get CAD design of the setup?",
    answer:
      "Unfortunately, we do not work with CAD software. We have an internal different mechanism for material planning. The entire system is very much connected with different steps, so it is not possible from our end to provide CAD design at this stage.",
  },
  {
    category: "Setup",
    question: "Why don't you buy back my product?",
    answer:
      "We do not offer a buyback policy for hydroponic setups due to logistical and operational considerations. However, we can provide guidance on selling produce via Buyers Connect.",
  },
  {
    category: "Training",
    question: "Why only butterfly design is used?",
    answer:
      "The butterfly design is chosen for its structural integrity, efficient use of space, and optimal ventilation. It offers a balanced combination of functionality and aesthetics, ensuring optimal performance for hydroponic setups.",
  },
  {
    category: "Setup",
    question: "If the labor leaves will you give training to other labor?",
    answer:
      "Yes, we offer training programs for farm personnel to ensure smooth operation and maintenance of hydroponic setups. If labor turnover occurs, we can provide training to new personnel to maintain continuity.",
  },
  {
    category: "Training",
    question: "How many liters of water are needed every day for a home unit?",
    answer:
      "Water consumption varies depending on factors such as crop type, system size, and environmental conditions. On average, a home hydroponic unit may require around 20 liters of water per day.",
  },
  {
    category: "Setup",
    question: "Is electricity needed 24x7 for a commercial setup?",
    answer:
      "We don't need continuous electricity. But what we need is power outages should not be for more than 3 hours. If you are at a location where power outages are very frequent and it is for more than 3 hours regularly, then we might need to go for a genset.",
  },
  {
    category: "Setup",
    question: "What if the rat or other rodents enter inside the polyhouse?",
    answer:
      "The polyhouse is covered with insect and rodent net from all sides. This net comes from 2ft underground, preventing all rodents from entering inside. Even if we find there is any rat inside, we can make use of a trapper or blocker to catch the rat and remove it from the system.",
  },
  {
    category: "Training",
    question: "I want to learn about hydroponics, what to do?",
    answer:
      "You can enroll in our hydroponic masterclass. We teach you everything about hydroponics in detail. The course is available in Hindi and English. You get lifetime access to the course and can connect with our team of professionals to get your questions resolved.",
  },
  {
    category: "Training",
    question: "Can I learn about hydroponics practically?",
    answer:
      "Yes, we run a two-day detailed workshop to teach you about hydroponics. We conduct this workshop on our farm where everything is practically taught to you. You can visit kryzen.com and enroll for hydroponic training.",
  },
  {
    category: "Training",
    question: "How many people are allowed for a free farm visit?",
    answer:
      "We allow one free farm visit per person who has done the hydroponic masterclass. For every extra person, there are charges for the visit.",
  },
  {
    category: "Training",
    question:
      "I haven't done the hydroponic masterclass, Can I still visit your farm?",
    answer:
      "Yes, you can visit our farm without doing the hydroponic masterclass. It will give you a good idea about hydroponic technology. But with the hydroponic masterclass, you will learn all theoretical insights about hydroponic farm construction and management.",
  },
  {
    category: "Training",
    question: "Can I use borewell water for my hydroponic project?",
    answer:
      "Any water is good water for a hydroponic project, but we might need some filtration. I would still suggest you take a water report and share it with us. Our team of experts can help you with more details about water quality.",
  },
];

const questionsArray1 = [
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "How many farm setups have you done till date?",
    questionHindi: "आपने अब तक कितने फार्म सेटअप किए हैं?",
    answer:
      "We've completed over 50 farm setups to date here at Kryzen Biotech, and I'm proud to say that the majority of them are running smoothly, generating sustainable yields and profits for our valued clients.",
    answerHindi:
      "यहां Kryzen Biotech में हमने अब तक 50 से अधिक फार्म सेटअप पूरे किए हैं, और मुझे गर्व है कि उनमें से अधिकांश सुचारू रूप से चल रहे हैं, हमारे मूल्यवान ग्राहकों के लिए स्थायी उपज और लाभ उत्पन्न कर रहे हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "I am new to this technology, where should I start?",
    questionHindi: "मैं इस तकनीक में नया हूँ, मुझे कहाँ से शुरू करना चाहिए?",
    answer:
      "Starting in hydroponics can be exciting but daunting. At Kryzen Biotech, we offer comprehensive consultation services to guide you through the process, starting from site assessment to construction and ongoing support. Our expert team will ensure your journey into hydroponics is smooth and successful.",
    answerHindi:
      "हाइड्रोपोनिक्स में शुरुआत करना रोमांचक हो सकता है लेकिन डरावना भी हो सकता है। Kryzen Biotech में, हम आपको प्रक्रिया के माध्यम से मार्गदर्शन करने के लिए व्यापक परामर्श सेवाएं प्रदान करते हैं, साइट मूल्यांकन से लेकर निर्माण और चल रहे समर्थन तक। हमारी विशेषज्ञ टीम यह सुनिश्चित करेगी कि हाइड्रोपोनिक्स में आपका सफर सुगम और सफल हो।",
  },
  {
    category: "All",
    categoryHindi: "सभी",
    question: "Differentiation from other competitors and Kryzen Biotech?",
    questionHindi: "अन्य प्रतिस्पर्धियों और Kryzen Biotech से विभेदन?",
    answer:
      "Since our establishment in 2019, we've pioneered the development of an ecosystem connecting farmers from seed to buyer, along with providing comprehensive support ranging from agronomy to farm construction. As an EPC (Engineering, Procurement, and Construction) company, we ensure seamless project execution and customer satisfaction.",
    answerHindi:
      "2019 में हमारी स्थापना के बाद से, हमने बीज से खरीदार तक किसानों को जोड़ने वाले पारिस्थितिकी तंत्र के विकास का मार्ग प्रशस्त किया है, साथ ही कृषि विज्ञान से लेकर फार्म निर्माण तक व्यापक समर्थन प्रदान किया है। एक ईपीसी (इंजीनियरिंग, प्रोक्योरमेंट और कंस्ट्रक्शन) कंपनी के रूप में, हम निर्बाध परियोजना निष्पादन और ग्राहक संतुष्टि सुनिश्चित करते हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question:
      "Do I need to study about hydroponics before starting the project?",
    questionHindi:
      "क्या मुझे परियोजना शुरू करने से पहले हाइड्रोपोनिक्स के बारे में अध्ययन करने की आवश्यकता है?",
    answer:
      "No prior knowledge of hydroponics is required. Our Hydroponic Masterclass online course covers all aspects, ensuring you're equipped with the necessary skills and knowledge for success.",
    answerHindi:
      "हाइड्रोपोनिक्स का पूर्व ज्ञान आवश्यक नहीं है। हमारा हाइड्रोपोनिक मास्टरक्लास ऑनलाइन कोर्स सभी पहलुओं को कवर करता है, यह सुनिश्चित करता है कि आप सफलता के लिए आवश्यक कौशल और ज्ञान से लैस हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "What support or AMC do you provide after construction?",
    questionHindi:
      "निर्माण के बाद आप किस प्रकार का समर्थन या एएमसी प्रदान करते हैं?",
    answer:
      "We offer ongoing support post-construction, including maintenance services and continuous consultation to address any concerns and ensure optimal performance of your hydroponic setup.",
    answerHindi:
      "हम निर्माण के बाद चल रहे समर्थन की पेशकश करते हैं, जिसमें रखरखाव सेवाएं और किसी भी चिंता को दूर करने और आपके हाइड्रोपोनिक सेटअप के इष्टतम प्रदर्शन को सुनिश्चित करने के लिए निरंतर परामर्श शामिल हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question:
      "We want to talk to your clients. Can we get their contact details?",
    questionHindi:
      "हम आपके ग्राहकों से बात करना चाहते हैं। क्या हम उनके संपर्क विवरण प्राप्त कर सकते हैं?",
    answer:
      "To respect the privacy of our clients, we do not disclose their contact information without their consent. However, we can provide testimonials and case studies to showcase our successful projects.",
    answerHindi:
      "हम अपने ग्राहकों की गोपनीयता का सम्मान करने के लिए उनकी सहमति के बिना उनके संपर्क विवरण का खुलासा नहीं करते हैं। हालांकि, हम अपनी सफल परियोजनाओं को प्रदर्शित करने के लिए प्रशंसापत्र और केस स्टडी प्रदान कर सकते हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "What is the crop cycle or produce time?",
    questionHindi: "फसल चक्र या उत्पादन समय क्या है?",
    answer:
      "It highly depends on the crop. But to simplify, leafy greens have a shorter cycle, typically 30 to 45 days, while vine crops have a longer cycle, around 3 to 4 months.",
    answerHindi:
      "यह काफी हद तक फसल पर निर्भर करता है। लेकिन इसे सरल बनाने के लिए, पत्तेदार साग का चक्र छोटा होता है, आमतौर पर 30 से 45 दिनों का, जबकि बेल वाली फसलों का चक्र लंबा होता है, लगभग 3 से 4 महीने।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "What technology do you provide?",
    questionHindi: "आप कौन सी तकनीक प्रदान करते हैं?",
    answer:
      "We provide state-of-the-art hydroponic systems, including naturally ventilated polyhouses, fan pad polyhouses, NFT systems, grow bags, and Dutch bucket systems, tailored to your specific requirements.",
    answerHindi:
      "हम अत्याधुनिक हाइड्रोपोनिक सिस्टम प्रदान करते हैं, जिनमें स्वाभाविक रूप से हवादार पॉलीहाउस, फैन पैड पॉलीहाउस, एनएफटी सिस्टम, ग्रो बैग और डच बकेट सिस्टम शामिल हैं, जो आपकी विशिष्ट आवश्यकताओं के अनुरूप हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Can we do a farm visit in my city?",
    questionHindi: "क्या हम अपने शहर में फार्म का दौरा कर सकते हैं?",
    answer:
      "To respect the privacy of our clients, we do not share client information with anyone. But we can plan a completely guided tour for you. You can plan our farm visit, which is located at Satara. We get almost 50 visitors a week from all over India. There you will get complete information about hydroponics and all different types of hydroponic systems.",
    answerHindi:
      "हम अपने ग्राहकों की गोपनीयता का सम्मान करने के लिए किसी के साथ ग्राहक जानकारी साझा नहीं करते हैं। लेकिन हम आपके लिए पूरी तरह से मार्गदर्शित यात्रा की योजना बना सकते हैं। आप हमारे फार्म का दौरा कर सकते हैं, जो सतारा में स्थित है। हमें पूरे भारत से लगभग 50 आगंतुक प्रति सप्ताह मिलते हैं। वहां आपको हाइड्रोपोनिक्स और सभी अलग-अलग प्रकार की हाइड्रोपोनिक प्रणालियों के बारे में पूरी जानकारी मिलेगी।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Are projects delivered by you profitable?",
    questionHindi: "क्या आपके द्वारा वितरित परियोजनाएं लाभदायक हैं?",
    answer:
      "Certainly! We have successfully completed numerous commercial hydroponic projects across various crops, delivering high yields and profitability for our clients.",
    answerHindi:
      "निश्चित रूप से! हमने विभिन्न फसलों में कई व्यावसायिक हाइड्रोपोनिक परियोजनाओं को सफलतापूर्वक पूरा किया है, अपने ग्राहकों के लिए उच्च उपज और लाभप्रदता प्रदान की है।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "What types of crops are suitable for a commercial project?",
    questionHindi:
      "व्यावसायिक परियोजना के लिए कौन सी प्रकार की फसलें उपयुक्त हैं?",
    answer:
      "A wide range of crops including leafy greens, herbs, tomatoes, cucumbers, and strawberries thrive in hydroponic systems, offering excellent commercial potential.",
    answerHindi:
      "पत्तेदार साग, जड़ी-बूटियाँ, टमाटर, खीरे और स्ट्रॉबेरी सहित विभिन्न प्रकार की फसलें हाइड्रोपोनिक सिस्टम में पनपती हैं, जो उत्कृष्ट व्यावसायिक क्षमता प्रदान करती हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Does hydroponic yield more than traditional farming?",
    questionHindi:
      "क्या हाइड्रोपोनिक परंपरागत खेती की तुलना में अधिक उपज देता है?",
    answer:
      "Hydroponically grown crops typically yield higher and faster compared to traditional methods, thanks to optimized growing conditions and resource efficiency.",
    answerHindi:
      "अनुकूलित बढ़ती परिस्थितियों और संसाधन दक्षता के कारण हाइड्रोपोनिक रूप से उगाई गई फसलें पारंपरिक तरीकों की तुलना में आमतौर पर अधिक और तेजी से उपज देती हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question:
      "What is the expected return on investment (ROI) for a commercial setup?",
    questionHindi:
      "व्यावसायिक सेटअप के लिए अपेक्षित निवेश पर प्रतिफल (आरओआई) क्या है?",
    answer:
      "Hydroponics generally gives returns on investment in 2 to 2 and a half years. But this depends on location and also on the technology we use. Once we do a site inspection, we share a detailed project report with you. That gives you complete detailed information about return on investment, also about breakeven and all other financial details.",
    answerHindi:
      "हाइड्रोपोनिक्स आम तौर पर 2 से 2 और आधे वर्षों में निवेश पर प्रतिफल देता है। लेकिन यह स्थान और उपयोग की जाने वाली तकनीक पर निर्भर करता है। एक बार जब हम साइट निरीक्षण कर लेते हैं, तो हम आपके साथ एक विस्तृत परियोजना रिपोर्ट साझा करते हैं। जो आपको निवेश पर प्रतिफल के बारे में पूरी विस्तृत जानकारी देता है, साथ ही ब्रेकईवन और अन्य सभी वित्तीय विवरणों के बारे में भी जानकारी देता है।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "How to manage pest and disease control in commercial farming?",
    questionHindi:
      "व्यावसायिक खेती में कीट और रोग नियंत्रण का प्रबंधन कैसे करें?",
    answer:
      "We cover this topic intensively in the hydroponic masterclass. Also, we have free handholding with our agronomist, they keep on handling these issues along with you until you get a complete understanding of this topic.",
    answerHindi:
      "हम हाइड्रोपोनिक मास्टरक्लास में इस विषय को गहनता से कवर करते हैं। साथ ही, हमारे पास हमारे कृषि विज्ञानी के साथ निःशुल्क हैंडलिंग है, वे इस विषय की पूरी समझ प्राप्त करने तक आपके साथ इन मुद्दों को संभालते रहते हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "What kind of training or education do you provide for staff?",
    questionHindi:
      "कर्मचारियों के लिए आप किस प्रकार का प्रशिक्षण या शिक्षा प्रदान करते हैं?",
    answer:
      "We provide 10 days free hydroponic training at our farm location. We teach them from seedling preparation to harvest and packaging of produce. Once any staff completes training with us, the staff is capable of managing any hydroponic farm on his own.",
    answerHindi:
      "हम अपने फार्म स्थान पर 10 दिन का मुफ्त हाइड्रोपोनिक प्रशिक्षण प्रदान करते हैं। हम उन्हें बीज तैयार करने से लेकर उत्पादन की कटाई और पैकेजिंग तक सिखाते हैं। एक बार जब कोई कर्मचारी हमारे साथ प्रशिक्षण पूरा कर लेता है, तो वह कर्मचारी किसी भी हाइड्रोपोनिक फार्म को अपने दम पर प्रबंधित करने में सक्षम होता है।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Can you provide guidance on crop selection?",
    questionHindi: "क्या आप फसल चयन पर मार्गदर्शन प्रदान कर सकते हैं?",
    answer:
      "In the final stage of project construction, we have an onboarding call with an agronomist. The Agronomist makes sure that you have complete information about crop selection to seedling, harvest and all other things. This call is also a foundation call to establish communication between ground staff and support staff.",
    answerHindi:
      "परियोजना निर्माण के अंतिम चरण में, हमारा एक कृषि विज्ञानी के साथ एक ऑनबोर्डिंग कॉल होता है। कृषि विज्ञानी यह सुनिश्चित करता है कि आपके पास फसल चयन से लेकर बीज, कटाई और अन्य सभी चीजों के बारे में पूरी जानकारी हो। यह कॉल ग्राउंड स्टाफ और सपोर्ट स्टाफ के बीच संचार स्थापित करने के लिए एक फाउंडेशन कॉल भी है।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "What is your farm size?",
    questionHindi: "आपके फार्म का आकार क्या है?",
    answer:
      "We have multiple types of hydroponic farms and systems located in an area of 1 Acre.",
    answerHindi:
      "हमारे पास 1 एकड़ के क्षेत्र में स्थित कई प्रकार के हाइड्रोपोनिक फार्म और सिस्टम हैं।",
  },

  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "Where do you sell your own produce?",
    questionHindi: "आप अपनी उपज कहाँ बेचते हैं?",
    answer:
      "We use the same mechanism which we have extended to our clients. We use buyers connect to identify buyers, demand generation and product selling.",
    answerHindi:
      "हम वही तंत्र का उपयोग करते हैं जिसे हमने अपने ग्राहकों तक बढ़ाया है। हम खरीदारों को पहचानने, मांग उत्पन्न करने और उत्पाद बेचने के लिए बायर्स कनेक्ट का उपयोग करते हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "What do you produce in your farm?",
    questionHindi: "आप अपने फार्म में क्या उत्पादन करते हैं?",
    answer:
      "We produce various varieties of food. From leafy greens to cherry tomatoes, we grow red and yellow bell peppers, jalapenos, Indian vegetables like spinach. We also grow various varieties of salads.",
    answerHindi:
      "हम विभिन्न प्रकार के खाद्य पदार्थों का उत्पादन करते हैं। पत्तेदार साग से लेकर चेरी टमाटर तक, हम लाल और पीली शिमला मिर्च, जलेपेनो, पालक जैसी भारतीय सब्जियां उगाते हैं। हम विभिन्न प्रकार के सलाद भी उगाते हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "Can I have practical training?",
    questionHindi: "क्या मुझे व्यावहारिक प्रशिक्षण मिल सकता है?",
    answer:
      "Yes, from time to time we run practical hydroponic trainings. You can avail that. Generally, we suggest visiting your hydroponic farm along with the training.",
    answerHindi:
      "हां, समय-समय पर हम व्यावहारिक हाइड्रोपोनिक प्रशिक्षण चलाते हैं। आप इसका लाभ उठा सकते हैं। आम तौर पर, हम प्रशिक्षण के साथ आपके हाइड्रोपोनिक फार्म का दौरा करने का सुझाव देते हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "Can I have an internship at your farm?",
    questionHindi: "क्या मुझे आपके फार्म में इंटर्नशिप मिल सकती है?",
    answer:
      "We get many inquiries for internship. Send your resume to us at contact@kryzen.com.",
    answerHindi:
      "हमें इंटर्नशिप के लिए कई पूछताछ मिलती है। अपना बायोडाटा हमें contact@kryzen.com पर भेजें।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Do you help in getting a subsidy?",
    questionHindi: "क्या आप सब्सिडी प्राप्त करने में मदद करते हैं?",
    answer:
      "We provide you a detailed project report. In subsidy term this is called DPR. This DPR helps you apply for subsidy. It covers all basic to advanced information which is required as project planning.",
    answerHindi:
      "हम आपको एक विस्तृत परियोजना रिपोर्ट प्रदान करते हैं। सब्सिडी के संदर्भ में इसे डीपीआर कहा जाता है। यह डीपीआर आपको सब्सिडी के लिए आवेदन करने में मदद करता है। इसमें परियोजना योजना के रूप में आवश्यक सभी बुनियादी से उन्नत जानकारी शामिल है।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Can I get a project report?",
    questionHindi: "क्या मुझे परियोजना रिपोर्ट मिल सकती है?",
    answer:
      "Yes, on a successful site inspection, we provide you a detailed 30-page project report. This project report covers all aspects of profitable hydroponic construction.",
    answerHindi:
      "हां, सफल साइट निरीक्षण पर, हम आपको 30-पृष्ठों की विस्तृत परियोजना रिपोर्ट प्रदान करते हैं। यह परियोजना रिपोर्ट लाभदायक हाइड्रोपोनिक निर्माण के सभी पहलुओं को कवर करती है।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Can we sell our entire farm setup to other people?",
    questionHindi:
      "क्या हम अपने पूरे फार्म सेटअप को अन्य लोगों को बेच सकते हैं?",
    answer:
      "Yes, the entire structure is a nut and bolt structure which can be disassembled and assembled at any location. We also provide service for relocation of the entire farm all over India.",
    answerHindi:
      "हां, पूरी संरचना एक नट और बोल्ट संरचना है जिसे किसी भी स्थान पर अलग किया जा सकता है और इकट्ठा किया जा सकता है। हम पूरे फार्म को पूरे भारत में पुनर्स्थापित करने की सेवा भी प्रदान करते हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "What temperature is ideal for farming?",
    questionHindi: "खेती के लिए आदर्श तापमान क्या है?",
    answer:
      "The ideal temperature varies depending on the crops being cultivated. However, for most hydroponic crops, a temperature range of 18-35°C is considered optimal. We use different mechanisms such as foggers, exhaust fans, and fan pad automation to control this temperature.",
    answerHindi:
      "खेती की जाने वाली फसलों के आधार पर आदर्श तापमान भिन्न होता है। हालांकि, अधिकांश हाइड्रोपोनिक फसलों के लिए 18-35°C का तापमान सीमा इष्टतम मानी जाती है। हम इस तापमान को नियंत्रित करने के लिए विभिन्न तंत्रों का उपयोग करते हैं जैसे फॉगर्स, एग्जॉस्ट फैन और फैन पैड ऑटोमेशन।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "Can we grow fruits and flowers?",
    questionHindi: "क्या हम फल और फूल उगा सकते हैं?",
    answer:
      "Yes, hydroponic systems are versatile and suitable for growing a wide range of fruits and flowers, including strawberries, tomatoes, peppers, and orchids. We mainly use Growbags for it.",
    answerHindi:
      "हां, हाइड्रोपोनिक सिस्टम बहुमुखी हैं और स्ट्रॉबेरी, टमाटर, मिर्च और ऑर्किड सहित विभिन्न प्रकार के फल और फूल उगाने के लिए उपयुक्त हैं। हम मुख्य रूप से इसके लिए ग्रोबैग का उपयोग करते हैं।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question: "How many hours of sunlight are needed for plants?",
    questionHindi: "पौधों के लिए कितने घंटे की धूप की आवश्यकता होती है?",
    answer:
      "Hydroponic systems provide controlled environments, reducing reliance on sunlight. However, most crops require around 8-12 hours of light per day for optimal growth.",
    answerHindi:
      "हाइड्रोपोनिक सिस्टम नियंत्रित वातावरण प्रदान करते हैं, धूप पर निर्भरता को कम करते हैं। हालांकि, अधिकांश फसलों को इष्टतम विकास के लिए प्रति दिन लगभग 8-12 घंटे की रोशनी की आवश्यकता होती है।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "What Guarantee or warranty do you offer on projects?",
    questionHindi: "आप परियोजनाओं पर कौन सी गारंटी या वारंटी प्रदान करते हैं?",
    answer:
      "We offer a warranty on our polyhouses and hydroponic setups to ensure quality and performance. Specific warranty terms are provided upon project completion. You will get to know about our warranty duration and conditions in our quotation as well.",
    answerHindi:
      "हम गुणवत्ता और प्रदर्शन सुनिश्चित करने के लिए अपने पॉलीहाउस और हाइड्रोपोनिक सेटअप पर वारंटी प्रदान करते हैं। परियोजना के पूरा होने पर विशिष्ट वारंटी शर्तें प्रदान की जाती हैं। आपको हमारे उद्धरण में भी हमारी वारंटी अवधि और शर्तों के बारे में पता चलेगा।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "How much area is needed for automation?",
    questionHindi: "स्वचालन के लिए कितने क्षेत्र की आवश्यकता होती है?",
    answer:
      "The area required for automation depends on the scale and complexity of the system. Our experts will assess your needs during the consultation phase and provide tailored recommendations during site inspection. From our past experiences once your setup is beyond 20,000 sqft then you need automation system to manage your farm. Below this area, the farm can be easily managed with labor.",
    answerHindi:
      "स्वचालन के लिए आवश्यक क्षेत्र प्रणाली के पैमाने और जटिलता पर निर्भर करता है। हमारे विशेषज्ञ परामर्श चरण के दौरान आपकी आवश्यकताओं का मूल्यांकन करेंगे और साइट निरीक्षण के दौरान अनुकूलित सिफारिशें प्रदान करेंगे। हमारे पिछले अनुभवों से एक बार आपका सेटअप 20,000 वर्गफुट से अधिक हो जाने पर आपको अपने फार्म को प्रबंधित करने के लिए स्वचालन प्रणाली की आवश्यकता होती है। इस क्षेत्र से नीचे, फार्म को आसानी से श्रम के साथ प्रबंधित किया जा सकता है।",
  },
  {
    category: "Training",
    categoryHindi: "प्रशिक्षण",
    question:
      "If temperatures in summers and winters are extreme what should be done in that condition?",
    questionHindi:
      "अगर गर्मियों और सर्दियों में तापमान चरम पर होता है तो उस स्थिति में क्या किया जाना चाहिए?",
    answer:
      "Our hydroponic systems are designed to withstand varying climatic conditions. Additional climate control measures such as heating or cooling systems may be recommended for extreme temperatures.",
    answerHindi:
      "हमारी हाइड्रोपोनिक प्रणालियाँ विभिन्न जलवायु परिस्थितियों का सामना करने के लिए डिज़ाइन की गई हैं। चरम तापमान के लिए अतिरिक्त जलवायु नियंत्रण उपाय जैसे हीटिंग या कूलिंग सिस्टम की सिफारिश की जा सकती है।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "My farm is 5000 sqft Can we do automation?",
    questionHindi: "मेरा फार्म 5000 वर्गफुट है क्या हम स्वचालन कर सकते हैं?",
    answer:
      "Yes, automation can be implemented on farms of varying sizes. Our team will assess your requirements and recommend suitable automation solutions for your 5000 sq. ft. farm.",
    answerHindi:
      "हां, स्वचालन विभिन्न आकार के फार्मों पर लागू किया जा सकता है। हमारी टीम आपकी आवश्यकताओं का मूल्यांकन करेगी और आपके 5000 वर्गफुट फार्म के लिए उपयुक्त स्वचालन समाधान की सिफारिश करेगी।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "What is the yield of the first batch?",
    questionHindi: "पहले बैच की उपज क्या है?",
    answer:
      "Yield and harvest times vary depending on the crop cycle and system used. Typically, leafy greens can be harvested within 30-45 days, while vine crops may take 3-4 months for full maturity.",
    answerHindi:
      "फसल चक्र और उपयोग की जाने वाली प्रणाली के आधार पर उपज और कटाई का समय भिन्न होता है। आम तौर पर, पत्तेदार साग को 30-45 दिनों के भीतर काटा जा सकता है, जबकि बेल वाली फसलों को पूर्ण परिपक्वता के लिए 3-4 महीने लग सकते हैं।",
  },
  {
    category: "Setup",
    categoryHindi: "सेटअप",
    question: "Can you do contract farming or JV?",
    questionHindi: "क्या आप अनुबंध खेती या संयुक्त उद्यम कर सकते हैं?",
    answer:
      "Currently, we are not offering any JV opportunity. But if you have something in mind, you can send details about it to contact@kryzen.com. It will be redirected to the respected person in the company.",
    answerHindi:
      "वर्तमान में, हम कोई संयुक्त उद्यम अवसर की पेशकश नहीं कर रहे हैं। लेकिन अगर आपके पास कुछ विचार है, तो आप इसके बारे में विवरण contact@kryzen.com पर भेज सकते हैं। इसे कंपनी में संबंधित व्यक्ति को पुनः निर्देशित किया जाएगा।",
  },
  {
    category: "Setup",
    question: "Can we use multiple home kits for commercial purposes?",
    questionHindi:
      "क्या हम कमर्शियल उद्देश्यों के लिए कई होम किट्स का उपयोग कर सकते हैं?",
    answer:
      "While our home kits are primarily designed for personal use, larger-scale commercial projects may require customized solutions. We only suggest going with a home kit if your area is less than 1000 sqft. Above 1000 sqft, we suggest going for a customized design as it will be cheaper and more functional.",
    answerHindi:
      "हालांकि हमारे होम किट्स प्राथमिक रूप से निजी उपयोग के लिए डिज़ाइन किए गए हैं, लेकिन बड़े पैमाने पर कमर्शियल परियोजनाओं के लिए अनुकूलित समाधान की आवश्यकता हो सकती है। हम केवल सुझाव देते हैं कि आप अपने क्षेत्र कम से कम 1000 वर्गफुट से छोटा हो, तो होम किट के साथ जाएं। 1000 वर्गफुट से ऊपर, हम सामान्य रूप से एक अनुकूलित डिज़ाइन के लिए सुझाव देते हैं क्योंकि यह सस्ता और अधिक कार्यक्षम होगा।",
  },
  {
    category: "Setup",
    question: "For how much time do we get agronomy support from Kryzen?",
    questionHindi: "कितने समय तक हमें क्राइजेन से कृषि आधारित समर्थन मिलता है?",
    answer:
      "We provide continuous support to our clients, ensuring their hydroponic ventures are successful. We have lifetime access to hydroponic masterclass and also lifetime free access to our forums. In-person support is given free of cost for the first 6 months which can be renewed yearly.",
    answerHindi:
      "हम अपने ग्राहकों को निरंतर समर्थन प्रदान करते हैं, उनकी हाइड्रोपोनिक उद्यम सफल होने की सुनिश्चित करते हुए। हमारे पास हाइड्रोपोनिक मास्टरक्लास का जीवनकालिक उपयोग और हमारे फोरम का जीवनकाल मुफ्त उपयोग है। व्यक्तिगत समर्थन को पहले 6 महीने के लिए नि: शुल्क दिया जाता है जो वार्षिक रूप से नवीनीकृत किया जा सकता है।",
  },
  {
    category: "Setup",
    question:
      "What if our city or location does not have buyers on your platform?",
    questionHindi:
      "हमारे शहर या स्थान पर आपके प्लेटफ़ॉर्म पर खरीददार नहीं हैं तो क्या होगा?",
    answer:
      "Our extensive network and buyer connect services enable us to reach buyers nationwide. In cases where local buyers are limited, we explore alternative marketing and distribution channels to maximize sales opportunities. Also, we are onboarding new buyers every day. Today if you have limited access, in no time you will have access to a large pool of buyers.",
    answerHindi:
      "हमारे व्यापक नेटवर्क और खरीददार कनेक्ट सेवाएं हमें पूरे राष्ट्रव्यापी खरीददारों तक पहुँचने में सक्षम बनाती हैं। जहां स्थानीय खरीददार सीमित होते हैं, हम विपरीत विपणन और वितरण चैनल की खोज करते हैं ताकि बिक्री के अवसरों को अधिकतम किया जा सके। वर्तमान में हम हर दिन नए खरीददारों को जोड़ रहे हैं। आज यदि आपके पास सीमित पहुँच है, तो आपको बहुत जल्द ही एक बड़े संख्या के खरीददारों का उपयोग करने का मौका मिलेगा।",
  },
  {
    category: "Setup",
    question: "How many laborers are required for a 5000 sqft farm?",
    questionHindi:
      "5000 वर्ग फीट के खेत के लिए कितने श्रमिकों की आवश्यकता होती है?",
    answer:
      "From our past experience and farm operations, we see 1 laborer is good enough to manage a farm of 5000 sq ft. Even up to 10,000 sq ft, one laborer can easily manage the hydroponic system.",
    answerHindi:
      "हमारे पिछले अनुभव और खेती के आयाम से, हम देखते हैं कि 1 श्रमिक 5000 वर्ग फुट के खेत का प्रबंधन करने के लिए पर्याप्त है। 10,000 वर्ग फुट तक, एक श्रमिक हाइड्रोपोनिक प्रणाली का संचालन आसानी से कर सकता है।",
  },
  {
    category: "Setup",
    question: "Do you provide seeds or fertilizers?",
    questionHindi: "क्या आप बीज या उर्वरक प्रदान करते हैं?",
    answer:
      "To sell seeds and fertilizers we need additional licenses and documentation process. We have identified a list of seeds and fertilizers which can be procured locally or online. In your software portal, you will have access to this list as well.",
    answerHindi:
      "बीज और उर्वरक बेचने के लिए हमें अतिरिक्त लाइसेंस और दस्तावेज़ीकरण प्रक्रिया की आवश्यकता होती है। हमने एक सूची का खोज किया है जिसमें स्थानीय या ऑनलाइन प्राप्त किए जा सकने वाले बीज और उर्वरक शामिल हैं। आपके सॉफ़्टवेयर पोर्टल में, आपको इस सूची तक पहुँच मिलेगी।",
  },
  {
    category: "Setup",
    question:
      "Who will bear the transportation charges of produced vegetables?",
    questionHindi: "उत्पन्न सब्जियों के परिवहन शुल्क कौन बोझगार करेगा?",
    answer:
      "It highly depends on the quantity of production. If the quantity is less you will have to deliver it to the buyer's collection center. If the quantity is more, then buyers will offer to pick vegetables from your location. It also depends on the negotiation you have with the buyer.",
    answerHindi:
      "इस परिवहन शुल्क की अधिकता उत्पादन की मात्रा पर निर्भर करती है। यदि मात्रा कम है तो आपको इसे खरीदार के संग्रह केंद्र में पहुँचाना होगा। यदि मात्रा अधिक है, तो खरीदार आपके स्थान से सब्जियों को लेने की पेशकश करेंगे। यह भी उस समझौते पर निर्भर करता है जिसे आपने खरीदार के साथ किया है।",
  },
  {
    category: "Setup",
    question: "What is the operational cost?",
    questionHindi: "ऑपरेशनल लागत क्या है?",
    answer:
      "Operational costs vary depending on factors such as energy consumption, labor, and maintenance. As a rule of thumb, we have kept our hydroponic farm operation cost less than 7% of Recurring Revenue. After a site inspection DPR, you will get details about all the operation costs involved along with working capital.",
    answerHindi:
      "ऑपरेशनल लागत ऊर्जा की खपत, श्रम और रखरखाव जैसे कारकों पर निर्भर करती है। एक नियम के रूप में, हमने अपने हाइड्रोपोनिक फार्म के ऑपरेशनल लागत को आवर्ती राजस्व के कम से कम 7% से कम रखा है। साइट निरीक्षण डीपीआर के बाद, आपको कार्यक्षमता के साथ संबंधित सभी ऑपरेशनल लागतों के बारे में विस्तृत जानकारी प्राप्त होगी।",
  },
  {
    category: "Setup",
    question: "Why is your setup cost high?",
    questionHindi: "आपका सेटअप लागत क्यों उच्च है?",
    answer:
      "We know construction projects can be done at any cost. But there are some benchmarks which we have designed and strictly adhered to in order to build sustainable breakeven. Our setup costs reflect the quality, durability, and performance of our hydroponic systems. We prioritize using premium materials and technology to ensure long-term reliability and productivity.",
    answerHindi:
      "हम जानते हैं कि निर्माण परियोजनाएँ किसी भी कीमत पर की जा सकती हैं। लेकिन हमने संतुलित ब्रेकइवन बनाने के लिए कुछ मानकों को डिज़ाइन किया है और पूरी तरह से पालन किया है। हमारे सेटअप लागत हमारे हाइड्रोपोनिक प्रणालियों की गुणवत्ता, टिकाऊता, और प्रदर्शन को दर्शाती हैं। हम उच्च गुणवत्ता के सामग्रियों और प्रौद्योगिकी का प्राथमिकता देते हैं ताकि दीर्घकालिक विश्वसनीयता और उत्पादकता सुनिश्चित हो।",
  },
  {
    category: "Setup",
    question: "What is the production per plant?",
    questionHindi: "प्रति पौधे का उत्पादन क्या है?",
    answer:
      "Production per plant varies depending on the crop type, growing conditions, and system used. This is a key calculator we use in our Break-even analysis. Generally, to understand in layman's terms, this is somewhere Rs.1.5 to Rs. 4 for leafy green and Rs. 6 to Rs. 9 for vine crops.",
    answerHindi:
      "पौधे के प्रति उत्पादन फसल के प्रकार, उगाई गई स्थिति, और प्रणाली पर निर्भर करता है। यह हम अपने ब्रेक-इवन विश्लेषण में उपयोग करने वाले मुख्य कैलकुलेटर है। सामान्य रूप से, सामान्य भाषा में समझने के लिए, यह है कि हरा पत्तेदार सब्जियों के लिए 1.5 से 4 रुपये और बेल सब्जियों के लिए 6 से 9 रुपये हैं।",
  },
  {
    category: "Setup",
    question: "How to provide nutrients to the plant or farm?",
    questionHindi: "पौधे या खेत को पोषण कैसे प्रदान करें?",
    answer:
      "Nutrient delivery in hydroponic systems is optimized through nutrient solutions, which are carefully formulated to provide essential elements for plant growth. Our team will guide you on nutrient management practices for optimal results.",
    answerHindi:
      "हाइड्रोपोनिक प्रणालियों में पोषण वितरण को पौधों के विकास के लिए आवश्यक तत्व प्रदान करने के लिए सावधानी से तैयार किए गए पोषण समाधानों के माध्यम से अनुकूलित किया जाता है। हमारी टीम आपको आदर्श परिणामों के लिए पोषण प्रबंधन अभ्यासों पर मार्गदर्शन करेगी।",
  },
  {
    category: "Setup",
    question: "Who will manage the farm?",
    questionHindi: "खेत का प्रबंधन कौन करेगा?",
    answer:
      "Farm management can be handled by your trained personnel. We provide comprehensive training and support to ensure effective farm management. You can send your person for 10 days training at our farm location. We also have a tie-up with a third party man-powering agency, which will provide laborers to you.",
    answerHindi:
      "खेत प्रबंधन को आपके प्रशिक्षित कर्मचारियों द्वारा संभाला जा सकता है। हम प्रभावी खेत प्रबंधन सुनिश्चित करने के लिए व्यापक प्रशिक्षण और समर्थन प्रदान करते हैं। आप अपने व्यक्ति को हमारे खेत स्थान पर 10 दिनों के प्रशिक्षण के लिए भेज सकते हैं। हमारे पास एक तृतीय पक्ष मानव शक्ति एजेंसी के साथ टाई-अप है, जो आपको श्रमिक प्रदान करेगी।",
  },

  {
    category: "Training",
    question: "On which platform can we buy seeds and fertilizers?",
    questionHindi: "हम बीज और उर्वरक कहां से खरीद सकते हैं?",
    answer:
      "We recommend reputable suppliers for seeds and fertilizers from Amazon and Flipkart, or you can purchase them through authorized channels based on your preferences and requirements.",
    answerHindi:
      "हम आपको बीज और उर्वरक के लिए मान्यता प्राप्त आपूर्तिकर्ताओं की सिफारिश करते हैं जैसे कि अमेज़न और फ्लिपकार्ट से, या आप अपनी पसंद और आवश्यकताओं के आधार पर उन्हें धारकों के माध्यम से खरीद सकते हैं।",
  },
  {
    category: "Training",
    question: "Can we grow fruity vegetables in NFT?",
    questionHindi: "क्या हम NFT में फलदार सब्जियां उगा सकते हैं?",
    answer:
      "Every hydroponic system is different and designed for a different use case. NFT is widely used for leafy green production. Growing fruity crops into it might be tricky and may not be financially viable. In our hydroponic masterclass, we have covered a detailed topic on growing of plants into various hydroponic systems.",
    answerHindi:
      "प्रत्येक हाइड्रोपोनिक प्रणाली अलग होती है और इस्तेमाल के लिए अलग डिज़ाइन की जाती है। एनएफटी को हरी पत्तेदार सब्जी के उत्पादन के लिए व्यापक रूप से प्रयोग किया जाता है। इसमें फलदार फसलों को उगाना कठिन हो सकता है और इससे वित्तीय रूप से संवित्त हो सकता है। हमारे हाइड्रोपोनिक मास्टरक्लास्टर में, हमने विभिन्न हाइड्रोपोनिक प्रणालियों में पौधों की उगाई के बारे में एक विस्तृत विषय को शामिल किया है।",
  },
  {
    category: "Training",
    question: "What things can be controlled in automation?",
    questionHindi: "स्वचालन में कौन सी चीजें नियंत्रित की जा सकती हैं?",
    answer:
      "Automation systems can control various parameters such as nutrient delivery, pH levels, temperature, humidity, and lighting schedules, ensuring precise management of growing conditions. It is a cloud-based two-way communication system. That means, it helps us telling what is happening at the farm and also it helps us giving instructions to controllers.",
    answerHindi:
      "स्वचालन प्रणालियाँ पोषक पदार्थ वितरण, pH स्तर, तापमान, आर्द्रता, और प्रकाश अनुसूचियों जैसे विभिन्न पैरामीटरों को नियंत्रित कर सकती हैं, जिससे पौधों की वृद्धि की स्थिति का निर्देशन सटीक रूप से होता है। यह एक क्लाउड-आधारित दोनों तरफी संचार प्रणाली है। इसका मतलब है, यह हमें बताता है कि खेत में क्या हो रहा है और यह हमें नियंत्रकों को निर्देश देने में मदद करता है।",
  },
  {
    category: "Setup",
    question:
      "I stay far from the site or out of India how should we manage the farm?",
    questionHindi:
      "मैं स्थल से दूर रहता हूँ या भारत के बाहर हूँ, हम खेत का प्रबंधन कैसे करें?",
    answer:
      "Remote farm management is possible through our advanced automation systems and remote monitoring capabilities. You can monitor and control farm operations from anywhere with an internet connection.",
    answerHindi:
      "आगे प्रगत ऑटोमेशन प्रणालियों और दूरस्थ निगरानी क्षमताओं के माध्यम से दूरस्थ खेत प्रबंधन संभव है। आप इंटरनेट कनेक्शन के साथ कहीं से भी खेत के आपरेशन का निगरानी और नियंत्रण कर सकते हैं।",
  },
  {
    category: "Setup",
    question: "Which things will you provide in the setup?",
    questionHindi: "सेटअप में आप क्या प्रदान करेंगे?",
    answer:
      "Our setup includes all necessary components such as hydroponic systems, infrastructure, irrigation systems, and automation units. You can refer to our detailed quotation, which will help you understand in more detailed manner about what are the things and standards we provide.",
    answerHindi:
      "हमारे सेटअप में हाइड्रोपोनिक प्रणालियाँ, बुनियादी ढांचा, सिंचाई प्रणालियाँ, और स्वचालन इकाइयों जैसे सभी आवश्यक घटक शामिल हैं। आप हमारे विस्तृत उद्धरण का संदर्भ ले सकते हैं, जो आपको समझने में और अधिक विस्तृत रूप में सहायक होगा कि हम कौन सी चीजें और मानक प्रदान करते हैं।",
  },
  {
    category: "Training",
    question: "Can we do more than 1 type of hydroponic setup in 1 polyhouse?",
    questionHindi:
      "क्या हम एक पॉलीहाउस में एक से अधिक प्रकार का हाइड्रोपोनिक सेटअप कर सकते हैं?",
    answer:
      "Yes, multiple hydroponic setups can be integrated into a single polyhouse, allowing for diverse crop cultivation and increased productivity. Hydroponic technology can be combined in any ratio or by any type.",
    answerHindi:
      "हाँ, एक ही पॉलीहाउस में एक से अधिक हाइड्रोपोनिक सेटअप शामिल किए जा सकते हैं, जो विविध फसल उत्पादन और बढ़ी हुई उत्पादकता को संभव बनाते हैं। हाइड्रोपोनिक प्रौद्योगिकी को किसी भी अनुपात में या किसी भी प्रकार से मिलाया जा सकता है।",
  },
  {
    category: "Training",
    question: "Can we grow different types of vegetables in 1 setup?",
    questionHindi:
      "क्या हम एक ही सेटअप में विभिन्न प्रकार के सब्जियां उगा सकते हैं?",
    answer:
      "Yes, our hydroponic systems are versatile and allow for mixed cropping, enabling you to grow different types of vegetables in the same setup, maximizing space and productivity.",
    answerHindi:
      "हाँ, हमारी हाइड्रोपोनिक प्रणालियाँ बहुपेशेवर हैं और मिश्रित क्रॉपिंग को संभव बनाती हैं, जिससे आप एक ही सेटअप में विभिन्न प्रकार के सब्जियां उगा सकते हैं, स्थान और उत्पादकता को अधिकतम करते हुए।",
  },

  {
    category: "Setup",
    question: "Do you have a middleman to connect between buyers?",
    questionHindi: "आपके पास खरीदारों के बीच कनेक्ट करने के लिए मध्यम है?",
    answer:
      "No, we do not operate as middlemen between buyers. However, our buyer connect services facilitate direct connections between producers and potential buyers, ensuring transparency and fair trade practices.",
    answerHindi:
      "नहीं, हम खरीदारों के बीच मध्यम के रूप में काम नहीं करते हैं। हालांकि, हमारी खरीददार कनेक्ट सेवाएं उत्पादकों और संभावित खरीदारों के बीच सीधे कनेक्शन को सुनिश्चित करने में मदद करती हैं, जिससे पारदर्शिता और निष्पक्ष व्यापार अभ्यास सुनिश्चित होता है।",
  },
  {
    category: "Setup",
    question:
      "Where is your office located? How many offices do you have Pan India?",
    questionHindi:
      "आपका कार्यालय कहाँ स्थित है? भारतवर्ष में आपके कितने कार्यालय हैं?",
    answer:
      "Our main office is located at Wakad Pune. We have multiple offices coming up in Bangalore and Delhi in the next few months.",
    answerHindi:
      "हमारा मुख्य कार्यालय वाकड पुणे में स्थित है। हम अगले कुछ महीनों में बैंगलोर और दिल्ली में कई कार्यालय खोल रहे हैं।",
  },
  {
    category: "Setup",
    question: "How many days does it take to complete the setup?",
    questionHindi: "सेटअप पूरा करने में कितने दिन लगते हैं?",
    answer:
      "The duration of setup depends on various factors such as project size, complexity, and location. Our team will provide a timeline estimate during the consultation phase based on your specific requirements. Generally, to give you an idea, we complete the entire project from start to finish in 45 days, including rigorous testing and Quality analysis.",
    answerHindi:
      "सेटअप की अवधि परियोजना के आकार, जटिलता, और स्थान जैसे विभिन्न कारकों पर निर्भर करती है। हमारी टीम आपकी विशेष आवश्यकताओं पर आधारित परामर्श चरण के दौरान एक समय-रेखा अनुमान प्रदान करेगी। आम तौर पर, आपको एक धारणा देने के लिए, हम पूरी परियोजना को 45 दिनों में पूरा करते हैं, जिसमें कठिन परीक्षण और गुणवत्ता विश्लेषण शामिल है।",
  },
  {
    category: "Setup",
    question: "Is this the final amount of quotation?",
    questionHindi: "क्या यह उद्धरण का अंतिम राशि है?",
    answer:
      "We get inquiries from all over India, to cater to everyone we have created a generic quotation. We have given you the same generic quotation which is generic in nature. The final amount in the quotation will be worked on-site inspection. There is always scope for negotiation after we get an understanding of the location where you are finalizing.",
    answerHindi:
      "हमें भारत भर से पूछताछ मिलती है, सभी को ध्यान में रखते हुए हमने एक सामान्य उद्धरण बनाया है। हमने आपको वही सामान्य उद्धरण दिया है जो सामान्यत: प्रकृति में है। उद्धरण में अंतिम राशि स्थल पर निरीक्षण पर काम किया जाएगा। हमें यह अनुमान है कि आप कहां स्थानांतरित हो रहे हैं, उसे समझने के बाद चरण में नमूनाबद्ध करने का क्षेत्र हमेशा बचत होता है।",
  },
  {
    category: "Training",
    question: "Do we require pesticides in this?",
    questionHindi: "क्या हमें इसमें कीटनाशक की आवश्यकता है?",
    answer:
      "Hydroponic systems generally require fewer pesticides compared to traditional soil-based farming due to controlled growing conditions. However, integrated pest management practices may still be necessary to address pest issues effectively. Our team of agronomists works with organic pesticides and ensures that your team follows ethical and sustainable agriculture practices.",
    answerHindi:
      "हाइड्रोपोनिक प्रणालियों में नियंत्रित उगाई गई स्थितियों के कारण, पारंपरिक मिट्टी आधारित खेती की तुलना में अधिक कीटनाशकों की कम आवश्यकता होती है। हालांकि, समेकित कीट प्रबंधन अभ्यासों की आवश्यकता कीट समस्याओं को प्रभावी ढंग से संबोधित करने के लिए आज्ञाकारियों की एक टीम को भी हो सकती है। हमारी खेतजीविज्ञ टीम जैविक कीटनाशकों के साथ काम करती है और सुनिश्चित करती है कि आपकी टीम नैतिक और प्रयोजनीय कृषि अभ्यासों का पालन करती है।",
  },
  {
    category: "Setup",
    question: "Why are vegetables in Hydroponics expensive?",
    questionHindi: "हाइड्रोपोनिक्स में सब्जियां महंगी क्यों हैं?",
    answer:
      "The initial investment costs associated with hydroponic systems may contribute to higher prices for hydroponically grown vegetables. Also, there is always surplus demand for this produce which results in higher selling costs. It's simple demand for hydroponic produce is more than the supply of produce.",
    answerHindi:
      "हाइड्रोपोनिक प्रणालियों से जुड़े प्रारंभिक निवेश लागत में बढ़ोतरी का कारण हो सकता है कि हाइड्रोपोनिक उत्पन्न सब्जियों के लिए उच्च मूल्य हो। इसके अलावा, इस उत्पाद की अधिशेष मांग हमेशा रहती है जिससे उच्च बिक्री लागत होती है। यह सरल है, हाइड्रोपोनिक उत्पाद की मांग उत्पाद की मांग से अधिक होती है।",
  },
  {
    category: "Training",
    question: "Is hydroponic product organic?",
    questionHindi: "क्या हाइड्रोपोनिक उत्पाद जैविक है?",
    answer:
      "Hydroponic farming can be organic if organic-certified inputs and practices are used. Many hydroponic growers adhere to organic standards to produce high-quality, environmentally friendly crops. Our agronomist guides your team into growing organic hydroponic produce.",
    answerHindi:
      "यदि जैविक प्रमाणित इनपुट और अभ्यास का उपयोग किया जाता है, तो हाइड्रोपोनिक खेती जैविक हो सकती है। उच्च गुणवत्ता, पर्यावरण के प्रिय फसल उत्पन्न करने के लिए कई हाइड्रोपोनिक उत्पादक जैविक मानकों का पालन करते हैं। हमारे खेतजीविज्ञ आपकी टीम को जैविक हाइड्रोपोनिक उत्पाद उत्पन्न करने में मार्गदर्शन करते हैं।",
  },
  {
    category: "Training",
    question: "Is nutritional value high in hydroponics?",
    questionHindi: "क्या हाइड्रोपोनिक्स में पोषण मूल्य उच्च होता है?",
    answer:
      "Yes, hydroponically grown crops can have high nutritional value as they receive optimal levels of nutrients and water, resulting in healthy and nutrient-rich produce.",
    answerHindi:
      "हां, हाइड्रोपोनिक प्रणालियों से उगाए गए फसलों का पोषण मूल्य उच्च हो सकता है क्योंकि वे पोषक तत्वों और पानी के आदर्श स्तर प्राप्त करते हैं, जिससे स्वस्थ और पोषण सम्पन्न उत्पाद होता है।",
  },
  {
    category: "Setup",
    question: "What are the details provided in the project report?",
    questionHindi: "प्रोजेक्ट रिपोर्ट में क्या विवरण हैं?",
    answer:
      "The Detailed Project Report (DPR) includes comprehensive information such as project overview, technical specifications, financial projections, ROI analysis, and implementation timelines, providing a roadmap for project planning and execution.",
    answerHindi:
      "विस्तृत परियोजना रिपोर्ट (डीपीआर) परियोजना के अवलोकन, तकनीकी विनिर्देशिका, वित्तीय पूर्वानुमान, आरओआई विश्लेषण, और कार्यान्वयन टाइमलाइन्स जैसी व्यापक जानकारी शामिल होती है, जो परियोजना योजना और कार्यान्वयन के लिए एक रोडमैप प्रदान करती है।",
  },
  {
    category: "Setup",
    question: "What is price watcher?",
    questionHindi: "प्राइस वॉचर क्या है?",
    answer:
      "Price watcher is an automated feature in our systems that monitors market prices for crops, helping farmers make informed decisions on timing and pricing for product sales.",
    answerHindi:
      "प्राइस वॉचर हमारे प्रणालियों में एक स्वचालित सुविधा है जो फसलों के बाजारी दामों को निगरानी करती है, उत्पाद बिक्री के समय और मूल्य निर्धारण के लिए किसानों को सूचित निर्णय लेने में मदद करती है।",
  },
  {
    category: "Setup",
    question: "What is buyers connect?",
    questionHindi: "खरीदार कनेक्ट क्या है?",
    answer:
      "Buyers connect is a service we offer to connect producers with potential buyers, facilitating direct sales and market access for farm produce.",
    answerHindi:
      "खरीदार कनेक्ट एक सेवा है जिसे हम उत्पादकों को संभावित खरीददारों से जोड़ने के लिए प्रदान करते हैं, खेती के उत्पादों के लिए सीधी बिक्री और बाजार पहुंच को सुविधाजनक बनाते हैं।",
  },
  {
    category: "Setup",
    question: "Do you provide agronomy support?",
    questionHindi: "क्या आप खेतजीविज्ञान समर्थन प्रदान करते हैं?",
    answer:
      "Yes, we provide agronomy support to assist farmers with crop management, pest control, nutrient management, and other agronomic aspects to optimize crop yields and quality.",
    answerHindi:
      "हां, हम फसल प्रबंधन, कीट नियंत्रण, पोषण प्रबंधन, और अन्य खेतजीविज्ञानिक पहलुओं में किसानों की सहायता के लिए खेतजीविज्ञान समर्थन प्रदान करते हैं ताकि फसल की उत्पादकता और गुणवत्ता को अधिकतम किया जा सके।",
  },
  {
    category: "All",
    question: "Will you help us sell our product?",
    questionHindi: "क्या आप हमें हमारा उत्पाद बेचने में मदद करेंगे?",
    answer:
      "Yes, we offer buyer connect services to help farmers connect with potential buyers and market their produce effectively, maximizing sales opportunities.",
    answerHindi:
      "हां, हम खेतीकर्ताओं को संभावित खरीददारों से जोड़ने और उनके उत्पादों को प्रभावी ढंग से बाजार में प्रसारित करने के लिए खरीदार कनेक्ट सेवाएं प्रदान करते हैं, बिक्री के अवसरों को अधिकतम करते हैं।",
  },
  {
    category: "All",
    question: "What is your team size?",
    questionHindi: "आपकी टीम का आकार क्या है?",
    answer:
      "Our team consists of experienced professionals in various fields including agronomy, engineering, project management, and customer support, ensuring comprehensive support and expertise for our clients. Currently, we are a team of 35 people, with an additional 20 contractual workers and consultants.",
    answerHindi:
      "हमारी टीम विभिन्न क्षेत्रों में अनुभवी पेशेवरों से मिलकर बनी है, जिसमें खेतजीविज्ञान, इंजीनियरिंग, परियोजना प्रबंधन, और ग्राहक समर्थन शामिल है, हमारे ग्राहकों के लिए समर्पित समर्थन और विशेषज्ञता सुनिश्चित करने के साथ। वर्तमान में, हम 35 लोगों की टीम हैं, जिसमें अतिरिक्त 20 ठेकेदार कामगार और सलाहकार शामिल हैं।",
  },
  {
    category: "All",
    question: "How old is your company?",
    questionHindi: "आपकी कंपनी कितनी पुरानी है?",
    answer:
      "We started in 2019. Initial two years were spent in rigorous research and we started operations from 2021.",
    answerHindi:
      "हम 2019 में शुरू हुए। प्रारंभिक दो वर्ष कठोर अनुसंधान में बिताए गए थे और हमने 2021 से कार्यान्वयन शुरू किया।",
  },
  {
    category: "All",
    question: "Can you connect me to your owner?",
    questionHindi: "क्या आप मुझे अपने मालिक से जोड़ सकते हैं?",
    answer:
      "Our founders, Sudhir Devkar and Priya Devkar, are deeply involved in the company's operations and vision. While direct contact with the owners may not always be feasible, once your project is in the final stage of negotiation, I can connect you with them.",
    answerHindi:
      "हमारे संस्थापक, सुधीर देवकर और प्रिया देवकर, कंपनी के कार्यों और दृष्टिकोण में गहराई से शामिल हैं। स्वामियों के साथ सीधा संपर्क हमेशा संभव नहीं हो सकता, लेकिन जब आपकी परियोजना उपसर्ग की अंतिम चरण में होती है, तो मैं आपको उनसे जोड़ सकता हूं।",
  },

  {
    category: "Setup",
    question: "I already have a hydroponic system, can you help me run it?",
    questionHindi:
      "मेरे पास पहले से हाइड्रोपोनिक सिस्टम है, क्या आप मुझे इसे चलाने में मदद कर सकते हैं?",
    answer:
      "Currently, we are occupied with our own farm projects and construction. Currently, we won't be able to support you. But in the future, we are launching a dedicated stream to help people manage their hydroponic farm.",
    answerHindi:
      "वर्तमान में, हम अपने खुद के फार्म परियोजनाओं और निर्माण के साथ व्यस्त हैं। वर्तमान में, हम आपका समर्थन नहीं कर सकेंगे। लेकिन भविष्य में, हम लोगों को उनके हाइड्रोपोनिक फार्म को प्रबंधित करने में मदद करने के लिए एक विशेष धारा लॉन्च कर रहे हैं।",
  },
  {
    category: "Training",
    question: "I need a refund of my hydroponic masterclass, What should I do?",
    questionHindi:
      "मुझे मेरी हाइड्रोपोनिक मास्टरक्लास का रिफंड चाहिए, मैं क्या करूं?",
    answer:
      "We provide 7 days refund. Please reach out to our customer support team at contact@kryzen.com with your request for a refund for the Hydroponic Masterclass. Our team will assist you further with the process. Please send an email via the same email id which was used for setting up the account. It helps us identify if the same user has requested a refund.",
    answerHindi:
      "हम 7 दिनों का रिफंड प्रदान करते हैं। कृपया हमारी ग्राहक सहायता टीम से contact@kryzen.com पर आपके हाइड्रोपोनिक मास्टरक्लास के लिए रिफंड के लिए अपना अनुरोध करें। हमारी टीम आपको प्रक्रिया के साथ आगे सहायता करेगी। कृपया उसी ईमेल आईडी के माध्यम से एक ईमेल भेजें जिसका उपयोग खाता सेटअप के लिए किया गया था। यह हमें पहचानने में मदद करता है कि क्या एक ही उपयोगकर्ता ने रिफंड का अनुरोध किया है।",
  },
  {
    category: "Setup",
    question: "Tell me details about site inspection?",
    questionHindi: "साइट इंस्पेक्शन के बारे में मुझे विवरण बताएं?",
    answer:
      "Site inspection involves a comprehensive assessment of your location to evaluate factors such as climate, soil conditions, available resources, and infrastructure. This information helps us design a customized hydroponic solution tailored to your needs.",
    answerHindi:
      "साइट इंस्पेक्शन में आपके स्थान की एक व्यापक मूल्यांकन शामिल होता है जिसमें जलवायु, मृदा की स्थिति, उपलब्ध संसाधन, और बुनियादी ढांचा जैसे कारकों का मूल्यांकन किया जाता है। यह जानकारी हमें आपकी आवश्यकताओं के अनुरूप एक विशेष रूप से डिज़ाइन किए गए हाइड्रोपोनिक समाधान को डिज़ाइन करने में मदद करती है।",
  },
  {
    category: "Setup",
    question: "Can I decide not to set up the project after site inspection?",
    questionHindi:
      "क्या मैं साइट इंस्पेक्शन के बाद प्रोजेक्ट सेटअप न करने का निर्णय ले सकता हूं?",
    answer:
      "Yes, you have the flexibility to decide whether to proceed with the project after the site inspection. Our team will provide you with detailed information and recommendations to help you make an informed decision.",
    answerHindi:
      "हां, आपको साइट इंस्पेक्शन के बाद प्रोजेक्ट के साथ आगे बढ़ने का निर्णय लेने की लगती है। हमारी टीम आपको एक तथ्यात्मक निर्णय लेने में मदद करने के लिए विस्तृत जानकारी और सिफारिशें प्रदान करेगी।",
  },
  {
    category: "Training",
    question: "What is the difference between NVPH and fanpad?",
    questionHindi: "NVPH और फैनपैड में अंतर क्या है?",
    answer:
      "NVPH (Naturally Ventilated Polyhouse) relies on natural airflow for ventilation, while fan pad polyhouses use fans and evaporative cooling pads to regulate temperature and humidity. The choice depends on specific climatic conditions and project requirements.",
    answerHindi:
      "NVPH (नैचुरली वेंटिलेटेड पॉलीहाउस) हवा की वेंटिलेशन के लिए प्राकृतिक हवा प्रवाह पर निर्भर करता है, जबकि फैन पैड पॉलीहाउस फैन और वाष्पीकरण कूलिंग पैड का उपयोग तापमान और आर्द्रता को नियंत्रित करने के लिए करते हैं। यह चुनाव विशेष जलवायु स्थितियों और परियोजना की आवश्यकताओं पर निर्भर करता है।",
  },
  {
    category: "Training",
    question:
      "I have a temperature of 40 degrees at my location, which system I need to choose?",
    questionHindi:
      "मेरे स्थान पर तापमान 40 डिग्री है, मुझे कौन सा सिस्टम चुनना चाहिए?",
    answer:
      "In hot climates, a fan pad polyhouse with evaporative cooling pads may be more suitable for maintaining optimal growing conditions. Our experts will recommend the best system based on your location and requirements.",
    answerHindi:
      "गर्म जलवायु में, वाष्पीकरण कूलिंग पैड्स के साथ फैन पैड पॉलीहाउस आदर्श उगाई की स्थिति को बनाए रखने के लिए अधिक उपयुक्त हो सकता है। हमारे विशेषज्ञ आपके स्थान और आवश्यकताओं के आधार पर सर्वश्रेष्ठ सिस्टम की सिफारिश करेंगे।",
  },
  {
    category: "Training",
    question:
      "I have very frequent electricity outages, do I need an extra generator?",
    questionHindi:
      "मेरे क्षेत्र में बिजली की अधिक सावधानिक अवधारणा होती है, क्या मुझे एक अतिरिक्त जनरेटर की आवश्यकता है?",
    answer:
      "In areas with frequent electricity outages, having a backup generator can ensure uninterrupted operation of essential equipment such as pumps and ventilation systems, providing stability and reliability to your hydroponic setup. If electricity outages are for more than 3 hours and it is very regular, then we suggest you go for a dedicated generator. Below this period, our system can manage electricity failure stress effectively.",
    answerHindi:
      "ऐसे क्षेत्रों में जहां बिजली की अधिक सावधानिक अवधारणा होती है, पंप्स और वेंटिलेशन सिस्टम जैसे आवश्यक उपकरणों का अविच्छिन्न संचालन सुनिश्चित करने के लिए एक बैकअप जनरेटर होना महत्वपूर्ण है, जो आपके हाइड्रोपोनिक सेटअप को स्थिरता और विश्वसनीयता प्रदान करता है। यदि बिजली की अधिक सावधानिक अवधारणा 3 घंटे से अधिक है और यह बहुत नियमित है, तो हम आपको एक विशेष जनरेटर के लिए जाने का सुझाव देते हैं। इस समय से कम होने पर, हमारी प्रणाली बिजली की असफलता का तनाव प्रभावी ढंग से संभाल सकती है।",
  },
  {
    category: "Training",
    question:
      "My farm is located in a remote area, can we still do the project setup?",
    questionHindi:
      "मेरा फार्म एक दूरस्थ क्षेत्र में स्थित है, क्या हम फिर भी प्रोजेक्ट सेटअप कर सकते हैं?",
    answer:
      "Yes, we have experience working in remote locations and can adapt our solutions to suit your specific circumstances. Our team will work closely with you to overcome logistical challenges and ensure successful project implementation.",
    answerHindi:
      "हां, हमारे पास दूरस्थ स्थानों में काम करने का अनुभव है और हम अपने समाधान को आपके विशेष परिस्थितियों के अनुसार बदल सकते हैं। हमारी टीम आपके साथ निकटतम संदर्भों को दूर करने और सफल परियोजना कार्यान्वयन सुनिश्चित करने के लिए कठिनाइयों को पार करने के लिए निकटतम सहयोग करेगी।",
  },
  {
    category: "Setup",
    question:
      "I am doing this setup for my parents? Will they be able to manage it?",
    questionHindi:
      "मैं अपने माता-पिता के लिए इस सेटअप को कर रहा हूँ? क्या वे इसे प्रबंधित कर पाएंगे?",
    answer:
      "Absolutely! Our hydroponic systems are designed for ease of use and require minimal maintenance. With our training and ongoing support, your parents can successfully manage the hydroponic setup and enjoy the benefits of fresh, homegrown produce.",
    answerHindi:
      "बिल्कुल! हमारे हाइड्रोपोनिक सिस्टम को सुविधाजनक उपयोग के लिए डिज़ाइन किया गया है और इसमें न्यूनतम रखरखाव की आवश्यकता होती है। हमारे प्रशिक्षण और नियमित समर्थन के साथ, आपके माता-पिता हाइड्रोपोनिक सेटअप का सफल प्रबंधन कर सकते हैं और ताजा, घर पर उत्पादित उत्पादों के लाभ उठा सकते हैं।",
  },
  {
    category: "Training",
    question: "Can you offer me hydroponic masterclass discount?",
    questionHindi: "क्या आप मुझे हाइड्रोपोनिक मास्टरक्लास पर छूट दे सकते हैं?",
    answer:
      "Discounts for the Hydroponic Masterclass may be available periodically. Are you planning to purchase it immediately? I can provide you with a custom coupon code from my end. But it will be available for a limited time only.",
    answerHindi:
      "हाइड्रोपोनिक मास्टरक्लास के लिए छूट समय-समय पर उपलब्ध हो सकती है। क्या आप इसे तुरंत खरीदने की योजना बना रहे हैं? मैं आपको अपनी ओर से एक कस्टम कूपन कोड प्रदान कर सकता हूं। लेकिन यह केवल सीमित समय के लिए होगा।",
  },
  {
    category: "Training",
    question: "Can you send me a refund of Hydroponic Masterclass on my UPI?",
    questionHindi:
      "क्या आप मुझे मेरे यूपीआई पर हाइड्रोपोनिक मास्टरक्लास का रिफंड भेज सकते हैं?",
    answer:
      "Refunds for the Hydroponic Masterclass are processed according to our refund policy. We refund the money via a third-party payment gateway and the amount is refunded to the same source from where the money was credited. If you have done the transaction via UPI, then the money will be refunded to UPI.",
    answerHindi:
      "हाइड्रोपोनिक मास्टरक्लास के लिए रिफंड हमारी रिफंड नीति के अनुसार प्रोसेस किए जाते हैं। हम पैसे को एक तृतीय-पक्ष पेमेंट गेटवे के माध्यम से वापस करते हैं और राशि को उसी स्रोत में वापस किया जाता है जहां से पैसे क्रेडिट किए गए थे। यदि आपने यूपीआई के माध्यम से लेन-देन किया है, तो पैसे को यूपीआई पर रिफंड किया जाएगा।",
  },
  {
    category: "Setup",
    question: "Is there will be a regular subscription for the automation app?",
    questionHindi: "क्या स्वचालन ऐप के लिए नियमित सदस्यता होगी?",
    answer:
      "Subscription details vary depending on the system chosen. Currently, all our automation system subscriptions are free for the next 3 years.",
    answerHindi:
      "सदस्यता विवरण चयनित सिस्टम पर निर्भर करता है। वर्तमान में, हमारी सभी स्वचालन सिस्टम सदस्यता अगले 3 वर्षों के लिए मुफ्त हैं।",
  },
  {
    category: "Setup",
    question: "Will I get CAD design of the setup?",
    questionHindi: "क्या मुझे सेटअप का सीएडी डिज़ाइन मिलेगा?",
    answer:
      "Unfortunately, we do not work with CAD software. We have an internal different mechanism for material planning. The entire system is very much connected with different steps, so it is not possible from our end to provide CAD design at this stage.",
    answerHindi:
      "दुर्भाग्यवश, हम कैड सॉफ़्टवेयर के साथ काम नहीं करते हैं। हमारे पास सामग्री योजना के लिए एक अंतर्निहित विभिन्न तंत्र है। पूरा प्रणाली विभिन्न चरणों के साथ बहुत ज्यादा जुड़ा हुआ है, इसलिए हमारी ओर से इस चरण पर कैड डिज़ाइन प्रदान करना इस चरण में संभव नहीं है।",
  },
  {
    category: "Setup",
    question: "Why don't you buy back my product?",
    questionHindi: "आप मेरे प्रोडक्ट को वापस क्यों नहीं खरीदते हैं?",
    answer:
      "We do not offer a buyback policy for hydroponic setups due to logistical and operational considerations. However, we can provide guidance on selling produce via Buyers Connect.",
    answerHindi:
      "हम हाइड्रोपोनिक सेटअप के लिए लॉजिस्टिकल और ऑपरेशनल समीक्षा के कारण वापसी नीति प्रदान नहीं करते हैं। हालांकि, हम खरीददार कनेक्ट के माध्यम से उत्पाद बेचने पर मार्गदर्शन प्रदान कर सकते हैं।",
  },
  {
    category: "Training",
    question: "Why only butterfly design is used?",
    questionHindi: "केवल तितली डिज़ाइन का ही इस्तेमाल क्यों किया जाता है?",
    answer:
      "The butterfly design is chosen for its structural integrity, efficient use of space, and optimal ventilation. It offers a balanced combination of functionality and aesthetics, ensuring optimal performance for hydroponic setups.",
    answerHindi:
      "तितली डिज़ाइन को इसकी संरचनात्मक पूर्णता, स्थान के प्रभावी उपयोग, और आदर्श वायुनिकरण के लिए चुना जाता है। यह कार्यक्षमता और सौंदर्य के एक संतुलित संयोजन को प्रदान करता है, हाइड्रोपोनिक सेटअप के लिए आदर्श प्रदर्शन सुनिश्चित करता है।",
  },
  {
    category: "Setup",
    question: "If the labor leaves will you give training to other labor?",
    questionHindi:
      "अगर श्रम चला जाता है तो क्या आप अन्य श्रम को प्रशिक्षण देंगे?",
    answer:
      "Yes, we offer training programs for farm personnel to ensure smooth operation and maintenance of hydroponic setups. If labor turnover occurs, we can provide training to new personnel to maintain continuity.",
    answerHindi:
      "हां, हम फार्म के कर्मचारियों के लिए प्रशिक्षण कार्यक्रम प्रदान करते हैं ताकि हाइड्रोपोनिक सेटअप का सहज ऑपरेशन और रखरखाव सुनिश्चित हो। यदि श्रम का घाटा होता है, तो हम नए कर्मचारियों को संचालन जारी रखने के लिए प्रशिक्षण प्रदान कर सकते हैं।",
  },
  {
    category: "Training",
    question: "How many liters of water are needed every day for a home unit?",
    questionHindi:
      "घरेलू इकाई के लिए प्रतिदिन कितने लीटर पानी की आवश्यकता होती है?",
    answer:
      "Water consumption varies depending on factors such as crop type, system size, and environmental conditions. On average, a home hydroponic unit may require around 20 liters of water per day.",
    answerHindi:
      "पानी की खपत फसल के प्रकार, सिस्टम के आकार, और पर्यावरणीय स्थितियों जैसे कारकों पर निर्भर करती है। औसतन, एक घरेलू हाइड्रोपोनिक इकाई को प्रतिदिन लगभग 20 लीटर पानी की आवश्यकता हो सकती है।",
  },
  {
    category: "Setup",
    question: "Is electricity needed 24x7 for a commercial setup?",
    questionHindi: "क्या व्यावसायिक सेटअप के लिए 24x7 बिजली की आवश्यकता है?",
    answer:
      "We don't need continuous electricity. But what we need is power outages should not be for more than 3 hours. If you are at a location where power outages are very frequent and it is for more than 3 hours regularly, then we might need to go for a genset.",
    answerHindi:
      "हमें लगातार बिजली की आवश्यकता नहीं है। लेकिन हमें यह चाहिए कि बिजली की अवरुद्धता 3 घंटे से अधिक न हो। अगर आप एक ऐसी स्थिति में हैं जहां बिजली की अवरुद्धता बहुत आम है और यह नियमित रूप से 3 घंटे से अधिक है, तो हमें एक जनरेटर के लिए जाने की आवश्यकता हो सकती है।",
  },
  {
    category: "Setup",
    question: "What if the rat or other rodents enter inside the polyhouse?",
    questionHindi:
      "अगर चूहा या अन्य गिलहरी पॉलीहाउस के अंदर प्रवेश कर जाते हैं तो क्या करना चाहिए?",
    answer:
      "The polyhouse is covered with insect and rodent net from all sides. This net comes from 2ft underground, preventing all rodents from entering inside. Even if we find there is any rat inside, we can make use of a trapper or blocker to catch the rat and remove it from the system.",
    answerHindi:
      "पॉलीहाउस को सभी ओर से कीट और गिलहरी नेट से ढ़का गया है। यह नेट 2 फीट भूमि के अंदर से आता है, जो सभी गिलहरियों को अंदर प्रवेश से रोकता है। यदि हमें अंदर कोई भी चूहा मिलता है, तो हम चूहे को पकड़ने और प्रणाली से निकालने के लिए ट्रैपर या ब्लॉकर का उपयोग कर सकते हैं।",
  },
  {
    category: "Training",
    question: "I want to learn about hydroponics, what to do?",
    questionHindi: "मुझे हाइड्रोपोनिक्स के बारे में सीखना है, क्या करना चाहिए?",
    answer:
      "You can enroll in our hydroponic masterclass. We teach you everything about hydroponics in detail. The course is available in Hindi and English. You get lifetime access to the course and can connect with our team of professionals to get your questions resolved.",
    answerHindi:
      "आप हमारी हाइड्रोपोनिक मास्टरक्लास में नामांकित कर सकते हैं। हम आपको हाइड्रोपोनिक्स के बारे में हर चीज़ को विस्तार से सिखाते हैं। कोर्स हिंदी और अंग्रेजी में उपलब्ध है। आपको कोर्स का लाइफटाइम एक्सेस मिलता है और अपने प्रश्नों को हल करने के लिए हमारी पेशेवरों की टीम से जुड़ सकते हैं।",
  },
  {
    category: "Training",
    question: "Can I learn about hydroponics practically?",
    questionHindi: "क्या मैं हाइड्रोपोनिक्स को व्यावहारिक रूप से सीख सकता हूँ?",
    answer:
      "Yes, we run a two-day detailed workshop to teach you about hydroponics. We conduct this workshop on our farm where everything is practically taught to you. You can visit kryzen.com and enroll for hydroponic training.",
    answerHindi:
      "हां, हम हाइड्रोपोनिक्स के बारे में आपको सिखाने के लिए एक दो-दिन कार्यशाला चलाते हैं। हम इस कार्यशाला को हमारी खेती पर आयोजित करते हैं जहां सभी चीजें आपको व्यावहारिक रूप से सिखाई जाती हैं। आप kryzen.com पर जाकर हाइड्रोपोनिक प्रशिक्षण के लिए नामांकित कर सकते हैं।",
  },
  {
    category: "Training",
    question: "How many people are allowed for a free farm visit?",
    questionHindi: "मुफ्त खेत की यात्रा के लिए कितने लोगों की अनुमति है?",
    answer:
      "We allow one free farm visit per person who has done the hydroponic masterclass. For every extra person, there are charges for the visit.",
    answerHindi:
      "हम एक हाइड्रोपोनिक मास्टरक्लास किया है वह व्यक्ति के लिए एक मुफ्त खेत दौरा अनुमति देते हैं। हर अतिरिक्त व्यक्ति के लिए, यात्रा के लिए शुल्क हैं।",
  },
  {
    category: "Training",
    question:
      "I haven't done the hydroponic masterclass, Can I still visit your farm?",
    questionHindi:
      "मैंने हाइड्रोपोनिक मास्टरक्लास नहीं किया है, क्या मैं फिर भी आपके खेत पर यात्रा कर सकता हूँ?",
    answer:
      "Yes, you can visit our farm without doing the hydroponic masterclass. It will give you a good idea about hydroponic technology. But with the hydroponic masterclass, you will learn all theoretical insights about hydroponic farm construction and management.",
    answerHindi:
      "हां, आप हमारे खेत पर हाइड्रोपोनिक मास्टरक्लास किए बिना भी यात्रा कर सकते हैं। यह आपको हाइड्रोपोनिक प्रौद्योगिकी के बारे में एक अच्छी जानकारी देगा। लेकिन हाइड्रोपोनिक मास्टरक्लास के साथ, आप हाइड्रोपोनिक खेत निर्माण और प्रबंधन के सभी सिद्धांतिक अंतर्दृष्टियों को सीखेंगे।",
  },
  {
    category: "Training",
    question: "Can I use borewell water for my hydroponic project?",
    questionHindi:
      "क्या मैं अपने हाइड्रोपोनिक परियोजना के लिए बोरवेल पानी का उपयोग कर सकता हूँ?",
    answer:
      "Any water is good water for a hydroponic project, but we might need some filtration. I would still suggest you take a water report and share it with us. Our team of experts can help you with more details about water quality.",
    answerHindi:
      "हाइड्रोपोनिक परियोजना के लिए कोई भी पानी अच्छा पानी है, लेकिन हमें कुछ फ़िल्ट्रेशन की आवश्यकता हो सकती है। मैं फिर भी आपको एक जल रिपोर्ट लेने और हमारे साथ इसे साझा करने की सलाह दूंगा। हमारी विशेषज्ञ टीम आपको जल की गुणवत्ता के बारे में अधिक विवरण प्रदान कर सकती है।",
  },
  {
    category: "Setup",
    question:
      "I already have a polyhouse, Can you help me set up only hydroponic inside?",
    questionHindi:
      "मेरे पास पॉलीहाउस पहले से है, क्या आप मुझे केवल हाइड्रोपोनिक की सेटिंग करने में मदद कर सकते हैं?",
    answer:
      "Yes, we can convert any protected cultivation project into hydroponics. But we will have to inspect your existing project first. You can share project photos with us and also plan for a site visit. We will identify if we need to do any maintenance work or modification to the system or not.",
    answerHindi:
      "हां, हम किसी भी संरक्षित उत्पादन परियोजना को हाइड्रोपोनिक में बदल सकते हैं। लेकिन हमें पहले आपकी मौजूदा परियोजना की जांच करनी होगी। आप हमारे साथ परियोजना की तस्वीरें साझा कर सकते हैं और साथ ही एक साइट यात्रा की योजना बना सकते हैं। हम यह निश्चित करेंगे कि क्या हमें सिस्टम में कोई रखरखाव काम या संशोधन करने की आवश्यकता है या नहीं।",
  },
  {
    category: "Setup",
    question:
      "How much time should I give to the hydroponic farm to start with?",
    questionHindi:
      "शुरू करने के लिए मुझे हाइड्रोपोनिक फार्म को कितना समय देना चाहिए?",
    answer:
      "The first three months are the learning curve months. We suggest you give at least 3 to 4 hours for at least 3 to 4 days a week. Once you get a complete understanding of the system, you can reduce your active involvement time.",
    answerHindi:
      "पहले तीन महीने सीखने के महीने होते हैं। हम आपको सलाह देते हैं कि कम से कम 3 से 4 घंटे का समय कम से कम 3 से 4 दिन प्रति सप्ताह दें। जब आपको प्रणाली का पूरा समझ आ जाए, तो आप अपना सक्रिय शामिल होने का समय कम कर सकते हैं।",
  },
  {
    category: "Setup",
    question: "Should I register a company to start a hydroponic business?",
    questionHindi:
      "हाइड्रोपोनिक व्यवसाय शुरू करने के लिए क्या मैं कंपनी पंजीकृत करवाऊं?",
    answer:
      "Hydroponic farming is considered an agricultural practice. If you haven't registered any company, we will suggest you practice it as a farmer for a few months before registering a company. Once you decide to create your own labeled product, then you can think about company registration.",
    answerHindi:
      "हाइड्रोपोनिक खेती को कृषि प्रथा के रूप में माना जाता है। अगर आपने कोई कंपनी पंजीकृत नहीं की है, तो हम आपको सलाह देंगे कि आप अगर एक कंपनी पंजीकृत करने से पहले कुछ महीने तक किसान के रूप में इसे अभ्यास करें। जब आप अपना खुद का लेबल उत्पाद बनाने का निर्णय लेते हैं, तो फिर आप कंपनी पंजीकरण के बारे में सोच सकते हैं।",
  },
  {
    category: "Setup",
    question: "Agriculture is tax free, then why do I have to pay GST?",
    questionHindi: "कृषि निशुल्क है, तो मुझे जीएसटी क्यों देना होगा?",
    answer:
      "Yes, you are right, agriculture is tax free in india. But Agricultural infrastructure and equipments are still under GST slabs. As we are providing you construction project it comes under 18% tax regime.",
    answerHindi:
      "हां, आप सही हैं, भारत में कृषि निःशुल्क है। लेकिन कृषि बुनियादी ढांचे और उपकरण अब भी जीएसटी स्लैब के अंतर्गत हैं। हम आपको निर्माण परियोजना प्रदान कर रहे हैं तो इसमें 18% कर शास्त्रीय आयाम होता है।",
  },
  {
    category: "Setup",
    question: "Do i need trained or skilled labour to manage my farm?",
    questionHindi:
      "क्या मुझे अपने खेत को प्रबंधित करने के लिए प्रशिक्षित या कुशल श्रमिक की आवश्यकता है?",
    answer:
      "We don't have any such requirement, But we suggest to hire someone who can read english words, not fluently but good enough to understand various product names. And he should be able to handle basic phone operations like video call, camera, video calls and other small things.",
    answerHindi:
      "हमें ऐसी कोई आवश्यकता नहीं है, लेकिन हम सुझाव देते हैं कि आप किसी को भर्ती करें जो अंग्रेजी शब्द पढ़ सकता है, धीरे-धीरे नहीं लेकिन प्रोडक्ट के विभिन्न नामों को समझने के लिए पर्याप्त है। और उसे बेसिक फोन ऑपरेशन्स जैसे कि वीडियो कॉल, कैमरा, वीडियो कॉल और अन्य छोटी-छोटी चीजें संभालने की क्षमता होनी चाहिए।",
  },
  {
    category: "Setup",
    question: "Will you guys help me find land as well?",
    questionHindi: "क्या आप मुझे जमीन भी खोजने में मदद करेंगे?",
    answer:
      "We wont be able to find the land, but we can conduct site inspection on multiple lands and help you finalize the lands which you have shortlisted.",
    answerHindi:
      "हम जमीन नहीं खोज पाएंगे, लेकिन हम कई जमीनों पर साइट निरीक्षण कर सकते हैं और आपकी जो जमीनों को आपने चुना है उन्हें अंतिम करने में आपकी मदद कर सकते हैं।",
  },
  {
    category: "Setup",
    question: "What is a life of hydroponic project?",
    questionHindi: "हाइड्रोपोनिक परियोजना की आयु क्या है?",
    answer:
      "Hydroponic project goes on easily for 10 to 12 years with basic maintainace. You can also optin for additional insurance on your project that can help you carry out regular interval maintanance and increase the life of project much further.",
    answerHindi:
      "हाइड्रोपोनिक परियोजना 10 से 12 वर्षों के लिए आसानी से चलती है बेसिक रखरखाव के साथ। आप अपने परियोजना पर अतिरिक्त बीमा का विकल्प भी चुन सकते हैं जो आपको नियमित अंतराल रखरखाव का कार्य करने में मदद कर सकता है और परियोजना की आयु को बहुत आगे बढ़ा सकता है।",
  },
];

const getClass = (string) => {
  if (string === "All") {
    return "badge bg-teal";
  } else if (string === "Setup") {
    return "badge bg-lime";
  } else {
    return "badge bg-orange";
  }
};
const InternalFAQ = () => {
  const [inputvalue, setInputvalue] = useState("");
  const [faq, setFAQ] = useState(questionsArray1);
  function searchQuestions(searchInput) {
    searchInput = searchInput.toLowerCase();
    const searchResult = questionsArray1.filter((que) => {
      const question = que.question.toLowerCase();
      const category = que.category.toLowerCase();
      if (question.includes(searchInput) || category.includes(searchInput)) {
        return true;
      }
    });
    setFAQ(searchResult);
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  marginBottom: "-30px",
                }}
              >
                <h2>Search Customer Queries...</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                <div className="row g-3 m-2 mb-4 col-lg-6 col-md-9 col-12 align-items-center">
                  <div className="col">
                    <div className="input-icon">
                      <input
                        onChange={(e) => setInputvalue(e.target.value)}
                        value={inputvalue}
                        onKeyPress={(event) => {
                          if (event.key == "Enter") {
                            searchQuestions(inputvalue);
                          }
                        }}
                        type="text"
                        className="form-control form-control-rounded"
                        placeholder="Search…"
                      />
                      <span className="input-icon-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                          <path d="M21 21l-6 -6" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-body">
            <div className="container-xl">
              <div className="card">
                <div className="card-body">
                  <div className="text-center px-md-5">
                    <p className="fw-bold fs-1">
                      GREET EVERY CALL WITH SMILE AND INTRODUCE YOURSELF &
                      KRYZEN BIOTECH LISTEN TO CUSTOMER & IDENTIFY CUSTOMER IS
                      INTERESTED IN COURSE OR SETUP
                    </p>
                    <p className="fw-bold fs-1 bg-black text-white px-6 py-1">
                      WE HAVE RAISED FUNDING FROM SHARK TANK INDIA PEYUSH BANSAL
                    </p>
                    <div className="row">
                      <div className="col-md border border-black border-3 p-3">
                        <h2 className="fw-bolder">HYDROPONIC MASTERCLASS</h2>
                        <ol className="text-start fs-3 fw-medium">
                          <li>
                            <span className="fw-bolder">20 hours</span> long
                            complete course.
                          </li>
                          <li>
                            The course is available in{" "}
                            <span className="fw-bolder">Hindi & English.</span>
                          </li>
                          <li>
                            We have{" "}
                            <span className="fw-bolder">
                              videos, software, handbooks, and excel sheets.
                            </span>
                          </li>
                          <li>
                            <span className="fw-bolder">
                              All hydroponic technologies
                            </span>{" "}
                            are covered in the course.
                          </li>
                          <li>
                            The entire course is updated and we teach you{" "}
                            <span className="fw-bolder">
                              the business side of hydroponics.
                            </span>
                          </li>
                          <li>
                            After the course{" "}
                            <span className="fw-bolder">free farm visit</span>{" "}
                            for hands-on practical experience. is provided.
                          </li>
                          <li>
                            <span className="fw-bolder">
                              {" "}
                              Fifty-five thousand plus people
                            </span>{" "}
                            has already done this course.
                          </li>
                          <li>
                            You get a{" "}
                            <span className="fw-bolder">
                              complete project report
                            </span>{" "}
                            inside the course.
                          </li>
                          <li>
                            Give customer coupon code{" "}
                            <span className="fw-bolder">FREE200</span> to give a{" "}
                            <span className="fw-bolder">
                              discount of 200 rupees
                            </span>{" "}
                            on the spot if he finishes the purchase immediately.
                          </li>
                        </ol>
                      </div>
                      <div className="col-md border border-black border-3 p-3">
                        <h2 className="fw-bolder">
                          {" "}
                          HYDROPONIC COMMERCIAL FARM SETUP
                        </h2>
                        <ol className="text-start fs-3 fw-medium">
                          <li>
                            We have set up{" "}
                            <span className="fw-bolder">
                              maximum hydroponic farms
                            </span>{" "}
                            across India.
                          </li>
                          <li>
                            Ask if the customer has completed the{" "}
                            <span className="fw-bolder">
                              hydroponic masterclass and farm visit.
                            </span>
                          </li>
                          <li>
                            We insist on{" "}
                            <span className="fw-bolder">
                              starting a hydroponic project on 5000 or 10000
                              sqft
                            </span>{" "}
                            if the customer is doing hydroponic for the first
                            time.
                          </li>
                          <li>
                            The starting
                            <span className="fw-bolder">
                              {" "}
                              cost of a base project of 5000 sqft is 17 lakhs.
                            </span>{" "}
                            NVPH + Growbags.
                          </li>
                          <li>
                            Ask the customer to set up a call again to{" "}
                            <span className="fw-bolder">
                              solve doubts about the quotation.
                            </span>
                          </li>
                          <li className="fw-bolder">
                            Insist for an office visit or farm visit.
                          </li>

                          <li>
                            We have inhouse buyers connect & Farm management
                            software
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div>
                      <ul className="text-start fs-3 fw-bolder m-2">
                        <li>
                          KBPL has a team of 135 people including contractors
                          and labourers.
                        </li>
                        <li>
                          KBPL has 3 agronomists with 30 years of experience.
                          KBPL has all In-house farm management Software.
                        </li>
                        <li>
                          KRYZEN BIOTECH is PVT LTD COMPANY/2019 Start/Debt
                          Free/Profitable.
                        </li>
                        <li>CUSTOMER CARE NUMBER: +91-9870424425</li>
                        <li>
                          We have our farm at Satara location area of 1 acre.
                        </li>
                        <li>
                          Farm Visit: Location Satara / 85km from Pune/350km
                          Mumbai.
                        </li>
                        <li>
                          Reach to Farm location: google search for Kryzen
                          Experience Center.
                        </li>
                        <li>
                          Kryzen Office: Wakad Pune / Pune Bangalore Expressway.
                        </li>
                      </ul>
                      <p className="fw-bold fs-2 bg-black text-white px-6 py-1">
                        IF YOU FIND CUSTOMER IS INTERESTED IN AFTER SERVICE &
                        SOFTWARES ASK TO BOOK SOFTWARE DEMO SESSIONS.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="grid">
                      {faq?.map((question, index) => {
                        return (
                          <div
                            className="card g-col-lg-4 g-col-md-6 g-col-12"
                            key={index}
                          >
                            <div className="card-body">
                              <p
                                style={{
                                  color: "#FFFFFF",
                                  fontSize: "10px",
                                  marginBottom: "3px",
                                }}
                                className={getClass(question?.category)}
                              >
                                {question.category.toUpperCase()}
                              </p>
                              <p style={{ marginBottom: "3px" }}>
                                <b>{question.question}</b>
                              </p>
                              <p>{question.answer}</p>
                              <hr />
                              <p style={{ marginBottom: "3px" }}>
                                <b>{question.questionHindi}</b>
                              </p>
                              <p>{question.answerHindi}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="grid"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default InternalFAQ;
