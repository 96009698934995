import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import Footer from "../../../Components/Footer/footer";
import CandidateDetailsForShortlist from "../../Find Talent/Job Board/candidate_details_for_shortlist";
import { recruiter } from "../../../Config/config";

const AllAppliedJobs = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [viewDetails, setViewDetails] = useState("");
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const getjobsandapplicants = (candidateId) => {
      performRequest(
        "GET",
        `/applyedcandidate/appliedjobsbycandidateid/${candidateId}`
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
            setLoading(false);
          } else {
            Toast("error", "Server Error");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("Error", err);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    };
    if (params.candidateId) getjobsandapplicants(params.candidateId);
  }, [params.candidateId, refresh]);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="page-wrapper">
        {!viewDetails && (
          <div className="page-body">
            <div className="container-xl">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Jobs Lists</h3>
                  </div>
                  <p className="text-secondary ms-2 mt-2">
                    <span className="status status-green">
                      <span className="status-dot"></span>
                      List of all jobs and candidates who apply for a job.
                    </span>
                  </p>
                  <div
                    className="list-group list-group-flush overflow-auto"
                    style={{ maxHeight: "50rem" }}
                  >
                    {data &&
                      data.length > 0 &&
                      data.map((el, index) => {
                        return (
                          <div className="list-group-item" key={index}>
                            <div className="row">
                              <div className="card m-2 p-2">
                                {el?.usermodel?.userRole === recruiter && (
                                  <div className="ribbon bg-red">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 320 512"
                                      fontSize="1em"
                                      className="me-2"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z"></path>
                                    </svg>
                                    URGENT HIRING
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-lg-2  col-md-4 col-sm-12 text-center">
                                    <div className="card">
                                      <img
                                        src={
                                          el?.jobsmodel?.employeronboardingmodel
                                            ?.companyLogo
                                        }
                                      />
                                    </div>
                                    <div className="card bg-primary-lt mt-1">
                                      <p className="m-0 p-0">
                                        {
                                          el.jobsmodel?.employeronboardingmodel
                                            ?.companyName
                                        }
                                      </p>
                                      <h1 className="m-0 p-0">90%</h1>
                                    </div>
                                    <p className="fs-5 m-0 p-0">
                                      * Your chances of clearing interview.
                                    </p>
                                  </div>
                                  <div className="col-lg-10 col-md-8 col-sm-12 mt-3 mb-lg-0 mb-md-0">
                                    <h3 className="m-0 p-0">
                                      {el.jobsmodel?.jobRole}
                                    </h3>
                                    <p className="m-0 p-0">
                                      at{" "}
                                      <strong>
                                        {
                                          el.jobsmodel?.employeronboardingmodel
                                            ?.companyName
                                        }
                                      </strong>{" "}
                                      in{" "}
                                      <strong>
                                        {el.jobsmodel?.jobDepartment}
                                      </strong>{" "}
                                      department.
                                    </p>
                                    <span className="badge bg-cyan-lt me-1">
                                      POSITIONS:{" "}
                                      {el.jobsmodel?.numberOfPositions || 1}
                                    </span>
                                    {el?.jobsmodel?.additionalPerks?.map(
                                      (item, index) => (
                                        <span
                                          key={index}
                                          className="badge badge-outline text-teal me-1"
                                        >
                                          {item}
                                        </span>
                                      )
                                    )}

                                    <div className="row my-2">
                                      <div className="col-12">
                                        <p className="m-0 p-0">
                                          Required Skills:
                                        </p>
                                        <div className="card d-flex p-0">
                                          <div className="card-body m-0">
                                            {el?.jobsmodel?.skillsDetails?.map(
                                              (ele, index) => {
                                                return (
                                                  <kbd
                                                    className="m-1"
                                                    key={index}
                                                  >
                                                    {ele.skill}
                                                  </kbd>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr className="m-0 p-0" />
                                    <div className="row mt-2" />
                                    <div className="col-9">
                                      <p className="text-secondary m-0 p-0">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 384 512"
                                          fontSize="1em"
                                          className="me-2"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path>
                                        </svg>{" "}
                                        {el.jobsmodel?.jobLocation},{" "}
                                        {el.jobsmodel?.jobCity},{" "}
                                        {el.jobsmodel?.jobState}
                                      </p>
                                      <p className="text-secondary m-0 p-0">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 640 512"
                                          fontSize="1em"
                                          className="me-2"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 176c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 48h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path>
                                        </svg>
                                        ₹{el.jobsmodel?.salaryPerMonthMin} - ₹
                                        {el.jobsmodel?.salaryPerMonthMax}
                                      </p>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                      <div className="col-lg-3 col-sm-5 col-md-6 mt-3">
                                        <button
                                          className="btn btn-primary me-2"
                                          onClick={() => setViewDetails(el)}
                                        >
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 512 512"
                                            fontSize="1em"
                                            className="me-2"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path>
                                          </svg>{" "}
                                          Shortlist{" "}
                                        </button>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            navigate(`/view-jobs/${el.jobId}`)
                                          }
                                        >
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 512 512"
                                            fontSize="1em"
                                            className="me-2"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path>
                                          </svg>{" "}
                                          Job Details{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {data && data.length < 1 && (
                      <div className="p-4">
                        <h3>No applied job by candiadte found!</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {viewDetails && (
          <CandidateDetailsForShortlist
            refresh={refresh}
            setRefresh={setRefresh}
            setViewDetails={setViewDetails}
            viewDetails={viewDetails}
          />
        )}
      </div>

      <Footer />
    </>
  );
};

export default AllAppliedJobs;
