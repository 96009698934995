import { useField } from "formik";

const CustomInput = ({ type, ...props }) => {
  const [field] = useField(props);

  if (type == "file") {
    return (
      <>
        <input
          type={type}
          className="form-control"
          onBlur={field.onBlur}
          onChange={field.onChange}
          {...props}
        />
      </>
    );
  } else {
    return (
      <>
        <input type={type} className="form-control" {...field} {...props} />
      </>
    );
  }
};
export default CustomInput;
