import React, { useRef, useState } from "react";
import { useLocation } from "react-router";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Buttonloader from "../../Components/layouts/button_loader";

const Qr = () => {

  const printRef = useRef()
  const location = useLocation();
  const { url, jobRole,purpose } = location.state || {}; // Get URL from location state
  const [isDownloadImageTrue, setIsDownloadImageTrue] = useState(false);
  const [isDownloadPdfTrue, setIsDownloadPdfTrue] = useState(false);
  const handleDownloadQr = async () => {
    setIsDownloadImageTrue(true);
    const element = printRef.current;

    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 3,
    });

    const data = canvas.toDataURL("image/png", "1.0");
    console.log(data)

    const link = document.createElement("a");
    link.href = data;
    link.download = "qr-code.png"; // Filename for the downloaded image
    document.body.appendChild(link); // Append link to the body
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up
    setIsDownloadImageTrue(false);
  };
  const handleDownloadPdf = async () => {
    setIsDownloadPdfTrue(true);
    const element = printRef.current;

    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 3,
    });

    const data = canvas.toDataURL("image/png", "1.0");
    console.log(data)

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();

    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    if (imgProperties > pdfHeight) {
      const scaleFactor = pdfHeight / imgProperties;
      pdfWidth *= scaleFactor;
    }
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");

    setIsDownloadPdfTrue(false);
  };

  return (
    <div className="Page">
    <div className="page-wrapper">
      <div className="container-xxl">
         <div className="card">
          <div className="card-header">
            <h1>QR Code </h1>
          </div>
          <div className="card-body" ref={printRef}>
          
            <div className="">
            {url ? (
              <div className="w-33 ms-8">
                <img   src={url} alt="Generated QR Code" />
                 {jobRole ? (
                   <div><h3>{`Role:${jobRole}`}</h3></div>
                 ): purpose ? (<div><h3>{`About:${purpose}`}</h3></div>):''}
              </div>
            
            ) : (
              <p>No QR code generated yet.</p>
            )}
            </div>
            <div className="ms-8 mt-4" data-html2canvas-ignore="true">
              {isDownloadImageTrue ? (<Buttonloader />):(<button className="btn btn-primary" onClick={handleDownloadQr}>Download Image</button>)}
              {isDownloadPdfTrue ?(<Buttonloader />):( <button className="btn btn-primary ms-2" onClick={handleDownloadPdf}>Download PDF</button>)}
            </div>
          </div>
         </div>
    </div> 
    </div> 
    </div> 
  );
};

export default Qr;
