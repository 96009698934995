export default function getFilteredURL(baseUrl, page, filters) {
  let url = `${baseUrl}?page=${page}`;

  if (filters && typeof filters === "object") {
    for (const key in filters) {
      if (filters[key] !== "") {
        url += `&${key}=${filters[key]}`;
      }
    }
  }

  return url;
}
