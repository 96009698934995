import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const FreeAcountManager = () => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();

  return (
    <div class="page">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="container-xl">
            <header className="hero text-center py-10 my-6 my-md-8">
              <h1
                className="hero-title aos-init aos-animate my-5 display-4 fw-bold"
                data-aos="zoom-y-out"
              >
                Get your dedicated account manager!
              </h1>
              <h2 className="p-0 m-0">
                With every listing, we asign a dedicated account manager to make
                sure you hire right & hire fast!
              </h2>
              {/* <div className="row my-4">
                <div className="col-mg-12 col-lg-6 text-lg-end text-md-center mb-3">
                  <div className="">
                    <span
                      onClick={() => {
                        if (!store?.isLoggedIn) {
                          navigate("/account-login");
                        } else {
                          navigate("/resume-builder");
                        }
                      }}
                      className="btn btn-primary btn-lg p-3 btn-text"
                    >
                      <FaAddressCard fontSize={"1.2em"} className="me-2" />{" "}
                      BUILD YOUR PROFILE
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 text-lg-start text-md-center">
                  <div className="">
                    <span
                      className="btn btn-lg p-3 btn-text"
                      onClick={() => {
                        navigate("/top-hires");
                      }}
                    >
                      <FaSearch fontSize={"1.2em"} className="me-2" />
                      BROWSE JOB POSTS
                    </span>
                  </div>
                </div>
              </div> */}
            </header>
            <hr />
            <div className="row">
              <div className="col-md-6"></div>

              <div className="col-md-6">
                <h2>Direct POC and Accessibility </h2>
                <p>
                  One of the greatest benefits of an account manager that is
                  dedicated to your company, is having a direct, single point of
                  contact for all of the questions and needs you may have. Gone
                  are the days of calling a general phone number and speaking to
                  several different customer service representatives, having to
                  explain your issues or needs to each rep who is not familiar
                  with your account.
                </p>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6">
                <h2>Good Knowledge of Processes and Requirements</h2>
                <p>
                  Each account manager has extreme expertise in the industry and
                  is equipped to answer any questions you may have. Account
                  managers have a good knowledge base of hiring requirements and
                  filteration processing. They are also committed to learning
                  your businesses inside and out to become well-informed of
                  every specific detail within the accounts they manage. This
                  helps to be able to point out any potential information that
                  might help into right hiring.
                </p>
              </div>

              <div className="col-md-6"></div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6"></div>

              <div className="col-md-6">
                <h2>Assistance in Setting Up Jobs </h2>
                <p>
                  If you are not familiar with setting up new job posts, it can
                  be quite confusing, but it doesn’t have to be. Your personal
                  account manager will provide assistance and support for every
                  step of the preparing job description to building process. We
                  walk you through getting set up and offer as much self-service
                  as you need. Our goal is to make this as easy as possible for
                  you so that you can focus on other aspects of your business.
                </p>
              </div>
            </div>
            <hr />
            <h1
              className="aos-init aos-animate my-5 display-4 fw-bold p-0 m-0  text-center"
              data-aos="zoom-y-out"
            >
              Have any question in mind?
            </h1>
            <h2 className="p-0 m-0 text-center">
              Shoot us a mail on contact@hire48.com
            </h2>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FreeAcountManager;
