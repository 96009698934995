
export const editCompanyProfileInfo ={

    fullName:"",
    companyName:"",
    aboutCompany:"",
    email:"",
    contactNumber:"",
    companyAddress:"",
    companyType:"",
    companyLogo:"",
    headquarter:"",
    designation:"",
    teamSize:"",
    website:"",
    exactMatch: true,
    industry: "",
    sectorName: "",
    industryName: "",
    foundedYear:''


  }