import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { BsFillPersonPlusFill } from "react-icons/bs";

const Pagination = ({
  page = 1,
  setPage,
  totalPages,
  pageDatalength,
  showPage,
  setShowPage,
}) => {
  useEffect(() => {
    if (pageDatalength === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [pageDatalength, page, setPage]);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setShowPage(true);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
      setShowPage(true);
    }
  };

  const handlePageClick = (pageNum) => {
    setPage(pageNum);
    setShowPage(true);
  };

  const getPages = () => {
    const pages = [];
    const maxPagesToShow = 10;
    let startPage = Math.max(page - 5, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <>
      <div className="card-footer d-flex flex-column flex-lg-row align-items-center ">
        <div className="mt-3  text-muted text-center w-100 order-0 order-lg-0 text-lg-start">
          Showing <span>{page}</span> to <span>{totalPages}</span> of{" "}
          <span>{pageDatalength}</span> entries
        </div>
        <div className="text-muted d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center mt-3 mt-lg-0 w-100 order-1 order-lg-1">
          <button
            onClick={handlePrevious}
            disabled={page <= 1}
            style={{ textAlign: "center", cursor: "pointer" }}
            className="page-link m-1"
            href="#"
            tabIndex="-1"
            aria-disabled="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
            prev
          </button>

          {getPages().map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => handlePageClick(pageNum)}
              style={pageNum === page ? styles.activeButton : styles.button1}
            >
              {pageNum}
            </button>
          ))}

          <button
            onClick={handleNext}
            disabled={page >= totalPages}
            style={{ textAlign: "center", cursor: "pointer" }}
            className="page-link m-1"
            href="#"
            tabIndex="-1"
            aria-disabled="true"
          >
            next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 6l6 6l-6 6" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

const styles = {
  button1: {
    padding: "4px 8px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#fff",
    color: "#333",
    transition: "background-color 0.3s",
  },
  activeButton: {
    padding: "4px 10px",
    fontSize: "14px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#0054a6",
    color: "#fff",
    transition: "background-color 0.3s",
  },
};

export default Pagination;
