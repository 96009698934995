import React, { useState, useEffect } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form, ErrorMessage } from "formik";
import CustomInput from "../../CustomComponent/custom_input";

// import StepRoutes from "../../../Routes/steps_routes";

import performRequest from "../../../APIs/request";
import { EmployeeLeavesInfo } from "../../../Schema Info/employeeleaveinfo";
import { EmployeeLeaveSchema } from "../../../Schemas/employee/employee_leave_schema";
import CustomTextArea from "../../CustomComponent/custom_textarea";
import CustomRadioButton from "../../CustomComponent/custom_radio";
import { useSelector } from "react-redux";
import { Toast } from "../../CustomComponent/toast";

import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import { useNavigate } from "react-router";
import { CustomErrorResponse } from "../../CustomComponent/custom_error_response";

const getLeavesTaken = (obj) => {
  if (obj.leaveType === "half day") {
    return 0.5;
  }
  if (obj.leaveType === "full day") {
    return 1;
  } else {
    const start = new Date(obj.leaveStartDate);
    const end = new Date(obj.leaveEndDate);
    return (end - start) / (1000 * 60 * 60 * 24) + 1;
  }
};

const getQuarter = (date) => {
  const month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

const Addemployeeleaves = () => {
  const employee = useSelector((store) => store.userLogin.user);

  const [leaveCount, setLeaveCount] = useState({ paid: 0, unpaid: 0 });
  const [quarterlyLeaveCount, setQuarterlyLeaveCount] = useState({});
  const [userLeaveData, setUserLeaveData] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [leavesCount, setLeavesCount] = useState({});
  const patchId = employee.UserId;

  const navigate = useNavigate();

  const getEmpInfoAndEmpLeaveInfo = async (empId) => {
    try {
      const res = await performRequest(
        "GET",
        `/leave/getempinfoandempleaveinfo/${empId}`
      );

      if (res.status == 200) {
        setEmployeeDetails(res?.data?.usermodel);
        let sickLeaves = res?.data?.sickLeaveCount;
        let casualLeaves = res?.data?.casualLeaveCount;
        let pendingLeaves = res?.data?.pendingLeaveCount;
        setLeavesCount({ sickLeaves, casualLeaves, pendingLeaves });
      }
    } catch (error) {
      console.log(error);
      CustomErrorResponse(error);
    }
  };

  useEffect(() => {
    if (employee?.UserId) {
      getEmpInfoAndEmpLeaveInfo(employee.UserId);
    }
  }, [employee.UserId]);

  const handleSubmit = async (values, actions) => {
    const takenLeaves = getLeavesTaken(values);
    const leaveQuarter = getQuarter(
      new Date(values.leaveDate || values.leaveStartDate)
    );
    const currentQuarterLeaves =
      (quarterlyLeaveCount[leaveQuarter] || 0) + takenLeaves;

    if (
      currentQuarterLeaves > 4 &&
      employeeDetails?.employeementStatus === "activePosition"
    ) {
      Toast("error", "You have exceeded the quarterly leave limit.");
    }

    let updatedLeaveCount = { ...leaveCount };
    let updatedQuarterlyLeaveCount = {
      ...quarterlyLeaveCount,
      [leaveQuarter]: currentQuarterLeaves,
    };

    if (values.leaveReasonType === "sick") {
      updatedLeaveCount.paid += takenLeaves;
    } else if (values.leaveReasonType === "casual") {
      updatedLeaveCount.unpaid += takenLeaves;
    }

    const leaveData = {
      userId: employee?.UserId,
      ...values,
    };

    try {
      const res = await performRequest(
        "POST",
        "/leave/createleaves/",
        leaveData
      );
      if (res.status === 201) {
        setLeaveCount(updatedLeaveCount);
        setQuarterlyLeaveCount(updatedQuarterlyLeaveCount);
        Toast("success", "Leave created successfully!");
        navigate("/employee-leave-management");
      } else {
        Toast("error", "Error while creating leave");
      }
      // }
    } catch (err) {
      let flag = err?.response?.data?.flag;
      CustomErrorResponse(err, flag);
    }
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h2 className="page-title mb-1">
                    <FcCalendar className="fs-2  me-1" /> Apply Leaves
                  </h2>
                  <h4 className="text-muted">
                    {/* <StepRoutes /> */} Apply leaves.
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="btn-list justify-content-end">
                    <a href={"/employee-leave-management"}>
                      <button className="btn btn-primary">
                        <BsCalendar2XFill className="me-2 fs-2" /> View All
                        Leaves
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Occasion</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          15th August 2024 <br />
                          <span className="text-secondary">
                            Independence Day
                          </span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          7th September 2024
                          <br />
                          <span className="text-secondary">
                            Ganesh Chaturthi
                          </span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          2nd October 2024
                          <br />
                          <span className="text-secondary">
                            Mahatma Gandhi Jayanti
                          </span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          12th October 2024
                          <br />
                          <span className="text-secondary">Dashehra</span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          1st November 2024
                          <br />
                          <span className="text-secondary">
                            Diwali (Laxmi Pujan)
                          </span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          3rd November 2024
                          <br />
                          <span className="text-secondary">Bhaubij</span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          25th December 2024
                          <br />
                          <span className="text-secondary">Christmas</span>
                        </td>
                        <td className="text-secondary">Full Day</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="card">
                <Formik
                  initialValues={EmployeeLeavesInfo}
                  validationSchema={EmployeeLeaveSchema}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body row">
                        <h4>
                          <span className="badge bg-purple-lt me-1">
                            Total leaves:{" "}
                            {employeeDetails?.employeementStatus ===
                            "activePosition"
                              ? 15
                              : 0}
                          </span>
                          <span className="badge bg-indigo-lt me-1">
                            {/* Sick leaves taken: {leaveCount.paid} */}
                            Sick leaves taken: {leavesCount?.sickLeaves ?? 0}
                          </span>
                          <span className="badge bg-green-lt me-1">
                            Casual leaves taken:{" "}
                            {leavesCount?.casualLeaves ?? 0}
                          </span>
                          <span className="badge bg-yellow-lt me-1">
                            Pending leaves:{" "}
                            {employeeDetails?.employeementStatus ===
                            "activePosition"
                              ? Math.max(
                                  15 - (leaveCount.paid + leaveCount.unpaid),
                                  0
                                )
                              : 0}
                          </span>
                        </h4>
                        <hr />
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Select Leave duration
                          </label>
                          <div className="w-100 col">
                            <div>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveType"
                                  value="half day"
                                />
                                <span className="form-check-label">
                                  Half day
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveType"
                                  value="fullDay"
                                />
                                <span className="form-check-label">
                                  Full day
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveType"
                                  value="multipleDays"
                                />
                                <span className="form-check-label">
                                  Multiple days
                                </span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="leaveType"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {props.values.leaveType !== "multipleDays" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label required col-3">
                              Leave Date
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="leaveDate" type="date" />
                              <ErrorMessage
                                name="leaveDate"
                                component="p"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}
                        {props.values.leaveType === "multipleDays" && (
                          <>
                            <div className="mb-3 row">
                              <label className="w-25 form-label required col-3">
                                Start date
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  name="leaveStartDate"
                                  type="date"
                                />
                                <ErrorMessage
                                  name="leaveStartDate"
                                  component="p"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label required col-3">
                                End date
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="leaveEndDate" type="date" />
                                <ErrorMessage
                                  name="leaveEndDate"
                                  component="p"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Leave Type
                          </label>
                          <div className="w-100 col">
                            <CustomTextArea name="leaveReason" type="text" />
                            <ErrorMessage
                              name="leaveReason"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Reason type
                          </label>
                          <div className="w-100 col">
                            <div>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveReasonType"
                                  value="sick"
                                />
                                <span className="form-check-label">Sick</span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveReasonType"
                                  value="casual"
                                />
                                <span className="form-check-label">
                                  Casual / Comp off
                                </span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="leaveReasonType"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Add Leaves" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="card p-3 my-2">
                <p className="m-0 p-0">
                  When you are in probation or in notice period; all leaves will
                  be unpaid.
                </p>
                <p className="m-0 p-0">
                  In a month you can not avail more then 3 comp-off leaves.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Addemployeeleaves;
