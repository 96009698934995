import React, { useState, useEffect } from "react";

const Logintimecounter = ({ loginTime }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const parseTime = (time) => {
      const [timePart, period] = time.split(" ");
      const [hours, minutes, seconds] = timePart.split(":");
      let hrs = parseInt(hours, 10);
      const mins = parseInt(minutes, 10);
      const secs = parseInt(seconds, 10);

      if (period === "PM" && hrs !== 12) {
        hrs += 12;
      } else if (period === "AM" && hrs === 12) {
        hrs = 0;
      }

      const date = new Date();
      date.setHours(hrs, mins, secs, 0);
      return date;
    };

    const loginDate = parseTime(loginTime);

    const now = new Date();
    const initialDiffInSeconds = Math.floor((now - loginDate) / 1000);
    setElapsedTime(initialDiffInSeconds);

    const intervalId = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [loginTime]);

  const formatTime = (seconds) => {
    const absSeconds = Math.abs(seconds);
    const hours = Math.floor(absSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((absSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (absSeconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <span>
      <span
        style={{
          display: "inline-block",
          width: "7px",
          height: "7px",
          borderRadius: "50%",
          backgroundColor: "green",
          animation: "blink 2s infinite",
          marginRight: "4px",
          verticalAlign: "center",
        }}
      ></span>
      <style>
        {`
         @keyframes blink {
         0% { opacity: 1; }
         50% { opacity: 0; }
         100% { opacity: 1; }
        }
      `}
      </style>
      {formatTime(elapsedTime)}
    </span>
  );
};

export default Logintimecounter;
