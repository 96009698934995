import React, { useState, useEffect } from "react";
import { Toast } from "../CustomComponent/toast";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import { useNavigate } from "react-router";
import {
  setCurrentPage,
  setReduxFilters,
} from "../../Redux/Pagination & Filters/allCandidateFiltersReducer";
import Pagination from "../../Components/layouts/pagination";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import SimpleButton from "../../Components/layouts/simple_button";
function countPastDays(dateString) {
  if (dateString === null) {
    return <span className="badge bg-red-lt">Not active</span>;
  }
  const pastDate = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const timeDifference = today - pastDate;
  let daysPast = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysPast <= 0) {
    daysPast = 1;
  }
  if (daysPast === 1) {
    return <span className="badge bg-green-lt">Active Today</span>;
  }
  if (daysPast > 30) {
    return <span className="badge bg-red-lt">Not active</span>;
  }
  return (
    <span className="badge bg-yellow-lt">{`Active ${daysPast} days ago`}</span>
  );
}

// Example usage:

const Allcandidatelist = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const paginationStore = useSelector((store) => store?.allCandidatesFilter);
  const [page, setCurrPage] = useState(paginationStore.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState(paginationStore?.filters);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  // console.log(paginationStore.currentPage);
  useEffect(() => {
    // Dynamic code

    const getjobsandapplicants = () => {
      performRequest(
        "GET",
        getFilteredURL(
          "user/getallcandidate",
          paginationStore.currentPage,
          paginationStore.filters
        )
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data?.data);
            setTotalPages(Math.ceil(res?.data?.totalData / 50));
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    };

    getjobsandapplicants();
  }, [paginationStore?.currentPage, refresh]);

  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore?.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header">
            <h2>All Candidates</h2>
          </div>
          <div className="page-body">
            <div className="card">
              <div className="accordion-body d-md-block">
                <div className="row row deck m-3">
                  <h3>Filter result</h3>{" "}
                  <div className=" col-lg-3 col-12 mb-2">
                    <DynamicFilterInput
                      label="Search name /mobile:"
                      value={filters.nameOrMobile}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          nameOrMobile: e.target.value,
                        });
                        dispatch(
                          setReduxFilters({
                            ...filters,
                            nameOrMobile: e.target.value,
                          })
                        );
                      }}
                      placeholder="search here..."
                    />
                  </div>
                  <div className="col-lg-3 col-12 mb-2">
                    <DynamicFilterSelect
                      label="Sort by skills:"
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          sortByskills: e.target.value,
                        });
                        dispatch(
                          setReduxFilters({
                            ...filters,
                            sortByskills: e.target.value,
                          })
                        );
                      }}
                      value={filters.sortByskills}
                    >
                      <option value="">select all</option>
                      <option value="ASC">Low To High</option>
                      <option value="DESC">High To Low</option>
                    </DynamicFilterSelect>
                  </div>
                  <div className="col-lg-3 col-12 mb-2">
                    <DynamicFilterSelect
                      label="Sort by active date:"
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          sortByActiveDate: e.target.value,
                        });
                        dispatch(
                          setReduxFilters({
                            ...filters,
                            sortByActiveDate: e.target.value,
                          })
                        );
                      }}
                      value={filters.sortByActiveDate}
                    >
                      <option value="">select all</option>
                      <option value="ASC">Low To High</option>
                      <option value="DESC">High To Low</option>
                    </DynamicFilterSelect>
                  </div>
                  <div className="col-lg-3 col-12 mb-2">
                    <div className="row mt-2">
                      <div className="col-6">
                        <SimpleButton
                          onClick={() => {
                            dispatch(setCurrentPage(1));
                            setRefresh(!refresh);
                          }}
                          className="btn btn-primary w-100"
                          buttonText={"Apply Filters"}
                        />
                      </div>
                      <div className="col-6">
                        <SimpleButton
                          onClick={() => {
                            dispatch(
                              setReduxFilters({
                                nameOrMobile: "",
                                sortByskills: "",
                                sortByActiveDate: "",
                              })
                            );
                            setFilters({
                              nameOrMobile: "",
                              sortByskills: "",
                              sortByActiveDate: "",
                            });

                            setRefresh(!refresh);
                          }}
                          className="btn btn-secondary w-100"
                          buttonText={"Reset Filters"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mb-0" />
              </div>
              <div className="table-responsive">
                <table className="table table-vcenter table-mobile-md card-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Contact Details</th>
                      <th>Total Skills & Total Applications</th>
                      <th className="w-1">actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data?.map((el, index) => {
                        return (
                          <tr key={index}>
                            <td data-label="Name">
                              <div className="d-flex py-1 align-items-center">
                                <span className="avatar me-2">
                                  <img
                                    src={
                                      el?.profilePicture ||
                                      el?.resumemodel?.personalDetails?.[0]
                                        ?.profilePicture ||
                                      "https://png.pngtree.com/element_our/20190601/ourmid/pngtree-address-book-icon-image_1347894.jpg"
                                    }
                                    alt=""
                                    srcSet=""
                                  />
                                </span>
                                <div className="flex-fill">
                                  <div className="font-weight-medium">
                                    {el.fullName}
                                  </div>
                                  <div className="fs-5">
                                    {countPastDays(el.lastLogin)}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td data-label="Contact Details">
                              <div>{el.contactNumber}</div>
                              <div className="text-secondary">{el.email}</div>
                            </td>
                            <td data-label="Total Skills & Total Applications">
                              <div>
                                Skills badges:{" "}
                                {el.resumemodel?.skills?.length || 0}
                              </div>
                              <div className="text-secondary">
                                Applications:{" "}
                                {el.candidateapplyandshortlists?.length || 0}
                              </div>
                            </td>

                            <td>
                              <div className="btn-list flex-nowrap">
                                <div className="dropdown">
                                  <button
                                    className="btn dropdown-toggle align-text-top"
                                    data-bs-toggle="dropdown"
                                  >
                                    Actions
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        if (el.resumemodel?.id) {
                                          navigate(
                                            `/candidate-details/${el.resumemodel?.id}`
                                          );
                                        } else {
                                          alert(
                                            "Resume not field by candidate"
                                          );
                                        }
                                      }}
                                    >
                                      View resume
                                    </button>

                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        if (el?.id) {
                                          navigate(`/applied-jobs/${el?.id}`);
                                        } else {
                                          alert("User not found");
                                        }
                                      }}
                                    >
                                      Applied Job
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Allcandidatelist;
