import React, { useEffect, useState } from "react";

export const TestCounter = ({ timeLeft, setTimeLeft }) => {
  useEffect(() => {
    if (timeLeft === 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <div className="text-center w-100">
      <p className="m-0 p-0">TIME REMAINING</p>
      <h2 className="card-title fs-1 m-0 p-0">{formatTime(timeLeft)}</h2>
    </div>
  );
};
