import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const StepRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  return (
    <div>
      <span className="cursor-pointer" onClick={() => navigate("/")}>
        {` home / `}
      </span>
      {pathSegments.map((segment, index) => (
        <span key={index}>
          {index > 0 && " / "}
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/${pathSegments.slice(0, index + 1).join("/")}`)
            }
          >
            {segment.replace(/-/g, " ")}
          </span>
        </span>
      ))}
    </div>
  );
};

export default StepRoutes;
