import * as yup from "yup";

export const ContactUsSchema = yup.object().shape({
  contactAs: yup.string().required("Required"),
  fullName: yup.string().required("Required"),
  email: yup.string().email("Invalid email format").required("Required"),
  message: yup.string().required("Required"),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits")
    .required("Required"),
  contactType: yup.string(),
});
