import React, { useEffect, useRef, useState } from "react";
import UAParser from "ua-parser-js";
import { WebcamCapture } from "./webcam";
import SimpleButton from "../../Components/layouts/simple_button";
import { useDispatch, useSelector } from "react-redux";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import { useNavigate } from "react-router";
import axios from "axios";
// import { userLoginLog } from "../redux/user/loginReducer";
import { userLoginLog } from "../../Redux/User/login_reducer";
import { store } from "../../Redux/store";
import Cookies from "js-cookie";
// import { getUplodedImage } from "../../CustomComponent/upload_Image";
import { CustomErrorResponse } from "../CustomComponent/custom_error_response";

const cookielog = Cookies.get("userlog") || null;
let userDatalog = null;
if (cookielog) {
  userDatalog = JSON.parse(cookielog);
}
function parseUserAgent(userAgentString) {
  const parser = new UAParser();
  const userAgentData = parser.setUA(userAgentString).getResult();

  const browser = userAgentData.browser.name;
  const browserVersion = userAgentData.browser.version;
  const operatingSystem = userAgentData.os.name;
  const renderingEngine = userAgentData.engine.name;
  const compatibility =
    userAgentData.engine.name + "/" + userAgentData.engine.version;

  return {
    browser: browser,
    version: browserVersion,
    operatingSystem: operatingSystem,
    renderingEngine: renderingEngine,
    compatibility: compatibility,
  };
}


const getLoginStatus = (time) => {
  const convertToMinutes = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes, seconds] = time
      .split(":")
      .map((part) => parseInt(part, 10));

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const currentTime = convertToMinutes(time);
  const startTime = convertToMinutes("8:00:00 AM");
  const endTime = convertToMinutes("9:00:00 AM");
  const graceStartTime = convertToMinutes("9:00:00 AM");
  const graceEndTime = convertToMinutes("9:30:00 AM");
  const lateStartTime = convertToMinutes("9:30:00 AM");
  const lateEndTime = convertToMinutes("1:00:00 PM");
  const secondHalfStartTime = convertToMinutes("1:00:00 PM");
  const secondHalfEndTime = convertToMinutes("2:05:00 PM");
  const lateSecondHalfStartTime = convertToMinutes("2:05:00 PM");
  const lateSecondHalfEndTime = convertToMinutes("3:00:00 PM");

  if (currentTime >= startTime && currentTime <= endTime) {
    return "intime";
  } else if (currentTime > graceStartTime && currentTime <= graceEndTime) {
    return "grace period";
  } else if (currentTime > graceEndTime && currentTime <= lateEndTime) {
    return "late";
  } else if (
    currentTime > secondHalfStartTime &&
    currentTime <= secondHalfEndTime
  ) {
    return "2nd half";
  } else if (
    currentTime > lateSecondHalfStartTime &&
    currentTime <= lateSecondHalfEndTime
  ) {
    return "late 2nd half";
  } else {
    return "non-attended";
  }
};

const Attendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((store) => store.userLogin);
  const user = useSelector((store) => store?.userLogin?.user);
  const [userLocation, setUserLocation] = useState(null);
  const [img, setImg] = useState(null);
  const [os, setOS] = useState(navigator.platform);
  const [ip, setIp] = useState("");
  const [checkAttendance, setCheckAttendance] = useState("");
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const [employeeData, setEmployeeData] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState({
    date: "",
    time: "",
  });
  const [imageUrls, setImageUrls] = useState({});
  const getDateTime = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
    });
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    setCurrentDateTime({ date: formattedDate, time: formattedTime });
    return formattedTime;
  };

  const fetchRealTime = () => {
    performRequest("GET", "/attendance/getcurrenttime/")
      .then((res) => {
        if (res.data?.datetime) {
          let date = new Date(res.data?.datetime);
          getDateTime(date);
        }
      })
      .catch((err) => CustomErrorResponse(err));
  };

  useEffect(() => {
    fetchRealTime();
    const interval = setInterval(fetchRealTime, 2 * 60000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (user?.UserId) {
      performRequest("GET", `/user/userbyid/${user?.UserId}`)
        .then((res) => {
          setEmployeeData(res.data);
        })
        .catch((err) => {
          CustomErrorResponse(err);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!checkAttendance && user.UserId) {
      performRequest("GET", `/attendance/getuserattendance/`)
        .then((res) => {
          if (res.data && res.data.msg === "Attendance already taken") {
            Toast("success", `Attendance already taken! for ${user.Name}`);
            dispatch(
              userLoginLog({
                ...store?.userlog,
                userId: user?.UserId,
                Role: user?.Role,
                firstLogin: true,
                isEmployee:user?.isEmployee
              })
            );
            navigate("/");
          } else {
            setCheckAttendance("Not Attended");
          }
        })
        .catch((err) => {
          // console.error(err);
          CustomErrorResponse(err);
        });
    }
  }, [user, checkAttendance]);


  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setUserLocation({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        const ip = data.ip;
        setIp(ip);
      });
  }, []);

  const downloadImage = async (image) => {
    try {
      const response = await axios.get(image, { responseType: "blob" });
      return response.data;
    } catch (error) {
      console.error("Error downloading the image:", error);
      return null;
    }
  };

  const uploadImage = async (image) => {
    const file0 = await downloadImage(image);
    setImageUrls(image);
    // Function to convert Blob to File
    function blobToFile(blob, filename) {
      return new File([blob], filename, {
        type: blob.type,
        lastModified: Date.now(),
      });
    }

    // Function to generate a unique filename
    function generateUniqueFilename(baseName, extension) {
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(2, 15);
      return `${baseName}_${timestamp}_${randomString}.${extension}`;
    }
    // Convert the Blob to a File with a unique name
    const uniqueFilename = generateUniqueFilename("example", "jpg");
    const file = blobToFile(file0, uniqueFilename);
    setImg(file);
  };

  const handelSubmit = () => {
    let data = {
      photo: img,
      time: currentDateTime.time,
      date: currentDateTime.date,
      operatingSystem: os,
      firstLogin: "true",
      ipAddress: ip,
      loginType: "login",
      browser: parseUserAgent(userAgent).browser,
      userLocation: userLocation, // This is an object
      userId: user.UserId,
      userName: user.Name,
      loginStatus: getLoginStatus(currentDateTime.time),
    };

    const formData = new FormData();
    for (let key in data) {
      if (key === "userLocation") {
        // Assuming userLocation is an object with fields like lat and lng
        for (let key2 in data[key]) {
          // Append each field in userLocation (like latitude, longitude, etc.)
          formData.append(`userLocation[${key2}]`, data[key][key2]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    performRequest("POST", `/attendance/createAttendance/`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        if (res.data.msg && res.data.msg === "Attendance already taken") {
          Toast("success", `Your Attendance has already been taken!`);

          dispatch(
            userLoginLog({
              ...store?.userlog,
              userId: user?.UserId,
              Role: user?.Role,
              firstLogin: true,
              isEmployee:user?.isEmployee
            })
          );
          navigate("/");
        } else {
          dispatch(
            userLoginLog({
              userId: store?.user?.UserId,
              Role: store?.user?.Role,
              firstLogin: true,
              loginTime: new Date().getTime(),
              isEmployee:user?.isEmployee
            })
          );
          Toast("success", `Your Attendance has been created!`);
          navigate("/");
        }
      })
      .catch((err) => {
        CustomErrorResponse(err);
      });
  };

  const getDisabledStatus = () => {
    if (img) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!store?.isLoggedIn) {
      navigate("/account-login");
    } else if (store.isLoggedIn && store.userlog && store.userlog.firstLogin) {
      navigate("/");
    }
  }, [store]);

  const handleLogout = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = "/account-login";
  };

  return (
    <div className="page page-center">
      <style>
        {` .otp-input {
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: 18px;
            margin: 5px;
            border: 1px solid #ccc;
            border-radius: 5px;
            display: inline-block;
            box-sizing: border-box;
          }
          `}
      </style>
      <div className="container py-4 ">
        <div className="card">
          <h2 className="h2 text-center mt-4">Attendance Dashboard</h2>
          <div className="card-body">
            <div className="container-tight">
              {img ? (
                <div className="text-center">
                  <div className=" text-center">
                    <img src={imageUrls} alt={"User Img"} />
                  </div>
                  <SimpleButton
                    onClick={() => setImg(null)}
                    buttonText={img ? "Retake" : "Capture photo"}
                  />
                </div>
              ) : (
                <WebcamCapture img={img} uploadImage={uploadImage} />
              )}

              
              <div>
                <h3>
                  LoggedIn Date & Time:{" "}
                  <span>
                    {currentDateTime.date} , {currentDateTime.time}
                  </span>
                </h3>
              </div>
              <div>
                <h3>
                  Ip Address & Operating System:{" "}
                  <span>
                    {ip} , {os}
                  </span>
                </h3>
              </div>
            </div>
            <div className="text-center">
              <button className="btn btn-primary me-2" onClick={handleLogout}>
                Logout
              </button>
              <button
                disabled={getDisabledStatus()}
                onClick={handelSubmit}
                className="btn btn-primary initialism"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
