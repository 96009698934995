import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaArrowAltCircleRight } from "react-icons/fa";
const ItemTypes = {
  ITEM: "item",
};

const DraggableItem = ({ item, index, setInterviewScheduleId,setInterviewSchedule }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      className="card"
      ref={drag}
      style={{
        backgroundColor: isDragging ? "lightgreen" : "",
        cursor: "move",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {index < 3 ? (
        <div className="card bg-primary-lt">
          <div className="card-body">
            <div className="text-center">
              <img
                style={{
                  borderRadius: "15%",
                }}
                width={"130px"}
                src={
                  item?.usermodel?.profilePicture
                    ? item?.usermodel?.profilePicture
                    : "https://in.ecscribe.com/assets/images/userlogo.png"
                }
                alt={item?.usermodel?.resumemodel?.personalDetails?.[0]?.name}
              />
            </div>
            <div>
              <hr className="p-0 my-2" />
              <h3 className="card-title m-0 p-0">
                Name: {item?.usermodel?.fullName}
              </h3>
              <hr className="p-0 my-2" />
              <h3 className="card-title m-0 p-0">
                Address:{" "}
                <span>
                  {item?.usermodel?.resumemodel?.personalDetails?.[0]?.city},{" "}
                  {item?.usermodel?.resumemodel?.personalDetails?.[0]?.state},{" "}
                  {item?.usermodel?.resumemodel?.personalDetails?.[0]?.country}
                </span>
              </h3>
              <hr className="p-0 my-2" />
              <h3 className="card-title m-0 p-0">
                Education:{" "}
                {item?.usermodel?.resumemodel?.education?.map((ele, idx) => (
                  <span key={idx}>{ele.educationSelect1}</span>
                ))}
              </h3>
              <hr className="p-0 my-2" />
              <h3 className="card-title m-0 p-0">
                Skills:{" "}
                {item?.usermodel?.resumemodel?.skills?.map((ele, idx) => (
                  <span key={idx}>
                    {idx > 0 && ", "} {ele.skill}
                  </span>
                ))}
              </h3>
              <hr className="p-0 my-2" />
              <h3 className="card-title m-0 p-0">
                Languages:{" "}
                {item?.usermodel?.resumemodel?.languages?.map((ele, idx) => (
                  <span key={idx}>
                    {ele.readLanguage.join(", ")},{" "}
                    {ele.languageSpeak.join(", ")},{" "}
                    {ele.languageWrite.join(", ")}
                  </span>
                ))}
              </h3>
              <hr className="p-0 my-2" />
              <h3 className="card-title m-0 p-0">
                Other Factors:{" "}
                {item?.usermodel?.resumemodel?.additionalInformation?.map(
                  (ele, idx) => (
                    <span key={idx}>
                      {idx > 0 && ", "} {ele}
                    </span>
                  )
                )}
              </h3>
              <hr className="p-0 my-2" />
            </div>

            <div className="text-center">
              {item?.interviewSchedule &&
              item.interviewSchedule?.date &&
              item.hireStatus !== "schedule cancelled" ? (
                <button
                  onClick={()=>{ setInterviewSchedule(item.interviewSchedule) ; setInterviewScheduleId(item.id)}}
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-edit"
                  className="btn btn-teal"
                >
                  <FaArrowAltCircleRight fontSize={"1em"} className="me-1" />{" "}
                 Edit Schedule
                </button>
              ) : (
                <button
                  onClick={() => setInterviewScheduleId(item.id)}
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-team"
                  className="btn btn-primary"
                >
                  <FaArrowAltCircleRight fontSize={"1em"} className="me-1 " />{" "}
                  Schedule Interview
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="card bg-green-lt text-center">
          <div className="text-center m-1">
            <img
              style={{
                borderRadius: "15%",
              }}
              width={"120px"}
              src={
                item?.usermodel?.profilePicture
                  ? item?.usermodel?.profilePicture
                  : "https://in.ecscribe.com/assets/images/userlogo.png"
              }
              alt={item?.usermodel?.resumemodel?.personalDetails?.[0]?.name}
            />
          </div>{" "}
          <h1 className="p-0 m-0 fs-3">
            {item?.usermodel?.resumemodel?.personalDetails?.[0]?.name}
          </h1>
          <p className="p-0 m-0">
            {item?.usermodel?.resumemodel?.personalDetails?.map(
              (ele, index) => {
                return (
                  <span key={index}>
                    {ele.city}, {ele.state}, {ele.country}
                    {index > 0 && ", "}
                  </span>
                );
              }
            )}
          </p>
          <p>
            {item?.usermodel?.resumemodel?.skills?.map((ele, idx) => {
              return (
                <span key={idx}>
                  {idx > 0 && ", "} {ele.skill}
                </span>
              );
            })}
          </p>
        </div>
      )}
    </div>
  );
};

const DropTarget = ({ item, index, onDropItem, setInterviewScheduleId,setInterviewSchedule }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop: (draggedItem) => onDropItem(draggedItem.index, index),
  });

  return (
    <div
      className={
        index < 3
          ? "col-lg-4 col-sm-12 col-md-12"
          : "col-lg-3 col-md-6 col-sm-12"
      }
      ref={drop}
    >
      <DraggableItem
        item={item}
        index={index}
        setInterviewScheduleId={setInterviewScheduleId}
        setInterviewSchedule = {setInterviewSchedule}
      />
    </div>
  );
};

const YourDropComponent = ({ data = [], setInterviewScheduleId,setInterviewSchedule }) => {
  const [items, setItems] = useState(data);

  const handleDropItem = (draggedIndex, targetIndex) => {
    const newItems = [...items];
    [newItems[draggedIndex], newItems[targetIndex]] = [
      newItems[targetIndex],
      newItems[draggedIndex],
    ];

    setItems(newItems);
  };

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  return (
    <div className="row row-deck row-cards m-1">
      {items.map((item, index) => (
        <DropTarget
          setInterviewScheduleId={setInterviewScheduleId}
          setInterviewSchedule = {setInterviewSchedule}
          key={index}
          item={item}
          index={index}
          onDropItem={handleDropItem}
        />
      ))}
    </div>
  );
};

const ProfileCompare = ({ data, setInterviewScheduleId,setInterviewSchedule  }) => (
  <DndProvider backend={HTML5Backend}>
    <YourDropComponent
      data={data}
      setInterviewScheduleId={setInterviewScheduleId}
      setInterviewSchedule = {setInterviewSchedule}
    />
  </DndProvider>
);

export default ProfileCompare;
