import LoadingPage from "../../../Components/layouts/loading";
import { useState } from "react";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
// import EmployeeDetails from "./Payslips Component/employe_details";
import EmployeeDetails from "./Payslips Component/employee_details"
import SalaryBreakDown from "./Payslips Component/salary_breakdown";
import { useLocation } from "react-router-dom";
import PayslipTop from "./Payslips Component/payslip_top";

const Previewpayslips = (myProp) => {
  const [loading, setLoading] = useState(false);
  const printPDF = () => {
    let name = "salarySlip";
    document.title = name;

    // Apply CSS for print
    const styleSheet = `
      @media print {
        body {
          -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
          color-adjust: exact !important;  /* Firefox */
        }
      }
    `;

    const styleElement = document.createElement("style");
    styleElement.innerHTML = styleSheet;
    document.head.appendChild(styleElement);

    window.print();
  };
  const location = useLocation();
  const attendanceData = location.state?.myProp;

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div
            className="page-header d-print-none"
            style={{ borderBottom: "1px solid #181818", marginBottom: "20px" }}
          >
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">Employee Salary Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <SimpleButton buttonText={"Save Pdf"} onClick={printPDF} />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ color: "black" }}>
            <PayslipTop />
            <hr style={{ marginTop: "0px" }} />
            {attendanceData && (
              <EmployeeDetails attendanceData={attendanceData} />
            )}
            <hr />
            {attendanceData && (
              <SalaryBreakDown attendanceData={attendanceData} />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Previewpayslips;
