import { createSlice } from "@reduxjs/toolkit";
export const jobsListsFilterSlice = createSlice({
  name: "jobsListsPaginationFilter",
  initialState: {
    currentPage: 1,
    filters: {
      department: "",
      sortByPostedDate: "",
      sortByskills: "",
      jobrole: "",
      sortByActiveJobs: "",
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReduxFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setCurrentPage, setReduxFilters } = jobsListsFilterSlice.actions;
export default jobsListsFilterSlice.reducer;
