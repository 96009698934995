import React from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
const tableHead = [
  "Sr.",
  "Employee name",
  "contact number",
  "Role In Organisation",
  "Reporting To",
  "status",
  "Actions",
];
const getStatus = (status) => {
  if (status === "leftOrganization") {
    return <span class="badge bg-red text-white">Left Organization</span>;
  }
  if (status === "onProbation") {
    return <span class="badge bg-yellow text-white">On Probation</span>;
  }
  if (status === "activePostion") {
    return <span class="badge bg-green text-white">Active Postion</span>;
  }
};
const AllEmployeeTable = ({ employees = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const getName = (fname = "", lname = "") => {
    let bag = "";
    if (fname) {
      bag += fname;
    }
    if (lname) {
      bag += ` ${lname}`;
    }
    if (!bag) {
      bag = "N/A";
    }
    return bag;
  };
  const goToView = (id) => {
    navigate(`/employee-management/view-employee/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {employees?.map((employee, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={employee?.id}>
                <td>{Index}</td>
                <td>{getName(employee.firstName, employee.lastName)}</td>
                <td>{employee?.contactNumber || "N/A"}</td>
                <td>{employee?.roleInOrganisation || "N/A"}</td>
                <td>{employee?.reportingTo || "N/A"}</td>

                {/* <td>{extractDate(employee?.createdAt) || "N/A"}</td> */}
                <td>{getStatus(employee?.employeeStatus) || "N/A"}</td>

                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => goToView(employee.id)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
  
};

export default AllEmployeeTable;
